import React from 'react';
import styled from 'styled-components';

import ChevronDown from '../../images/chevron_down.svg';
import { Typography } from '..';

const Select = (
  {
    children,
    disabled,
    isError,
    name,
    onBlur,
    onChange,
    placeholder,
    styleOption,
    value = '',
  },
  ref
) => {
  return (
    <>
      <Container
        ref={ref}
        $isError={isError}
        disabled={disabled}
        name={name}
        styleOption={styleOption}
        value={value}
        onBlur={onBlur}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        <Placeholder value="" disabled>
          {placeholder}
        </Placeholder>
        {children}
      </Container>
      {isError && (
        <Typography as="p" color="red2" fontSize="12px">
          {isError?.message}
        </Typography>
      )}
    </>
  );
};

const Placeholder = styled.option`
  color: ${({ theme }) => theme.gray300};
`;

const Container = styled.select`
  background-color: ${({ styleOption, theme }) =>
    styleOption ? styleOption[0] : theme.gray10};
  border: ${({ $isError, styleOption, theme }) =>
    styleOption
      ? styleOption[1]
      : `2px solid ${$isError ? theme.red2 : 'transparent'}`};
  appearance: ${({ styleOption }) => styleOption && 'none'};
  -webkit-appearance: ${({ styleOption }) => styleOption && 'none'};
  -moz-appearance: ${({ styleOption }) => styleOption && 'none'};
  background-image: ${({ styleOption }) =>
    styleOption && `url(${ChevronDown})`};
  background-repeat: ${({ styleOption }) => styleOption && 'no-repeat'};
  background-position: ${({ styleOption }) => styleOption && '96%'};
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 12px;
  height: 48px;
  width: 100%;
  &:disabled {
    color: ${({ theme }) => theme.gray600};
    opacity: 1;
    appearance: none;
  }
`;

export default React.forwardRef(Select);

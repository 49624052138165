/* eslint-disable no-nested-ternary */
import '../style.css';

import { BoardTag } from 'components/common';
import { tagOf } from 'components/common/codes';
import Icon from 'components/Icons/v2/Icon';
import { Image } from 'components/views';
import counter from 'functions/counter';
import React from 'react';
import LazyLoad from 'react-lazyload';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ratingFormatter } from 'utils';

import { business_status } from './_support';

const thumbnailTags = ['yesterday-price', 'today-price'];

const RegularView = React.memo(({ item }) => {
  const stateFilter = item.tags.filter((i) => thumbnailTags.includes(i));
  const tagFilter = item.tags.filter((i) => !thumbnailTags.includes(i));
  const location = useLocation();
  const pathname = location.pathname.slice(1) || '홈';

  const handleClickCard = () => {
    sessionStorage.setItem(`${pathname}-scrollY`, window.scrollY);
  };

  const sortTags = () => {
    const result = [];
    tagFilter.forEach((i) => {
      if (i === 'sale-coupon') {
        result.unshift('sale-coupon');
      }
      if (i === 'app-order') {
        result.push('app-order');
      }
    });
    return result;
  };

  const pageTitle = () => {
    if (pathname.includes('/')) {
      return pathname.split('/')[1] === '내관심정보'
        ? '즐겨찾기'
        : pathname.split('/')[1];
    }
    return pathname;
  };

  return (
    <Container>
      <Link to={{ pathname: item.uri, state: pageTitle() }}>
        <StoreBox key={item.id} onClick={handleClickCard}>
          <StoreImageContainer>
            <LazyLoad height={100} offset={100}>
              <StyledImage height={100} src={item.thumbnail} width={100} />
            </LazyLoad>
            {item.state !== 'OPEN' && (
              <>
                <StateImageDim />
                <StateImageDimTypo>
                  {business_status(item.state)}
                </StateImageDimTypo>
              </>
            )}
          </StoreImageContainer>

          <DescriptionContainer>
            <TitleBox>
              <Title>{item.label}</Title>
              {item.distance && (
                <DistanceSpan>{item.distance.toFixed(1)} km</DistanceSpan>
              )}
            </TitleBox>

            <LocationOrderStateBox>
              <LocationSpan>{item.market}</LocationSpan>
              <BoardTag tags={sortTags()} />
            </LocationOrderStateBox>

            <InfoRatingBox>
              <InfoSpan>{item.description}</InfoSpan>
              <RatingBox>
                <RatingTypoBox>
                  <Icon color="#FFCC33" name="star_filled-24" size={24} />
                  <RatingNumberSpan>
                    {ratingFormatter(item.summary.rating)}
                  </RatingNumberSpan>
                </RatingTypoBox>
                <RatingCountSpan>
                  ({counter(item.summary.comments)})
                </RatingCountSpan>
              </RatingBox>
            </InfoRatingBox>
          </DescriptionContainer>
          <PriceStateSpan isTodayPrice={stateFilter[0] === 'today-price'}>
            {tagOf(stateFilter[0])}
          </PriceStateSpan>
        </StoreBox>
      </Link>
    </Container>
  );
});

const PriceStateSpan = styled.span`
  color: ${({ isTodayPrice }) =>
    isTodayPrice
      ? 'var(--new-blue-blue-9, #2F80ED)'
      : 'var(--new-gray-gray-6, #8A8D90)'};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  white-space: nowrap;
  position: absolute;
  right: 0;
  bottom: 16px;
`;

const RatingCountSpan = styled.div`
  color: var(--Gray-gray-6, #8a8d90);
  font-family: 'Noto Sans KR';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3px;
`;

const RatingNumberSpan = styled.div`
  color: var(--Gray-gray-12, #1a1b1e);
  font-family: 'Noto Sans KR';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.3px;
`;

const RatingTypoBox = styled.div`
  display: flex;
  align-items: center;
`;

const RatingBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const InfoSpan = styled.span`
  overflow: hidden;
  color: var(--new-gray-gray-6, #8a8d90);
  text-overflow: ellipsis;
  font-size: 14px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const InfoRatingBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

const LocationSpan = styled.span`
  color: var(--new-gray-gray-11, #26282b);
  font-size: 14px;
  letter-spacing: -0.3px;
`;

const LocationOrderStateBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DistanceSpan = styled.div`
  min-width: fit-content;
  font-size: 12px;
  color: #2f80ed;
  font-weight: 500;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  color: #26282b;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  height: 100%;
`;

const StateImageDimTypo = styled.div`
  color: white;
  z-index: 2;
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.3px;
  white-space: nowrap;
`;

const StateImageDim = styled.div`
  position: absolute;
  top: 0;
  width: 102px;
  height: 102px;
  background-color: #000000;
  opacity: 0.5;
  z-index: 1;
  border-radius: 8px;
`;

const StyledImage = styled(Image)`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  border: 1px solid #f7f8f9;
`;

const StoreImageContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 8px;
`;

const StoreBox = styled.div`
  border-bottom: 1px solid #f3f3f3;
  padding: 16px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  &:last-child {
    border-bottom: none;
  }
`;

export default RegularView;

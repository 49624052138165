import React from 'react';
import { withRouter } from 'react-router-dom';

import { CounterView, Button } from 'components/views';
import './style.css';

let HeadTemplate = ({ children }) => {
  return (
    <div className="head">
      <div className="section content-information">
        <div className="inner-section type1 use-fixed">
          <div className="head-line-area">{children}</div>
        </div>
        <div className="inner-section type2" />
      </div>
    </div>
  );
};

let HeadWithCloseButton = ({ label, onClose, style }) => {
  return (
    <HeadTemplate>
      <span style={style} className="name txt-ellipsis">
        {label}
      </span>
      <Button.Close onClose={onClose} />
    </HeadTemplate>
  );
};

let HeadWithBackButton = ({ label, views, history, session }) => {
  return (
    <HeadTemplate>
      <Button.Back session={session} />
      <span className="name txt-ellipsis" style={{ display: 'block', flex: 1 }}>
        {label}
      </span>
      <span
        className="view"
        style={{ position: 'static', marginRight: '15px', lineHeight: 'unset' }}
      >
        <CounterView number={views} />
      </span>
    </HeadTemplate>
  );
};

export default withRouter(HeadWithBackButton);
export { HeadWithCloseButton };

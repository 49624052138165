const SET_ORDER = 'orderSuccess/SET_ORDER';
const SET_MESSAGE = 'orderSuccess/SET_MESSAGE';

export const setOrderSuccess = (payload) => {
  return { type: SET_ORDER, ...payload };
};

export const setOrderMessage = (message) => {
  return { type: SET_MESSAGE, message };
};

const initialState = {
  permalink: '',
  message: '',
  init: false,
};

const orderSucess = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER:
      return { ...state, ...action };
    case SET_MESSAGE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export default orderSucess;

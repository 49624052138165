import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReservationDate } from 'api/checkout';
import client from 'api/client';
import { checkDelivery } from 'api/order';
import { conf } from 'conf/settings';

export const fetchDeliveryAvailable = createAsyncThunk(
  'checkout/dilveryAvailable',
  async (address, { rejectWithValue }) => {
    try {
      const { data } = await checkDelivery(address);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchAddresses = createAsyncThunk(
  'checkout/fetchAddresses',
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await client.get(
        `${conf.USER_API_URL}/v1/users/${userId}/delivery-addresses`
      );
      return data.filter((address) => !address.deleted);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchDeliveryTypes = createAsyncThunk(
  'checkout/fetchDeliveryTypes',
  async (storeId) => {
    return await getReservationDate({
      orderType: 'QUICK',
      storeCode: storeId,
    }).then(({ data }) => {
      const {
        availableDays = [],
        immediateDelivery = true,
        reservationDelivery = true,
      } = data;
      const convertedDates = availableDays.map((cur) => {
        return {
          endDate: cur.endDate,
          type: cur.today ? 'today' : 'preorder',
          value: cur.day,
        };
      });
      const deliveryTypes = {
        convertedDates,
        immediateDelivery,
        reservationDelivery,
      };
      return deliveryTypes;
    });
  }
);

export const fetchImmediateDeliveryTime = createAsyncThunk(
  'checkout/fetchImmediateDeliveryTime',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await client.get(
        '/www/order-service/orders/immediate-delivery-times',
        {
          params,
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchDeliveryTimes = createAsyncThunk(
  'checkout/fetchDeliveryTimes',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await client.get(
        '/www/order-service/orders/delivery-arrival-times',
        {
          params,
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchShippingFee = createAsyncThunk(
  'checkout/fetchShippingFee',
  async (
    {
      deliveryTime,
      deliveryType,
      menuPrice,
      pickupTime,
      selectedAddressId,
      selectedDate,
      storeId,
      userId,
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await client.get(
        '/www/order-service/orders/delivery-fee',
        {
          params: {
            deliveryTime,
            deliveryType,
            memberCode: userId,
            memberDeliveryAddressId: selectedAddressId,
            menuPrice,
            pickupTime: `${selectedDate}T${pickupTime}`,
            storeCode: storeId,
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

import { TransparentButton } from 'components/atoms/Button';
import { Normal } from 'components/modules/Modal';
import { memo } from 'react';
import styled from 'styled-components';

const Footer = memo(
  ({ cancelText = '취소', onClose, onSuccess, successText = '확인' }) => {
    return (
      <FooterWrapper>
        <StyledButton onClick={onClose}>{cancelText}</StyledButton>
        <StyledButton
          color="primary"
          onClick={() => {
            onSuccess();
            onClose();
          }}
        >
          {successText}
        </StyledButton>
      </FooterWrapper>
    );
  }
);

const ConfirmModal = ({
  cancelText,
  children,
  footer,
  onClose,
  onSuccess,
  successText,
  visible,
}) => {
  return (
    <Normal
      footer={
        footer || (
          <Footer
            cancelText={cancelText}
            successText={successText}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )
      }
      visible={visible}
      onClose={onClose}
    >
      {children}
    </Normal>
  );
};

const FooterWrapper = styled.footer`
  border-top: 1px solid #f3f3f3;
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(TransparentButton)`
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#2F80ED';
      default:
        return '#26282B';
    }
  }};

  & + & {
    border-left: 1px solid #f3f3f3;
  }
`;

export default ConfirmModal;

import { atom } from 'recoil';
import { v1 } from 'uuid';

export const globalSearchKeyword = atom({
  default: '',
  key: `globalSearchKeyword/${v1()}`,
});

export const eventSearchKeyword = atom({
  default: '',
  key: `eventSearchKeyword/${v1()}`,
});

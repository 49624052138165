import * as api from 'api/price';
import * as filter_api from 'api/search-filters';
import { handleActions } from 'redux-actions';

import createApiRequest from './_support';

const FILTER = 'pirates-data.price/FILTER';
const RECOMMENDS = 'pirates-data.price/RECOMMENDS';
const PRICES = 'pirates-data.price/PRICES';
const PRICES_MORE = `${PRICES}.MORE`;
const PRICES_SHOW_MORE = `${PRICES}.SHOW_MORE`;
const SAVE_QUERY = 'pirates-data.price/SAVE_QUERY';
const SAVE_ITEM = 'pirates-data.price/SAVE_ITEM';
const LOAD_DATA = 'pirates-data.price/LOAD_DATA';

const load_filters = async () => {
  const _filters = () =>
    Promise.all([
      filter_api.load_store_regions(),
      filter_api.load_item_codes(['recommend']),
    ]);
  const [regions, items] = await _filters();

  return {
    data: {
      items: items.data,
      regions: regions.data,
    },
  };
};

const [get_filters, FILTER_OK, FILTER_FAIL] = createApiRequest(
  FILTER,
  load_filters
);
const [get_recommend_prices, RECOMMENDS_OK, RECOMMENDS_FAIL] = createApiRequest(
  RECOMMENDS,
  api.get_recommend_price
);
const [get_prices, PRICES_OK, PRICES_FAIL] = createApiRequest(
  PRICES,
  api.get_prices
);
const [get_prices_more, PRICES_MORE_OK, PRICES_MORE_FAIL] = createApiRequest(
  PRICES_MORE,
  api.get_prices
);
const [get_prices_show_more, PRICES_SHOW_MORE_OK, PRICES_SHOW_MORE_FAIL] =
  createApiRequest(PRICES_SHOW_MORE, api.get_prices_show_more);
const [save_query, SAVE_QUERY_OK, SAVE_QUERY_FAIL] = createApiRequest(
  SAVE_QUERY,
  (query) => ({ data: query })
);
const [save_item, SAVE_ITEM_OK, SAVE_ITEM_FAIL] = createApiRequest(
  SAVE_ITEM,
  (item) => ({ data: item })
);
const [load_data, LOAD_DATA_OK, LOAD_DATA_FAIL] = createApiRequest(
  LOAD_DATA,
  (data) => ({ data })
);

const state = {
  filter: {},
  item: null,
  next: {},
  prices: [],
  query: {
    item: { code: '0000000000', label: '모든 품목' },
    query: false,
    region: { code: 'all', label: '모든 지역' },
  },
  recommends: [],
};

const merge_more_list = (prev, next) => {
  const [last_item] = prev.slice(-1);
  const next_item = next[0];

  if (last_item?.code === next_item?.code) {
    const merged = {
      ...last_item,
      prices: [...last_item.prices, ...next_item.prices],
    };
    return [...prev.slice(0, -1), merged, ...next.slice(1)];
  }
  return [...prev, ...next];
};

const price = handleActions(
  {
    [FILTER]: (state) => ({ ...state }),
    [FILTER_OK]: (state, action) => ({
      ...state,
      filter: {
        ...action.payload,
        items: action.payload.items.filter(
          (item) => item.code !== '0000000066'
        ),
      },
    }),

    [LOAD_DATA]: (state) => ({ ...state }),
    [LOAD_DATA_OK]: (state, action) => ({
      ...action.payload,
    }),

    [PRICES]: (state) => ({ ...state }),
    [PRICES_MORE]: (state) => ({ ...state }),

    [PRICES_MORE_OK]: (state, action) => ({
      ...state,
      next: {
        page: action?.payload.nextPageNo,
      },
      prices: merge_more_list(state?.prices, action?.payload.list),
    }),
    [PRICES_OK]: (state, action) => ({
      ...state,
      next: {
        page: action.payload.nextPageNo,
      },
      prices: action.payload.list || [],
    }),

    [PRICES_SHOW_MORE]: (state) => ({ ...state }),
    [PRICES_SHOW_MORE_OK]: (state, action) => {
      const inner_id = action.payload.id;
      const outer_id = action.payload.id.slice(2, 12);
      return {
        ...state,
        prices: [
          ...state.prices.map((group) => {
            if (group.code === outer_id) {
              return {
                ...group,
                prices: group.prices.map((price_group) => {
                  if (price_group.id.price === inner_id) {
                    return {
                      ...price_group,
                      stores: [...price_group.stores, ...action.payload.stores],
                    };
                  }
                  return price_group;
                }),
              };
            }
            return group;
          }),
        ],
      };
    },

    [RECOMMENDS]: (state) => ({ ...state }),
    [RECOMMENDS_OK]: (state, action) => ({
      ...state,
      recommends: action.payload,
    }),

    [SAVE_ITEM]: (state) => ({ ...state }),
    [SAVE_ITEM_OK]: (state, action) => ({
      ...state,
      item: action.payload,
    }),

    [SAVE_QUERY]: (state) => ({ ...state }),
    [SAVE_QUERY_OK]: (state, action) => ({
      ...state,
      query: action.payload,
    }),
  },
  state
);

const Types = {
  PRICES,
  PRICES_MORE,
  RECOMMENDS,
};

export {
  get_filters,
  get_prices,
  get_prices_more,
  get_prices_show_more,
  get_recommend_prices,
  load_data,
  save_item,
  save_query,
  Types,
};

export default price;
export { merge_more_list };

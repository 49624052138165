import profileClient from 'api/profileClient';
import { useEffect, useState } from 'react';

const useProfile = (onLoad) => {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    Promise.all([profileClient.get('/account/profile')]).then((res) => {
      const [profileRes, phoneRes] = res;

      const {
        data: {
          name,
          shopping: { address, addressDetail, phone },
        },
      } = profileRes;

      const profile = {
        address: address || '',
        addressDetail: addressDetail || '',
        isValid: phoneRes.data.result === 'success',
        name: name || '',
        phoneNumber: phone || '',
      };

      setProfile(profile);

      if (typeof onLoad === 'function') {
        onLoad(profile);
      }
    });
  }, [onLoad]);

  return { ...profile, setProfile };
};

export default useProfile;

import { useQuery } from '@tanstack/react-query';
import { getStorePhone } from 'api/order';

import cartKeys from './key';

export default function useGetStorePhone(storeCode) {
  return useQuery(
    cartKeys.getStorePhone(storeCode),
    () => getStorePhone(storeCode),
    { enabled: !!storeCode }
  );
}

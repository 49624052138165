import {
  Container,
  SectionInnerText,
  SectionInnerTitle,
  SectionTitle,
} from 'components/mypage/myOrder/orderHistoryDetail';
import styled from 'styled-components';
import { phoneParser } from 'utils';

const Purchaser = ({ name, phone }) => {
  return (
    <Container>
      <SectionTitle>구매자</SectionTitle>
      <Box>
        <TypoBox>
          <SectionInnerTitle>구매자명</SectionInnerTitle>
          <SectionInnerText>{name}</SectionInnerText>
        </TypoBox>
        <TypoBox>
          <SectionInnerTitle>연락처</SectionInnerTitle>
          <SectionInnerText>{phoneParser(phone)}</SectionInnerText>
        </TypoBox>
      </Box>
    </Container>
  );
};

const TypoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export default Purchaser;

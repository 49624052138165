import './style.css';

import { moneyFormatter } from 'utils/Formatter';

const TicketItem = ({ item }) => {
  return (
    <div className="card-template type4">
      <div className="fixed inner-section type1">
        <span className="subject type2">{`${item.name} `}</span>
        <span className="description type2">{item.grade}</span>
        <br />
        <span className="price">
          {moneyFormatter(item.price)} 원/{item.measure} ~
        </span>
      </div>
    </div>
  );
};

export default TicketItem;

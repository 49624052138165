import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const LoginTopBar = () => {
  const history = useHistory();

  return (
    <LoginTopBarStyle>
      <figure
        onClick={() => {
          history.push('/');
        }}
      >
        <img src="img/logo_ic.svg" alt="" />
      </figure>
      <div className="alarm-bell">
        <i className="far fa-bell"></i>
      </div>
    </LoginTopBarStyle>
  );
};

const LoginTopBarStyle = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  min-width: 360px;
  max-width: 420px;

  width: 100%;
  height: 56px;
  padding: 14px 16px;

  overflow: hidden;
  z-index: 9999;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  & > figure {
    float: left;
    height: 100%;
    cursor: pointer;
    & > img {
      height: 100%;
      object-fit: contain;
    }
  }
  & > .alarm-bell {
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    & > i {
      font-size: 24px;
    }
  }
  & + div {
    top: 56px;
    background: #fff;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
`;

export default LoginTopBar;

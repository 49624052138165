export const getOrderCancelStatus = (orderType, status) => {
  if (orderType === 'CONVENIENCE_STORE') {
    switch (status) {
      // 결제 완료
      case 'REQUEST':
        return true;
    }
  }

  if (orderType === 'DAY_DELIVERY') {
    switch (status) {
      // 결제 완료
      case 'APPROVAL_PAYMENT':
        return true;
    }
  }

  if (orderType === 'QUICK') {
    switch (status) {
      // 주문 접수 중
      case 'REQUEST':
        return true;
    }
  }

  if (orderType === 'PACKAGE') {
    switch (status) {
      // 주문 접수 중
      case 'REQUEST':
        return true;
    }
  }

  return false;
};

export const getWriteReviewStatus = (orderType, status) => {
  if (orderType === 'CONVENIENCE_STORE') {
    switch (status) {
      // 수령 확인
      case 'PURCHASE_CONFIRMATION':
        return true;
    }
  }

  if (orderType === 'DAY_DELIVERY') {
    switch (status) {
      // 배송 완료
      case 'SENT':
        return true;
    }
  }

  if (orderType === 'QUICK') {
    switch (status) {
      case 'SENT':
        return true;
    }
  }

  if (orderType === 'PACKAGE') {
    switch (status) {
      // 픽업 완료
      case 'SENT':
        return true;
    }
  }

  return false;
};

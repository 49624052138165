import client from 'api/client';

export const load_setting = () => client.get('/www/setting');

export const load_menus = () => client.get('/www/menus');

export const load_widgets = (params) => {
  return client.get('/www/widget', { params });
};

export const update_view_or_like = (item, params) => {
  const query = {
    ...params,
    doLike: item.favorite === 'off',
    godoContents: { ...item, price: null },
    targetCode: item.id || item.targetCode,
  };
  return client.put(
    params.type === 'like' ? '/www/like/count' : '/www/view/count',
    query
  );
};

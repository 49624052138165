import { useInfiniteQuery } from '@tanstack/react-query';
import { getLabelFavoriteList } from 'api/Prices';
import { extractInfinityDataFromDeep } from 'utils/reactQueryFormatter';

import priceKeys from './keys';

export default function useGetLabelFavoriteList() {
  return useInfiniteQuery(
    priceKeys.getLabelFavoriteList(),
    ({ pageParam = 0 }) =>
      getLabelFavoriteList({ params: { page: pageParam, size: 10 } }),
    {
      getNextPageParam: (pagination) => {
        const { last, number } = pagination;
        return last ? null : number + 1;
      },
      select: (data) => {
        const infiniteData = extractInfinityDataFromDeep(data, 'content');
        return infiniteData;
      },
    }
  );
}

import './style.css';

import { Container, Progress } from 'components/common';
import { Section } from 'components/section';
import { BIZ } from 'context';
import { cancel_order, get_orders } from 'context/biz/order';
import { finish_data_load, start_data_load } from 'context/global/mode';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { moneyFormatter } from 'utils/Formatter';

import PanelItem from '../../components/biz/_panel/_panel-item';
import EmptyPage from '../_mypage/_empty_page';

const STATUS = {
  [-3]: '주문취소',
  0: '접수완료',
  1: '상담예정',
  2: '입금확인중',
  3: '준비중',
  4: '배송',
  5: '정산중',
  6: '완료',
};

const OrderView = ({ cancel_order, end, get_orders, orders, start }) => {
  const [loading, setLoading] = useState(true);
  const LOAD_PAGE = 'pirates-page.order/LOAD';

  const initialize = async () => {
    await get_orders();
  };
  useEffect(() => {
    start(LOAD_PAGE);
    setLoading(true);
    initialize().then(() => {
      end(LOAD_PAGE);
      setLoading(false);
    });
  }, [end, start]);

  const onClickCancel = (orderCode) => {
    cancel_order(orderCode);
  };

  const renderOrders = () => {
    return orders.map((order, i) => {
      return (
        <React.Fragment key={i}>
          <section className="supplier-head">
            <div className="item">
              <h3>주문번호 {order.orderCode}</h3>
              {order.status !== -3 && (
                <span
                  style={{
                    borderBottom: '2px solid #1b79bc',
                    textAlign: 'right',
                  }}
                >
                  <a
                    href="#"
                    style={{ color: '#999999' }}
                    onClick={() => onClickCancel(order.orderCode)}
                  >
                    [주문취소]
                  </a>
                </span>
              )}
            </div>
          </section>
          <section className="order-by-supplier">
            <div className="item">
              <span>{order.item}</span>
            </div>
            <PanelItem
              description={`${order.orderDate
                .slice(0, 10)
                .replace(/-/g, '.')}  ${order.orderDate.slice(11, 16)}`}
              label="주문일자"
            />
          </section>
          <section className="estimate-by-supplier">
            <PanelItem
              description={`${moneyFormatter(order.quoteTotalPrice)}원`}
              label="견적합계"
            />
            <PanelItem label="입금액">
              <span>
                {order.depositPrice !== null ? (
                  `${moneyFormatter(order.depositPrice)}원`
                ) : (
                  <em>(주문 후 입금한 금액이 표기됨)</em>
                )}
              </span>
            </PanelItem>
            <PanelItem label="납품합계">
              <span>
                {order.salesTotalPrice !== null ? (
                  `${moneyFormatter(order.salesTotalPrice)}원`
                ) : (
                  <em>(납품 후 실중량을 측정한 금액이 표기됨)</em>
                )}
              </span>
            </PanelItem>
            <PanelItem label="최종정산금">
              <span>
                {order.inputPrice !== null ? (
                  `${moneyFormatter(order.inputPrice)}원`
                ) : (
                  <em>(부분환불액 또는 추가입금액이 표기됨)</em>
                )}
              </span>
            </PanelItem>
            <PanelItem label="미정산금">
              <span>
                {order.inputPrice !== null ? (
                  `${moneyFormatter(
                    order.salesTotalPrice -
                      order.inputPrice -
                      order.depositTotalPrice
                  )}원`
                ) : (
                  <em>(최종정산 완료 시 0원으로 표기됨)</em>
                )}
              </span>
            </PanelItem>
          </section>

          <section className="estimate-by-supplier">
            <PanelItem label="주문상태">
              <span className={order.status === -3 ? 'red' : ''}>
                {STATUS[order.status]}
              </span>
            </PanelItem>
          </section>

          <section className="order">
            {orders.length === 0 && (
              <EmptyPage message="아직 주문이 없습니다." />
            )}
          </section>
        </React.Fragment>
      );
    });
  };

  return (
    <Container>
      <div className="head" />
      <div className="main t-pirates-biz">
        <Section title="내 주문" type="board">
          <Progress message="조회중입니다..." targets={[LOAD_PAGE]} />
          {!loading && renderOrders()}
        </Section>
      </div>
    </Container>
  );
};

export default connect(
  ({ mypage, [BIZ.ORDER]: order }) => ({
    orders: order.orders,
    profile: mypage.profile,
  }),
  {
    cancel_order,
    end: finish_data_load,
    get_orders,
    start: start_data_load,
  }
)(OrderView);

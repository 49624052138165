import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import setting from 'img/setting.svg';
import no_image_mypage from 'components/images/no-image_mypage.svg';

let MyProfile = ({ session }) => {
  const { identity, profileImage } = session;
  const history = useHistory();

  return (
    <MyProfileStyle>
      <div className="profile-pic">
        <figure>
          <img src={profileImage ? profileImage : no_image_mypage} alt="" />
          <p>
            <strong>{identity && identity.name}</strong>
            님
            <br />
            안녕하세요
          </p>
        </figure>
        <div
          onClick={() => {
            history.push('/마이페이지/프로필');
          }}
          className="setting"
        >
          <img src={setting} alt="" />
        </div>
      </div>
    </MyProfileStyle>
  );
};

const MyProfileStyle = styled.div`
  height: 120px;
  padding: 24px 16px;
  background: #2a4b8d;
  & > .profile-pic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > figure {
      margin: 0;
      display: flex;
      align-items: center;
      & > img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;
        border: 1px solid #ccc;
        background-color: #fff;
      }
      & > p {
        margin-left: 12px;
        line-height: 30px;
        font-size: 20px;
        font-weight: 300;
        color: #fff;
        & > strong {
          line-height: 30px;
          font-size: 20px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
    & > .setting {
      width: 36px;
      height: 36px;
      padding: 4px;
      /* background: #f8f8f8; */
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;
      & > img {
        width: 100%;
        height: 100%;
        filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(129deg)
          brightness(105%) contrast(105%);
        border: none;
      }
    }
  }
  & > .profile-count {
    margin-top: 18px;
    display: flex;
    align-items: center;
    height: 76px;

    border: 1px solid #dddddd;
    border-radius: 5px;

    & > article {
      flex: 1;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      position: relative;

      cursor: pointer;

      & > strong {
        color: #0e4e97;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
      }
      & > span {
        color: #777777;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      & > p {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 40px;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
      & + article {
      }
    }
  }
`;

export default MyProfile;

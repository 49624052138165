import { MODE } from 'context';
import { restore_point, save_point } from 'context/global/mode';
import { useDispatch, useSelector } from 'react-redux';

const usePageRestore = (point) => {
  const state = useSelector((state) => state[MODE].restore);
  const dispatch = useDispatch();

  return {
    restore: () => {
      // 순서주의!!
      const data = state[point];
      dispatch(restore_point(point));
      return data;
    },
    save: (data) => dispatch(save_point(point, data)),
  };
};

export default usePageRestore;

import { Typography } from 'components/atoms';
import BoardTitleSection from 'components/common/BoardTitleSection';
import Tag from 'components/molecules/Tag';
import { removeSpecialChaRegex } from 'constants/marketFilter';
import { useWholeSaleSortState } from 'hooks/custom/useWholeSaleSortState';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { globalListOrder, globalSearchStoreScrollPage } from 'recoil/filter';
import { globalPriceFilter, globalStoresFilter } from 'recoil/price';
import { eventSearchKeyword, globalSearchKeyword } from 'recoil/search';
import styled from 'styled-components';
import genPrevPath from 'utils/genPrevPath';

const RecentKeyword = ({ isWholeSale, recently }) => {
  const history = useHistory();
  const [globalKeyword, setGlobalKeyword] = useRecoilState(globalSearchKeyword);
  const setEventKeyword = useSetRecoilState(eventSearchKeyword);
  const [storesFilter, setStoresFilter] = useRecoilState(globalStoresFilter);
  const [priceFilter, setPriceFilter] = useRecoilState(globalPriceFilter);
  const setStorePage = useSetRecoilState(globalSearchStoreScrollPage);
  const setListOrder = useSetRecoilState(globalListOrder);
  const prevPath = sessionStorage.getItem('prevPath');

  const { initWholeSaleSortState } = useWholeSaleSortState();

  const clickTag = (key) => {
    const cleanKeyword = key.replace(removeSpecialChaRegex, '');
    initWholeSaleSortState();
    if (!globalKeyword.length) {
      const newObj = Object.keys(storesFilter).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});
      setStoresFilter(newObj);

      const newPriceObj = Object.keys(priceFilter).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});
      setPriceFilter(newPriceObj);
    }
    setGlobalKeyword(cleanKeyword);
    setStorePage(1);
    setEventKeyword('');
    setListOrder('default');
    if (isWholeSale) {
      history.push('/도매');
      return;
    }
    history.push(genPrevPath(prevPath));
  };

  const reversedRecentKeywords = useCallback(
    () => [...recently].reverse(),
    [recently]
  );

  return (
    <BoardTitleSection
      margin="0 0 24px 0"
      padding="24px 0 0 0"
      title="최근 검색어"
    >
      {recently.length ? (
        <TagWrapper>
          {reversedRecentKeywords().map((v, i) => {
            return (
              <Tag key={i} onClick={() => clickTag(v)}>
                {v}
              </Tag>
            );
          })}
        </TagWrapper>
      ) : (
        <EmptyNoticeWrapper>
          <Typography color="gray300" fontSize="16px" fontWeight={400}>
            최근 검색한 검색어가 없습니다
          </Typography>
        </EmptyNoticeWrapper>
      )}
    </BoardTitleSection>
  );
};

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  overflow: hidden;
  padding: 0px 16px;
  margin-bottom: 24px;
`;

const EmptyNoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

export default RecentKeyword;

import React, { memo } from 'react';
import styled from 'styled-components';

const Button = ({ block, children, color, onClick }) => {
  return (
    <StyledButton block={block} color={color} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button.attrs({ type: 'button' })`
  background-color: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#1c79bc';
      case 'danger':
        return 'red';
      default:
        return '#e8ebed';
    }
  }};
  border-radius: 16px;
  color: #464c53;
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  padding: 8px 16px;
  width: ${({ block }) => block && '100%'};
`;

export default memo(Button);

import styled from 'styled-components';

const CloseThin = ({
  className = '',
  fill = '#464C53',
  onClick,
  size = 12,
}) => {
  return (
    <Svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.92721 8.05208L15.3022 1.63542C15.5522 1.38542 15.5522 0.96875 15.3022 0.71875C15.0522 0.46875 14.6355 0.46875 14.3855 0.71875L8.01054 7.13542L1.59387 0.760417C1.34387 0.46875 0.968872 0.46875 0.718872 0.760417C0.427205 1.01042 0.427205 1.38542 0.718872 1.63542L7.09387 8.05208L0.718872 14.4687C0.468872 14.7187 0.468872 15.1354 0.718872 15.3854C0.843872 15.4687 0.968872 15.5521 1.13554 15.5521C1.30221 15.5521 1.46887 15.4687 1.59387 15.3437L8.01054 8.96875L14.4272 15.3854C14.5522 15.5104 14.7189 15.5937 14.8855 15.5937C15.0522 15.5937 15.2189 15.5104 15.3439 15.3854C15.5939 15.1354 15.5939 14.7187 15.3439 14.4687L8.92721 8.05208Z"
        fill={fill}
      />
    </Svg>
  );
};

const Svg = styled.svg`
  cursor: pointer;
  z-index: 1;
`;

export default CloseThin;

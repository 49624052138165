export const getParam = (target = '') => {
  if (typeof target !== 'string') {
    throw new Error('target must be string type');
  }
  try {
    const url = new URL(window.location.href);
    return url.searchParams.get(target);
  } catch (err) {
    return null;
  }
};

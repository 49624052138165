export const createUrl = (parts) => {
  return parts
    .map((part) => {
      let string = part.toString();
      if (string.charAt(0) === '/') string = string.substr(1);
      if (string.charAt(string.length - 1) === '/')
        string = string.substr(0, string.length - 1);
      return string;
    })
    .join('/');
};

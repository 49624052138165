const ChevronLeft = ({ fill = 'gray', onClick, size = 12 }) => {
  return (
    <svg
      fill="none"
      height={size}
      style={{ cursor: 'pointer' }}
      viewBox="0 0 7 12"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        clipRule="evenodd"
        d="M2.27614 5.99972L6.80474 1.47113L5.86193 0.52832L0.861926 5.52832C0.601577 5.78867 0.601577 6.21078 0.861926 6.47113L5.86193 11.4711L6.80474 10.5283L2.27614 5.99972Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ChevronLeft;

export const getPayType = (payType) => {
  switch (payType) {
    case 'CARD':
      return `카드`;
    case 'TRAN':
      return `계좌이체`;
    case 'KAKAOPAY':
      return `카카오페이`;
    case 'NAVERPAY':
      return `네이버페이`;
  }
};

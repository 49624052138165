import { useEffect } from 'react';

export default function useObserver({
  onIntersect,
  root,
  rootMargin = '0px',
  target,
  threshold = 1.0,
}) {
  useEffect(() => {
    const observedDOM = target.current;
    let observer = null;
    if (observedDOM) {
      observer = new IntersectionObserver(
        (entries) =>
          entries.forEach((entry) => onIntersect && onIntersect(entry)),
        {
          root,
          rootMargin,
          threshold,
        }
      );
      observer.observe(observedDOM);
    }
    return () => {
      if (observer && observedDOM) observer.unobserve(observedDOM);
    };
  }, [root, target, rootMargin, threshold, onIntersect]);

  return { target };
}

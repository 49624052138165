import { Box } from 'components/atoms';
import styled from 'styled-components';

const APP_ORDER = ['전체', '앱주문'];

const MyLikeAppFilter = ({ checked, setChecked }) => {
  return (
    <Box borderBottom="1px solid #E8EBED" padding="12px 16px">
      {APP_ORDER.map((item, i) => {
        return (
          <TagWrap key={i}>
            <FormCheckLeft
              checked={checked === item}
              id={item + i}
              name="type"
              type="radio"
              onChange={() => {
                setChecked(item);
              }}
            />
            <Label htmlFor={item + i}>{item}</Label>
          </TagWrap>
        );
      })}
    </Box>
  );
};

export default MyLikeAppFilter;

const TagWrap = styled.div`
  height: 36px;
  display: flex;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
`;

const Label = styled.label`
  padding: 8px 12px;
  color: #464c53;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.3px;
  border-radius: 20px;
  border: 1px solid #e8ebed;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  color: #666c78;
`;

const FormCheckLeft = styled.input.attrs({ type: 'radio' })`
  &:checked + ${Label} {
    background: #ecf6ff;
    color: #2f80ed;
    font-weight: 500;
    border: 1px solid #2f80ed;
  }
  display: none;
`;

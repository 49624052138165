import { cancelOrderByOrderType } from 'api/order';
import InquirySlide from 'components/mypage/myOrder/inquirySlide/InquirySlide';
import OrderCancelConfirmModal from 'components/mypage/myOrder/OrderCancelConfirmModal';
import {
  SectionInnerText,
  SectionInnerTitle,
} from 'components/mypage/myOrder/orderHistoryDetail';
import { conf } from 'conf/settings';
import { ToastContext } from 'context/toast';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  getOrderCancelStatus,
  getOrderStatus,
  getOrderType,
  getOrderTypeIcon,
  getReservationDateTimeFormat,
  getWriteReviewStatus,
} from 'utils';

const OrderStatusInfo = ({
  cancelComment,
  delivery,
  etaEndDateTime,
  etaStartDateTime,
  orderCode,
  orderType,
  permalink,
  refetch,
  reservationDateTime,
  status,
  storeCode,
}) => {
  const { showToast } = useContext(ToastContext);

  const [isOpenInquirySlide, setIsOpenInquirySlide] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  const isPickupType =
    orderType === 'CONVENIENCE_STORE' || orderType === 'PACKAGE';
  const isCancelStatus = status === 'CANCEL' || status === 'CANCEL_ADMIN';

  const handleClickCancelOrder = () => {
    cancelOrderByOrderType({ orderCode, orderType })
      .then(() => {
        refetch();
        showToast('주문취소가 완료되었습니다.');
      })
      .catch((err) => {
        showToast(
          err?.response?.data?.message ||
            '주문취소를 실패했습니다. 잠시후 다시 시도해주세요.'
        );
      });
  };

  const handleClickWriteReview = () => {
    window.location.href = `${conf.FISH_MARKET_URL}/${permalink}/리뷰`;
  };

  return (
    <>
      <Container>
        <Box>
          <div>
            <OrderStatusTypo isCancelStatus={isCancelStatus}>
              {getOrderStatus(status, orderType)}
            </OrderStatusTypo>
            <OrderTypeBox>
              <OrderTypeTypo>{getOrderType(orderType)}</OrderTypeTypo>
              <IconWrap>{getOrderTypeIcon(orderType)}</IconWrap>
            </OrderTypeBox>
          </div>

          <ReservationDateTimeBox>
            {isCancelStatus ? (
              <>
                <SectionInnerTitle>취소 사유</SectionInnerTitle>
                <SectionInnerText color="var(--Gray-gray-11, #26282B)">
                  {cancelComment ?? '사용자 주문 취소'}
                </SectionInnerText>
              </>
            ) : (
              <>
                <SectionInnerTitle>
                  {isPickupType ? '픽업 예정시간' : '배송 예정일'}
                </SectionInnerTitle>
                <SectionInnerText color="var(--Blue-blue-9, #2f80ed)">
                  {getReservationDateTimeFormat({
                    deliveryDuration: delivery?.deliveryDuration,
                    etaEndDateTime: etaEndDateTime ?? delivery?.etaEndDateTime,
                    etaStartDateTime:
                      etaStartDateTime ?? delivery?.etaStartDateTime,
                    orderType,
                    reservationDateTime,
                  })}
                </SectionInnerText>
              </>
            )}
          </ReservationDateTimeBox>

          <ButtonBox>
            {getOrderCancelStatus(orderType, status) && (
              <StyledButton onClick={() => setIsOpenCancelModal(true)}>
                주문 취소
              </StyledButton>
            )}
            {storeCode && (
              <StyledButton onClick={() => setIsOpenInquirySlide(true)}>
                상품 문의
              </StyledButton>
            )}
            {getWriteReviewStatus(orderType, status) && (
              <StyledButton
                isBlackStyle
                onClick={() => handleClickWriteReview()}
              >
                후기 작성
              </StyledButton>
            )}
          </ButtonBox>
        </Box>
      </Container>

      <InquirySlide
        open={isOpenInquirySlide}
        orderType={orderType}
        storeCode={storeCode}
        onClose={() => setIsOpenInquirySlide(false)}
      />

      <OrderCancelConfirmModal
        isOpen={isOpenCancelModal}
        onClose={() => setIsOpenCancelModal(false)}
        onSuccess={() => handleClickCancelOrder()}
      />
    </>
  );
};

export default OrderStatusInfo;

const StyledButton = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  text-align: center;
  border: ${({ isBlackStyle }) =>
    isBlackStyle ? 'none' : '1px solid var(--new-gray-gray-2, #e8ebed)'};
  border-radius: 4px;
  color: ${({ isBlackStyle }) => (isBlackStyle ? 'white' : 'black')};
  background-color: ${({ isBlackStyle }) =>
    isBlackStyle ? 'var(--new-gray-gray-10, #383B3E)' : 'white'};
`;

const ButtonBox = styled.div`
  display: flex;
  gap: 8px;
`;

const ReservationDateTimeBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50px;
  max-height: 50px;
`;

const OrderTypeTypo = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.3px;
`;

const OrderTypeBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const OrderStatusTypo = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: ${({ isCancelStatus }) =>
    isCancelStatus
      ? 'var(--Gray-gray-5, #9FA4AA)'
      : 'var(--Blue-blue-9, #2f80ed)'};
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.3px;
`;

const Container = styled.div`
  padding: 24px 16px;
  border-top: 1px solid var(--Gray-gray-3, #e8ebed);
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

import client from './client';

export const getZoneFilter = () => {
  return client.get('/www/market/filter/zone/list').then((res) => res.data);
};

export const getProductFilter = () => {
  return client.get('/www/market/filter/category/list').then((res) => res.data);
};

export const getSortedStoreList = (params) => {
  return client
    .get('/www/market/filter/list', {
      params,
    })
    .then((res) => res.data);
};

export const updateRecommendLike = (data) => {
  return client.put('/www/like/count', data).then((res) => res.data);
};

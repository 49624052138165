import React, { memo } from 'react';

const Logo = ({ size = 32 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <defs>
        <style>{`.b{fill:#074f97;}.c{clip-path:url(#a);}.d{fill:#060c1a;}.e{fill:#eeb922;}.f{fill:#fff;}.g{fill:#eeeeef;}.h{fill:#bebfbf;}.i{fill:#f8f7f7;}.j{fill:#dd9273;}.k{fill:#f2c2a6;}.l{fill:#e5a98c;}.m{fill:#edac9e;}.n{fill:#b37a71;}.o{fill:#da9174;}.p{fill:#dbdad7;}.q{fill:#07529a;}.r{fill:#0c3765;}`}</style>
        <clipPath id="a">
          <path
            className="a"
            d="M4.86,18.2A13.964,13.964,0,1,0,18.822,4.24,13.964,13.964,0,0,0,4.86,18.2"
            transform="translate(-4.86 -4.24)"
          />
        </clipPath>
      </defs>
      <g transform="translate(-1.65 -1)">
        <path
          className="b"
          d="M17.638,2.652A14.356,14.356,0,1,1,3.287,17.007,14.356,14.356,0,0,1,17.638,2.652m0-1.652a16,16,0,1,0,14.794,9.876A16,16,0,0,0,17.638,1"
          transform="translate(0)"
        />
        <g className="c" transform="translate(3.678 3.047)">
          <path
            className="d"
            d="M24.1,11.392S26.407,9.2,28.292,9.2a6.171,6.171,0,0,1,4.162,1.94c1.027.893-5.035,4.906-5.035,4.906Z"
            transform="translate(-14.556 -6.74)"
          />
          <path
            className="e"
            d="M14.985,23.374a.308.308,0,0,1-.193-.069c-.446-.357-2.693-1.984-2.9-3.676-.174-1.488,1.141-6.191,6.116-8.269a.308.308,0,0,1,.417.213,3.779,3.779,0,0,0,4.038,2.976,3.527,3.527,0,0,0,3.859-2.976.308.308,0,0,1,.432-.213,12.659,12.659,0,0,1,4.99,4.613,5.4,5.4,0,0,1,1.032,3.71c-.238,1.577-2.455,3.259-2.951,3.7a.327.327,0,0,1-.2.074Z"
            transform="translate(-8.398 -7.814)"
          />
          <path
            className="d"
            d="M32.713,20.107c0,1.2-2.867,3.889-2.867,3.889l-14.727.02a11.7,11.7,0,0,1-2.976-3.676c-.585-1.627,1.528-6,5.838-8.12a4.464,4.464,0,0,0,4.494,3.16c3.13-.055,3.968-2.292,4.464-3.115,0,0,5.853,3.011,5.759,7.837"
            transform="translate(-8.482 -8.262)"
          />
          <path
            className="f"
            d="M31.723,11.974c-.551,0-.883.789-.883.789s.312.794.848.794a.62.62,0,0,0,.63-.5l.193.322a1.43,1.43,0,0,0,.144-.149c.069-.1.184-.372-.064-.5.248-.1.134-.4.064-.5a1.908,1.908,0,0,0-.144-.144l-.193.347a.575.575,0,0,0-.6-.5m-.308.739a.149.149,0,1,1-.064-.151.149.149,0,0,1,.064.151"
            transform="translate(-17.953 -8.125)"
          />
          <path
            className="g"
            d="M21.741,23.5a7.19,7.19,0,0,1,4.96,2.1c2.54,2.292,1.811,2.614,3.9,3.11s2.391,1.726,2.093,2.922S31,32.653,30.6,33.218s-.729,2.123-2.619,1.984-1.84,1.488-6.205,1.429c-2.788-.035-2.689-1.458-4.251-1.324s-3.085.2-3.472-1.86a2.5,2.5,0,0,1-1.523-4.767c.863-.352,2.128-.362,2.892-1.295s3.08-3.889,6.3-3.889"
            transform="translate(-7.872 -13.942)"
          />
          <path
            className="h"
            d="M30.62,39.129c.4-.565,1.786-.367,2.093-1.592a2.068,2.068,0,0,0-.625-2.267c.372,1.275-.739,3.145-2.326,2.922-1.156-.164,0,.8-1.161,1.037s-3.924-.66-8.785.332c-5.923,1.21-4.688-.764-4.836-.928-.367-.367-.848.347-2.317-.3a2.81,2.81,0,0,1-1.528-2.45,2.277,2.277,0,0,0,.04,2.188A2.51,2.51,0,0,0,14.1,39.362c.362,2.059,1.89,1.984,3.472,1.855s1.463,1.295,4.251,1.329c4.365.055,4.311-1.558,6.205-1.429s2.222-1.424,2.619-1.984"
            transform="translate(-7.891 -19.878)"
          />
          <path
            className="h"
            d="M44.25,32.854a2.1,2.1,0,0,0,.62.565,2.614,2.614,0,0,0,.754.3c.129.03.263.05.4.069h.432a2.367,2.367,0,0,1,.913.293,1.226,1.226,0,0,1,.4.372.863.863,0,0,1,.139.585.942.942,0,0,1-.253.5.868.868,0,0,1-.5.278.992.992,0,0,1-.952-.3.828.828,0,0,1-.2-.446.923.923,0,0,1,.064-.451,1.086,1.086,0,0,0,.079.412.63.63,0,0,0,.233.293.709.709,0,0,0,.66.089.536.536,0,0,0,.342-.441.327.327,0,0,0-.069-.238.838.838,0,0,0-.238-.213,2.262,2.262,0,0,0-.714-.258l-.2-.04-.228-.04a2.843,2.843,0,0,1-.441-.139,2.4,2.4,0,0,1-.779-.5,1.637,1.637,0,0,1-.5-.759"
            transform="translate(-24.691 -18.628)"
          />
          <path
            className="h"
            d="M17.867,32.585a5.326,5.326,0,0,1-.714.744,4.617,4.617,0,0,1-.863.6,2.753,2.753,0,0,1-.5.208l-.139.035-.109.025a2.049,2.049,0,0,0-.208.069,1.533,1.533,0,0,0-.675.5.7.7,0,0,0-.1.7.853.853,0,0,0,.561.526.764.764,0,0,0,.744-.273A.58.58,0,0,0,16,35.308a.838.838,0,0,0-.193-.437.769.769,0,0,1,.332.412.754.754,0,0,1-.06.565,1.07,1.07,0,0,1-1.587.317,1.577,1.577,0,0,1-.372-.466,1.076,1.076,0,0,1-.124-.635,1.314,1.314,0,0,1,.223-.585,1.88,1.88,0,0,1,.928-.7,2.539,2.539,0,0,1,.278-.074l.248-.04a2.679,2.679,0,0,0,.446-.134A6.043,6.043,0,0,0,17,33.1c.293-.159.575-.342.868-.526"
            transform="translate(-9.459 -18.517)"
          />
          <path
            className="h"
            d="M37.194,33.85a2.954,2.954,0,0,1,2.48,1.364c1.027,1.954,2.262,2.818,4.038,1.92a.168.168,0,0,0,.04.084c.67.754-.719.863-.719.863s-2.48,1.429-2.525,1.429l-4.678-.248Z"
            transform="translate(-20.468 -19.162)"
          />
          <path
            className="i"
            d="M41.571,39.24a1.746,1.746,0,0,0,1.071,1.548,1.984,1.984,0,0,0,1.488.263s.114.278-.5.635a4.344,4.344,0,0,1-3.1,0c-2.153-.6.992-2.426.992-2.426"
            transform="translate(-22.459 -21.879)"
          />
          <path
            className="j"
            d="M41.9,32.022a1.657,1.657,0,0,1,1.458.2c.6.5.382,2.207-.694,2.078Z"
            transform="translate(-23.527 -18.197)"
          />
          <path
            className="j"
            d="M21.935,32.022a1.657,1.657,0,0,0-1.458.2c-.6.5-.382,2.207.694,2.078Z"
            transform="translate(-12.565 -18.197)"
          />
          <path
            className="h"
            d="M17.14,40.583a1.358,1.358,0,0,0,1.275-1.443s.451,1.225-.342,1.766c-.605.412-.933-.322-.933-.322"
            transform="translate(-11.049 -21.828)"
          />
          <path
            className="j"
            d="M25.54,45.572s.223,4.206,3.859,4.355,3.854-4.777,3.854-4.777Z"
            transform="translate(-15.282 -24.857)"
          />
          <path
            className="k"
            d="M27.271,23.48A6.984,6.984,0,0,0,23.4,24.775c-.992,2.267-2.192,5.154-1.622,7.892.308,1.458,2.768,4.375,5.486,4.38h0c2.718,0,5.184-2.922,5.486-4.38.575-2.738-.645-5.625-1.622-7.892a6.984,6.984,0,0,0-3.859-1.295"
            transform="translate(-13.313 -13.936)"
          />
          <path
            className="l"
            d="M33.668,32.667c.575-2.738-.645-5.625-1.622-7.892a6.984,6.984,0,0,0-3.859-1.295h0a5.457,5.457,0,0,0-.694.055l.124.347s3.209.05,4.157,1.166,2.188,5.154,1.617,6.945a6.7,6.7,0,0,1-5.437,4.147,6.147,6.147,0,0,1-4.5-1.93c.992,1.374,2.793,2.847,4.722,2.852h0c2.718,0,5.184-2.922,5.486-4.38"
            transform="translate(-14.229 -13.936)"
          />
          <path
            className="l"
            d="M30.7,41.649a4.559,4.559,0,0,0,2.445-.169,1.543,1.543,0,0,1-2.445.169"
            transform="translate(-17.882 -23.007)"
          />
          <path
            className="j"
            d="M27.51,37.18s3.472.5,5.218-.05c0,0-2.619,2.872-5.218.05"
            transform="translate(-16.275 -20.815)"
          />
          <path
            className="m"
            d="M38.374,33.034c0,.412-.58.744-1.29.744s-1.295-.332-1.295-.744.58-.744,1.295-.744,1.29.352,1.29.744"
            transform="translate(-20.447 -18.376)"
          />
          <path
            className="f"
            d="M39.343,32.694c0,.035-.05.064-.109.064s-.114-.03-.114-.064.05-.065.114-.065.109.03.109.065"
            transform="translate(-22.126 -18.547)"
          />
          <path
            className="m"
            d="M30.78,33.147s.605-.7,1.121-.5,1.037.685.853.992-.278.357-1.042.446-1.538-.263-.933-.992"
            transform="translate(-17.813 -18.539)"
          />
          <path
            className="f"
            d="M33.172,32.969c0,.06-.079.1-.174.1s-.179-.045-.179-.1.079-.1.179-.1.174.045.174.1"
            transform="translate(-18.951 -18.668)"
          />
          <path
            className="n"
            d="M31.691,34.456s.079-.124.164-.1.064.179,0,.283-.293,0-.164-.184"
            transform="translate(-18.362 -19.416)"
          />
          <path
            className="n"
            d="M33.5,34.456s-.084-.124-.169-.1-.064.179,0,.283.293,0,.169-.184"
            transform="translate(-19.181 -19.416)"
          />
          <path
            className="g"
            d="M26.731,34.452a1.642,1.642,0,0,1,1.071.2,1.8,1.8,0,0,0,1.548-.035,1.935,1.935,0,0,1,1.488-.218c.759.124,1.984.437,1.84.858s-.7.278-.858.461a.779.779,0,0,1-.689.218c-.283-.03-.2,0-.367.188a1.076,1.076,0,0,1-.848.283c-.456,0-.258,0-.57.144a.992.992,0,0,1-.992.055.451.451,0,0,0-.362,0,1.245,1.245,0,0,1-1.071-.114c-.362-.263-.253-.184-.67-.2s-.575-.109-.685-.317-.233-.089-.531-.119-.66-.2-.5-.58,1.136-.7,2.193-.818"
            transform="translate(-14.757 -19.407)"
          />
          <path
            className="h"
            d="M25.077,35.43s-.417.268-.218.5.918-.149,1.027.347.942-.035,1.255.427.933-.134,1.329.094.878-.317,1.25-.2.873,0,.957-.223.357-.089.7-.139.193-.322.665-.327.565-.069.6-.169,0-.154-.109-.258a.334.334,0,0,1,.149.5c-.208.322-.6.114-.873.387a.824.824,0,0,1-.709.179c-.159,0-.1.035-.273.193a1.26,1.26,0,0,1-.928.278c-.531,0-.556.357-1.136.288s-.456-.149-.868-.065a1.116,1.116,0,0,1-.992-.149c-.188-.193-.228-.159-.585-.184s-.541-.065-.7-.313-.159-.079-.536-.129-.63-.2-.556-.5.541-.5.541-.5"
            transform="translate(-14.76 -19.959)"
          />
          <path
            className="o"
            d="M30.754,33.305s.63-.729,1.166-.5a1.746,1.746,0,0,1,.878.724c0-.313-.441-.685-.888-.878s-1.121.5-1.121.5a.774.774,0,0,0-.218.456,1.31,1.31,0,0,1,.184-.288"
            transform="translate(-17.817 -18.543)"
          />
          <path
            className="o"
            d="M34.678,29.435a.913.913,0,0,1,.908-.4.809.809,0,0,1,.675,1.086.858.858,0,0,1-1.091.551.828.828,0,0,1-.5-1.24"
            transform="translate(-19.821 -16.731)"
          />
          <path
            className="f"
            d="M34.871,29.585a.913.913,0,0,1,.908-.4.809.809,0,0,1,.665,1.086.868.868,0,0,1-1.091.551.828.828,0,0,1-.481-1.24"
            transform="translate(-19.92 -16.807)"
          />
          <path
            className="d"
            d="M36.393,30.171a.5.5,0,1,1-.471-.461.471.471,0,0,1,.471.461"
            transform="translate(-20.252 -17.075)"
          />
          <path
            className="f"
            d="M37.073,29.894c0,.06-.064.1-.144.1a.126.126,0,0,1-.139-.1c0-.055.064-.1.144-.1a.126.126,0,0,1,.139.1"
            transform="translate(-20.951 -17.116)"
          />
          <path
            className="d"
            d="M34.615,27.778a2.54,2.54,0,0,1,1.984.055.992.992,0,0,0-1.081-.625c-.8,0-.918.57-.918.57"
            transform="translate(-19.848 -15.809)"
          />
          <path
            className="d"
            d="M25.2,29.985a1.136,1.136,0,0,1,.06-.794,2.624,2.624,0,0,1,2.386-1.047c.992.278.789,1.563,0,2.178a1.607,1.607,0,0,1-2.445-.337"
            transform="translate(-15.09 -16.28)"
          />
          <path
            className="d"
            d="M30.451,24.79c-.818.387-2.48,1.19-4.023,1.94-1.984.992-3.72,1.944-3.9,2.044l-.089.308c.347-.189,1.935-1.032,4.082-2.088,1.766-.868,3.418-1.691,4.187-2.078l-.258-.124"
            transform="translate(-13.72 -14.596)"
          />
          <path
            className="g"
            d="M26.352,23.128A4.082,4.082,0,0,0,23.376,23c-1.354.546-1.518,1.315-2.48,1.419s-1.146-.3-1.146-.3a1.3,1.3,0,0,0,.933,1.1c.546.188.714.045.446.159a1.255,1.255,0,0,1-.8.035c-.3-.089,0,.352,1.295.585s4.43-.526,4.747-2.877"
            transform="translate(-12.364 -13.584)"
          />
          <path
            className="h"
            d="M26.523,23.089a2.078,2.078,0,0,1-.719,1.334,4.8,4.8,0,0,1-5.114,1.121h0l.035.03h.025l.035.025h.025l.064.04h0l.084.045h.025l.094.04h0l.129.05h.03l.124.04h.035l.164.045h.03l.159.035H21.8l.208.045c1.295.233,4.43-.526,4.747-2.877a2.539,2.539,0,0,0-.3-.119"
            transform="translate(-12.838 -13.664)"
          />
          <path
            className="f"
            d="M23.712,50.689A12.55,12.55,0,0,0,26.039,52.1,14.767,14.767,0,0,0,28.6,50.54l3.934,15.968L19,67.2Z"
            transform="translate(-11.986 -27.573)"
          />
          <path
            className="p"
            d="M25.717,55.616l.675.8,2.2-2.54,2.1,2.351.9-1.176,1.716,7.118a7.8,7.8,0,0,0-9.241.317Z"
            transform="translate(-14.541 -29.257)"
          />
          <path
            className="q"
            d="M29.78,54.919a1.458,1.458,0,0,1,1.066.045l.605.66a1.592,1.592,0,0,1-.5.536A25.971,25.971,0,0,0,32.494,58.5a5.045,5.045,0,0,1,.764,2.083,1.984,1.984,0,0,0-.992-.188c-.57.045-.585.377-1.23.188s-.878-.2-1.31.06-.64.069-.928-.119a.933.933,0,0,0-1.131.154c-.372.347-.313-.5.084-1.394s1.736-2.594,1.89-3.145c0,0-.184-.1-.362-.625Z"
            transform="translate(-16.229 -29.74)"
          />
          <path
            className="r"
            d="M31.2,57.2a1.334,1.334,0,0,1,.65-.263,1.126,1.126,0,0,1,.69.273,10.184,10.184,0,0,0,1.111,2.371c.5.561.308,1.106.119.893a3.472,3.472,0,0,1-.689-1.181c-.169-.5-.6-1.726-.6-1.726a5.738,5.738,0,0,0,.154,1.285c.169.68.645,1.776.064,1.93s-.774-.64-.694-1.394a5.016,5.016,0,0,0-.089-2.083c-.179-.2-.5.288-.61.992s-.144,2.48-.575,2.634-.045-1.156.119-1.984a14.212,14.212,0,0,0,.352-1.731"
            transform="translate(-17.798 -30.799)"
          />
          <path
            className="b"
            d="M11.552,45.985s2.535,1.716,2.48,3.1a44.311,44.311,0,0,1-1.275,7.758c-.883,3.671-1.761,8.745-1.488,10.769a18.21,18.21,0,0,1-8.254-1.429s-.5.03-.164-1.186a14.771,14.771,0,0,1,1.116-2.639S-.75,61.109-.7,57.032s1.181-6.513,9.45-8.825c0,0,1.667-.372,1.84-.749.238-.5.357-1.409.992-1.488"
            transform="translate(-2.058 -25.27)"
          />
          <path
            className="r"
            d="M22.716,52.414s-.055.3.228.213.675-.908,1.488-1.627,1.31-.377,1.31-.377,0-.749-.5-.923-.784-.446-1.245.288-1.25,2.426-1.25,2.426"
            transform="translate(-13.856 -27.051)"
          />
          <path
            className="f"
            d="M17.395,61.24l2.371.64-.045.193-2.361-.685Z"
            transform="translate(-11.16 -32.966)"
          />
          <path
            className="r"
            d="M10.243,62.789A5.625,5.625,0,0,1,8.79,61.6a1.567,1.567,0,0,1-.441-1.488,1.27,1.27,0,0,0,.193,1.374,8.308,8.308,0,0,0,1.7,1.488Z"
            transform="translate(-6.557 -32.396)"
          />
          <path
            className="e"
            d="M18.995,49.069a1.235,1.235,0,0,1,.233-.4c.124-.1-.04.064-.064.913a4.509,4.509,0,0,0,.5,1.984c.094.03.2-.451.521-1.161s.8-1.488,1.319-1.488a1.369,1.369,0,0,1,1.166,1.24,31.525,31.525,0,0,1-1.141,8.219c-.987,3.944-1.761,8.175-1.453,10.615,0,0,.05.124-.149.1s-1.86,0-3.373-.1c0,0-.119,0-.119-.278s1.275-5.576,2.009-8.626A32.957,32.957,0,0,1,21.177,52.4a63.112,63.112,0,0,0-2.113,6.449c-.893,3.4-2.044,9.271-2.044,9.271l-.035.233s-.069.114.159.119l2.232.084s.079.04.1-.1a26.166,26.166,0,0,1,.5-5.332c.605-3.165,1.186-4.921,1.756-7.237a20.487,20.487,0,0,0,.714-4.846l-.025-.814s-.1-.908-.744-1.022-1.131,1.2-1.488,2.054c-.3.744-.4,1.136-.6.883a4.737,4.737,0,0,1-.6-3.07"
            transform="translate(-10.691 -26.618)"
          />
          <path
            className="r"
            d="M7.768,61.119a1.557,1.557,0,0,0,2.143-.367A5.69,5.69,0,0,1,11.28,59.16a41.063,41.063,0,0,1-2.059,6.711c-1.572,4.048-2.282,6.706-1.954,7.282L6.538,72.9s-.615-.1-.184-1.349c.461-1.324,3.547-8.71,3.547-8.71a6.714,6.714,0,0,1-2.133-1.716"
            transform="translate(-5.536 -31.917)"
          />
          <path
            className="e"
            d="M4.375,70.524a.273.273,0,0,1-.422,0c-.238-.228-1.047-1.558-.451-4.241A3.2,3.2,0,0,1,6.325,63.52c.8,0,.67.4.258.372S4.6,63.981,3.929,66.4a5.774,5.774,0,0,0,.446,4.122"
            transform="translate(-4.068 -34.115)"
          />
          <path
            className="b"
            d="M39.4,45.99s-2.54,1.716-2.48,3.1a43.825,43.825,0,0,0,1.28,7.763c.878,3.671,1.761,8.745,1.488,10.769a18.21,18.21,0,0,0,8.269-1.434s.5.03.169-1.186a14.624,14.624,0,0,0-1.136-2.639s4.717-1.24,4.673-5.318-1.186-6.513-9.425-8.825c0,0-1.667-.372-1.845-.749-.233-.5-.357-1.409-.967-1.488"
            transform="translate(-21.016 -25.275)"
          />
          <path
            className="r"
            d="M40.141,52.414s.06.3-.228.213-.67-.908-1.488-1.627-1.3-.377-1.3-.377,0-.749.5-.923.784-.446,1.245.288,1.245,2.426,1.245,2.426"
            transform="translate(-21.118 -27.051)"
          />
          <path
            className="f"
            d="M46.091,61.24l-2.371.64.05.193,2.356-.685Z"
            transform="translate(-24.444 -32.966)"
          />
          <path
            className="r"
            d="M53.64,62.789A5.625,5.625,0,0,0,55.093,61.6a1.568,1.568,0,0,0,.441-1.488,1.26,1.26,0,0,1-.189,1.374,8.284,8.284,0,0,1-1.706,1.488Z"
            transform="translate(-29.443 -32.396)"
          />
          <path
            className="e"
            d="M40.608,49.069a1.235,1.235,0,0,0-.233-.4c-.124-.1.04.064.064.913a4.6,4.6,0,0,1-.5,1.984c-.094.03-.2-.451-.521-1.161S38.614,48.92,38.1,48.92a1.379,1.379,0,0,0-1.131,1.24,32,32,0,0,0,1.141,8.219c.982,3.944,1.766,8.175,1.458,10.615,0,0-.05.124.149.1s1.855,0,3.368-.1c0,0,.119,0,.119-.278s-1.285-5.576-2.019-8.626A32.74,32.74,0,0,0,38.44,52.4a62.419,62.419,0,0,1,2.118,6.449c.878,3.4,2.019,9.276,2.019,9.276l.035.233s.064.114-.159.119l-2.237.084s-.074.04-.094-.1a26.871,26.871,0,0,0-.5-5.332c-.6-3.165-1.186-4.921-1.756-7.237a20.764,20.764,0,0,1-.714-4.846l.025-.813s.109-.913.784-1.027,1.136,1.2,1.488,2.054c.3.744.4,1.136.6.883a4.673,4.673,0,0,0,.585-3.07"
            transform="translate(-21.029 -26.618)"
          />
          <path
            className="r"
            d="M53,61.119a1.557,1.557,0,0,1-2.143-.367A5.586,5.586,0,0,0,49.49,59.16a41.063,41.063,0,0,0,2.059,6.711c1.572,4.048,2.287,6.706,1.954,7.282l.734-.258s.615-.1.179-1.349c-.456-1.324-3.547-8.71-3.547-8.71A6.66,6.66,0,0,0,53,61.119"
            transform="translate(-27.352 -31.917)"
          />
          <path
            className="e"
            d="M57.884,70.524a.268.268,0,0,0,.417,0c.243-.228,1.052-1.558.456-4.241a3.207,3.207,0,0,0-2.827-2.763c-.8,0-.665.4-.253.372s1.984.089,2.649,2.51a5.759,5.759,0,0,1-.441,4.122"
            transform="translate(-30.304 -34.115)"
          />
        </g>
      </g>
    </svg>
  );
};

export default memo(Logo);

import { conf } from 'conf/settings';

const getAccountUrl = ({ asPath, redirectUrl, type = 'FISH' }) => {
  try {
    const url = new URL(
      redirectUrl || `${conf.FISH_MARKET_URL}${asPath || ''}`
    );
    return `${conf.ACCOUNT_URL}/v2/login?${new URLSearchParams({
      redirectUrl: url.href,
      type,
    })}`;
  } catch {
    if (!redirectUrl && !asPath) {
      throw new Error('인자가 없습니다.');
    }
    throw new Error('URL 형태를 확인해주세요.');
  }
};

export default getAccountUrl;

import { useQuery } from '@tanstack/react-query';
import { getLabelByItemCode } from 'api/Prices';

import priceKeys from './keys';

export default function useGetLabelByItemCode({ itemCode }) {
  return useQuery(
    priceKeys.getLabelByItemCode({ itemCode }),
    () => getLabelByItemCode({ itemCode }),
    {
      enabled: !!itemCode,
    }
  );
}

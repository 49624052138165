import 'dayjs/locale/ko';

import TP_GA from 'components/common/tpirates-ga';
import rootReducer from 'context';
import dayjs from 'dayjs';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { initializeApp } from 'script/app-initializer';

import App from './App';

const middle_wares = [ReduxThunk];
const enhancer = applyMiddleware(...middle_wares);

const store = createStore(rootReducer, enhancer);

dayjs.locale('ko');
TP_GA.init();

initializeApp();

const container = document.getElementById('wrap');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </BrowserRouter>
  </Provider>
);

const ChevronUp = ({ className, fill = '#0E4E97', onClick, size = 20 }) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={size}
      style={{ cursor: 'pointer' }}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        clipRule="evenodd"
        d="M12.0001 7C12.2653 7 12.5196 7.10536 12.7072 7.29289L20.2072 14.7929L18.793 16.2071L12.0001 9.41421L5.20718 16.2071L3.79297 14.7929L11.293 7.29289C11.4805 7.10536 11.7349 7 12.0001 7Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ChevronUp;

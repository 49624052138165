export const mobileIosCheck = () => {
  const { userAgent } = navigator;
  if (userAgent.match('iPhone')) return true;
  if (userAgent.match('iPad')) return true;
  return false;
};

export const mobileIosAppCheck = () => {
  const { userAgent } = navigator;
  if (userAgent.match('iPhone') && userAgent.includes('tpirates')) return true;
  if (userAgent.match('iPad') && userAgent.includes('tpirates')) return true;
  return false;
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = { errors: {}, terms: false };

const orderForm = createSlice({
  extraReducers: {},
  initialState,
  name: 'orderForm',
  reducers: {
    setValue: (state, { payload = {} }) => {
      const { name, value } = payload;

      // if (state.errors[name]) {
      // }

      state[name] = value;
    },
  },
});

export const { setValue } = orderForm.actions;
export default orderForm.reducer;

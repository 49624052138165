import dayjs from 'dayjs';

export const getReservationDateTimeFormat = ({
  deliveryDuration,
  etaEndDateTime,
  etaStartDateTime,
  isVisibleTitle = false,
  orderType,
  reservationDateTime,
}) => {
  if (orderType === 'QUICK') {
    return etaStartDateTime
      ? `${dayjs(etaStartDateTime).format('YYYY-MM-DD(ddd) HH:mm')}~${dayjs(
          etaEndDateTime
        ).format('HH:mm')}`
      : `${deliveryDuration}분 내외`;
  }

  switch (orderType) {
    case 'CONVENIENCE_STORE':
      return `${isVisibleTitle ? '배송 예정일' : ''} ${dayjs(
        reservationDateTime
      ).format('MM/DD(ddd) HH:mm')}부터 픽업 가능`;
    case 'DAY_DELIVERY':
      return `${dayjs(reservationDateTime).format('MM/DD(ddd) HH:mm')}이전`;
    case 'PACKAGE':
      return `${dayjs(reservationDateTime).format('YYYY-MM-DD(ddd) HH:mm')}`;
  }
};

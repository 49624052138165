import React from 'react';
import './style.css';

const ImageForm = ({ images, fileNames = [], onRemove }) => {
  const _handleRemove = (image) => () => onRemove(image);

  return (
    <div className="upload-images">
      <ul>
        {images.map((image, i) => (
          <li key={i}>
            <i className="btn remove" onClick={_handleRemove(image)} />
            <img src={image} alt={fileNames[i] || 'upload-image'} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImageForm;

import { createSlice } from '@reduxjs/toolkit';

import { fetchDeliveryDates, fetchDeliveryTimes } from './thunks';

const initialState = {
  availableOrder: true,
  dateStatus: 'idle',
  deliveryDates: [],
  deliveryTimes: [],
  selectedDate: {},
  selectedTime: {},
  timeStatus: 'idle',
};

const slice = createSlice({
  extraReducers: {
    [fetchDeliveryTimes.pending.type]: (state) => {
      state.timeStatus = 'loading';
      state.selectedTime = {};
      state.deliveryTimes = [];
    },
    [fetchDeliveryTimes.fulfilled.type]: (state, { payload = [] }) => {
      state.timeStatus = 'success';
      state.deliveryTimes = payload;
    },
    [fetchDeliveryTimes.rejected.type]: (state) => {
      state.timeStatus = 'error';
      state.deliveryTimes = [];
    },
    [fetchDeliveryDates.pending.type]: (state) => {
      state.dateStatus = 'loading';
      state.selectedDate = {};
      state.deliveryTimes = [];
    },
    [fetchDeliveryDates.fulfilled.type]: (state, { payload = [] }) => {
      state.dateStatus = 'success';
      state.deliveryDates = payload;

      if (payload.length === 0) {
        state.availableOrder = false;
        return;
      }

      state.availableOrder = true;
      const today = payload.find((value) => value.type === 'today');
      if (today) {
        state.selectedDate = today;
      }
    },
    [fetchDeliveryDates.rejected.type]: (state) => {
      state.dateStatus = 'error';
      state.selectedDate = {};
      state.deliveryTimes = [];
    },
  },
  initialState,
  name: 'deliveryTime',
  reducers: {
    resetSelectedTime: (state) => {
      state.selectedTime = {};
    },
    selectDate: (state, { payload }) => {
      const date = state.deliveryDates.find((date) => date.value === payload);
      if (date) state.selectedDate = date;
    },
    selectTime: (state, { payload }) => {
      const time = state.deliveryTimes.find(
        (time) => time.maxArrivalTime === payload
      );
      if (time) state.selectedTime = time;
    },
  },
});

export const { resetSelectedTime, selectDate, selectTime } = slice.actions;
export default slice.reducer;

import { ToastContext } from 'context/toast';
import { useContext } from 'react';

/**
 * @returns {(message:string)=>void}
 */
const useToast = () => {
  const { showToast } = useContext(ToastContext);
  return showToast;
};

export default useToast;

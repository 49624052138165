import React from 'react';
import styled, { css } from 'styled-components';

/**
 * @typedef {object} IProps
 * @property {string} backgroundImage
 * @property {React.CSSProperties['height']} height
 * @property {React.CSSProperties['opacity']} opacity
 * @property {React.CSSProperties['width']} width
 *
 * @param {IProps & React.HTMLAttributes<HTMLDivElement>} props
 */
const Skeleton = ({ backgroundImage, height, opacity, width, ...props }) => {
  return (
    <Wrapper
      $backgroundImage={backgroundImage}
      $height={height}
      $opacity={opacity}
      $width={width}
      {...props}
    />
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.gray100};
  border-radius: 5px;
  height: ${({ $height }) => $height};
  opacity: ${({ $opacity }) => $opacity};
  width: ${({ $width }) => $width};

  ${({ $backgroundImage }) =>
    $backgroundImage &&
    css`
      background-image: url(${$backgroundImage});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export default React.memo(Skeleton);

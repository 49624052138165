import React from 'react';

const Minus = ({ fill = '#000000', size = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      fill={fill}
    >
      <path d="M19 13H5v-2h14v2z" />
    </svg>
  );
};

export default React.memo(Minus);

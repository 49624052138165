import React from 'react';

const ChevronUp = ({ width = 16, height = 16, onClick }) => {
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99972 6.27609L12.5283 10.8047L13.4711 9.86188L8.47113 4.86188C8.21078 4.60153 7.78867 4.60153 7.52832 4.86188L2.52832 9.86188L3.47113 10.8047L7.99972 6.27609Z"
        fill="#72787F"
      />
    </svg>
  );
};

export default ChevronUp;

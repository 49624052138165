// import { handleActions } from 'redux-actions';
// import createApiRequest from '../_support';
// import * as session_api from 'api/session';

// const SESSION_CHECK = 'pirates-data/SESSION.CHECK';
// const SESSION_CREATE = 'pirates-data/SESSION.CREATE';
// const CART_SESSION_CHECK = 'pirates-data/CART_SESSION_CHECK';
// const SESSION_REMOVE = 'pirates-data/SESSION.REMOVE';
// const SESSION_APPTOKEN = 'pirates-data/SESSION.APPTOKEN';

// const [check_session, SESSION_CHECK_OK, SESSION_CHECK_FAIL] = createApiRequest(
//   SESSION_CHECK,
//   session_api.get_session,
// );
// const [cart_check_session, CART_SESSION_CHECK_OK, CART_SESSION_CHECK_FAIL] =
//   createApiRequest(CART_SESSION_CHECK, session_api.get_cart_session);
// const [create_session, SESSION_CREATE_OK, SESSION_CREATE_FAIL] =
//   createApiRequest(SESSION_CREATE, session_api.sign_in_with_provider);

// const [remove_session, SESSION_REMOVE_OK, SESSION_REMOVE_FAIL] =
//   createApiRequest(SESSION_REMOVE, session_api.sign_out);
// const [apptoken_session, SESSION_APPTOKEN_OK, SESSION_APPTOKEN_FAIL] =
//   createApiRequest(SESSION_APPTOKEN, session_api.add_app_token);

// const state = {
//   isLoading: true,
//   isSuccess: false,
//   isError: false,
//   account: { id: null, name: null },
// };

// const reducer = handleActions(
//   {
//     [SESSION_CHECK]: (state) => ({
//       ...state,
//       isLoading: true,
//     }),
//     [SESSION_CHECK_OK]: (state, action) => ({
//       ...state,
//       account: action.payload.account,
//       isLoading: false,
//       isSuccess: true,
//     }),
//     [SESSION_CHECK_FAIL]: (state, action) => ({
//       ...state,
//       account: action.payload.account,
//       isLoading: false,
//       isSuccess: false,
//       isError: true,
//     }),
//     [CART_SESSION_CHECK]: (state) => ({
//       ...state,
//       isLoading: true,
//     }),
//     [CART_SESSION_CHECK_OK]: (state, action) => ({
//       ...state,
//       account: action.payload.account,
//       isLoading: false,
//       isSuccess: true,
//     }),
//     [CART_SESSION_CHECK_FAIL]: (state, action) => ({
//       ...state,
//       account: action.payload.account,
//       isLoading: false,
//       isSuccess: false,
//       isError: true,
//     }),

//     [SESSION_CREATE]: (state) => ({
//       ...state,
//     }),
//     [SESSION_CREATE_OK]: (state, action) => {
//       window.location.reload();
//       return {
//         ...state,
//         account: action.payload.account,
//       };
//     },

//     [SESSION_REMOVE]: (state) => ({
//       ...state,
//     }),
//     [SESSION_REMOVE_OK]: (state, action) => ({
//       ...state,
//       account: { id: null, name: null },
//     }),
//     [SESSION_APPTOKEN]: (state) => ({
//       ...state,
//     }),
//     [SESSION_APPTOKEN_OK]: (state, action) => ({
//       ...state,
//       account: { id: null, name: null },
//     }),
//   },
//   state,
// );

// export {
//   check_session,
//   cart_check_session,
//   create_session,
//   remove_session,
//   apptoken_session,
// };

// export default reducer;
import * as session_api from 'api/session';
import { handleActions } from 'redux-actions';

import createApiRequest from '../_support';
// import { useCookies } from 'react-cookie';

const CHECK_SESSION = 'pirates-data/SESSION.CHECK';
const SESSION_APPTOKEN = 'pirates-data/SESSION.APPTOKEN';

const [check_session, CHECK_SESSION_OK] = createApiRequest(
  CHECK_SESSION,
  session_api.get_profile
);
const [apptoken_session, SESSION_APPTOKEN_OK, SESSION_APPTOKEN_FAIL] =
  createApiRequest(SESSION_APPTOKEN, session_api.add_app_token);
const state = {
  address: { building: null, street: null },
  business: null,
  email: null,
  identity: null,
  memberType: null,
  name: null,
  phone: null,
  profileImage: null,
  registerAt: null,
};

const reducer = handleActions(
  {
    [CHECK_SESSION]: (state) => ({
      ...state,
    }),
    [CHECK_SESSION_OK]: (state, action) => {
      if (action.payload === undefined) {
        return { ...state };
      }
      return {
        ...state,
        address: {
          building: action.payload.address.building,
          street: action.payload.address.street,
        },
        business: action.payload.business,
        email: action.payload.email,
        identity: action.payload.identity,
        memberType: action.payload.memberType,
        name: action.payload.name,
        phone: action.payload.phone,
        profileImage: action.payload.profileImage,
        registerAt: action.payload.registerAt,
      };
    },
    [SESSION_APPTOKEN]: (state) => ({
      ...state,
    }),
    [SESSION_APPTOKEN_OK]: (state) => ({
      ...state,
      /** 과거 로직 체크 */
      // account: { id: null, name: null },
    }),
  },
  state
);

export { apptoken_session, check_session };

export default reducer;

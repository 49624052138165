import styled, { css } from 'styled-components';

const PartDivider = ({ children, level = 1 }) => {
  return <Container level={level}>{children}</Container>;
};

const Container = styled.ul`
  position: relative;
  ${({ level, theme }) => {
    return css`
      > * {
        border-top: ${level}px solid ${theme.newGray2};
      }
    `;
  }}
  >*:first-child {
    border-top: none;
  }
`;

export default PartDivider;

import { motion } from 'framer-motion/dist/framer-motion';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const DragSliderCarousel = ({
  animate,
  carouselWidthCalcDep,
  children,
  handleMouseDown,
  handleMouseUp,
  itemGap = 8,
  margin,
}) => {
  const carousel = useRef(null);
  const [carouselWidth, setCarouselWidth] = useState(0);

  useEffect(() => {
    setCarouselWidth(
      carousel.current.scrollWidth - carousel.current.offsetWidth
    );
  }, [carouselWidthCalcDep]);

  return (
    <OuterCarousel className="carousel" whileTap={{ cursor: 'grabbing' }}>
      <InnerCarousel
        ref={carousel}
        animate={animate}
        className="inner-carousel"
        drag="x"
        dragConstraints={{ left: -carouselWidth, right: 0 }}
        initial={{ x: 0 }}
        itemGap={itemGap}
        margin={margin}
        transition={{ duration: 0.1 }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {children}
      </InnerCarousel>
    </OuterCarousel>
  );
};

const OuterCarousel = styled(motion.div)`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding-right: 16px;
`;

const InnerCarousel = styled(motion.div)`
  display: flex;
  gap: ${({ itemGap }) => itemGap}px;
  margin: ${({ margin }) => margin ?? '0 16px'};
  margin-left: 16px;
`;

export default DragSliderCarousel;

import styled from 'styled-components';

const SingleLens = ({
  className = '',
  fill = '#9FA4AA',
  onClick = () => {},
  size = 24,
}) => {
  return (
    <Svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        clipRule="evenodd"
        d="M17.442 15.745C18.527 14.307 19.178 12.525 19.178 10.589C19.178 5.853 15.324 2 10.589 2C5.853 2.001 2 5.854 2 10.59C2 15.325 5.853 19.179 10.589 19.179C12.524 19.179 14.307 18.528 15.745 17.443L20.352 22.05C20.586 22.284 20.893 22.401 21.2 22.401C21.507 22.401 21.814 22.284 22.049 22.049C22.517 21.581 22.517 20.821 22.049 20.352L17.442 15.745ZM10.589 4.401C14.001 4.401 16.777 7.177 16.777 10.589C16.777 14.001 14.001 16.777 10.589 16.777C7.176 16.778 4.4 14.002 4.4 10.59C4.4 7.177 7.176 4.401 10.589 4.401Z"
        fill={fill}
        fillRule="evenodd"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  cursor: pointer;
`;

export default SingleLens;

import { Box } from 'components/atoms';
import { useMemo } from 'react';
import styled from 'styled-components';

import SortFilter from './\bSortFilter';

const BoardTitleSection = ({
  backgroundColor = 'white',
  children,
  globalKeyword,
  margin = '0 16px 16px 0px',
  orderOption,
  orderState,
  padding = '32px 0 0 0',
  setOrderState,
  subtitle,
  title,
}) => {
  const clickListOrder = (e) => {
    setOrderState(e.target.id);
  };

  const selectedValue = useMemo(
    () => orderOption?.find((item) => item.key === orderState)?.label,
    [orderState, orderOption]
  );

  return (
    <Box backgroundColor={backgroundColor} display="block" padding={padding}>
      {title && (
        <Wrap>
          <Title margin={globalKeyword ? '32px 16px 16px 0px' : margin}>
            {title}
            {subtitle && <span>{subtitle}</span>}
          </Title>
          {orderOption && (
            <SortFilter
              option={orderOption}
              value={selectedValue}
              onChangeSelection={clickListOrder}
            />
          )}
        </Wrap>
      )}
      {children}
    </Box>
  );
};

export default BoardTitleSection;

const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
`;

const Title = styled.h3`
  margin: ${({ margin }) => margin && margin};
  /* padding: 7px 15px; */
  color: #26282b;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.3px;
`;

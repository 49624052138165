import { useQuery } from '@tanstack/react-query';
import { getOrderHistoryDetail } from 'api/mypage';
import { ToastContext } from 'context/toast';
import { useContext } from 'react';

import myPageKeys from './keys';

export default function useOrderHistoryDetail(orderCode) {
  const { showToast } = useContext(ToastContext);

  return useQuery(
    myPageKeys.getOrderHistoryDetail(),
    () => getOrderHistoryDetail(orderCode),
    {
      enabled: !!orderCode,
      onError: () => {
        showToast('주문상세내역을 가져오지 못했습니다.');
      },
    }
  );
}

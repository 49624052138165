import { createSlice } from '@reduxjs/toolkit';

import { fetchAddresses } from './thunks';

const initialState = {
  addresses: [],
  selected: {},
  status: 'idle',
};

const addressSlice = createSlice({
  initialState,
  name: 'address',
  extraReducers: {
    [fetchAddresses.pending.type]: (state) => {
      state.status = 'loading';
    },
    [fetchAddresses.fulfilled.type]: (state, { payload = [] }) => {
      const defaultAddress =
        payload.find((address) => address.default) || payload[0];

      const newAddresses = payload
        .filter((address) => {
          if (address.default) {
            return false;
          }
          if (address.id === defaultAddress?.id) {
            return false;
          }
          if (address.deleted) {
            return false;
          }
          return true;
        })
        .sort((a, b) => a.id < b.id);
      state.addresses = [defaultAddress, ...newAddresses];
      state.status = 'success';

      if (defaultAddress) {
        state.selected = defaultAddress;
      }
    },
    [fetchAddresses.rejected.type]: (state) => {
      state.status = 'error';
    },
  },
  reducers: {
    resetAddress: (state) => {
      state.addresses = initialState.addresses;
      state.selected = initialState.selected;
      state.status = initialState.status;
    },
    selectAddress: (state, { payload = {} }) => {
      if (!payload.id) {
        state.selected = {};
        return;
      }
      state.selected = payload;
    },
  },
});

export const { selectAddress, resetAddress } = addressSlice.actions;
export default addressSlice.reducer;

import { loadSDK } from 'components/hook';

import credentials from '.config/credentials';

const KakaoButton = ({ children, style }) => {
  loadSDK(
    () => {
      window.Kakao.init(credentials.kakao['app-id']);
      const check = window.Kakao.isInitialized();
    },
    {
      module: 'kakao',
      src: 'https://developers.kakao.com/sdk/js/kakao.min.js',
    }
  );

  const onClickKakaoButton = () => {
    return window.Kakao?.Link?.sendScrap({
      fail(error) {},
      requestUrl: window.location.href,
      success(response) {},
    });
  };
  return (
    <button
      className="btn fl-right use-share-count"
      id="kakao-link-btn"
      style={style}
      onClick={onClickKakaoButton}
    >
      {children}
    </button>
  );
};

export { KakaoButton };

import './style.css';

import getAccountUrl from 'api/getAccountUrl';
import AnimationSkeleton from 'components/atoms/Skeleton/AnimationSkeleton';
import { BoardTag } from 'components/common';
import LoginContext, { View } from 'components/common/login/_context';
import { motion, usePresence } from 'framer-motion/dist/framer-motion';
import { useFindOutDragging } from 'hooks/custom/useFindOutDragging';
import { useUpdateRecommendContentsLike } from 'hooks/Mutate/Contents';
import { useUpdateRecommendLike } from 'hooks/Query/MarketTap';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { globalSearchKeyword } from 'recoil/search';
import styled, { css } from 'styled-components';
import { getImageSrc, imagePreloader } from 'utils';

const variants = {
  center: {
    opacity: 1,
    // zIndex: 1,
    x: 0,
  },
  enter: (direction) => {
    return {
      opacity: 0,
      x: direction > 0 ? 200 : -200,
    };
  },
  exit: (direction) => {
    return {
      opacity: 0,
      // zIndex: 0,
      x: direction < 0 ? 200 : -200,
    };
  },
};
const swipeConfidenceThreshold = 6000;

const thumbnailTags = ['yesterday-price', 'today-price'];

/**
 * @prop type : 'market'(시장) | 'content'(콘텐츠) | 'recommend'(추천)
 * @description  type 프롭스에 recommend가 추가된 이유는 패딩 스타일로 인해서 추가 됨
 */
const Carousel = ({
  bottomRender,
  isPrice,
  items,
  preventClick = false,
  refetchItems,
  session,
  type,
}) => {
  const history = useHistory();
  const isMarket = type === 'market';
  const isContent = type === 'content' || type === 'recommend';
  const ishome = type === 'recommend';

  const [[page, direction], setPage] = useState([0, 0]);
  const { handleMouseDown, handleMouseUp, isDragging } = useFindOutDragging();
  const [globalSearch, setGlobalSearch] = useRecoilState(globalSearchKeyword);

  const { mutateAsync: putContentLike } = useUpdateRecommendContentsLike();
  const { mutateAsync: putMarketLike } = useUpdateRecommendLike();
  const { action } = useContext(LoginContext);
  /** AnimatePresence를 한번에 처리 할 수 있는 훅 (글씨 깜빡임 잡을 수 있음) */
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    !isPresent && setTimeout(safeToRemove, 2000);
  }, [isPresent, safeToRemove]);

  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };
  const paginate = (newDirection) => {
    const changedItemIndex = page + newDirection;
    const lastIndex = items && items.length - 1;
    if (changedItemIndex < 0) {
      setPage([lastIndex, newDirection]);
      return;
    }
    if (changedItemIndex > lastIndex) {
      setPage([0, newDirection]);
      return;
    }
    setPage([page + newDirection, newDirection]);
  };

  const onClickImage = () => {
    if (isDragging) return;
    if (preventClick) return;
    if (isMarket) history.push(items[page]?.uri);
    if (isContent) {
      if (!items[page]?.url || items[page]?.url === '') {
        history.push(`/콘텐츠/${items[page]?.uri}`);
        return;
      }
      window.location.href = items[page].url;
    }
  };

  const onClickDescription = () => {
    if (preventClick) return;
    if (isMarket) history.push(items[page]?.uri);
    if (isContent) {
      if (!items[page]?.url || items[page]?.url === '') {
        history.push(`/콘텐츠/${items[page]?.uri}`);
        return;
      }
      window.location.href = items[page].url;
    }
  };

  const handleInterval = useCallback(() => {
    const interval = setInterval(() => {
      setPage(([currPage]) => {
        if (currPage + 1 >= items.length) {
          return [0, 1];
        }
        return [currPage + 1, 1];
      });
    }, 5000);
    return () => clearInterval(interval);
  }, [items]);

  useEffect(() => {
    if (items?.length <= 1) return;
    return handleInterval();
  }, [page, items, handleInterval]);

  const sortTags = () => {
    const tagFilter = (items?.[page]?.tags || []).filter(
      (i) => !thumbnailTags.includes(i)
    );

    if (isMarket && tagFilter) {
      const result = tagFilter.reduce((acc, curr) => {
        if (curr === 'sale-coupon') {
          acc.unshift('sale-coupon');
        }
        if (curr === 'app-order') {
          acc.push('app-order');
        }
        return acc;
      }, []);
      return result;
    }
    return [];
  };

  useEffect(() => {
    const thumbnails = items?.map((item) => item.thumbnail);
    if (thumbnails?.length) {
      imagePreloader(thumbnails);
    }
  }, [items]);

  // 슬라이드 배너 찜하기
  const onClickFavorite = async (item, e) => {
    e.stopPropagation();
    if (session.identity) {
      if (isMarket) {
        await putMarketLike({
          contentsType: 1,
          doLike: item.favorite === 'off',
          godoContents: item,
          targetCode: item.id,
          type: 'like',
        });
      } else {
        /** @param contentsType 시장이 아닌 데이터는 2를 보냄 */
        await putContentLike({
          contentsType: 2,
          doLike: item.favorite === 'off',
          godoContents: item,
          targetCode: item.id,
          type: 'like',
        });
      }
    } else {
      action.open(View.CHANNEL, {
        onOk: () => {
          try {
            const redirectUrl = new URL(window.location.href);
            const pathname = decodeURI(window.location.pathname);

            redirectUrl.pathname = pathname;

            sessionStorage.setItem(
              'session-carousel-heart-data',
              JSON.stringify({
                globalSearch: globalSearch || '',
                item,
                page,
                path: pathname,
              })
            );

            window.location.href = getAccountUrl({
              redirectUrl: redirectUrl.href,
            });
          } catch {
            window.location.href = getAccountUrl({
              redirectUrl: window.location.href,
            });
          }
        },
      });
    }
  };

  const sessionHeartDataCheck = useCallback(() => {
    const sessionCarouselHeartData = JSON.parse(
      sessionStorage.getItem('session-carousel-heart-data')
    );

    if (sessionCarouselHeartData) {
      setGlobalSearch(sessionCarouselHeartData?.globalSearch || '');
      if (isMarket) {
        putMarketLike({
          contentsType: 1,
          doLike: true,
          godoContents: sessionCarouselHeartData?.item,
          targetCode: sessionCarouselHeartData?.item.id,
          type: 'like',
        }).finally(() => {
          /** 시장탭은 순서가 랜덤으로 바뀌기 때문에 배열 메소드 사용 */
          if (items?.length > 0) {
            const arr = [...(items || [])];
            const findIndex = arr.findIndex(
              (list) => list.id === sessionCarouselHeartData?.item?.id
            );
            setPage((prev) => [findIndex >= 0 ? findIndex : prev[0], prev[1]]);
          }
          sessionStorage.removeItem('session-carousel-heart-data');
          if (refetchItems) refetchItems();
        });
      } else {
        putContentLike({
          contentsType: 2,
          doLike: true,
          godoContents: sessionCarouselHeartData?.item,
          targetCode: sessionCarouselHeartData?.item.id,
          type: 'like',
        }).finally(() => {
          sessionStorage.removeItem('session-carousel-heart-data');
          setPage((prev) => [sessionCarouselHeartData.page, prev[1]]);
          if (refetchItems) refetchItems();
        });
      }
    }
  }, [isMarket, items, putContentLike, putMarketLike, setGlobalSearch]);

  useEffect(() => {
    if (session?.identity) {
      sessionHeartDataCheck();
    }
  }, [session?.identity, sessionHeartDataCheck]);

  if (!items) {
    return (
      <EmptyCarouselWrapper>
        <AnimationSkeleton />
      </EmptyCarouselWrapper>
    );
  }

  // 시장쪽 배너
  return (
    <Container>
      <ImageContainer>
        <StyledMotionImg
          key={`carousel-img-${page}`}
          alt={items?.[page]?.uri}
          animate="center"
          custom={direction}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          exit="exit"
          initial="enter"
          ishome={ishome.toString()}
          src={getImageSrc(items?.[page]?.thumbnail)}
          transition={{
            opacity: { duration: 0.05 },
            x: { damping: 40, stiffness: 400, type: 'spring' },
          }}
          variants={variants}
          onClick={onClickImage}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        />
        {/* 배너안에 태그 */}
        {isMarket && (
          <StyledMotionDiv
            key={`carousel-market-${page}`}
            animate="center"
            custom={direction}
            exit="exit"
            initial="enter"
            ishome={ishome.toString()}
            transition={{
              opacity: { duration: 0.05 },
              x: { damping: 40, stiffness: 400, type: 'spring' },
            }}
            variants={variants}
          >
            <BoardTag tags={sortTags()} slide />
          </StyledMotionDiv>
        )}

        {/* 배너안에 페이지 표시 */}
        {items?.length > 1 && (
          <PageBox isRecommendType={type === 'recommend'}>
            <CurrentPage>{page + 1}</CurrentPage>
            <Bar />
            {items?.length}
          </PageBox>
        )}
      </ImageContainer>

      {bottomRender && (
        <TextWrapper onClick={onClickDescription}>
          {bottomRender({ items, onClickFavorite, page })}
        </TextWrapper>
      )}
    </Container>
  );
};

export default Carousel;

const EmptyCarouselWrapper = styled.div`
  width: 100%;
  aspect-ratio: 420 / 190;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledMotionImg = styled(motion.img)`
  cursor: pointer;
  aspect-ratio: 420 / 190;
  max-width: 100%;

  ${({ ishome }) => {
    if (ishome === 'true') {
      return css`
        border-radius: 5px;
        width: calc(100% - 32px);
      `;
    }
    return css`
      width: 100%;
    `;
  }}
`;

const StyledMotionDiv = styled(motion.div)`
  position: absolute;
  bottom: 16px;
  z-index: 10;
  ${({ ishome }) => {
    if (ishome === 'true') {
      return css`
        left: 32px;
      `;
    }
    return css`
      left: 16px;
    `;
  }}
`;

const Bar = styled.span`
  width: 1px;
  height: 8px;
  background-color: wheat;
  opacity: 0.5;
`;

const CurrentPage = styled.span`
  color: white;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.3px;
`;

const PageBox = styled.div`
  position: absolute;
  z-index: 2;
  right: ${({ isRecommendType }) => (isRecommendType ? '32px' : '16px')};
  bottom: 16px;
  border-radius: 100px;
  opacity: 0.7;
  background: var(--new-black-white-black, #000);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.3px;
  padding: 0 12px;
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bottom_desc {
    display: flex;
    flex-direction: column;
    gap: 3px;
    cursor: pointer;
  }
  .store_title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
  }
  .store_rating {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const TAG = {
  'app-order': '앱주문',
  'convenience': '편의점픽업',
  'day-delivery': '당일배송',
  'package': '포장',
  'quick-delivery': '퀵배달',
  'sale-coupon': '쿠폰',
  'today-price': '오늘시세',
  'yesterday-price': '전일시세',
};

const DELIVERY = {
  EXPRESS: '고속버스 화물',
  PARCEL: '택배',
  QUICK: '퀵서비스',
};

export const ORDERTYPE = {
  CONVENIENCE_STORE: '편의점 픽업',
  DAY_DELIVERY: '당일배송',
  PACKAGE: '포장',
  QUICK: '퀵 배달',
};

const WEEK_OF_DAYS = {
  FRIDAY: '금요일',
  MONDAY: '월요일',
  SATURDAY: '토요일',
  SUNDAY: '일요일',
  THURSDAY: '목요일',
  TUESDAY: '화요일',
  WEDNESDAY: '수요일',
};

export const tagOf = (tag_code) => {
  if (TAG[tag_code]) {
    return TAG[tag_code];
  }
  return tag_code;
};

export const deliveryOf = (code) => {
  return DELIVERY[code];
};

export const weekOfDay = (code) => {
  return WEEK_OF_DAYS[code];
};

import React from 'react';
import styled from 'styled-components';
import { IconButton } from '../index';
import Box from '../Box';
import { Minus, Plus } from 'components/Icons';

const PlusMinusInput = ({ id, forwardRef, value, onChange }) => {
  return (
    <StyledBox>
      <IconButton
        onClick={() => {
          if (typeof onChange === 'function' && value > 1) {
            onChange({
              id,
              value: +value - 1,
            });
          }
        }}
      >
        <Minus fill="#464C53" size={16} />
      </IconButton>
      <Input
        ref={forwardRef}
        inputMode="numeric"
        pattern="\d*"
        value={value}
        onChange={(e) => {
          const value = e.target.value.replace(/[^0-9]/g, '');

          if (!value || value.length > 2) {
            return;
          }

          if (typeof onChange === 'function') {
            onChange({
              id,
              value: +value || value,
            });
          }
        }}
      />
      <IconButton
        onClick={() => {
          if (typeof onChange === 'function' && value < 99) {
            onChange({
              id,
              value: +value + 1,
            });
          }
        }}
      >
        <Plus fill="#464C53" size={16} />
      </IconButton>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.gray300};
`;

const Input = styled.input`
  border: none;
  border-left: 1px solid ${({ theme }) => theme.gray300};
  border-right: 1px solid ${({ theme }) => theme.gray300};
  display: block;
  text-align: center;
  width: 32px;
  height: 24px;
`;

export default PlusMinusInput;

import { useInfiniteQuery } from '@tanstack/react-query';
import { getLatestPriceByStore } from 'api/Prices';
import { extractInfinityDataFromDeep } from 'utils/reactQueryFormatter';

import priceKeys from './keys';

export default function useGetLatestPriceByStore({ id, name, size = 20 }) {
  return useInfiniteQuery(
    priceKeys.getLatestPriceByStore({ id, name }),
    ({ pageParam = 0 }) =>
      getLatestPriceByStore({ id, name, page: pageParam, size }),
    {
      enabled: !!id,
      getNextPageParam: (pagination) => {
        const { last, number } = pagination;
        return last ? null : number + 1;
      },
      select: (data) => {
        return extractInfinityDataFromDeep(data, 'content');
      },
    }
  );
}

import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
  name: 'global',
  initialState: {},
  reducers: {
    setGlobal: (state, { payload = {} }) => {
      const { key, value } = payload;
      state[key] = value;
    },
  },
});

export const { setGlobal } = globalSlice.actions;
export default globalSlice.reducer;

import { createContext, useReducer } from 'react';

import LoginView from '../login/index';
import getAccountUrl from '../../../api/getAccountUrl';

const LoginContext = createContext({});

const View = {
  CHANNEL: 'login.view/LOGINVIEW',
  CONTRACT_LOCATION_TMP: 'login.view/CONTRACT.LOCATION_TMP',
  CONTRACT_PRIVACY_TMP: 'login.view/CONTRACT.PRIVACY_TMP',
  CONTRACT_SERVICE_TMP: 'login.view/CONTRACT.SERVICE_TMP',
};

const init_state = {
  data: {},
  isOpen: false,
  confirm: false,
  view: {
    current: null,
    prev: null,
  },
};

const Action = {
  CHANGE_VIEW: 'login.action/CHANGE-VIEW',
  INITIALIZE: 'login.action/INITIALIZE',
  LOGIN_CONFIRM_VIEW: 'login.action/LOGIN_CONFIRM_VIEW',
};

const reducer = (state, action) => {
  const { data, view } = action.payload || {};

  switch (action.type) {
    case Action.INITIALIZE:
      return { ...init_state };
    case Action.CHANGE_VIEW:
      return {
        ...state,
        data: { ...state.data, ...data },
        isOpen: true,
        view: {
          current: view,
          prev: state.view.current,
        },
      };
    case Action.LOGIN_CONFIRM_VIEW:
      return {
        ...state,
        data: { ...state.data, ...data },
        isOpen: true,
        view: {
          current: view,
          prev: state.view.current,
        },
      };
    default:
      return state;
  }
};

/**
 * social sign in
 *
 * @param channel
 * @param token
 * @returns {Promise<any>}
 */

const LoginProvider = ({ children }) => {
  // const location = useLocation();
  // const history = useHistory();

  const [_state, _dispatch] = useReducer(reducer, init_state);

  const action = {
    // 로그인 모달 닫기
    close: (view) => {
      if (!_state.confirm) sessionStorage.removeItem('preLoginState');
      if (view) {
        _dispatch({
          payload: { view: _state.view.prev },
          type: Action.LOGIN_CONFIRM_VIEW,
        });
      } else {
        _dispatch({ type: Action.INITIALIZE });
      }
    },
    close_contract: () => {
      _dispatch({ type: Action.INITIALIZE });
    },
    close_at_login: () => {
      _state.confirm = true;
      _dispatch({ type: Action.INITIALIZE });
      window.location.href = getAccountUrl({
        redirectUrl: window.location.href,
      });
    },
    // 로그인 모달 열기
    open: (view, data) => {
      if (
        view.includes('SERVICE_TMP') ||
        view.includes('PRIVACY_TMP') ||
        view.includes('LOCATION_TMP')
      ) {
        // history.push({ ...location });
        _dispatch({ payload: { data, view }, type: Action.CHANGE_VIEW });
        return;
      }
      // history.push({ ...location });
      _dispatch({
        payload: { data, view },
        type: Action.LOGIN_CONFIRM_VIEW,
      });

      // open: (view, data) => {
      //   if (
      //     view.includes('SERVICE_TMP') ||
      //     view.includes('PRIVACY_TMP') ||
      //     view.includes('LOCATION_TMP')
      //   ) {
      //     history.push({ ...location });
      //     _dispatch({ type: Action.CHANGE_VIEW, payload: { view, data } });
      //     return;
      //   }
      //   history.push({ ...location, search: '?loginview' });
      //   _dispatch({ type: Action.CHANGE_VIEW, payload: { view, data } });
    },
  };
  return (
    <LoginContext.Provider value={{ action, state: _state }}>
      {children}
      <LoginView action={action} state={_state} bgClick />
    </LoginContext.Provider>
  );
};

export { LoginProvider, View };
export default LoginContext;

import styled, { keyframes } from 'styled-components';

const AnimationSkeleton = ({ borderRadius, height, width }) => {
  return (
    <Container borderRadius={borderRadius} height={height} width={width} />
  );
};
const Loading = keyframes`
    0% {
        background-position: 0% 0;
    }
    100% {
        background-position: -200% 0;
    }
`;

const Container = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  /* min-width: 40px; */
  min-height: 20px;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : '5px'};
  background-image: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.2)
  );
  background-size: 200% 100%;
  /* animation: ${Loading} 2s ease-in-out infinite; */
`;
export default AnimationSkeleton;

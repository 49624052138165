import no_image_1x1 from 'components/images/no-image_1x1_grey.jpg';
import no_image_1x1_profile from 'components/images/no-image_1x1_profile.svg';
import no_image_11x5 from 'components/images/no-image_11x5_grey.jpg';
import no_image_16x9 from 'components/images/no-image_16x9.jpg';
import no_image_16x9_logo from 'components/images/no-image_16x9_logo.jpg';
import no_image_mypage from 'components/images/no-image_mypage.svg';
import React, { useCallback, useRef, useState } from 'react';

const Image = (attr) => {
  const {
    alt = '',
    flick,
    height,
    noImageType = '',
    onLoad,
    origin,
    resizeWidth,
    scale,
    src,
    width, // logo 이미지 넣고싶을때만 프롭스에 'logo'추가
    ...other
  } = attr;
  const isObject = typeof src === 'object';
  const imageRef = useRef();
  const [errorCount, setErrorCount] = useState(0);

  const no_image = (() => {
    switch (scale) {
      case '11x5':
        return no_image_11x5;
      case '16x9':
        if (noImageType === 'logo') {
          return no_image_16x9_logo;
        }
        return no_image_16x9;
      case '1x1_profile':
        return no_image_1x1_profile;
      case 'mypage':
        return no_image_mypage;
      default:
        return no_image_1x1;
    }
  })();

  const flickOnOff = () => {
    if (flick) {
      const temp = document.querySelector('.pinch-to-zoom-area');
      if (temp) {
        if (temp.style.transform) {
          const scale = Number(
            temp.style.transform
              .match(/scale\((.+?)\)/)[0]
              .replace('scale(', '')
              .replace(')', '')
          );
          if (scale > 1.05) {
            flick.current.flicking.disableInput();
          } else {
            flick.current.flicking.enableInput();
          }
        }
      }
    }
  };

  const findSrc = () => {
    if (isObject) {
      switch (errorCount) {
        case 0:
          return src?.crop;
        case 1:
          return src.thumbnail;
        case 2:
          return src.origin;
        default:
          if (origin) return origin;
          return no_image;
      }
    } else {
      switch (errorCount) {
        case 0:
          return src;
        default:
          if (origin) return origin;
          return no_image;
      }
    }
  };

  const handleOnLoad = useCallback(() => {
    if (onLoad) {
      onLoad(imageRef);
    }
  }, [onLoad]);

  return (
    <img
      ref={imageRef}
      alt={alt}
      src={resizeWidth ? `${findSrc()}?w=${resizeWidth}` : findSrc()}
      onTouchEnd={flickOnOff}
      onTouchStart={flickOnOff}
      {...other}
      height={height}
      width={width}
      onError={(e) => {
        if (isObject && errorCount < 3) {
          setErrorCount((v) => v + 1);
        } else if (errorCount === 0) {
          if (origin) e.target.src = origin;
          else e.target.src = no_image;

          setErrorCount(1);
        } else {
          e.target.src = no_image;
        }
      }}
      onLoad={handleOnLoad}
    />
  );
};

export default React.memo(Image);

export function business_status(code) {
  switch (code) {
    case 'OPEN':
      return '영업중';
    case 'READY':
      return '준비중'; // 원래 영업준비
    case 'CLOSED':
      return '영업종료';
    case 'DAY_OFF':
      return '휴무';
  }
}

import './style.css';

const PanelItem = ({
  align,
  children,
  color,
  description,
  disabled,
  label,
  labelStyle,
}) => {
  return (
    <div
      className="item"
      style={{
        alignItems: align,
        display: disabled ? 'none' : 'flex',
      }}
    >
      <label style={labelStyle}>{label}</label>
      {description && <span className={color || ''}>{description}</span>}
      {children}
    </div>
  );
};

export default PanelItem;

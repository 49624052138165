import { handleActions } from 'redux-actions';

import createApiRequest from './_support';

const SAVE_HISTORY = 'pirates-data.history/SAVE_HISTORY';

const [save_history, SAVE_HISTORY_OK] = createApiRequest(
  SAVE_HISTORY,
  (data, key, hash) => ({ data: { data, hash, key } })
);

const initState = {
  data: {},
};

const history = handleActions(
  {
    [SAVE_HISTORY]: (state) => ({ ...state }),
    [SAVE_HISTORY_OK]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: {
            ...state.data[action.payload.key],
            [action.payload.hash ? action.payload.hash : 'default']:
              action.payload.data,
          },
        },
      };
    },
  },
  initState
);

export { save_history };

export default history;

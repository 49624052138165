/* eslint-disable no-undef */
const environments = {
  dev: {
    ACCOUNT_API_URL: 'https://api.test.tpirates.com',
    ACCOUNT_URL: 'https://account.test.tpirates.com',
    CART_API_URL: 'https://cart.test.tpirates.com',
    CART_URL: 'https://cart.test.tpirates.com/fish-market',
    FISH_MARKET_API_URL: 'https://pub-api.test.tpirates.com',
    FISH_MARKET_URL: 'https://test.tpirates.com',
    MY_URL: 'https://market.test.tpirates.com/mypage',
    OPEN_MARKET_URL: 'https://market.test.tpirates.com',
    PRICE_API_URL: 'https://api-feat.test.tpirates.com/v2',
    SHOPPING_MALL_URL: 'https://shop.tpirates.com',
    USER_API_URL: 'https://api.test.tpirates.com',
    addressUrl: 'https://address.test.tpirates.com',
    api: {
      host: 'pub-api.test.tpirates.com',
      path: 'v2',
      port: null,
      protocol: 'https',
    },
    domain: 'test.tpirates.com',
    rUrl: 'https://pub-api.test.tpirates.com/v2/www/quick/payment-result',
    rUrl2:
      'https://pub-api.test.tpirates.com/v2/www/payment-result/day-delivery',
    tools: {
      ga: 'UA-175200202-1',
      gtm: 'GTM-WV49LKS',
    },
  },
  local: {
    ACCOUNT_API_URL: 'https://api.test.tpirates.com',
    ACCOUNT_URL: 'https://account.test.tpirates.com',
    CART_API_URL: 'https://cart.test.tpirates.com',
    CART_URL: 'https://cart.test.tpirates.com/fish-market',
    FISH_MARKET_API_URL: 'https://pub-api.test.tpirates.com',
    FISH_MARKET_URL: 'http://localhost:3000',
    MY_URL: 'https://market.test.tpirates.com/mypage',
    OPEN_MARKET_URL: 'https://market.test.tpirates.com',
    PRICE_API_URL: 'https://api-feat.test.tpirates.com/v2',
    SHOPPING_MALL_URL: 'https://shop.tpirates.com',
    USER_API_URL: 'https://api.test.tpirates.com',
    addressUrl: 'https://address.test.tpirates.com',
    api: {
      host: 'pub-api.test.tpirates.com',
      path: 'v2',

      // host: '192.168.0.73:8080',
      port: null,

      protocol: 'https',
    },
    domain: 'localhost',
    rUrl: 'https://pub-api.test.tpirates.com/v2/www/quick/payment-result',
    rUrl2:
      'https://pub-api.test.tpirates.com/v2/www/payment-result/day-delivery',
    tools: {
      ga: 'UA-175200202-1',
      gtm: 'GTM-WV49LKS',
    },
  },
  prod: {
    ACCOUNT_API_URL: 'https://api.tpirates.com',
    ACCOUNT_URL: 'https://account.tpirates.com',
    CART_API_URL: 'https://cart.tpirates.com',
    CART_URL: 'https://cart.tpirates.com/fish-market',
    FISH_MARKET_API_URL: 'https://pub-api.tpirates.com',
    FISH_MARKET_URL: 'https://www.tpirates.com',
    MY_URL: 'https://market.tpirates.com/mypage',
    OPEN_MARKET_URL: 'https://market.tpirates.com',
    PRICE_API_URL: 'https://pub-api.tpirates.com',
    SHOPPING_MALL_URL: 'https://shop.tpirates.com',
    USER_API_URL: 'https://api.tpirates.com',
    addressUrl: 'https://address.tpirates.com',
    api: {
      host: 'pub-api.tpirates.com',
      path: 'v2',
      port: null,
      protocol: 'https',
    },
    domain: 'tpirates.com',
    rUrl: 'https://pub-api.tpirates.com/v2/www/quick/payment-result',
    rUrl2: 'https://pub-api.tpirates.com/v2/www/payment-result/day-delivery',
    tools: {
      ga: 'UA-62874395-1',
      gtm: 'GTM-NFT9WBG',
    },
  },
  stg: {
    ACCOUNT_API_URL: 'https://api.stg.tpirates.com',
    ACCOUNT_URL: 'https://account.stg.tpirates.com',
    CART_API_URL: 'https://cart.stg.tpirates.com',
    CART_URL: 'https://cart.stg.tpirates.com/fish-market',
    FISH_MARKET_API_URL: 'https://pub-api.stg.tpirates.com',
    FISH_MARKET_URL: 'https://stg.tpirates.com',
    MY_URL: 'https://market.stg.tpirates.com/mypage',
    OPEN_MARKET_URL: 'https://market.stg.tpirates.com',
    PRICE_API_URL: 'https://pub-api.stg.tpirates.com',
    SHOPPING_MALL_URL: 'https://shop.tpirates.com',
    USER_API_URL: 'https://api.stg.tpirates.com',
    addressUrl: 'https://address.stg.tpirates.com',
    api: {
      host: 'pub-api.stg.tpirates.com',
      path: 'v2',
      port: null,
      protocol: 'https',
    },
    domain: 'stg.tpirates.com',
    rUrl: 'https://pub-api.stg.tpirates.com/v2/www/quick/payment-result',
    rUrl2:
      'https://pub-api.stg.tpirates.com/v2/www/payment-result/day-delivery',
    tools: {
      ga: 'UA-62874395-1',
      gtm: 'GTM-NFT9WBG',
    },
  },
};

const conf = environments[process.env.REACT_APP_ENV] || 'prod';

const api_host_url = [
  conf.api.protocol,
  '://',
  conf.api.host,
  conf.api.port ? ':' : null,
  conf.api.port,
  '/',
  conf.api.path,
]
  .filter((e) => e != null)
  .join('');

export { conf };
export function api_url(uri) {
  return uri.indexOf('/') === 0 ? api_host_url + uri : `${api_host_url}/${uri}`;
}

import styled from 'styled-components';

const Transparent = styled.button.attrs({
  type: 'button',
})`
  align-items: center;
  background: none;
  border: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: 0;
  padding: 0;
`;

export default Transparent;

import { useQuery } from '@tanstack/react-query';
import { getQuoteDetailContents } from 'api/Prices';
import { convertMinToMS } from 'utils/convertTimeToMS';

import priceKeys from './keys';

export default function useGetQuoteDetailContents({ id }) {
  return useQuery(
    priceKeys.getQuoteDetailContents({ id }),
    () => getQuoteDetailContents({ id }),
    {
      enabled: !!id,
      staleTime: convertMinToMS(5),
    }
  );
}

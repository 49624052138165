/* eslint-disable no-console */
import '../style.css';

import { Box } from 'components/atoms';
import { BoardTag } from 'components/common';
import Star from 'components/Icons/Star';
import { Image } from 'components/views';
import { ToastContext } from 'context/toast';
import counter from 'functions/counter';
import { useCallback, useContext, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Heart } from '../img/heart.svg';
import { ReactComponent as HeartOn } from '../img/heart_on.svg';
const thumbnailTags = ['yesterday-price', 'today-price'];

const WideView = ({
  _handle_store_link,
  handleBannerRef,
  item,
  onClickFavorite,
  session,
  type,
}) => {
  const history = useHistory();
  const tagFilter = item.tags.filter((i) => !thumbnailTags.includes(i));

  const { showToast } = useContext(ToastContext);

  const sortTags = () => {
    const result = [];

    tagFilter.map((i) => {
      if (i === 'sale-coupon') {
        result.unshift('sale-coupon');
      }
      if (i === 'app-order') {
        result.push('app-order');
      }
    });
    return result;
  };

  const location = useLocation();
  const pageTitle = location.pathname.slice(1);

  const bannerRef = useRef();

  const handleOnLoad = useCallback(() => {
    if (handleBannerRef) {
      handleBannerRef(bannerRef);
    }
  }, [handleBannerRef]);

  const formattedRating = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });

  return (
    <Container
      className={[
        'card-template',
        'type1',
        item.state === 'OPEN' ? 'on' : 'off',
      ].join(' ')}
      id="bannerBox"
    >
      <Link
        key={item.id}
        ref={bannerRef}
        to={item.uri}
        onClick={(e) => {
          e.preventDefault();
          if (_handle_store_link) {
            _handle_store_link();
          }
          history.push({
            pathname: item.uri,
            state: pageTitle,
          });
        }}
        onLoad={handleOnLoad}
      >
        <ImageSection className="fixed inner-section type2">
          <ImageWrapper className="img-block">
            <Image
              className="display-block"
              scale="11x5"
              src={item.thumbnail}
            />
          </ImageWrapper>
          {item.state !== 'OPEN' ? (
            <DimContainer className="dim-container" />
          ) : null}

          <Box
            bottom="16px"
            height="26px"
            left="16px"
            overflow="hidden"
            position="absolute"
            // margin="0 40px 0 10px"
          >
            <BoardTag tags={sortTags()} slide />
          </Box>
        </ImageSection>
        <Box
          alignItems="center"
          justifyContent="space-between"
          padding=" 8px 16px"
        >
          <Box display="block">
            <Box
              color="#26282B"
              fontSize="16px"
              fontWeight="500"
              letterSpacing="-0.3px"
              lineHeight="20px"
              marginBottom="4px"
            >
              {item.label}
              <Box
                color="#72787F"
                fontSize="14px"
                fontWeight="400"
                letterSpacing="-0.3px"
                lineHeight="20px"
                marginLeft="8px"
              >
                {item.market}
              </Box>
            </Box>
            <Box alignItems="center">
              <Box
                color="#26282B"
                fontSize="14px"
                fontWeight="400"
                letterSpacing="-0.3px"
                lineHeight="20px"
                marginRight="2px"
              >
                <Star />
                {formattedRating.format(item.summary.rating)}
              </Box>
              <Box
                color="#26282B"
                fontSize="14px"
                fontWeight="400"
                letterSpacing="-0.3px"
                lineHeight="20px"
              >{`(${counter(item.summary.comments)})`}</Box>
            </Box>
          </Box>
        </Box>
      </Link>
      <Box
        bottom="18px"
        position="absolute"
        right="16px"
        onClick={() => {
          onClickFavorite(item, type);
          if (session.identity) {
            if (item.favorite !== 'off') {
              showToast('즐겨찾기 등록이 해제되었습니다.');
            } else {
              showToast('즐겨찾기에 추가되었습니다.마이페이지에서 확인하세요.');
            }
          }
        }}
      >
        {item.favorite === 'off' || !session.identity ? (
          <Heart fill="#EB5757" />
        ) : (
          <HeartOn fill="#EB5757" />
        )}
      </Box>
    </Container>
  );
};

const Container = styled.div``;

const ImageSection = styled.div`
  position: relative;
`;
const ImageWrapper = styled.span`
  &:before {
    position: absolute;
    bottom: 0;
    content: '';
    width: 100%;
    height: 61px;
    /* background: linear-gradient(to bottom, #26282B00, #26282B); */
    background: transparent linear-gradient(0deg, #26282b80 0%, #26282b00 100%)
      0% 0% no-repeat padding-box;
  }
`;
const DimContainer = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 40, 43, 0.4);
`;

export default WideView;

const priceKeys = {
  all: ['price'],
  getContentsByRecommendQuote: ({ id }) => [
    ...priceKeys.all,
    'recommend',
    'quote',
    id,
    'contents',
  ],
  getFilterProductList: () => [...priceKeys.all, 'filter', 'product'],
  getLabelByItemCode: ({ itemCode }) => [
    ...priceKeys.all,
    'label',
    'by',
    'itemCode',
    itemCode,
  ],
  getLabelFavoriteList: () => [...priceKeys.all, 'labelFavoriteList'],
  getLatestPriceByStore: ({ id, name }) => [
    ...priceKeys.all,
    'latestPrice',
    'store',
    id,
    name,
  ],
  getLatestQuoteByStoreAndItem: ({ itemId, storeId }) => [
    ...priceKeys.all,
    'latestQuote',
    'store',
    'item',
    itemId,
    storeId,
  ],
  getLatestView: ({ id }) => [...priceKeys.all, 'latestView', id, 'label'],
  getPriceAggregate: ({ ...rest }) => [...priceKeys.all, 'aggregate', rest],
  getQuoteChart: ({ marketCodeList, month, sizeId }) => [
    ...priceKeys.all,
    'quote',
    'chart',
    sizeId,
    marketCodeList,
    month,
  ],
  getQuoteDetail: ({ id }) => [...priceKeys.all, 'quote', id, 'detail', 'main'],
  getQuoteDetailContents: ({ id }) => [
    ...priceKeys.all,
    'quote',
    id,
    'detail',
    'contents',
  ],
  getQuoteDetailLabels: ({ id, ...rest }) => [
    ...priceKeys.all,
    'quote',
    id,
    'detail',
    'labels',
    rest,
  ],
  getQuoteDetailRecommendLabel: ({ id }) => [
    ...priceKeys.all,
    'quote',
    id,
    'detail',
    'recommendLabel',
  ],
  getRecommendQuoteDetailInfo: ({ id }) => [
    ...priceKeys.all,
    'recommend',
    'quote',
    'info',
    id,
  ],
  getRecommendQuoteDetailList: ({ id, ...rest }) => [
    ...priceKeys.all,
    'recommend',
    'quote',
    'list',
    id,
    rest,
  ],
  getRecommendQuoteList: () => [...priceKeys.all, 'recommend', 'quote'],
  getStoresByIndividualSize: ({ filter, sizeId }) => [
    ...priceKeys.all,
    'stores',
    'size',
    'by',
    sizeId,
    filter,
  ],
};

export default priceKeys;

import { tagOf } from 'components/common/codes';
import Icon from 'components/Icons/v2/Icon';
import styled from 'styled-components';

const Tag = ({ tag }) => {
  switch (tag) {
    case 'sale-coupon':
      return (
        <StyledTagContainer>
          <Icon
            color="var(--Red-red-8, #eb5757)"
            name="coupon-16_1"
            size={16}
          />
          <span>쿠폰</span>
        </StyledTagContainer>
      );
    default:
      return (
        <StyledTag>
          <span>{tagOf(tag)}</span>
        </StyledTag>
      );
  }
};

const tagSet = new Set([
  'day-delivery',
  'quick-delivery',
  'package',
  'app-order',
]);
const BoardTag = ({ slide = false, tags }) => {
  return (
    <StyledBoardTag className={slide ? 'slide' : ''}>
      {[
        ...new Set(tags.map((tag) => (tagSet.has(tag) ? 'app-order' : tag))),
      ].map((tag, i) => (
        <Tag key={`board-tag-${i}`} tag={tag} />
      ))}
    </StyledBoardTag>
  );
};

const StyledBoardTag = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  .sale-coupon {
    color: #eb5757 !important;
    border: 1px solid #eb5757;
    background-color: #ffffff !important;
    border-radius: 4px;
  }

  .app-order,
  .quick-delivery,
  .day-delivery {
    color: #1a1b1e !important;
    border: 1px solid #1a1b1e;
    background-color: #ffffff !important;
    border-radius: 4px;
  }

  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledTagContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 4px;

  border-radius: 4px;
  background: var(--Red-red-1, #fff3f5);

  span {
    color: var(--Red-red-8, #eb5757);
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.3px;
  }
`;

const StyledTag = styled.div`
  border-radius: 4px;
  background: var(--Blue-blue-2, #ecf6ff);
  padding: 0 4px;
  display: flex;
  align-items: center;

  span {
    color: var(--Blue-blue-9, #2f80ed);
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.3px;
  }
`;

export default BoardTag;

import { useInfiniteQuery } from '@tanstack/react-query';
import { getRecommendQuoteDetailList } from 'api/Prices';
import { extractInfinityDataFromDeep } from 'utils/reactQueryFormatter';

import priceKeys from './keys';

export default function useGetRecommendQuoteDetailList({ filter, id }) {
  const marketCodeList = filter.location.map((item) => item.marketCode);
  const labelIdList = filter.product.map((item) => item.labelId);
  const originTypeList = filter.origin.map((item) => item.code);
  const raisedTypeList = filter.raised.map((item) => item.code);

  const params = {
    labelIdList,
    latitude: filter.around[0]?.lat ?? null,
    longitude: filter.around[0]?.long ?? null,
    marketCodeList,
    originTypeList,
    raisedTypeList,
  };

  return useInfiniteQuery(
    priceKeys.getRecommendQuoteDetailList({
      id,
      ...params,
    }),
    ({ pageParam = 0 }) =>
      getRecommendQuoteDetailList({ id, page: pageParam, params }),
    {
      cacheTime: 0,
      enabled: !!id,
      getNextPageParam: (pagination) => {
        const { last, number } = pagination;
        return last ? null : number + 1;
      },
      select: (data) =>
        extractInfinityDataFromDeep(data, 'content')?.sort(
          (a, b) => b.orderNo - a.orderNo
        ),
      suspense: true,
    }
  );
}

import client from './client';

export const getPriceProductList = (params) => {
  return client
    .get('/www/price/home', {
      params,
    })
    .then((res) => res.data);
};

export const getPriceRecommend = () => {
  return client.get('/www/price/recommend').then((res) => res.data);
};

export const getRegion = (params) => {
  return client
    .get('/www/market/region', {
      params,
    })
    .then((res) => res.data);
};

export const getPriceProductFilter = () => {
  return client.get('/www/market/product/group').then((res) => res.data);
};

import React from 'react';

const Star = () => {
  return (
    <svg
      fill="none"
      height="15"
      viewBox="0 0 16 15"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.04894 1.42705C7.3483 0.505739 8.6517 0.50574 8.95106 1.42705L10.0206 4.71885C10.1545 5.13087 10.5385 5.40983 10.9717 5.40983H14.4329C15.4016 5.40983 15.8044 6.64945 15.0207 7.21885L12.2205 9.25329C11.87 9.50793 11.7234 9.9593 11.8572 10.3713L12.9268 13.6631C13.2261 14.5844 12.1717 15.3506 11.388 14.7812L8.58778 12.7467C8.2373 12.4921 7.7627 12.4921 7.41221 12.7467L4.61204 14.7812C3.82833 15.3506 2.77385 14.5844 3.0732 13.6631L4.14277 10.3713C4.27665 9.9593 4.12999 9.50793 3.7795 9.25329L0.979333 7.21885C0.195619 6.64945 0.598395 5.40983 1.56712 5.40983H5.02832C5.46154 5.40983 5.8455 5.13087 5.97937 4.71885L7.04894 1.42705Z"
        fill="#FFCC33"
      />
    </svg>
  );
};

export default Star;

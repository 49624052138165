function counter(numbers) {
  const criterion = [
    [12, '조'],
    [8, '억'],
    [4, '만'],
    [0, ''],
  ];
  if (numbers === undefined || numbers <= 0) {
    return numbers;
  }

  const [digit, unit] = criterion
    .map((x) => [10 ** x[0], x[1]])
    .find((x) => numbers >= x[0]);
  const formatter = new Intl.NumberFormat('ko-KR');
  if (digit === 1) {
    return formatter.format(numbers);
  }
  const formattedNumber = formatter.format((numbers / digit).toFixed(1));
  return `${formattedNumber} ${unit}`;
}

export default counter;

import { MODE } from 'context';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useProgress = (targets) => {
  const loading = useSelector((state) => state[MODE].load);
  const _check = () => {
    for (const target of targets) {
      if (loading[target] === 'loading') return true;
    }
    return false;
  };

  const _loaded = (handler, list) => {
    const target_list = list || targets;
    useEffect(() => {
      for (const target of target_list) {
        if (loading && loading[target] === 'complete') {
          handler(target);
        }
      }
    }, [...target_list.map((t) => loading && loading[t])]);
  };

  return {
    isLoading: _check,
    onLoaded: _loaded,
  };
};

export default useProgress;

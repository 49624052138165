export const loadScript = () => {
  return new Promise((resolve) => {
    if (document && document.querySelectorAll('#naver-map-sdk').length === 0) {
      const script = document.createElement('script');
      script.id = 'naver-map-sdk';
      script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=ey7evn3uc1`;
      script.onload = () => {
        resolve(true);
      };
      document.head.appendChild(script);
    } else {
      resolve(true);
      console.log('script already loaded');
    }
  });
};

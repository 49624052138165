import { Fragment } from 'react';

const regex = /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}(\/.*)?/;

const Line = ({ line }) => {
  if (regex.test(line)) {
    return (
      <>
        {line.split(' ').map((text, i) => {
          if (regex.test(text)) {
            return (
              <a
                key={i}
                href={
                  `//${regex.exec(text)[0].replace(/http(s)?:\/\//, '')}` || '#'
                }
              >
                {text}
              </a>
            );
          }
          return <Fragment key={i}>{text}</Fragment>;
        })}
        <br />
      </>
    );
  }
  return (
    <>
      {line}
      <br />
    </>
  );
};

const TextView = ({ children }) => {
  const _children = children
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"');

  return (
    <>
      {_children
        .replace(/\r/g, '')
        .split('\n')
        .map((line, i) => {
          return <Line key={i} line={line} />;
        })}
    </>
  );
};

export default TextView;

import { motion } from 'framer-motion/dist/framer-motion';

const pageStyle = {
  display: 'inline-block',
  flex: 'none',
  height: '100%',
  width: '100%',
};

const Slider = ({ children, i, onDragEnd, totalSliders, x }) => (
  <motion.div
    drag={totalSliders > 1 && 'x'}
    dragElastic={0.3}
    style={{
      ...pageStyle,
      left: `${i * 100}%`,
      right: `${i * 100}%`,
      x,
    }}
    onDragEnd={onDragEnd}
  >
    {children}
  </motion.div>
);

export default Slider;

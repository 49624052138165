import { Link, useLocation } from 'react-router-dom';
import { Image } from '../views';
import React from 'react';
import closeImg from 'img/ftp_fa_x.svg';

const RecommendedContent = ({ item, removeItem, remove }) => {
  const location = useLocation();
  const pathname = location.pathname.slice(1);

  const pageTitle = () => {
    if (pathname.includes('/')) {
      return pathname.split('/')[1] === '내관심정보'
        ? '즐겨찾기'
        : pathname.split('/')[1];
    }
    return pathname;
  };

  return (
    <div className="card-template type12">
      <div className="fixed inner-section type1">
        {!item?.url || item?.url === '' ? (
          <Link
            to={{
              pathname: item.targetCode || `/콘텐츠/${item.uri}`,
              state: pageTitle(),
            }}
          >
            <span className="img-block">
              <Image
                scale="11x5"
                className="display-block"
                src={item.thumbnail}
              />
            </span>
          </Link>
        ) : (
          <a href={item.url}>
            <span className="img-block">
              <Image
                scale="11x5"
                className="display-block"
                src={item.thumbnail}
              />
            </span>
          </a>
        )}
      </div>
      <div className="fixed inner-section type1 description">
        {!item?.url || item?.url === '' ? (
          <Link to={item.targetCode || `/콘텐츠/${item.uri}`}>
            <div className="inner-inner-section type2">
              <span
                ref={(v) => {
                  if (!v) return;
                  let over = false;
                  while (v.offsetHeight > 40) {
                    const str = v.innerText;
                    v.innerText = `${str.substr(0, str.length - 1)}`;
                    over = true;
                  }
                  if (over)
                    v.innerText =
                      v.innerText.substr(0, v.innerText.length - 3) + '...';
                }}
                style={{ display: 'block' }}
              >
                {item.subject}
              </span>
            </div>
          </Link>
        ) : (
          <a href={item.url}>
            <div className="inner-inner-section type2">
              <span
                ref={(v) => {
                  if (!v) return;
                  let over = false;
                  while (v.offsetHeight > 40) {
                    const str = v.innerText;
                    v.innerText = `${str.substr(0, str.length - 1)}`;
                    over = true;
                  }
                  if (over)
                    v.innerText =
                      v.innerText.substr(0, v.innerText.length - 3) + '...';
                }}
                style={{ display: 'block' }}
              >
                {item.subject}
              </span>
            </div>
          </a>
        )}
      </div>
      {remove && (
        <img
          className="close_img"
          src={closeImg}
          alt="close_img"
          onClick={() => {
            removeItem(item, 'content');
          }}
        />
      )}
    </div>
  );
};

const RecommendedContents = ({ contents, removeItem, remove }) => {
  return contents.map((item, i) => (
    <RecommendedContent
      item={item}
      key={i}
      removeItem={removeItem}
      remove={remove}
    />
  ));
};

export default RecommendedContents;

import ConfirmModal from 'components/modules/ConfirmModal';
import styled from 'styled-components';

const OrderCancelConfirmModal = ({ isOpen, onClose, onSuccess }) => {
  return (
    <ConfirmModal visible={isOpen} onClose={onClose} onSuccess={onSuccess}>
      <Container>
        <Title>주문취소</Title>
        <Typo>
          정말 주문을 취소하시겠습니까?
          <br />
          주문을 취소하시면 상품이 품절되어
          <br />
          다시 구매가 불가능할 수도 있습니다.
        </Typo>
      </Container>
    </ConfirmModal>
  );
};

const Typo = styled.div`
  color: var(--new-gray-gray-11, #26282b);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3px;
  min-height: 66px;
  align-self: stretch;
`;

const Title = styled.div`
  color: var(--new-gray-gray-11, #26282b);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.3px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 4px;
`;

export default OrderCancelConfirmModal;

import {
  Container,
  SectionInnerText,
  SectionInnerTitle,
  SectionTitle,
} from 'components/mypage/myOrder/orderHistoryDetail';
import dayjs from 'dayjs';
import styled from 'styled-components';

const OrderInfo = ({ name, orderCode, orderDateTime }) => {
  return (
    <Container>
      <SectionTitle>주문정보</SectionTitle>
      <Box>
        <TypoBox>
          <SectionInnerTitle>주문일시</SectionInnerTitle>
          <SectionInnerText>
            {dayjs(new Date(orderDateTime)).format('YYYY.MM.DD(ddd) HH:mm')}
          </SectionInnerText>
        </TypoBox>

        <TypoBox>
          <SectionInnerTitle>주문번호</SectionInnerTitle>
          <SectionInnerText>{orderCode}</SectionInnerText>
        </TypoBox>

        <TypoBox>
          <SectionInnerTitle>가게명</SectionInnerTitle>
          <SectionInnerText>{name}</SectionInnerText>
        </TypoBox>
      </Box>
    </Container>
  );
};

const TypoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export default OrderInfo;

import client from './client';

const arrayToQueryString = (key, array) => {
  return array.map((item) => `${key}=${item}`).join('&')
    ? `&${array.map((item) => `${key}=${item}`).join('&')}`
    : '';
};

export const getFilterProductList = () => {
  return client
    .get('/www/retail-price/filter/category')
    .then((res) => res.data);
};

export const getRecommendQuoteList = () =>
  client.get('/www/retail-price/recommend/item-group').then((res) => res.data);

export const getContentsByRecommendQuote = ({ id }) =>
  client
    .get(`/www/retail-price/recommend/item-group/${id}/contents`)
    .then((res) => res.data);

export const getRecommendQuoteDetail = ({ id }) =>
  client
    .get(`/www/retail-price/recommend/item-group/${id}`)
    .then((res) => res.data);

export const getRecommendQuoteDetailList = ({ id, page, params }) => {
  const marketCodeList = arrayToQueryString(
    'marketCodeList',
    params.marketCodeList
  );
  const labelIdList = arrayToQueryString('labelIdList', params.labelIdList);
  const originTypeList = arrayToQueryString(
    'originTypeList',
    params.originTypeList
  );
  const raisedTypeList = arrayToQueryString(
    'raisedTypeList',
    params.raisedTypeList
  );

  return client
    .get(
      `/www/retail-price/recommend/item-group/${id}/price?${marketCodeList}${labelIdList}${originTypeList}${raisedTypeList}`,
      {
        params: {
          latitude: params.latitude,
          longitude: params.longitude,
          page,
          size: 100,
        },
      }
    )
    .then((res) => res.data);
};

export const getQuoteDetail = ({ id }) =>
  client.get(`/www/retail-price/label/${id}`).then((res) => res.data);

export const getQuoteDetailLabels = ({ id, page, params }) => {
  const marketCodeList = arrayToQueryString(
    'marketCodeList',
    params.marketCodeList
  );
  const categoryCodeList = arrayToQueryString(
    'categoryCodeList',
    params.categoryCodeList
  );
  const originTypeList = arrayToQueryString(
    'originTypeList',
    params.originTypeList
  );
  const raisedTypeList = arrayToQueryString(
    'raisedTypeList',
    params.raisedTypeList
  );
  const sizeTypeList = arrayToQueryString('sizeTypeList', params.sizeTypeList);
  const unitTypeList = arrayToQueryString('unitTypeList', params.unitTypeList);

  return client
    .get(
      `/www/retail-price/label/${id}/price?${marketCodeList}${categoryCodeList}${originTypeList}${raisedTypeList}${sizeTypeList}${unitTypeList}`,
      {
        params: {
          latitude: params.latitude,
          longitude: params.longitude,
          page,
          size: 1000,
        },
      }
    )
    .then((res) => res.data);
};

export const getQuoteDetailRecommendLabel = ({ id }) =>
  client
    .get(`/www/retail-price/label/${id}/recommend-label`, {
      params: { size: 2000 },
    })
    .then((res) => res.data);

export const getLatestView = ({ id }) =>
  client
    .get(`/www/retail-price/label/${id}/latest-view`, {
      params: { size: 10 },
    })
    .then((res) => res.data);

export const getQuoteDetailContents = ({ id }) =>
  client
    .get(`/www/retail-price/label/${id}/recommend-contents`, {
      params: { size: 100 },
    })
    .then((res) => res.data);

export const putLabelFavorite = (data) =>
  client
    .put(`/www/retail-price/label/${data.id}/favorite`, {
      command: data.command,
    })
    .then((res) => res.data);

export const getLabelFavoriteList = ({ params }) =>
  client
    .get('/www/retail-price/label/favorite', { params })
    .then((res) => res.data);

export const getStoresByIndividualSize = ({ page, params, sizeId }) => {
  const marketCodeList = arrayToQueryString(
    'marketCodeList',
    params.marketCodeList
  );
  return client
    .get(`/www/retail-price/item/${sizeId}/store?${marketCodeList}`, {
      params: {
        latitude: params.latitude,
        longitude: params.longitude,
        page,
        size: 10,
      },
    })
    .then((res) => res.data);
};

export const getQuoteChart = ({ month, params, sizeId }) => {
  const marketCodeList = arrayToQueryString(
    'marketCodeList',
    params.marketCodeList
  );
  return client
    .get(
      `/www/retail-price/item/${sizeId}/period-price/${month}?${marketCodeList}`
    )
    .then((res) => res.data);
};

export const getLatestPriceByStore = ({ id, name, page, size }) =>
  client
    .get(`/www/retail-price/store/${id}/latest-price`, {
      params: { name, page, size },
    })
    .then((res) => res.data);

export const getLatestQuoteByStoreAndItem = ({ itemId, storeId }) =>
  client
    .get(`/www/retail-price/store/${storeId}/item/${itemId}/daily-price`, {
      params: { size: 7 },
    })
    .then((res) => res.data);

export const getPriceAggregate = ({ page, ...params }) => {
  const marketCodeList = arrayToQueryString(
    'marketCodeList',
    params.marketCodeList
  );
  const labelIdList = arrayToQueryString('labelIdList', params.labelIdList);
  const originTypeList = arrayToQueryString(
    'originTypeList',
    params.originTypeList
  );
  const raisedTypeList = arrayToQueryString(
    'raisedTypeList',
    params.raisedTypeList
  );

  return client
    .get(
      `/www/retail-price/price/aggregate/region?${marketCodeList}${labelIdList}${originTypeList}${raisedTypeList}`,
      {
        params: {
          keyword: params.keyword || null,
          latitude: params.latitude,
          longitude: params.longitude,
          orderState: params.orderState,
          page,
          size: 20,
        },
      }
    )
    .then((res) => res.data);
};

export const get_store_temp = (store_link) =>
  client.get(`/www/market/store/${encodeURI(store_link)}`);

/**
 * 네이버 시세조회를 통해 진입하는 경우 해당 itemCode로 label정보를 조회
 */
export const getLabelByItemCode = ({ itemCode }) =>
  client
    .get(`/www/retail-price/item/${itemCode}/label`)
    .then((res) => res.data);

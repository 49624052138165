import { useLayoutEffect, useState } from 'react';

/**
 * TODO: Add attributes to options
 * @param {object} options
 * @param {string} options.src
 * @return {[isLoading:boolean, isError:boolean]}
 */
const useScript = (options) => {
  const { src } = options;

  const [isLoading, setIsLoading] = useState(() => {
    const script = document.querySelector(`body > script[src="${src}"]`);
    return script ? script.getAttribute('data-status') === 'loading' : true;
  });
  const [isError, setIsError] = useState(() => {
    const script = document.querySelector(`body > script[src="${src}"]`);
    return script ? script.getAttribute('data-status') === 'error' : false;
  });

  useLayoutEffect(() => {
    let script = document.querySelector(`body > script[src="${src}"]`);

    if (script) {
      const status = script.getAttribute('data-status');

      setIsLoading(status === 'loading');
      setIsError(status === 'error');
    } else {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');

      const setAttribute = (e) => {
        script.setAttribute('data-status', e.type);
      };

      script.addEventListener('load', setAttribute);
      script.addEventListener('error', setAttribute);

      document.body.appendChild(script);
    }

    const setStatus = (e) => {
      setIsLoading(false);
      setIsError(e.type === 'error');
    };

    script.addEventListener('load', setStatus);
    script.addEventListener('error', setStatus);

    return () => {
      script.removeEventListener('load', setStatus);
      script.removeEventListener('error', setStatus);
    };
  }, [src]);

  return [isLoading, isError];
};

export default useScript;

import { motion } from 'framer-motion/dist/framer-motion';
import { useEffect, useRef, useState } from 'react';

const Carousel = ({ children, data, dragEnd, selectable, style }) => {
  const carouselRef = useRef();
  const [carouselWidth, setCarouselWidth] = useState(0);

  useEffect(() => {
    const carouselEl = carouselRef.current;

    const updateCarouselWidth = () => {
      if (carouselEl) {
        if (selectable) {
          const width = selectable - carouselEl.offsetWidth;
          setCarouselWidth(width);
        } else {
          const width = carouselEl.scrollWidth + 16 - carouselEl.offsetWidth;
          setCarouselWidth(width);
        }
      }
    };

    updateCarouselWidth();
  }, [data, dragEnd, selectable]);

  return (
    <motion.div className="carousel" whileTap={{ cursor: 'grabbing' }}>
      <motion.div
        ref={carouselRef}
        className="inner-carousel"
        dragConstraints={{
          right: 0,
          left: -carouselWidth,
        }}
        // style={{ ...style, display: 'flex' }}
        drag="x"
      >
        <motion.div className="item" style={{ ...style, display: 'flex' }}>
          {children}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Carousel;

import { Box } from 'components/atoms';
import React from 'react';
import { ReactComponent as Down } from '../../../stores/img/chevron-down.svg';

const _OptionTabs = ({ children }) => {
  return <div className="option-tabs">{children}</div>;
};

const _OptionTabItem = ({ on, selected, onClick, open }) => {
  const getFilterClassName = (selected) => {
    switch (selected) {
      case '모든 지역':
        return 'all-regions';
      case '모든 품목':
        return 'all-items';
      case '기본 순':
        return 'basic';
      default:
        return '';
    }
  };

  const styleClasses = [
    'filter',
    'option',
    `${getFilterClassName(selected)}`,
    open ? 'on' : null,
    on ? null : 'off',
  ]
    .filter((cls) => cls != null)
    .join(' ');
  return (
    <div className={styleClasses} onClick={onClick}>
      <Box alignItems="center" justifyContent="center">
        <Box
          color="#464C53"
          fontSize="14px"
          fontWeight="400"
          letterSpacing="-0.3px"
          lineHeight="20px"
        >
          {selected}
        </Box>
        <Down
          fill="#464C53"
          style={{ marginLeft: '6.5px', width: '16px', height: '16px' }}
        />
      </Box>
    </div>
  );
};

export { _OptionTabItem };
export default _OptionTabs;

import { Loading } from 'components/views';
import { MODE } from 'context';
import { useMemo } from 'react';
import { connect } from 'react-redux';

const ProgressContainer = ({ load, message = 'Progress...', targets }) => {
  const check_progress = useMemo(() => {
    if (load === null) return false;
    for (const target of targets) {
      if (load[target] === 'loading') {
        return true;
      }
    }
    return false;
  }, [load, targets]);

  return <div>{check_progress && <Loading message={message} />}</div>;
};

export default connect(({ [MODE]: mode }) => ({ load: mode.load }))(
  ProgressContainer
);

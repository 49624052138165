import CustomAlert from 'components/common/customAlert';
import { set_reply_report } from 'context/review';
import { ToastContext } from 'context/toast';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReportWrap from './_report';
import BlockWrap from './_report-block';
import BlockResetWrap from './_report-block-reset';

const ReportPopup = () => {
  const replyReport = useSelector(({ review }) => review.replyReport);
  const { reportAgree, reportType } = replyReport;
  const dispatch = useDispatch();

  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    if (replyReport) {
      document.body.style.height = 'auto';
      document.body.style.overflow = 'hidden';
      document.querySelector('html').style.overflow = 'hidden';
    } else {
      document.body.style.height = '';
      document.body.style.overflow = '';
      document.querySelector('html').style.overflow = '';
    }
  }, [replyReport]);

  const reportAgreePopup = {
    close: () => dispatch(set_reply_report(false)),
    message: '',
    options: [
      {
        callback: () => {
          dispatch(
            set_reply_report({
              ...replyReport,
              reportAgree: true,
              reportType: 'report',
            })
          );
        },
        label: '댓글 신고',
        style: { color: '#3366cc' },
      },
      {
        callback: () => {
          dispatch(
            set_reply_report({
              ...replyReport,
              reportAgree: true,
              reportType: 'block',
            })
          );
        },
        label: '회원 차단',
      },
    ],
    title: '신고/차단',
  };

  const singleCloseOption = [
    {
      callback: () => {
        if (reportType?.indexOf('Already') !== -1) window.location.reload();
        dispatch(set_reply_report(false));
      },
      label: '확인',
    },
  ];

  const reportComplete = {
    message: '신고가 접수되었습니다.',
    options: singleCloseOption,
    title: '신고하기',
  };
  const reportAlready = {
    message: '이미 신고 접수된 후기입니다.',
    options: singleCloseOption,
    title: '신고하기',
  };

  const blockComplete = {
    message: `해당 회원의 모든 글을 차단했습니다.
해제를 원하시면 '차단 해제' 버튼을
클릭해주세요.`,
    options: singleCloseOption,
    title: '차단하기',
  };
  const blockAlready = {
    message: '이미 차단 한 유저입니다.',
    options: singleCloseOption,
    title: '차단하기',
  };

  const reportAlert = () => {
    switch (reportType) {
      case 'report':
        return (
          <ReportWrap
            dispatch={dispatch}
            replyReport={replyReport}
            showToast={showToast}
          />
        );
      case 'reportComplete':
        return <CustomAlert type="button" visible={reportComplete} />;
      case 'reportAlready':
        return <CustomAlert type="button" visible={reportAlready} />;
      case 'block':
        return (
          <BlockWrap
            dispatch={dispatch}
            replyReport={replyReport}
            showToast={showToast}
          />
        );
      case 'blockReset':
        return (
          <BlockResetWrap
            dispatch={dispatch}
            replyReport={replyReport}
            showToast={showToast}
          />
        );
      case 'blockComplete':
        return <CustomAlert type="button" visible={blockComplete} />;
      case 'blockAlready':
        return <CustomAlert type="button" visible={blockAlready} />;
      default:
    }
  };

  return (
    <>
      {replyReport && (
        <>
          {reportAgree ? (
            reportAlert()
          ) : (
            <CustomAlert type="button" visible={reportAgreePopup} />
          )}
        </>
      )}
    </>
  );
};

export default ReportPopup;

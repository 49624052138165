import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// 스크롤 Context
export const ScrollContext = createContext(null);

const ScrollProvider = ({ children }) => {
  const location = useLocation();
  const [direction, setDirection] = useState(null);
  const value = useMemo(
    () => ({
      direction,
      isDown: direction === 'down',
      isUp: direction === 'up',
    }),
    [direction]
  );

  useEffect(() => {
    const threshold = 50;
    let lastScrollY = window.scrollY;
    let ticking = false;

    if (window.scrollY === 0) {
      setDirection(null);
    }
    const updateScrollDir = () => {
      const { scrollY } = window;
      if (scrollY === 0) {
        setDirection(null);
        ticking = false;
        return;
      }
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setDirection(scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [location]);

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
};

export default ScrollProvider;

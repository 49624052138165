import client from 'api/client';

export const get_prices = ({
  itemGroupCode,
  keyword,
  page,
  serial,
  zoneCode,
} = {}) =>
  client.get('/www/price/home', {
    params: {
      itemGroupCode,
      keyword,
      pageNum: page,
      serialCode: serial,
      zoneCode,
    },
  });

export const get_recommend_price = () => client.get(`/www/price/recommend`);

export const get_price_ups_and_downs = (params) =>
  client.get('/www/price/chart', { params });

export const get_prices_show_more = (params) =>
  client.get('/www/price/store/more', { params });

export default {
  get_price_ups_and_downs,
  get_prices,
  get_prices_show_more,
  get_recommend_price,
};

import './style.css';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { mobileIosCheck } from 'utils/mobileCheck';

const footerPage = [
  '/',
  '/홈',
  '/시장',
  '/시세',
  '/콘텐츠',
  '/마이페이지',
  '/후기',
  '/도매',
  '/쿠폰/모든쿠폰',
];

const Container = ({ bg, children, style }) => {
  const location = useLocation();
  const [viewFooter, setViewFooter] = useState(true);
  const mobileIOS = mobileIosCheck();

  useEffect(() => {
    setViewFooter(
      footerPage.includes(location.pathname) ||
        location.pathname.indexOf('/마이페이지/') === 0
    );
  }, [location]);

  return (
    <Wrapper
      className={`container ${viewFooter ? 'with-footer' : ''} `}
      mobileIOS={mobileIOS}
      style={{ ...style, background: bg || '' }}
    >
      <div className="colgroup">
        <div className="content fixed" style={{ background: bg || '' }}>
          {children}
        </div>
      </div>
    </Wrapper>
  );
};

export default Container;

const Wrapper = styled.div`
  height: 100%;
  ${({ mobileIOS }) =>
    mobileIOS &&
    css`
      #wrap > &.with-footer {
        padding-bottom: 100px;
      }
    `}
`;

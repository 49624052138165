import PanelItem from './_panel-item';

const Delivery = ({
  charge,
  closing,
  comment,
  contacts,
  guide,
  storeName,
  time,
  type,
  unit,
  zone,
}) => {
  return (
    <section>
      <PanelItem description={storeName} label="공급처" />
      <PanelItem description={charge_view(charge, unit)} label="배송비" />
      <PanelItem description={zone} label="배송지역" />
      <PanelItem label="배송방식">
        <TypeView type={type} />
      </PanelItem>
      {time && time !== '' && <PanelItem description={time} label="배송시간" />}
      {closing && closing !== '' && (
        <PanelItem description={closing} label="주문마감시간" />
      )}
      {guide && guide !== '' && (
        <PanelItem description={guide} label="배송정책" />
      )}
      {contacts && contacts.length > 0 && (
        <PanelItem label="상담번호">
          {contacts
            .map((contact) => <a href={`tel:${contact}`}>{contact}</a>)
            .reduce((acc, c) => (
              <>
                {acc}
                <span>/</span>
                {c}
              </>
            ))}
        </PanelItem>
      )}
      {comment && (
        <PanelItem color="red" description={comment} label="코멘트" />
      )}
    </section>
  );
};

const TypeView = ({ type }) => {
  switch (type) {
    case 'ITSELF':
      return <span>업체자체배송</span>;
    case 'TPIRATES':
      return <span>인어교주배송</span>;
    case 'PARCEL_SERVICE':
      return <span>택배</span>;
    default:
      return <></>;
  }
};

const charge_view = (charge, unit) => {
  const qty = charge.unit * unit.qty;

  switch (charge.type) {
    case 0: // 무료배송
      return '무료';
    case 1: // 조건부 무료배송
      return '조건부 무료배송';
    case 2: // 유료배송
      return `${charge.price.toLocaleString()}원`;
    case 3: // 단위당 유료배송
      return `${charge.price.toLocaleString()}원 / ${charge.unit}${
        unit.label
      }(${qty}${unit.name}) 당`;
    case 99: // 기타 comment
      return charge.comment;
    default:
      return charge.comment;
  }
};

export default Delivery;

import React from 'react';
import TextForm from './_text';

const ModifyForm = ({ props, onClickCancel, modifyReview }) => {
  const { item } = props;
  const _props = {
    ...props,
    id: item.id,
    member: {
      ...item.writer,
      type: props.member ? props.member.memberType : null,
    },
    initText: item.comment,
    initScore: item.rating,
    initImages: item?.attachments?.map((img) => img.thumbnail),
    initSecret: item.secret,
    toastMessage: '댓글이 수정되었습니다.',
  };

  return (
    <>
      <TextForm props={_props} onSubmit={modifyReview}>
        <li>
          <a className="btn blue" onClick={onClickCancel}>
            취소
          </a>
        </li>
      </TextForm>
    </>
  );
};

export default ModifyForm;

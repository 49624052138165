import { memo } from 'react';
import styled from 'styled-components';

import { ButtonBase } from '.';

const UnderlineButton = styled(ButtonBase)`
  color: ${({ $color, theme }) => theme[$color] || theme.gray500} !important;
  font-size: 14px !important;
  padding: 0;
  position: relative;

  &::after {
    border-top: 1px solid
      ${({ $color, theme }) => theme[$color] || theme.gray500};
    bottom: 2px;
    content: '';
    left: 1px;
    position: absolute;
    right: 1px;
  }
`;

export default memo(UnderlineButton);

import { confirmPurcahse } from 'api/order';
import { useToast } from 'hooks';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const OrderConfirm = ({ match }) => {
  const {
    params: { id: orderId },
  } = match;

  const { identity: accountId } = useSelector(
    (state) => state['global.session']
  );

  const { replace } = useHistory();
  const showToast = useToast();

  useEffect(() => {
    if (!accountId) {
      return;
    }

    confirmPurcahse(orderId)
      .then(() => {
        showToast('수령이 완료되었습니다.');
      })
      .catch((error) => {
        showToast(error.response?.data?.message || error.message);
      })
      .finally(() => {
        replace('/마이페이지/내주문');
      });
  }, [orderId, accountId, replace]);

  return <div>잠시만 기다려주세요...</div>;
};

export default React.memo(OrderConfirm);

import NotiConfirmModal from 'components/organisms/Modal/NotiConfirmModal';
const ChannelView = ({ onClose, onOk }) => {
  return (
    <div
      style={{
        margin: 'auto',
        width: '272px',
      }}
    >
      <div className="txt-guide">
        <NotiConfirmModal
          cancelLabel="취소"
          content="로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?"
          okLabel="확인"
          type="confirm"
          replace
          onClose={onClose}
          onOk={onOk}
        />
      </div>
    </div>
  );
};
export default ChannelView;

import { useDialogActionContext } from '@locked-dobby/library-react-dialog';
import { useEffect } from 'react';
import styled from 'styled-components';

export const Toast = ({ duration = 2000, message }) => {
  const { hide } = useDialogActionContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      hide();
    }, duration);

    return () => clearTimeout(timer);
  }, [hide, duration]);

  return <StyledBox>{message}</StyledBox>;
};

const StyledBox = styled.pre`
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black-white-black, #000);
  border-radius: 8px;
  bottom: 72px;
  color: white;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 20px;
  width: 90%;
  max-width: 388px;
  opacity: 0.8;
  position: fixed;
  right: 0;
  margin: auto;
  z-index: 10000;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.3px;
  overflow: auto;
  white-space: pre-wrap;
  min-height: 52px;
  padding: 8px;
`;

import CryingPirates from 'components/Icons/CryingPirates';
import { conf } from 'conf/settings';
import styled from 'styled-components';

const ErrorNotice = ({ errorComment = '서비스 연결' }) => {
  return (
    <Container>
      <InnerContainer>
        <ContentWrap alignItems="center">
          <ImgWrap>
            <CryingPirates fill="#262828" />
          </ImgWrap>
          <MainText>
            고객님
            <br />
            불편을 드려 죄송합니다.
          </MainText>
          <SubText color="gray300" fontSize="16px" textAlign="center">
            {errorComment}에 일시적인 오류가 발생하였습니다.
            <br />
            새로고침을 눌러 다시 시도해주세요.
          </SubText>
        </ContentWrap>

        <ButtonWrap>
          <ReloadButton onClick={() => window.location.reload()}>
            새로고침
          </ReloadButton>
          <HomeButton
            onClick={() => {
              window.location.href = `${conf.FISH_MARKET_URL}`;
            }}
          >
            홈으로 돌아가기
          </HomeButton>
        </ButtonWrap>
      </InnerContainer>
    </Container>
  );
};

export default ErrorNotice;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 500px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  width: auto;
  max-height: 520px;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const ImgWrap = styled.div`
  margin: 0 auto;
`;

const MainText = styled.div`
  color: #262828;
  font-size: 22px;
  text-align: center;
  line-height: 1.5;
`;

const SubText = styled.div`
  color: #9fa4aa;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const ReloadButton = styled.button`
  color: #72787f;
  width: 100%;
  border: 1px solid #9fa4aa;
  background: none;
  border-radius: 5px;
  height: 50px;
  font-size: 16px;
  cursor: pointer;
`;

const HomeButton = styled.button`
  color: #2f80ed;
  width: 100%;
  border: 1px solid #2f80ed;
  background: none;
  border-radius: 5px;
  height: 50px;
  font-size: 16px;
  cursor: pointer;
`;

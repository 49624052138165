import React, { memo, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import coupon from 'img/Coupon.svg';
import conver from 'img/conver.svg';
import orderDetail from 'img/Order_details.svg';
import question from 'img/ftp_fa_Service.svg';

const SubMenuPopUp = ({ sub }) => {
  const history = useHistory();
  useEffect(() => {
    const before = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = before;
    };
  }, []);

  return (
    <div onClick={(e) => e.stopPropagation()} className="menu-content">
      <div className="triangle"></div>
      {sub &&
        sub.map((v, i) => (
          <p
            key={i}
            onClick={() => {
              if (v.outlink) window.location.href = v.outlink;
              if (v.path) history.push(v.path);
            }}
          >
            {v.label}
          </p>
        ))}
    </div>
  );
};

const MenuItem = memo(({ img, name, sub, onClick, ...props }) => {
  const [submenus, setSubmenus] = useState(false);

  useEffect(() => {
    const headerFilter = document.getElementById('header-color-filter');
    const footerFilter = document.getElementById('footer-color-filter');
    if (submenus) {
      if (headerFilter) headerFilter.style.display = 'block';
      if (footerFilter) footerFilter.style.display = 'block';
    } else {
      if (headerFilter) headerFilter.style.display = 'none';
      if (footerFilter) footerFilter.style.display = 'none';
    }
  }, [submenus]);

  return (
    <MenuItemStyle
      {...props}
      onClick={() => {
        if (sub) setSubmenus((p) => !p);
        else onClick();
      }}
    >
      <figure>
        <img src={img} alt="" />
      </figure>
      <span>{name}</span>

      {submenus && (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setSubmenus(false);
            }}
            className="dark-filter"
          ></div>
          <SubMenuPopUp sub={sub} />
        </>
      )}
    </MenuItemStyle>
  );
});

const MenuItemStyle = styled.div`
  display: flex;
  flex: 0 0 25%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #fafafa;
  }
  & > figure {
    width: 40px;
    height: 40px;
    padding: 9px;
    margin: 0;

    /* border: 2px solid #222; */
    background: #eaf3ff;
    border-radius: 50%;
    box-sizing: border-box;
    & > img {
      filter: invert(24%) sepia(84%) saturate(701%) hue-rotate(188deg)
        brightness(91%) contrast(93%);
    }
  }
  & > img {
    width: 100%;
    height: 100%;
    filter: invert(30%) sepia(8%) saturate(660%) hue-rotate(172deg)
      brightness(85%) contrast(85%);
  }
  & > span {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #555;
  }
  & > .dark-filter {
    position: fixed;
    transform: translate3d(0, 0, 0);
    left: 0;
    top: -180px;
    /* bottom: calc(-100% - 16px); */

    width: 100%;
    height: calc(100vh + 48px);

    background: rgba(0, 0, 0, 0.3);

    z-index: 99;
    cursor: default;
  }

  & > .menu-content {
    position: absolute;
    background: #fff;
    top: calc(80% + 15px);
    left: 0;
    width: 180px;
    max-width: 180px;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;

    z-index: 100;
    cursor: default;

    & > .triangle {
      position: absolute;
      left: 20px;
      top: 0;
      transform: translateY(-100%);
      width: 0px;
      height: 0px;
      border-top: 15px solid none;
      border-bottom: 15px solid #fff;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
    }

    & > p {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: #0e4e97;
      }
      & + p {
        margin-top: 20px;
      }
    }
  }
`;

const MenuList = [
  {
    name: '주문 내역',
    link: '/마이페이지/내주문',
    img: orderDetail,
  },
  { name: '쿠폰', link: '/마이페이지/내쿠폰', img: coupon },
  {
    name: '후기/댓글',
    link: '/마이페이지/내댓글',
    img: conver,
  },
];

const StoreMenuList = [
  {
    name: '주문 내역',
    link: 'https://shop.tpirates.com/mypage/order_list.php',
    img: orderDetail,
    sub: [
      {
        label: '주문/배송',
        outlink: 'https://shop.tpirates.com/mypage/order_list.php?mode=order',
      },
      {
        label: '반품/교환',
        outlink:
          'https://shop.tpirates.com/mypage/cancel_list.php?mode=cancelRequest',
      },
      {
        label: '주문 취소',
        outlink:
          'https://shop.tpirates.com/mypage/refund_list.php?mode=refundRequest',
      },
    ],
  },
  {
    name: '쿠폰',
    link: 'https://shop.tpirates.com/mypage/coupon.php',
    img: coupon,
  },
  {
    name: '구매후기',
    link: 'https://shop.tpirates.com/mypage/mypage_goods_review.php',
    img: conver,
  },
  {
    name: '상품문의',
    link: 'https://shop.tpirates.com/mypage/mypage_goods_qa.php',
    img: question,
  },
];

const MyMenus = () => {
  const history = useHistory();

  return (
    <>
      <MyMenusStyle>
        <div className="menu-wrap">
          <div className="title">
            <strong>
              수산
              <br />
              시장
            </strong>
            <p></p>
          </div>
          <div className="menu-content">
            {MenuList.map(({ name, link, img, sub }, idx) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  if (name === '1:1문의') window.location.href = link;
                  else history.push(link);
                }}
                sub={sub}
                name={name}
                img={img}
              />
            ))}
          </div>
        </div>
        <div className="menu-wrap">
          <div className="title">
            <strong>쇼핑몰</strong>
            <p></p>
          </div>
          <div className="menu-content">
            {useMemo(() => {
              return StoreMenuList.map(({ name, link, img, sub }, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() => {
                    if (name === '1:1문의') window.location.href = link;
                    else window.location.href = link;
                  }}
                  name={name}
                  sub={sub}
                  img={img}
                />
              ));
            }, [StoreMenuList])}
          </div>
        </div>
        <div className="menu-bottom-wrap">
          <div
            onClick={() => {
              history.push('/마이페이지/내관심정보');
            }}
            className="menu-bottom-list"
          >
            <strong>즐겨찾기</strong>
            <div>
              {/* <span></span> */}
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
          <div
            onClick={() => {
              history.push('/마이페이지/내메시지');
            }}
            className="menu-bottom-list"
          >
            <strong>메시지 관리</strong>
            <div>
              {/* <span></span> */}
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
          <div
            onClick={() => {
              window.location = `https://shop.tpirates.com/mypage/shipping.php`;
              // history.push('/마이페이지/배송지');
            }}
            className="menu-bottom-list"
          >
            <strong>배송지 관리</strong>
            <div>
              {/* <span></span> */}
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = 'https://pf.kakao.com/_lVxhbl/chat';
            }}
            className="menu-bottom-list"
          >
            <strong>1 : 1 상담</strong>
            <div>
              {/* <span></span> */}
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </MyMenusStyle>
    </>
  );
};

const MyMenusStyle = styled.div`
  position: relative;
  width: 100%;
  transform: translateY(-47px);
  box-sizing: border-box;

  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 48px);
    position: absolute;
    background: #e8e8ed;
    z-index: -1;
    transform: translateY(47px);
  }

  & > .menu-wrap {
    display: flex;
    flex-wrap: nowrap;

    margin: 0 16px;
    padding-right: 24px;

    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    & > .title {
      flex: 0 0 74px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      & > strong {
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }
      & > p {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        /* width: 1px; */
        height: 50%;
        background: #ccc;
      }
    }
    & > .menu-content {
      flex: 1;
      display: flex;
      align-items: center;
      height: 94px;
    }
    & + .menu-wrap {
      margin-top: 20px;
    }
  }

  & > .menu-bottom-wrap {
    margin-top: 20px;

    /* border-radius: 5px; */
    /* box-shadow: 0px 3px 6px #00000029; */
    background: #fff;

    overflow: hidden;
    & > .menu-bottom-list {
      display: flex;
      align-items: center;
      box-sizing: border-box;

      padding: 0 16px;
      width: 100%;
      height: 48px;

      border-bottom: 1px solid #eee;

      cursor: pointer;

      & > strong {
        color: #333333;
      }
      & > div {
        flex: 1;
        height: 54%;
        position: relative;
        & > span {
          position: absolute;

          width: 6px;
          height: 6px;
          margin-left: 8px;

          border-radius: 50%;
          background: #ee0000;
        }
        & > i {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
      & + .menu-bottom-list {
        /* border-top: 1px solid #eee; */
      }
      &:hover {
        background: #fafafa;
      }
    }
  }
`;

export default MyMenus;

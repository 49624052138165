import React, { useReducer } from 'react';

const Action = {
  SCORES: 'pirates-form/SCORE',
  WRITE: 'pirates-form/WRITE.COMMENT',
  INIT_IMAGE: 'pirates-form/ADD.IMAGE',
  ADD_IMAGE: 'pirates-form/ADD.IMAGE',
  REPLACE_IMAGE: 'pirates-form/REPLACE.IMAGE',
  REMOVE_IMAGE: 'pirates-form/REMOVE.IMAGE',
  CLEAR: 'pirates-form/CLEAR',
};

const reducer = (state, action) => {
  switch (action.type) {
    case Action.SCORES:
      return {
        ...state,
        score: action.payload,
      };
    case Action.WRITE:
      return {
        ...state,
        comment: action.payload,
      };
    case Action.INIT_IMAGE:
      return {
        ...state,
        images: [action.payload],
      };
    case Action.ADD_IMAGE:
      return {
        ...state,
        images: [...state.images, action.payload],
      };
    case Action.REPLACE_IMAGE:
      return {
        ...state,
        images: [action.payload],
      };
    case Action.REMOVE_IMAGE:
      return {
        ...state,
        images: state.images.filter((image) => image !== action.payload),
        deleteImages: [...state.deleteImages, action.payload],
      };
    case Action.CLEAR:
      return {
        images: [],
        score: 5,
        comment: '',
      };
    default:
      return state;
  }
};

const useImageUpload = (initImages) => {
  const [state, dispatch] = useReducer(reducer, {
    images: initImages ? [...initImages] : [],
    deleteImages: [],
  });

  const _handleImageSelect = (e) => {
    const files = e.target.files;
    for (let file of files) {
      if (file.type.indexOf('image/') < 0) {
        alert('업로드가 허용된 파일이 아닙니다.');
        continue;
      }
      const reader = new FileReader();
      reader.onload = (pe) => {
        dispatch({ type: Action.ADD_IMAGE, payload: pe.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const _handleInitImage = (e) => {
    dispatch({ type: Action.INIT_IMAGE, payload: e.image });
  };

  const _handleReplaceImage = (e) => {
    const files = e.target.files;
    for (let file of files) {
      if (file.type.indexOf('image/') < 0) {
        alert('업로드가 허용된 파일이 아닙니다.');
        continue;
      }
      const reader = new FileReader();
      reader.onload = (pe) => {
        dispatch({ type: Action.REPLACE_IMAGE, payload: pe.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const _handleImageRemove = (image) =>
    dispatch({ type: Action.REMOVE_IMAGE, payload: image });

  return {
    imageState: state,
    replaceImage: _handleReplaceImage,
    initImage: _handleInitImage,
    selectImage: _handleImageSelect,
    removeImage: _handleImageRemove,
  };
};

export default useImageUpload;

/* eslint-disable no-undef */
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import credentials from '.config/credentials';

const Head = ({ meta, searchTitle, title, type }) => {
  const location = useLocation();
  const pageTitle =
    location.state === undefined || typeof location.state === 'object'
      ? ''
      : ` | ${location.state === '' ? '홈' : location.state}`;
  const _title = `${title} ${pageTitle} - 인어교주해적단`;

  const _desc =
    '노량진수산시장, 가락시장 회센터, 가락시장 회센터, 부산 자갈치시장, 속초수산시장, 대게, 킹크랩, 랍스타, 꽃게, 새우, 방어 가격, 시세, 주차';
  const _thumbnail = `${document.location.origin}/img/ci_logo.gif`;
  const _meta_tags = {
    'author': '인어교주해적단',
    'description': _desc,
    'fb:app_id': credentials.facebook['app-id'],
    'og:description': _desc,
    'og:image': _thumbnail,
    'og:locale': 'ko_KR',
    'og:site_name': '인어교주해적단',
    'og:title': _title,
    'og:type': 'article',
    'og:url': document.location.href,
    'subject': _title,
    'thumbnail': _thumbnail,
    'url': document.location.href,
  };
  const filtered = ((m) =>
    Object.keys(m)
      .filter((k) => m[k])
      .reduce((acc, k) => ({ ...acc, ...{ k: m[k] } }), {}))(meta || {});

  const targets = ((tags) => Object.keys(tags).map((key) => [key, tags[key]]))({
    ..._meta_tags,
    ...filtered,
  });

  return (
    <>
      {!type && (
        <Helmet>
          <title>{`${title} ${pageTitle} - 인어교주해적단`}</title>
          {targets.map(([name, value]) => (
            <meta key={name} content={value} name={name} />
          ))}
        </Helmet>
      )}
      {type === 'multiple' && (
        <Helmet>
          <title>
            {searchTitle ? `검색 | ${title} -` : `${title} |`} 오늘 수산시장의
            해산물 시세를 한번에, 인어교주해적단
          </title>
        </Helmet>
      )}
      {type === 'single' && (
        <Helmet>
          <title>{title} - 인어교주해적단</title>
        </Helmet>
      )}
    </>
  );
};

export default {
  Head,
};

import { useQuery } from '@tanstack/react-query';
import { getFilterProductList } from 'api/Prices';
import { convertMinToMS } from 'utils/convertTimeToMS';

import priceKeys from './keys';

export default function useGetFilterProductList() {
  return useQuery(priceKeys.getFilterProductList(), getFilterProductList, {
    staleTime: convertMinToMS(5),
  });
}

export const IOS_REGEX = /iPad|iPhone|iPod/;

export const AOS_REGEX = /Android/;

export const TPIRATES_APP_NAME = 'tpirates';
export const FISH_MARKET_SELLER_APP_NAME = 'fish-market-seller-app';

/**
 * @description 플랫폼 별로 구분 할 수 있는 함수입니다.
 * @returns {Object} An object containing the platform details.
 * @returns {boolean} return.isTpiratesAppMode - 더파이러츠앱이 앱으로 열렸는지 확인하는 변수
 * @returns {boolean} return.isFishMarketSellerAppMode - 더파이러츠 사장님앱이 앱으로 열렸는지 확인하는 변수
 * @returns {boolean} return.isIos - UA가 iPad, iPhone, iPod 일 경우(모바일 웹)
 * @returns {boolean} return.isAos - UA가 Android 일 경우(모바일 웹)
 * @returns {boolean} return.isPc - isAos or isIos가 하나라도 참이 아닌 경우(pc 웹)
 * @returns {boolean} return.isTpiratesIosApp - 더파이러츠 앱이 ios 앱으로 열렸는지 확인하는 변수
 * @returns {boolean} return.isTpiratesAosApp - 더파이러츠 앱이 aos 앱으로 열렸는지 확인하는 변수
 * @returns {boolean} return.isFishMarketSellerIosApp - 더파이러츠 사장님 앱이 ios 앱으로 열렸는지 확인하는 변수
 * @returns {boolean} return.isFishMarketSellerAosApp - 더파이러츠 사장님 앱이 aos 앱으로 열렸는지 확인하는 변수
 * @throws window가 없는 경우 에러 (next.js에서 해당 상황 발생 할 수 있읍)
 */
export const detectPlatformIdentity = () => {
  if (typeof window === 'undefined') {
    throw new Error('window가 없습니다.');
  }

  const isTpiratesAppMode = navigator.userAgent.includes(TPIRATES_APP_NAME);
  const isFishMarketSellerAppMode = navigator.userAgent.includes(
    FISH_MARKET_SELLER_APP_NAME
  );

  const isIos = IOS_REGEX.test(navigator.userAgent);
  const isAos = AOS_REGEX.test(navigator.userAgent);
  const isPc = !(isIos || isAos);

  const isTpiratesIosApp = isTpiratesAppMode && isIos;
  const isTpiratesAosApp = isTpiratesAppMode && isAos;

  const isFishMarketSellerIosApp = isFishMarketSellerAppMode && isIos;
  const isFishMarketSellerAosApp = isFishMarketSellerAppMode && isAos;

  return {
    isAos,
    isFishMarketSellerAosApp,
    isFishMarketSellerAppMode,
    isFishMarketSellerIosApp,
    isIos,
    isPc,
    isTpiratesAosApp,
    isTpiratesAppMode,
    isTpiratesIosApp,
  };
};

import { Section } from 'components/section';
import EmptyPage from 'pages/_mypage/_empty_page';
import { memo, useEffect, useState } from 'react';

import MyReviewItem from './myReviewItem';

const MyReviewList = ({
  Popups,
  category,
  deletePopup,
  profile,
  reviews = [],
  setObserver,
  setPopMsg,
}) => {
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    document.body.onclick = () => setExpand(false);
    return () => (document.body.onclick = null);
  }, []);

  useEffect(() => {
    setExpand(false);
  }, [reviews]);

  return (
    <div>
      {reviews.length !== 0 ? (
        <Section title="" type="board">
          {reviews.map((review, i) => {
            return (
              <MyReviewItem
                key={i}
                expand={expand}
                item={review}
                profile={profile}
                remove={() => setPopMsg(deletePopup(review))}
                setExpand={setExpand}
                type={category ? 'content' : ''}
                warning={
                  review.isActivatedStore
                    ? null
                    : () =>
                        setPopMsg(
                          category
                            ? Popups(
                                '해당 컨텐츠가 삭제되어 이동할 수 없습니다.'
                              )
                            : Popups('제휴가 종료된 가게로 이동할 수 없습니다.')
                        )
                }
              />
            );
          })}
          <div ref={setObserver} className="scroll-observer" />
        </Section>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
};

export default memo(MyReviewList);

import React from 'react';
import styled from 'styled-components';

const Checkbox = (
  { id, name, onBlur, onChange, value = false, ...props },
  ref
) => {
  return (
    <Input
      ref={ref}
      checked={value}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      {...props}
    />
  );
};

const Input = styled.input.attrs({ type: 'checkbox' })`
  height: 16px;
  width: 16px;
`;

export default React.forwardRef(Checkbox);

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartCount: 0,
  storeCartItem: {},
  thumbnail: '',
};

const cartSlice = createSlice({
  initialState,
  name: 'cart',
  reducers: {
    setCarts: (state, { payload }) => {
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

export const { setCarts } = cartSlice.actions;
export default cartSlice.reducer;

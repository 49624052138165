import Icon from 'components/Icons/v2/Icon';
import {
  getDiscountChipTitle,
  getDiscountIconName,
} from 'script/util/service-utils';
import styled from 'styled-components';

const CommonDiscountChip = ({ discountType, isDisabled = false }) => {
  return (
    <ChipWrap
      style={{
        backgroundColor: !isDisabled ? '#FFF3F5' : '#F7F8F9',
      }}
    >
      <Icon
        color={!isDisabled ? '#EB5757' : '#9FA4AA'}
        name={getDiscountIconName(discountType)}
        size={16}
      />
      <p style={{ color: !isDisabled ? '#EB5757' : '#9FA4AA' }}>
        {getDiscountChipTitle(discountType)}
      </p>
    </ChipWrap>
  );
};

const ChipWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  max-width: fit-content;
  padding: 0px 6px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  line-height: 23px;

  p {
    font-size: 11px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.3px;
  }
`;

export default CommonDiscountChip;

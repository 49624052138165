import { createContext, useRef, useState } from 'react';
import styled from 'styled-components';

const ToastContext = createContext({});

const ToastProvider = ({ children }) => {
  const [activate, setActivate] = useState(false);
  const [message, setMessage] = useState('');
  const Toast = useRef();
  const timeOut = useRef();

  const handleTransition = () => {
    if (!Toast.current) {
      return;
    }

    Toast.current.style.display = 'flex';
    timeOut.current = setTimeout(() => {
      timeOut.current = setTimeout(() => {
        Toast.current.style.display = 'none';
        clearTimeout(timeOut.current);
        timeOut.current = null;
      }, 200);
    }, 2000);
  };

  const showToast = (_message) => {
    setMessage(_message);
    if (!activate) {
      setActivate(true);
      handleTransition();
    } else {
      clearTimeout(timeOut.current);
      handleTransition();
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <StyledBox ref={Toast} className={activate ? '' : 'disabled'}>
        {activate && message?.replace(/\./g, '.\n')}
      </StyledBox>
    </ToastContext.Provider>
  );
};

const StyledBox = styled.pre`
  align-items: center;
  background-color: var(--black-white-black, #000);
  border-radius: 8px;
  bottom: 72px;
  color: white;
  display: none;
  height: 52px;
  justify-content: center;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin: auto;
  width: 90%;
  max-width: 388px;
  opacity: 0.8;
  position: fixed;
  right: 0;
  z-index: 10000;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

export default ToastProvider;
export { ToastContext };

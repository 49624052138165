import Icon from 'components/Icons/v2/Icon';
import styled from 'styled-components';

export const InfoIcon = ({ children }) => {
  return (
    <InfoIconBox>
      <Icon
        className="info-icon"
        name="info_line_round-20"
        size={20}
        style={{ cursor: 'pointer' }}
      />
      <InfoBubble className="info-bubble">{children}</InfoBubble>
    </InfoIconBox>
  );
};

const InfoBubble = styled.div`
  display: none;
  padding: 8px 12px;
  border-radius: 12px;
  background-color: white;
  box-shadow:
    2px 6px 12px 0px rgba(0, 0, 0, 0.12),
    0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  white-space: nowrap;
`;

const InfoIconBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  gap: 4px;

  .info-icon:hover + .info-bubble {
    display: flex;
  }
`;

export const SectionInnerText = styled.div`
  overflow: hidden;
  color: ${({ color }) => color ?? `var(--Gray-gray-11, #26282B)`};
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const SectionInnerTitle = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-6, #8a8d90);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  min-width: 76px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

export const SectionTitle = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
  height: 100%;
  background-color: white;
`;

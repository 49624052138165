import { ListView } from 'components/views';

import TicketItem from '../card/_ticket';

const TicketView = ({ items }) => {
  return (
    <ListView items={items} render={(item) => <TicketItem item={item} />} />
  );
};

export default TicketView;

import './style.css';

import fdate from 'lib/fdate';
import { moneyFormatter } from 'utils/Formatter';

import PanelItem from './_panel-item';

const Price = ({ prices }) => {
  const fluct = (value) => {
    if (value > 0) return 'up';
    if (value < 0) return 'down';
    return '';
  };
  return (
    <section>
      {prices.map((p) => (
        <PanelItem
          key={p.priceDate}
          label={fdate(p.priceDate).date('%Y-%m-%d(%W)')}
        >
          <span className="price">{moneyFormatter(p.price)}원</span>
          <span className={['fluctuation', fluct(p.fluctuation)].join(' ')}>
            {p.fluctuation === 0
              ? '변동없음'
              : `${moneyFormatter(Math.abs(p.fluctuation))}원`}
          </span>
        </PanelItem>
      ))}
    </section>
  );
};

export default Price;

import DeliveryPanel from './_panel/_delivery';
import EstimatePanel from './_panel/_estimate';
import OrderPanel from './_panel/_order';
import PanelItem from './_panel/_panel-item';
import PricePanel from './_panel/_prices';

const Panel = {
  Delivery: DeliveryPanel,
  Estimate: EstimatePanel,
  Item: PanelItem,
  Order: OrderPanel,
  Prices: PricePanel,
};

export { Panel };
export { default as Board } from './_board';
export { default as Cart } from './_cart';
export { default as useCart } from './_cart/_use-cart';
export { default as Header } from './_head';

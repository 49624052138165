import { createSlice } from '@reduxjs/toolkit';

const cuSlice = createSlice({
  name: 'cu',
  initialState: {
    canOrder: true,
  },
  reducers: {
    setCanOrder: (state, { payload = true }) => {
      state.canOrder = payload;
    },
  },
});

export const { setCanOrder } = cuSlice.actions;
export default cuSlice.reducer;

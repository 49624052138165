import {
  useDialogActionContext,
  useDialogContext,
} from '@locked-dobby/library-react-dialog';
import profileClient from 'api/profileClient';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import marketingCheckedMan from './img/marketing_checked_man.svg';
import marketingCheckedWoman from './img/marketing_checked_woman.svg';
import marketingUncheckedMan from './img/marketing_unchecked_man.svg';
import marketingUncheckedWoman from './img/marketing_unchecked_woman.svg';

const SetMarketingInfoDialog = ({ message, textNo, textYes, title }) => {
  const yearRef = useRef(null);
  const monthRef = useRef(null);
  const dayRef = useRef(null);
  const submit = useRef();
  const prevInputs = useRef({
    day: undefined,
    month: undefined,
    year: undefined,
  });

  const { hide } = useDialogActionContext();
  const [isBirthday, setIsBirthday] = useState(null);
  const [isGender, setIsGender] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const { confirm, toast } = useDialogContext();

  const numberCheck = /^[0-9]*$/;

  const marketingContents = {
    birthday: `생년월일`,
    gender: `성별`,
    notifyText: `마이페이지 > 설정 에서 언제든지 설정할 수 있습니다.`,
    subTitle: (
      <>
        성별과 생년월일을 선택해주시면, 고객님에게 딱 맞는{' '}
        <b>맞춤형 프로모션</b>을 추천해드립니다.
      </>
    ),
    textNo: `다음에 할게요`,
    textYes: `적용하기`,
    title: `성별과 연령대를 알려주세요!`,
  };

  const handleGenderChange = (gender) => {
    setIsGender(gender);
  };

  const handleInputFocus = (ref) => {
    if (ref.current) {
      ref.current.style.backgroundColor = 'lightyellow';
    }
  };
  const handleInputOnblur = (ref) => {
    if (ref.current) {
      ref.current.style.backgroundColor = '';
    }
  };
  const handleOnInput = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
  };
  const handleAutoFocus = (value, ref) => {
    let newBirthday = isBirthday || '';

    if (ref === yearRef) {
      newBirthday = value.slice(0, 4) + newBirthday.slice(4);
    } else if (ref === monthRef) {
      newBirthday =
        newBirthday.slice(0, 4) + value.slice(0, 2) + newBirthday.slice(6);
    } else if (ref === dayRef) {
      newBirthday = newBirthday.slice(0, 6) + value.slice(0, 2);
    }

    setIsBirthday(newBirthday.slice(0, 8));

    if (ref === yearRef && value.length === 4) {
      monthRef.current?.focus();
    } else if (ref === monthRef && value.length === 2) {
      dayRef.current?.focus();
    }

    if (!numberCheck.test(value)) {
      return setIsValid(false);
    }
    return setIsValid(true);
  };

  const hanldeOnSubmit = async () => {
    if (
      (!isGender && !isBirthday) ||
      (!(isBirthday?.length === 8 && isBirthday?.length > 1) && isBirthday)
    ) {
      setIsValid(false);
      return yearRef.current?.focus();
    }
    if (isBirthday && isGender) {
      await profileClient
        .patch('/account/profile/marketing-info', {
          birthday: isBirthday === '' ? null : isBirthday,
          sex: isGender,
        })
        .then(() => {
          hide(true);
        })
        .catch((err) => {
          toast({
            message: err.response?.data.message || err.message,
          });
        });
    } else if (
      await confirm({
        message: `${!isBirthday ? '생년월일' : '성별'}도 입력해주시면, 고객님을 위한\n맞춤형 광고를 받으실 수 있습니다.\n이대로 적용하시겠습니까?`,
        title: '',
      })
    ) {
      await profileClient
        .patch('/account/profile/marketing-info', {
          birthday: isBirthday === '' ? null : isBirthday,
          sex: isGender,
        })
        .then(() => {
          hide(true);
        })
        .catch((err) => {
          toast({
            message: err.response?.data.message || err.message,
          });
        });
    }
  };

  return (
    <CustomDialogCss>
      <div className="dialogCsswrap">
        <div className="ques">
          <h3>{marketingContents.title ?? title}</h3>
          <pre>{marketingContents.subTitle ?? message}</pre>
        </div>
        <div className="dialogCssInfoWrap">
          <div className="gender">
            <p>{marketingContents.gender}</p>
            <div className="imgSection">
              <input
                checked={isGender === 'MALE'}
                id="male"
                type="checkbox"
                onChange={() => handleGenderChange('MALE')}
              />
              <label className="isCheckedMale" htmlFor="male" />
              <input
                checked={isGender === 'FEMALE'}
                id="female"
                type="checkbox"
                onChange={() => handleGenderChange('FEMALE')}
              />
              <label className="isCheckedFemale" htmlFor="female" />
            </div>
          </div>
          <div className="birthday">
            <p>{marketingContents.birthday}</p>
            <div className="inputSection">
              <input
                ref={yearRef}
                className={isValid ? 'year' : 'year errorInput'}
                placeholder="YYYY"
                type="text"
                onBlur={() => handleInputOnblur(yearRef)}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (
                    newValue.length >= 4 &&
                    ((Number(newValue) || 0) < 1900 ||
                      (Number(newValue) || 0) > new Date().getFullYear())
                  ) {
                    e.target.value = prevInputs.current.year;
                  } else {
                    handleAutoFocus(newValue, yearRef);
                    prevInputs.current.year = newValue;
                  }
                }}
                onFocus={() => handleInputFocus(yearRef)}
                onInput={(e) => handleOnInput(e)}
              />
              <input
                ref={monthRef}
                className={isValid ? 'month' : 'month errorInput'}
                placeholder="MM"
                type="text"
                onBlur={() => handleInputOnblur(monthRef)}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length >= 2 && (Number(newValue) || 0) > 12) {
                    e.target.value = prevInputs.current.month;
                  } else {
                    handleAutoFocus(newValue, monthRef);
                    prevInputs.current.month = newValue;
                  }
                }}
                onFocus={() => handleInputFocus(monthRef)}
                onInput={(e) => handleOnInput(e)}
              />
              <input
                ref={dayRef}
                className={isValid ? 'day' : 'day errorInput'}
                placeholder="DD"
                type="text"
                onBlur={() => handleInputOnblur(dayRef)}
                onChange={(e) => {
                  const date = new Date();
                  date.setFullYear(
                    prevInputs.current.year,
                    prevInputs.current.month
                  );
                  date.setDate(0);
                  const currentMonthMaxDay = date.getDate();

                  const newValue = e.target.value;
                  if (
                    newValue.length >= 2 &&
                    (Number(newValue) || 0) > currentMonthMaxDay
                  ) {
                    e.target.value = prevInputs.current.day;
                  } else {
                    handleAutoFocus(newValue, dayRef);
                    prevInputs.current.day = newValue;
                  }
                }}
                onFocus={() => handleInputFocus(dayRef)}
                onInput={(e) => handleOnInput(e)}
              />
            </div>
            {isValid ? (
              ''
            ) : (
              <p className="errorMessage">생년월일 8자리를 입력해주세요.</p>
            )}
          </div>
          <div className="notifyText">
            <p>{marketingContents.notifyText}</p>
          </div>
        </div>
        <div className="respon">
          <span
            className="dialogCssActionButton negative"
            onClick={async () => {
              if (
                await confirm({
                  message:
                    '이 정보를 나중에 제공하시겠습니까?\n언제든지 마이페이지 > 설정에서\n수정하실 수 있습니다.',
                  // title: '확인',
                })
              ) {
                hide(false);
              }
            }}
          >
            {marketingContents.textNo ?? textNo}
          </span>
          <span
            ref={submit}
            className={
              !isGender && !isBirthday
                ? 'disabled'
                : 'dialogCssActionButton positive'
            }
            type="submit"
            onClick={() => {
              !isGender && !isBirthday ? console.log('no') : hanldeOnSubmit();
            }}
          >
            {marketingContents.textYes ?? textYes}
          </span>
        </div>
      </div>
    </CustomDialogCss>
  );
};

export default SetMarketingInfoDialog;

const CustomDialogCss = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);

  & > .dialogCsswrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 312px;

    transform: translate(-50%, -50%);

    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    & > .ques {
      padding: 24px 24px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > * {
        text-align: center;
        color: #26282b;
      }
      & > h3 {
        overflow: hidden;
        color: var(--Gray-gray-11, #26282b);
        text-align: center;
        text-overflow: ellipsis;
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 118.182% */
        letter-spacing: -0.3px;
      }
      & > pre {
        text-align: center;
        color: #666;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.3px;
      }
    }
    & > .dialogCssInfoWrap {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 24px 0;

      & > .gender {
        display: flex;
        flex-direction: column;
        gap: 16px;
        & > p {
          overflow: hidden;
          color: var(--Gray-gray-11, #26282b);
          text-overflow: ellipsis;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.3px;
        }
        & > .imgSection {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          gap: 16px;

          & > input[type='checkbox'] + label {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 100px;
            height: 100px;
            cursor: pointer;
          }
          & > input[type='checkbox']:checked + label {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 100px;
            height: 100px;
            cursor: pointer;
          }
          & > #male[type='checkbox'] {
            display: none;
          }
          & > #male[type='checkbox'] + label {
            background-image: url(${marketingUncheckedMan});
          }
          & > #male[type='checkbox']:checked + label {
            background-image: url(${marketingCheckedMan});
          }
          & > #female[type='checkbox'] {
            display: none;
          }
          & > #female[type='checkbox'] + label {
            background-image: url(${marketingUncheckedWoman});
          }
          & > #female[type='checkbox']:checked + label {
            background-image: url(${marketingCheckedWoman});
          }
        }
      }
      & > .birthday {
        display: flex;
        flex-direction: column;
        gap: 8px;
        & > p {
          overflow: hidden;
          color: var(--Gray-gray-11, #26282b);
          text-overflow: ellipsis;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.3px;
        }
        & .inputSection {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          gap: 8px;
          & > input {
            padding: 8px 16px;
            font-size: 15px;
            color: #222222;
            width: 100%;
            border-radius: 5px;
            border: 1px solid var(--Gray-gray-3, #e8ebed);
            background: var(--black-white-white, #fff);
            position: relative;
            background: none;
            z-index: 5;
            & ::placeholder {
              color: #9fa0a0;
            }
            & :focus {
              outline: none;
            }
          }
          & > .errorInput {
            outline: none;
            border: 1px solid var(--Red-red-9, #f43636) !important;
          }

          & > .year::placeholder,
          .month::placeholder,
          .day::placeholder {
            color: #9fa4aa;
          }
        }
        & > .errorMessage {
          color: var(--Red-red-9, #f43636);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.3px;
        }
      }
      > .notifyText {
        padding: 16px 0;
        & > p {
          color: #919191;
          text-align: center;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px; /* 220% */
          letter-spacing: -0.3px;
        }
      }
    }
    & > .respon {
      display: flex;
      height: 50px;

      border-top: 1px solid #c9cdd2;
      & > span {
        flex: 1;
        height: 100%;
        line-height: 50px;
        font-size: 14px;
        text-align: center;

        box-sizing: border-box;
        cursor: pointer;
        &:first-of-type {
          border-radius: 0px 0px 0px 8px;
          background: var(--Gray-gray-1, #f7f8f9);
          color: #9fa0a0;
        }
        &:last-of-type {
          background-color: #2f80ed;
          color: #fff;
        }
        &.disabled {
          background: #97bff6;
        }
      }
    }
  }
`;

import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';

// title : 제목, 상단 굵은글씨, message : 제목 밑 보통 글씨, options : 버튼의 배열, options[i].callback에 클릭 시 동작을 담을 수 있음
const CustomAlert = ({ bottom = false, type, visible }) => {
  const { close, message, options, title } = visible;
  return (
    <>
      {visible && (
        <CustomAlertStyle className={`${bottom ? 'bottom' : ''} ${type || ''}`}>
          <div className="alert-wrap">
            {close && <div className="img-bul close gray" onClick={close} />}
            <div className="ques">
              {title && <h3>{title}</h3>}
              <pre>{message}</pre>
            </div>
            <div className="respon">
              {options ? (
                options.map((v, i) => (
                  <span
                    key={`custom-alert-${i}`}
                    style={v.style}
                    onClick={v.callback}
                  >
                    {v.label}
                  </span>
                ))
              ) : (
                <span onClick={() => {}}>확인</span>
              )}
            </div>
          </div>
        </CustomAlertStyle>
      )}
    </>
  );
};

const bottomToTop = keyframes`
 0%{
  transform: translate(-50%, 100%);
 }
 100%{
  transform: translate(-50%, 0);
 }
`;

const CustomAlertStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;

  & > .alert-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 270px;

    transform: translate(-50%, -50%);

    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    & > .img-bul.close {
      position: absolute;
      right: 4px;
      top: 4px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    & > .ques {
      padding: 24px 0;
      & > * {
        text-align: center;
        color: #26282b;
      }
      & > h3 {
        font-size: 16px;
        font-weight: 700;
        & + pre {
          margin-top: 8px;
        }
      }
      & > pre {
        font-size: 14px;
      }
    }
    & > .respon {
      display: flex;
      height: 50px;

      border-top: 1px solid #c9cdd2;
      & > span {
        flex: 1;
        height: 100%;
        line-height: 50px;
        font-size: 14px;
        text-align: center;

        box-sizing: border-box;
        cursor: pointer;
        & + span {
          border-left: 1px solid #c9cdd2;
        }
        &:last-of-type {
          color: #3366cc;
        }
      }
    }
  }

  &.bottom {
    & > .alert-wrap {
      top: auto;
      bottom: 0;
      width: 100%;
      min-width: 360px;
      max-width: 420px;
      border-radius: 0;

      transform: translate(-50%, 0);
      animation: ${bottomToTop} 0.2s;

      & > .respon {
        & > span {
          color: #fff;
          background: #1c79bc;
          border: none;
        }
      }
    }
  }

  &.button {
    & > .alert-wrap {
      & > .respon {
        border: none;
        justify-content: center;
        height: auto;
        padding: 0 0 24px;
        & > span {
          width: 80px;
          flex: 0 0 80px;
          height: 40px;
          line-height: 38px;

          border: 1px solid #ccc;
          border-radius: 5px;
          & + span {
            margin-left: 12px;
          }
        }
      }
    }
  }
`;

export default memo(CustomAlert);

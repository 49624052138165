import { Slide } from 'components/modules/Modal';
import { GlobalStyle } from 'styles';
import NotificationRequestContent from './NotificationRequestContent';

const NotificationRequest = ({ data, open, onClose }) => {
  if (!open) {
    return null;
  }
  return (
    <Slide
      backgroundColor="white"
      onClose={onClose}
      onSuccess={() => {}}
      visible={open}
    >
      <GlobalStyle>
        <NotificationRequestContent data={data} onClose={onClose} />
      </GlobalStyle>
    </Slide>
  );
};

export default NotificationRequest;

import getAccountUrl from 'api/getAccountUrl';
import { Container } from 'components/common';
import CustomAlert from 'components/common/customAlert';
import Document from 'components/common/document';
import { HeadNew } from 'components/common/header';
import { infiniteScroll } from 'components/hook';
import MyReviewList from 'components/mypage/myReview/myReviewList';
import { conf } from 'conf/settings';
import { SESSION } from 'context';
import { check_session } from 'context/global/session';
import ModalProvider from 'context/modal';
import {
  delete_my_review,
  get_my_content_reviews,
  get_my_content_reviews_more,
  get_my_profile,
  get_my_reviews,
  get_my_reviews_more,
} from 'context/mypage';
import { ToastContext } from 'context/toast';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const thisPathname = '/마이페이지/내댓글';

const MyReview = memo(
  ({
    check_session,
    delete_my_review,
    get_my_content_reviews,
    get_my_content_reviews_more,
    get_my_profile,
    get_my_reviews,
    get_my_reviews_more,
    state,
  }) => {
    const location = useLocation();
    const [observer, setObserver] = useState(null);
    const [scrollCan, setScrollCan] = useState(false);
    const [nowNext, setNowNext] = useState(false);
    const [category, setCategory] = useState(false);
    const [popMsg, setPopMsg] = useState();
    const { showToast } = useContext(ToastContext);
    const { history } = useHistory();
    const { profile } = state;
    let reviews;
    let reviewNext;
    if (category) {
      reviews = state.contentReviews;
      reviewNext = state.contentReviewNext;
    } else {
      reviews = state.reviews;
      reviewNext = state.reviewNext;
    }

    useEffect(() => {
      // const checkSession = async () => {
      //   const session = await check_session();

      // if (!session?.identity) {
      //   return window.location.replace('https://market.tpirates.com/mypage');
      // }
      //   get_my_profile();
      //   load();
      // };

      // if (!session || !session.identity) {
      //   checkSession();
      // } else {
      //   get_my_profile();
      //   load();
      // }
      check_session()
        .then((r) => {
          if (!r?.identity) {
            window.location.href = getAccountUrl({
              redirectUrl: `${conf.FISH_MARKET_URL}/마이페이지/내댓글`,
            });
            history.replace('/');
          }
        })
        .catch(() => {
          history.replace('/');
        });
      get_my_profile();
      load();
    }, [category]);

    const load = () => {
      setScrollCan(false);
      const api = category ? get_my_content_reviews : get_my_reviews;
      api({ targetType: category ? 2 : 1 }).finally(() => {
        setScrollCan(true);
      });
    };

    const Popups = useCallback(
      (message) => ({
        message,
        options: [
          {
            callback: () => {
              setPopMsg(false);
            },
            label: '확인',
          },
        ],
        title: '',
      }),
      []
    );

    const deletePopup = useCallback(
      (review) => ({
        message: '댓글을 삭제하시겠습니까?',
        options: [
          {
            callback: () => {
              setPopMsg(false);
            },
            label: '취소',
          },
          {
            callback: () => {
              delete_my_review(review.id, {
                params: {
                  parentCode: review.parentCode,
                  targetCode: review.targetCode,
                  type: category ? 2 : 1,
                },
              }).then(() => {
                showToast('댓글이 삭제되었습니다.');
                setPopMsg(false);
              });
            },
            label: '확인',
          },
        ],
        title: '확인',
      }),
      [category]
    );

    infiniteScroll(([entry]) => {
      if (
        entry.isIntersecting &&
        scrollCan &&
        reviewNext &&
        (!nowNext || nowNext !== reviewNext)
      ) {
        setScrollCan(false);
        setNowNext(reviewNext);

        const api = category
          ? get_my_content_reviews_more
          : get_my_reviews_more;
        api({ sortNum: reviewNext, targetType: category ? 2 : 1 }).finally(
          () => {
            setScrollCan(true);
          }
        );
      }
    }, observer);

    useEffect(() => {
      if (location.pathname === thisPathname) {
        sessionStorage.setItem('mainPath', thisPathname);
      }
    }, [location.pathname]);

    return (
      <>
        <Document.Head title="후기 | 마이페이지" type="single" />
        <ModalProvider>
          <Container bg="#fff">
            <HeadNew label="수산시장 후기/댓글" />
            <MyReviewStyle
              className="main my-page"
              style={{ paddingTop: '56px' }}
            >
              {useCallback(
                <div className="select-review-category">
                  <span
                    className={category ? `` : `ON`}
                    onClick={() => setCategory(false)}
                  >
                    후기
                  </span>
                  <span
                    className={category ? `ON` : ``}
                    onClick={() => setCategory(true)}
                  >
                    댓글
                  </span>
                </div>,
                [category]
              )}
              <MyReviewList
                Popups={Popups}
                category={category}
                deletePopup={deletePopup}
                profile={profile}
                reviews={reviews}
                setObserver={setObserver}
                setPopMsg={setPopMsg}
              />
            </MyReviewStyle>
          </Container>
        </ModalProvider>
        <CustomAlert visible={popMsg || false} />
      </>
    );
  }
);

const MyReviewStyle = styled.div`
  /* padding: 0 4px; */
  & > .select-review-category {
    display: flex;
    height: 44px;

    border-bottom: 1px solid #c9cdd2;
    background: #fff;
    & > span {
      position: relative;
      flex: 1;
      height: 100%;
      line-height: 43px;

      text-align: center;
      color: #9fa4aa;
      font-size: 14px;
      font-weight: 600;

      cursor: pointer;

      &.ON {
        color: #26282b;
        &::after {
          display: block;
          content: '';

          position: absolute;
          bottom: -2px;
          left: 0;

          width: 100%;
          height: 4px;
          background: #26282b;
        }
      }
    }
  }

  & > .comment-area {
    & + .comment-area {
      margin-top: 4px;
    }
  }
`;
export default connect(
  ({ mypage, [SESSION]: session }) => ({ session, state: mypage }),
  {
    check_session,
    delete_my_review,
    get_my_content_reviews,
    get_my_content_reviews_more,
    get_my_profile,
    get_my_reviews,
    get_my_reviews_more,
  }
)(MyReview);

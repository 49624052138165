import Icon from 'components/Icons/v2/Icon';
import styled from 'styled-components';

// TODO: Hendrix1995 getAssetUrl of @the-pirates/asset-url
const dashImageUrl =
  'https://public-assets.tpirates.com/contents/common/border-dash-type1-gray.png';

const Dash = ({ style }) => {
  return (
    <DashWrap
      style={{
        ...style,
        backgroundImage: `url(${dashImageUrl})`,
        backgroundSize: '10px',
      }}
    />
  );
};

const PaymentItemList = ({ product }) => {
  const orderDetailInfoList = product?.orderDetailInfoList || [];
  const productTotalPrice = Number(product?.productTotalPrice || 0);

  const orderData = {
    products: orderDetailInfoList.filter(
      (detailItem) => !detailItem.option && !detailItem.requiredOption
    ),
    requiredOptionGroups: orderDetailInfoList.filter(
      (detailItem) => detailItem.option && detailItem.requiredOption
    ),
    selectionOptionGroups: orderDetailInfoList.filter(
      (detailItem) => detailItem.option && !detailItem.requiredOption
    ),
  };

  return (
    <ContainerWrap>
      <ListWrap>
        <ColumnWrap>
          {COLUMNS_INFO.map((column) => {
            return (
              <column.component key={column.value}>
                {column.value}
              </column.component>
            );
          })}
        </ColumnWrap>

        <Dash style={{ margin: '-8px -12px 0px -12px' }} />

        <ContentsListWrap>
          {orderData.products.map((item, index) => {
            const originPrice = Number(item.originPrice || 0);
            const unitPrice = Number(item.unitPrice || 0);
            const discountPrice =
              originPrice - unitPrice > 0 ? originPrice - unitPrice : 0;
            const totalQuantity = Number(item.totalQuantity || 0);
            const quantity = Number(item.quantity || 0);
            const totalPrice = Number(item.totalPrice || 0);
            return (
              <ContentsList key={index}>
                <DetailColumnSpan>
                  {item.priceName}
                  {totalQuantity > 0 && ` / ${quantity.toLocaleString()}개`}
                </DetailColumnSpan>
                <PerPriceColumnSpan>
                  {discountPrice > 0 && (
                    <DiscountSpan
                      style={{
                        color: '#8A8D90',
                        textDecoration: 'line-through',
                      }}
                    >
                      {originPrice.toLocaleString()}
                    </DiscountSpan>
                  )}
                  <DarkColorSpan>{unitPrice.toLocaleString()}</DarkColorSpan>
                </PerPriceColumnSpan>
                <QuantityColumnSpan>
                  {totalQuantity > 0
                    ? totalQuantity.toLocaleString()
                    : quantity.toLocaleString()}
                </QuantityColumnSpan>
                <TotalPriceColumnSpan>
                  {totalPrice.toLocaleString()}
                </TotalPriceColumnSpan>
              </ContentsList>
            );
          })}

          {orderData.requiredOptionGroups.map((item, index) => {
            const originPrice = Number(item.originPrice || 0);
            const unitPrice = Number(item.unitPrice || 0);
            const discountPrice =
              originPrice - unitPrice > 0 ? originPrice - unitPrice : 0;
            const totalQuantity = Number(item.totalQuantity || 0);
            const quantity = Number(item.quantity || 0);
            const totalPrice = Number(item.totalPrice || 0);
            return (
              <ContentsList key={index}>
                <DetailColumnSpan
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  <Icon color="#464C53" name="conection-16_1" size={16} />
                  {item.priceName}
                  {totalQuantity > 0 && ` / ${quantity.toLocaleString()}개`}
                </DetailColumnSpan>
                <PerPriceColumnSpan>
                  {discountPrice > 0 && (
                    <DiscountSpan
                      style={{
                        color: '#8A8D90',
                        textDecoration: 'line-through',
                      }}
                    >
                      {originPrice.toLocaleString()}
                    </DiscountSpan>
                  )}
                  <DarkColorSpan>{unitPrice.toLocaleString()}</DarkColorSpan>
                </PerPriceColumnSpan>
                <QuantityColumnSpan>
                  {totalQuantity > 0
                    ? totalQuantity.toLocaleString()
                    : quantity.toLocaleString()}
                </QuantityColumnSpan>
                <TotalPriceColumnSpan>
                  {totalPrice.toLocaleString()}
                </TotalPriceColumnSpan>
              </ContentsList>
            );
          })}

          {orderData.selectionOptionGroups.map((item, index) => {
            const originPrice = Number(item.originPrice || 0);
            const quantity = Number(item.quantity || 0);
            const totalPrice = Number(item.totalPrice || 0);
            return (
              <ContentsList key={index}>
                <DetailColumnSpan
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  <Icon color="#464C53" name="plus-16_1" size={16} />
                  {item.priceName}
                </DetailColumnSpan>
                <PerPriceColumnSpan>
                  <DarkColorSpan>{originPrice.toLocaleString()}</DarkColorSpan>
                </PerPriceColumnSpan>
                <QuantityColumnSpan>
                  {quantity.toLocaleString()}
                </QuantityColumnSpan>
                <TotalPriceColumnSpan>
                  {totalPrice.toLocaleString()}
                </TotalPriceColumnSpan>
              </ContentsList>
            );
          })}
        </ContentsListWrap>

        <Divider />

        <BottomWrap>
          <PriceText>상품 금액</PriceText>
          <Price>{productTotalPrice.toLocaleString()}원</Price>
        </BottomWrap>
      </ListWrap>
    </ContainerWrap>
  );
};

const Price = styled.span`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const PriceText = styled.span`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const Divider = styled.div`
  border-bottom: 1px solid;
  margin: 0 -12px;
`;

const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentsList = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--Gray-gray-11, #26282b);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ContentsListWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentSpan = styled.span`
  font-size: 12px;
`;

const DarkColorSpan = styled(ContentSpan)`
  color: #26282b;
`;

const DiscountSpan = styled(ContentSpan)`
  color: '#8A8D90';
  text-decoration: 'line-through';
`;

const ColumnSpan = styled(DarkColorSpan)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const DetailColumnSpan = styled(ColumnSpan)`
  width: 100%;
  text-align: start;
`;

const PerPriceColumnSpan = styled(ColumnSpan)`
  min-width: 62px;
  text-align: end;
  display: flex;
  flex-direction: column;
`;

const QuantityColumnSpan = styled(ColumnSpan)`
  min-width: 26px;
  text-align: end;
`;

const TotalPriceColumnSpan = styled(ColumnSpan)`
  min-width: 63px;
  text-align: end;
  padding-left: 4px;
  font-weight: 700;
`;

const ColumnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${ColumnSpan} {
    color: #8a8d90;
  }

  ${TotalPriceColumnSpan} {
    font-weight: inherit;
  }
`;

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContainerWrap = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f7f8f9;
  border-radius: 8px;
`;

const DashWrap = styled.div`
  height: 1px;
`;

const COLUMNS_INFO = [
  { component: DetailColumnSpan, value: '상세' },
  { component: PerPriceColumnSpan, value: '개당 금액' },
  { component: QuantityColumnSpan, value: '수량' },
  { component: TotalPriceColumnSpan, value: '총 금액' },
];

export default PaymentItemList;

import React from 'react';

const ArrowRight = ({ size = 24, fill = '#9FA4AA' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 12"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 0L0.589996 1.41L5.17 6L0.589996 10.59L2 12L8 6L2 0Z" />
  </svg>
);

export default React.memo(ArrowRight);

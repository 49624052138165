import { Typography } from 'components/atoms';
import SadPiratesIcon from 'components/Icons/SadPiratesIcon';
import styled from 'styled-components';

const NoResultAlarm = ({
  contents = '검색결과가 없습니다.',
  paddingBottom = 0,
  paddingTop = 100,
}) => {
  return (
    <NoResultWrapper paddingBottom={paddingBottom} paddingTop={paddingTop}>
      <SadPiratesIcon />
      <Typography className="alert-text" fontSize="18px" fontWeight={500}>
        {contents}
      </Typography>
    </NoResultWrapper>
  );
};

const NoResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: ${({ paddingTop }) => `${paddingTop}px`};
  padding-bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
  .alert-text {
    color: ${({ theme }) => theme.newGray4};
    font-size: 20px;
    font-weight: 500;
  }
`;

export default NoResultAlarm;

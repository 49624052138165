import { useRecoilState } from 'recoil';
import { priceSortState } from 'recoil/filter';
import { convertJson } from 'utils/convertJson';

const usePriceSortState = (isLogin, nonLoginFilterValue) => {
  const [priceState, setPriceState] = useRecoilState(priceSortState);

  const initializePriceState = () => {
    const newState = convertJson(priceState);
    newState.forEach((element) => {
      if (element.contents === '모든지역') {
        element.value = '';
        element.zoneCode = '0000000000';
      } else {
        element.value = '';
        element.itemGroupCode = '0000000000';
      }
    });

    if (isLogin === 'notLogin') {
      nonLoginFilterValue(newState);
      setPriceState(newState);
    } else {
      setPriceState(newState);
    }
  };

  return { priceState, setPriceState, initializePriceState };
};

export default usePriceSortState;

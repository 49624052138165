import './style.css';

import { Image } from 'components/views';
import React from 'react';
import styled from 'styled-components';

const CardItem = ({ block, item, onClickItem }) => {
  return (
    <div className="card-template type5 level2">
      <div
        className="use-ga-event"
        data-ga-label="온라인(쇼핑) 아웃링크"
        data-ga-location={item.subject}
        onClick={(e) => onClickItem(e, item)}
      >
        <div className="fixed inner-section type1">
          <span className="img-block">
            <Image
              src={item.thumbnail}
              onDragStart={(e) => e.preventDefault()}
            />
          </span>
          <span className="spot">{item.tag}</span>
        </div>
        <div className="fixed inner-section type2">
          <Subject block={block} className="subject txt-ellipsis">
            {item.subject}
          </Subject>
          <div
            className="price type2"
            style={
              {
                // fontFamily: 'Open Sans, Noto Sans KR, sans-serif',
              }
            }
          >
            {item.price}
          </div>
        </div>
      </div>
    </div>
  );
};

const Subject = styled.div`
  display: ${({ block }) => block && 'block !important'};
`;

CardItem.defaultProps = {
  onClickItem: (e) => {},
};

export default CardItem;

import * as g_api from 'api/global';
import { createAction, handleActions } from 'redux-actions';

import createApiRequest from '../_support';

/** Common Data loading Action Types */
const SETTING = 'pirates-data/SETTING';
const MENUS = 'pirates-data/MENUS';
const WIDGETS = 'pirates-data/WIDGETS';
const ZONE_SAVE = 'pirates-data/ZONE_SAVE';

const [load_setting, SETTING_OK, SETTING_FAIL] = createApiRequest(
  SETTING,
  g_api.load_setting
);
const [load_menus, MENUS_OK, MENUS_FAIL] = createApiRequest(
  MENUS,
  g_api.load_menus
);
const [load_widgets, WIDGETS_OK, WIDGETS_FAIL] = createApiRequest(
  WIDGETS,
  g_api.load_widgets
);
const zoneSave = createAction(ZONE_SAVE, (data) => data);

const state = {
  menus: [],
  setting: null,
  widgets: [],
  zone: false,
};

const app = handleActions(
  {
    [MENUS]: (state, action) => ({
      ...state,
    }),
    [MENUS_OK]: (state, action) => ({
      ...state,
      menus: action.payload,
    }),
    [SETTING]: (state, action) => ({
      ...state,
    }),
    [SETTING_OK]: (state, action) => ({
      ...state,
      setting: action.payload,
    }),
    [WIDGETS]: (state, action) => ({
      ...state,
    }),
    [WIDGETS_OK]: (state, action) => ({
      ...state,
      widgets: action.payload,
    }),
    [ZONE_SAVE]: (state, action) => ({
      ...state,
      zone: action.payload,
    }),
  },
  state
);

export { load_menus, load_setting, load_widgets, zoneSave };
export default app;

const contentsKeys = {
  all: ['contents'],
  getRecommend: () => [...contentsKeys.all, 'recommend'],
  getContents: (params) => [
    ...contentsKeys.all,
    'useGetInfiniteContents',
    params,
  ],
};

export default contentsKeys;

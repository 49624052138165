import { createAsyncThunk } from '@reduxjs/toolkit';
import profileClient from 'api/profileClient';

// User Profile
export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile',
  async (_, { rejectWithValue }) => {
    try {
      const [
        {
          data: {
            name = '',
            shopping: { address = '', addressDetail = '', phone = '' },
          },
        },
        {
          data: { result },
        },
      ] = await Promise.all([profileClient.get('/account/profile')]);

      return {
        address: address || '',
        addressDetail: addressDetail || '',
        isValid: result === 'success',
        name: name || '',
        phoneNumber: phone || '',
      };
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

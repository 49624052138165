import getAccountUrl from 'api/getAccountUrl';
import Observer from 'components/atoms/Observer';
import { Container } from 'components/common';
import CustomAlert from 'components/common/customAlert';
import Document from 'components/common/document';
import { HeadNew } from 'components/common/header';
import SortFilter from 'components/common/\bSortFilter';
import Coupons from 'components/coupons/_coupons';
import { Section } from 'components/section';
import { Loading } from 'components/views';
import { conf } from 'conf/settings';
import { SESSION } from 'context';
import { check_session } from 'context/global/session';
import ModalProvider, { ModalContext } from 'context/modal';
import useMyCouponList from 'hooks/custom/useMyCouponList';
import useGetCouponDetail from 'hooks/Query/CouponList/useGetCouponDetail';
import useObserver from 'hooks/useObserver';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { allCouponsListState } from 'recoil/coupons';
import styled from 'styled-components';

import EmptyPage from './_empty_page';

const getSortState = (data) => {
  switch (data) {
    case 'default':
      return '기본순';
    case 'discountRate':
      return '할인율 높은순';
    default:
      return '사용 기간순';
  }
};

const SORT_LIST = [
  { key: 'default', label: '기본순' },
  { key: 'discountRate', label: '할인율 높은순' },
  { key: 'useDate', label: '사용 기간순' },
];

const MyCouponWithModal = ({ ...props }) => {
  const { session } = props;

  const [couponId, setCouponId] = useState(0); // 쿠폰 모달 데이터요청시 필요한 id값
  return (
    <ModalProvider
      closeModal={() => setCouponId(0)}
      session={session}
      type="myCoupon"
    >
      <MyCoupon {...props} couponId={couponId} setCouponId={setCouponId} />
    </ModalProvider>
  );
};

const MyCoupon = memo(({ check_session, couponId, setCouponId }) => {
  const history = useHistory();
  const scrollTarget = useRef(null);
  const { showModal } = useContext(ModalContext);
  const [popMsg, setPopMsg] = useState();
  const [couponList, setCouponList] = useRecoilState(allCouponsListState);
  const newState = useResetRecoilState(allCouponsListState);

  const { data: couponDetailData } = useGetCouponDetail(couponId); // id값이 있을떄만 요청.
  const {
    data = { pages: [{ coupons: [], total: 0 }] },
    fetchNextPage,
    hasNextPage,
    isLoading,
    newCouponList,
  } = useMyCouponList();

  const onIntersect = useCallback(
    (entry) => {
      if (hasNextPage && entry.isIntersecting) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useObserver({
    onIntersect,
    target: scrollTarget,
  });

  useEffect(() => {
    if (couponDetailData) {
      showModal(couponDetailData);
    }
  }, [couponDetailData]);

  useEffect(() => {
    check_session()
      .then((r) => {
        if (!r?.identity) {
          window.location.href = getAccountUrl({
            redirectUrl: `${conf.FISH_MARKET_URL}/마이페이지/내쿠폰`,
          });
          history.replace('/');
        }
      })
      .catch(() => {
        history.replace('/');
      });
  }, []);

  useEffect(() => {
    sessionStorage.setItem('mainPath', '/마이페이지/내쿠폰');

    return () => {
      newState();
    };
  }, []);

  const clickListSort = (e) => {
    setCouponList((a) => ({ ...a, sort: e.target.id }));
  };

  const onClickCoupon = (id) => {
    setCouponId(id);
  };

  const Popups = {
    null: {
      message: '발급 가능한 쿠폰이 없습니다.',
      options: [
        {
          callback: () => {
            setPopMsg(false);
          },
          label: '확인',
        },
      ],
      title: '',
    },
  };

  return (
    <>
      <Document.Head title="쿠폰함" type="single" />
      <Container bg="#fff">
        <HeadNew label="수산시장 쿠폰함" />
        <MyCouponStyle className="main my-page" style={{ paddingTop: '56px' }}>
          <div className="my-coupon-button-wrap">
            <button
              onClick={() => {
                history.push('/쿠폰/모든쿠폰');
              }}
            >
              + 신규 쿠폰 받기
            </button>
          </div>
          <TitleWrap>
            <TitleBox>
              <Title>사용 가능 쿠폰</Title>
              <CouponTotal>{data.pages[0].totalCount}</CouponTotal>
            </TitleBox>
            <SortFilter
              option={SORT_LIST}
              value={getSortState(couponList.sort) || '기본순'}
              onChangeSelection={clickListSort}
            />
          </TitleWrap>
          {isLoading && <Loading />}
          {newCouponList.length !== 0 ? (
            <>
              <Section type="board">
                <Coupons
                  items={newCouponList}
                  view="imgboard"
                  onClick={onClickCoupon}
                />
              </Section>
              <Observer ref={scrollTarget} />
              <FooterText>
                <p>쿠폰을 가게에 제시하시면 할인이 적용됩니다.</p>
                <p>사용기한 만료 시 자동삭제 됩니다.</p>
              </FooterText>
            </>
          ) : (
            <EmptyPage />
          )}
        </MyCouponStyle>
      </Container>

      <CustomAlert visible={Popups[popMsg] || false} />
    </>
  );
});

const MyCouponStyle = styled.div`
  & > .my-coupon-button-wrap {
    padding: 0 15px;
    margin-top: 10px;
    & > button {
      width: 100%;
      height: 44px;
      color: #26282b;
      font-size: 16px;

      background: #f7f8f9;
      border: 1px solid #c9cdd2;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  & > .section.card-list7 {
    display: flex;
    flex-wrap: wrap;
  }
`;
const TitleWrap = styled.div`
  padding: 32px 16px 16px 16px;
  display: flex;

  position: relative;
  justify-content: space-between;
  align-items: center;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #26282b;
`;

const CouponTotal = styled.div`
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: #72787f;
`;

const FooterText = styled.div`
  margin: 24px 16px 0 16px;

  & > p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.3px;
    color: ${({ theme }) => theme.gray400};
  }
`;

export default connect(({ [SESSION]: session }) => ({ session }), {
  check_session,
})(MyCouponWithModal);

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRecommendLike } from 'api/marketFilter';
import { ToastContext } from 'context/toast';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { globalStoresFilter } from 'recoil/price';
import { globalSearchKeyword } from 'recoil/search';

import filterKeys from '../Filter/keys';

export default function useUpdateRecommendLike() {
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  // <> useGetRecommendStores
  const filter = useRecoilValue(globalStoresFilter);
  const keyword = useRecoilValue(globalSearchKeyword) || null;
  const deliveryList =
    filter?.orderType?.map((item) => item.value)?.join(',') || null;
  const labelIdList =
    filter.product
      .flatMap((item) => item.labels)
      .map((item) => item.labelId)
      .join(',') || null;

  const latitude = filter?.around[0] ? filter.around[0].lat : null;

  const longitude = filter?.around[0] ? filter.around[0].long : null;
  const marketCodeList =
    filter?.location.map((item) => item.marketCode)?.join(',') || null;

  return useMutation(updateRecommendLike, {
    onError: (err, value, context) => {
      queryClient.setQueryData(
        filterKeys.getRecommendStores({
          deliveryList,
          keyword,
          labelIdList,
          latitude,
          longitude,
          marketCodeList,
        }),
        context.previousData
      );
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(
        filterKeys.getRecommendStores({
          deliveryList,
          keyword,
          labelIdList,
          latitude,
          longitude,
          marketCodeList,
        })
      );

      const previousData = queryClient.getQueryData(
        filterKeys.getRecommendStores({
          deliveryList,
          keyword,
          labelIdList,
          latitude,
          longitude,
          marketCodeList,
        })
      );

      const targetIndex = [...previousData].findIndex(
        (item) => item.id === newData.targetCode
      );

      const totalNewData = [...previousData];
      totalNewData[targetIndex].favorite =
        totalNewData[targetIndex].favorite === 'off' ? 'on' : 'off';

      queryClient.setQueryData(
        filterKeys.getRecommendStores({
          deliveryList,
          keyword,
          labelIdList,
          latitude,
          longitude,
          marketCodeList,
        }),
        () => totalNewData
      );

      return { previousData };
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(
        filterKeys.getRecommendStores({
          deliveryList,
          keyword,
          labelIdList,
          latitude,
          longitude,
          marketCodeList,
        })
      );
      if (res.doLike) {
        showToast('즐겨찾기에 추가되었습니다.마이페이지에서 확인하세요.');
      } else {
        showToast('즐겨찾기 등록이 해제되었습니다.');
      }
    },
  });
}

import TagManager from '@sooro-io/react-gtm-module';
import {
  BUILD_TYPE_DEVELOPMENT,
  BUILD_TYPE_PRODUCTION,
  BUILD_TYPE_STAGING,
  getBuildType,
} from 'script/system/build-configs';
import { getGtmTokens } from 'script/system/system-configs';

import { handleCookieDomain } from './config/url-origin-configs';
import { Cookie } from './util/cookie/cookie';

const initializeGtm = () => {
  const buildType = getBuildType();
  if (
    buildType === BUILD_TYPE_PRODUCTION ||
    buildType === BUILD_TYPE_STAGING ||
    buildType === BUILD_TYPE_DEVELOPMENT
  ) {
    const gtmTokens = getGtmTokens();
    if (gtmTokens) {
      TagManager.initialize({
        auth: gtmTokens.gtmAuth,
        gtmId: gtmTokens.gtmId,
        preview: gtmTokens.gtmPreview,
      });
    }
  }
};

export const initializeApp = () => {
  // initializeGtm();
  openChatTrigger();
};

const openChatTrigger = () => {
  const cookie = new Cookie('_tp_');
  const openChat = cookie.getCookie('openChat');

  if (openChat === 'FISH_MARKET') {
    cookie.deleteCookies('openChat', {
      domain: handleCookieDomain(),
      path: '/',
    });
    window.location.href = 'https://pf.kakao.com/_lVxhbl/chat';
  } else {
    cookie.deleteCookies('openChat', {
      domain: handleCookieDomain(),
      path: '/',
    });
  }
};

import { useState } from 'react';

const useCarousel = (flicking) => {
  const [current, setCurrent] = useState(0);

  const selectSlide = (idx) => {
    flicking.current && flicking.current.flicking.moveTo(idx);
    setCurrent(idx);
  };

  if (flicking.current) {
    flicking.current.flicking.on('moveEnd', () => {
      const id = flicking.current.flicking.getIndex();
      setCurrent(id);
    });
  }

  return [current, setCurrent, selectSlide];
};

export default useCarousel;

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  fetchAddresses,
  fetchDeliveryAvailable,
  fetchDeliveryTimes,
  fetchDeliveryTypes,
  fetchImmediateDeliveryTime,
  fetchShippingFee,
} from './thunk';

const initialState = {
  addressStatus: 'idle',
  addresses: [],
  dates: [],
  deliveryAvailable: true,
  deliveryId: '',
  immediateOrderTime: '',
  immediateOrderTimeRequestId: '',
  immediateOrderTimeStatus: 'idle',
  immediateShippingFee: 0,
  isImmediateDelivery: false,
  isImmediateDeliveryId: '',
  preOrderAvailable: false,
  reservationDelivery: false,
  selectedAddress: {},
  shippingFee: 0,
  shippingFeeStatus: 'idle',
  timeRequestId: '',
  timeStatus: 'idle',
  times: [],
  todayOrderAvailable: true,
};

const checkoutSlice = createSlice({
  extraReducers: {
    [fetchDeliveryAvailable.pending.type]: (state) => {
      state.shippingFee = 0;
      state.deliveryAvailable = true;
    },
    [fetchDeliveryAvailable.fulfilled.type]: (state, { payload = {} }) => {
      const { canDeliver } = payload;

      state.shippingFee = canDeliver ? 0 : -1;
    },
    [fetchDeliveryAvailable.rejected.type]: (state) => {
      state.shippingFee = -1;
      state.deliveryAvailable = false;
    },
    [fetchAddresses.pending.type]: (state) => {
      state.addresses = [];
      state.selectedAddress = {};
      state.addressStatus = 'loading';
    },
    [fetchAddresses.fulfilled.type]: (state, { payload }) => {
      const defaultAddress =
        payload.find((address) => address.default) || payload[0];

      const newAddresses = payload
        .filter((address) => {
          if (address.default || address.id === defaultAddress?.id)
            return false;

          return true;
        })
        .sort((a, b) => a.id - b.id);

      state.addresses = defaultAddress
        ? [defaultAddress, ...newAddresses]
        : newAddresses;
      state.selectedAddress = defaultAddress || {};
      state.addressStatus = 'success';
    },
    [fetchAddresses.rejected.type]: (state) => {
      state.addresses = [];
      state.selectedAddress = {};
      state.addressStatus = 'error';
    },
    [fetchDeliveryTypes.pending.type]: (state) => {
      state.dates = [];
    },
    [fetchDeliveryTypes.fulfilled.type]: (state, { payload }) => {
      state.dates = payload.convertedDates;
      const today = payload.convertedDates.find(
        (date) => date.type === 'today'
      );
      const next = payload.convertedDates.find(
        (date) => date.type === 'preorder'
      );

      state.todayOrderAvailable = !!today;
      state.preOrderAvailable = !!next;
      state.isImmediateDelivery = payload.immediateDelivery;
      state.reservationDelivery = payload.reservationDelivery;
    },
    [fetchDeliveryTypes.rejected.type]: (state) => {
      state.dates = [];
    },
    [fetchImmediateDeliveryTime.pending.type]: (state, { meta }) => {
      state.immediateOrderTimeRequestId = meta.requestId;
      state.immediateOrderTime = '';
      state.immediateShippingFee = 0;
      state.isImmediateDeliveryId = '';
      state.immediateOrderTimeStatus = 'loading';
      state.distance = null;
    },
    [fetchImmediateDeliveryTime.fulfilled.type]: (state, { meta, payload }) => {
      if (state.immediateOrderTimeRequestId !== meta.requestId) return;

      state.immediateOrderTime = payload.deliveryTime;
      state.immediateShippingFee = payload.fee;
      state.isImmediateDeliveryId = payload.deliveryId;
      state.immediateOrderTimeStatus = 'success';
      state.distance = null;
    },
    [fetchImmediateDeliveryTime.rejected.type]: (state, { payload }) => {
      const { message = '' } = payload || {};
      const [, distance] = /:\s*(\w*)/.exec(message) || [];
      state.immediateOrderTime = '';
      state.immediateShippingFee = -1;
      state.isImmediateDeliveryId = '';
      state.distance = distance || '';
      state.immediateOrderTimeStatus = 'error';
    },
    [fetchDeliveryTimes.pending.type]: (state, { meta }) => {
      state.timeRequestId = meta.requestId;
      state.times = [];
      state.timeStatus = 'loading';
      state.distance = null;
    },
    [fetchDeliveryTimes.fulfilled.type]: (state, { meta, payload = {} }) => {
      if (state.timeRequestId !== meta.requestId) return;

      const { orderCompletions = [] } = payload;

      state.times = orderCompletions.map((time) => ({
        ...time,
        maxArrivalTime: dayjs(time.maxArrivalTime).format('HH:mm:ss'),
        minArrivalTime: dayjs(time.minArrivalTime).format('HH:mm:ss'),
        pickupTime: dayjs(time.pickupTime).format('HH:mm:ss'),
      }));
      state.timeStatus = 'success';
      state.distance = null;
    },
    [fetchDeliveryTimes.rejected.type]: (state, { payload }) => {
      const { message = '' } = payload || {};
      const [, distance] = /:\s*(\w*)/.exec(message) || [];
      state.times = [];
      state.distance = distance || '';
      state.timeStatus = 'error';
    },
    [fetchShippingFee.pending.type]: (state) => {
      state.shippingFeeStatus = 'loading';
      state.deliveryId = '';
    },
    [fetchShippingFee.fulfilled.type]: (state, { payload }) => {
      state.deliveryId = payload.deliveryId;
      state.shippingFee = payload.fee;
      state.shippingFeeStatus = 'success';
    },
    [fetchShippingFee.rejected.type]: (state) => {
      state.shippingFee = -1;
      state.deliveryId = '';
      state.shippingFeeStatus = 'error';
    },
  },
  initialState,
  name: 'checkout',
  reducers: {
    resetCheckout: (state) => {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    setCheckout: (state, { payload }) => {
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

export const { resetCheckout, setCheckout } = checkoutSlice.actions;
export default checkoutSlice.reducer;

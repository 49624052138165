import React from 'react';

const KakaoPay = ({ size = 48 }) => {
  return (
    <svg
      width={size}
      height={size * 0.42}
      viewBox="0 0 48 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_175)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9851 20H10C4.47761 20 0 15.5224 0 10C0 4.47761 4.47761 0 10 0H38.01C43.5323 0 48.0099 4.47761 48.0099 10C48.0099 15.5224 43.5075 20 37.9851 20Z"
          fill="#FFEB00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.219 4.80115C8.20905 4.80115 5.77124 6.71657 5.77124 9.10463C5.77124 10.622 6.79114 11.9653 8.30855 12.7365L7.78617 14.6768C7.76129 14.7265 7.78617 14.8011 7.83592 14.8509C7.86079 14.8758 7.91054 14.9007 7.93542 14.9007C7.96029 14.9007 8.01005 14.8758 8.03492 14.8758L10.2489 13.3832C10.5722 13.433 10.8956 13.4579 11.2439 13.4579C14.2538 13.4579 16.6916 11.5176 16.6916 9.15438C16.6916 6.71657 14.2538 4.80115 11.219 4.80115Z"
          fill="black"
        />
        <path
          d="M21.2935 12.6865V15.4726H19.3035V5.19898H20.6965L20.9453 5.84574C21.3681 5.42286 22.0149 4.9751 23.0348 4.9751C24.9751 4.9751 25.8955 6.41788 25.8955 8.78107C25.8955 11.2438 24.4776 12.8109 22.4378 12.8109C22.0398 12.8358 21.7413 12.8109 21.2935 12.6865ZM21.2935 6.86565V11.2935C21.393 11.3184 21.6667 11.3433 21.9403 11.3433C23.3582 11.3433 23.9055 10.3482 23.9055 8.78107C23.9055 7.41291 23.5323 6.59201 22.4129 6.59201C22.0149 6.59201 21.6169 6.69152 21.2935 6.86565Z"
          fill="black"
        />
        <path
          d="M30.1991 8.10972H31.3185V7.86097C31.3185 7.04007 30.8458 6.66694 30.0498 6.66694C29.4279 6.66694 28.6568 6.84107 28.01 7.16445L27.4628 5.84604C28.1593 5.34853 29.2538 5.02515 30.224 5.02515C32.1145 5.02515 33.1593 6.02017 33.1593 7.93559V12.6371H31.7662L31.5672 12.0152C30.7712 12.5873 30.0249 12.8361 29.3782 12.8361C27.9354 12.8361 27.1145 11.9654 27.1145 10.4978C27.1145 8.93062 28.1842 8.10972 30.1991 8.10972ZM31.3434 10.7217V9.3535H30.423C29.4031 9.3535 28.8807 9.72664 28.8807 10.448C28.8807 10.9953 29.1543 11.2689 29.7513 11.2689C30.2986 11.2938 30.9951 11.0202 31.3434 10.7217Z"
          fill="black"
        />
        <path
          d="M39.179 11.8658C38.4825 13.7066 37.6616 15.0499 36.4427 15.7962L35.2238 14.6768C35.9203 14.0549 36.4427 13.4579 36.8656 12.6619L34.2288 5.4728L36.1939 4.95041L37.8855 10.7713L39.5521 4.92554L41.4924 5.4728L39.179 11.8658Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_175">
          <rect width="47.9851" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(KakaoPay);

import { createSlice } from '@reduxjs/toolkit';

import { getAvailableOrderTypes, getShippingMethod } from './thunk';

const initialState = {
  availableOrderTypes: [],
  isError: false,
  isLoading: false,
  nextDayDeliveryOpenDate: null,
  now: null,
  orderTypes: [],
};

const shippingMethodSlice = createSlice({
  extraReducers: {
    [getShippingMethod.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getShippingMethod.fulfilled.type]: (state, { payload = {} }) => {
      for (const key of Object.keys(payload)) {
        const value = payload[key];
        state[key] = value;
      }

      state.isLoading = false;
    },
    [getShippingMethod.rejected.type]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [getAvailableOrderTypes.fulfilled.type]: (state, { payload = {} }) => {
      state.availableOrderTypes = payload.orderTypes;
    },
  },
  initialState,
  name: 'shippingMethod',
  reducers: {
    disableShippingMethod: (state, { payload }) => {
      const orderType = state.orderTypes.find(
        ({ orderType }) => orderType === payload
      );

      if (!orderType) {
        return;
      }

      orderType.message = '<b>오늘 배송마감!</b>';
      orderType.isAvailable = false;
    },
  },
});

export const { disableShippingMethod } = shippingMethodSlice.actions;
export default shippingMethodSlice.reducer;

import './style.css';

import { _Estimate } from '../_panel/_estimate';
import PanelItem from '../_panel/_panel-item';
import useCart from './_use-cart';

const Supplier = ({ remove_cart_item, supplier, update_cart_count }) => {
  const { items, name } = supplier;

  return (
    <>
      <section className="supplier-head">
        <div className="item">
          <h3>{name}</h3>
        </div>
      </section>
      {items.map((item) => (
        <OrderInCart
          key={`order-in-cart_${item.id}`}
          handleChangeOrder={update_cart_count}
          handleRemoveItem={remove_cart_item}
          item={item}
          supplier={supplier.name}
        />
      ))}
      <EstimateInCart items={items} />
    </>
  );
};

const OrderInCart = ({
  handleChangeOrder,
  handleRemoveItem,
  item,
  supplier,
}) => {
  const { count, id, label, price, unit } = item;
  const criteria = item.order;
  const storage = useCart();

  const onClickAdd = () => {
    const newCount = count + criteria.addQty;
    handleChangeOrder({ count: newCount, id, supplier });
  };
  const onClickSub = () => {
    if (count !== criteria.minQty) {
      const newCount = count - criteria.addQty;
      handleChangeOrder({ count: newCount, id, supplier });
    }
  };
  const minusClassName = () => {
    return count === criteria.minQty ? 'minus disabled' : 'minus';
  };

  const onClickRemove = (id) => (e) => {
    e.preventDefault();
    storage.remove(id);
    handleRemoveItem(id);
  };

  return (
    <section className="order-by-supplier">
      <div className="item">
        <span>{label}</span>
        <span style={{ textAlign: 'right' }}>
          <a href="#" style={{ color: '#999999' }} onClick={onClickRemove(id)}>
            [삭제]
          </a>
        </span>
      </div>
      <PanelItem label="수량">
        <_AddControl
          minusClassName={minusClassName}
          quantity={count}
          unit={unit}
          onClickAdd={onClickAdd}
          onClickSub={onClickSub}
        />
      </PanelItem>
      <PanelItem label="가격">
        <span>
          {(count * price).toLocaleString()} 원{' '}
          {item.unit.tax === 'FREE' ? '(부가세 없음)' : ''}
        </span>
      </PanelItem>
    </section>
  );
};

const EstimateInCart = ({ items }) => {
  const { order_price } = items.reduce((acc, e) => ({
    ...acc,
    order_price: acc.order_price + e.order_price,
  }));
  const { delivery_price } = items.reduce((acc, e) => ({
    ...acc,
    delivery_price: Math.max(acc.delivery_price, e.delivery_price),
  }));

  return (
    <section className="estimate-by-supplier">
      <_Estimate
        delivery_charge={delivery_price}
        order_price={order_price}
        tax={null}
      />
    </section>
  );
};

const _AddControl = ({
  minusClassName,
  onClickAdd,
  onClickSub,
  quantity,
  unit,
}) => {
  return (
    <div className="add-control">
      <i className={minusClassName()} onClick={onClickSub} />
      <span>{`${(quantity / unit.qty).toFixed(0) + unit.label} (${
        quantity.toFixed(1) + unit.name
      })`}</span>
      <i className="plus" onClick={onClickAdd} />
    </div>
  );
};

export default Supplier;

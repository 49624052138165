import * as wholesale_api from 'api/wholesale';
import { handleActions } from 'redux-actions';

import createApiRequest from '../_support';

const ORDERS = 'pirates-data.biz/ORDERS';
const CANCEL_ORDER = 'pirates-data.biz/CANCEL_ORDER';

const [get_orders, ORDERS_OK, ORDERS_FAIL] = createApiRequest(
  ORDERS,
  wholesale_api.get_orders
);
const [cancel_order, CANCEL_ORDER_OK, CANCEL_ORDER_FAIL] = createApiRequest(
  CANCEL_ORDER,
  wholesale_api.cancel_order
);

const state = {
  orders: [],
};

const reducer = handleActions(
  {
    [CANCEL_ORDER]: (state) => state,
    [CANCEL_ORDER_OK]: (state, action) => ({
      orders: state.orders.map((order) => {
        if (action.payload.data === order.orderCode) {
          return {
            ...order,
            status: -3,
          };
        }
        return order;
      }),
    }),

    [ORDERS]: (state) => state,
    [ORDERS_OK]: (state, action) => ({
      orders: action.payload,
    }),
  },
  state
);

export { cancel_order, get_orders };

export default reducer;

import * as wholesale_api from 'api/wholesale';
import { handleActions } from 'redux-actions';

import createApiRequest from '../_support';

const ITEM = 'pirates-data.biz/ITEM';
const CART = 'pirates-data.biz/CART';
const ORDER = 'pirates-data.biz/ORDER';
const CART_INIT = 'pirates-data.biz/CART_INIT';
const CART_COUNT = 'pirates-data.biz/CART_COUNT';
const CART_REMOVE = 'pirates-data.biz/CART_REMOVE';

const [get_item, ITEM_OK, ITEM_FAIL] = createApiRequest(
  ITEM,
  wholesale_api.get_wholesale_item
);
const [get_cart_items, CART_OK, CART_FAIL] = createApiRequest(
  CART,
  wholesale_api.get_wholesale_cart_items
);
const [submit_order, ORDER_OK, ORDER_FAIL] = createApiRequest(
  ORDER,
  wholesale_api.submit_order
);
const cart_init = (cart_cookie) => (dispatch) =>
  dispatch({ payload: cart_cookie, type: CART_INIT });
const update_cart_count = (params) => (dispatch) =>
  dispatch({ payload: params, type: CART_COUNT });
const remove_cart_item = (id) => (dispatch) =>
  dispatch({ payload: id, type: CART_REMOVE });

const calculate_price = (item, count) => {
  return {
    delivery_price: item.delivery.charge.unit
      ? item.delivery.charge.price *
        Math.ceil(count / item.order.addQty / item.delivery.charge.unit)
      : item.delivery.charge.price,
    order_price: item.price * count * (item.unit.tax === 'VAT' ? 1.1 : 1),
  };
};

const initState = {
  articles: [],
  cart: [],
  delivery: {},
  info: {},
  isLoading: false,
  prices: [],
};

const reducer = handleActions(
  {
    [CART]: (state) => state,
    [CART_COUNT]: (state, action) => ({
      ...state,
      cart: state.cart.map((supplier) => {
        if (supplier.name === action.payload.supplier) {
          return {
            ...supplier,
            items: supplier.items.map((item) => {
              if (action.payload.id === item.id) {
                return {
                  ...item,
                  count: action.payload.count,
                  ...calculate_price(item, action.payload.count),
                };
              }
              return item;
            }),
          };
        }
        return supplier;
      }),
    }),
    [CART_INIT]: (state, action) => ({
      ...state,
      cart: state.cart.map((supplier) => ({
        ...supplier,
        items: supplier.items.map((item) => ({
          ...item,
          count: action.payload[item.id],
          ...calculate_price(item, action.payload[item.id]),
        })),
      })),
    }),
    [CART_OK]: (state, action) => ({
      ...state,
      cart: action.payload.map((supplier) => ({
        ...supplier,
        items: supplier.items.map((item) => ({
          ...item,
          count: 0,
          delivery_price: 0,
          order_price: 0,
        })),
      })),
    }),
    [CART_REMOVE]: (state, action) => ({
      ...state,
      cart: state.cart
        .map((supplier) => {
          const items = supplier.items.filter(
            (item) => item.id !== action.payload
          );
          if (items.length !== 0) {
            return {
              ...supplier,
              items,
            };
          }
          return null;
        })
        .filter((sup) => sup != null),
    }),
    [ITEM]: (state) => ({ ...state, isLoading: true }),
    [ITEM_FAIL]: (state) => ({ ...state, isLoading: false }),
    [ITEM_OK]: (state, action) => {
      const { articles, delivery, prices, ...info } = action.payload;
      return {
        ...state,
        articles,
        delivery,
        info,
        isLoading: false,
        prices,
      };
    },
    [ORDER]: (state) => state,
    [ORDER_OK]: (state, action) => {
      if (action.payload.code === 'failure') {
        return state;
      }
      return {
        ...state,
        cart: [],
      };
    },
  },
  initState
);

export {
  cart_init,
  get_cart_items,
  get_item,
  remove_cart_item,
  submit_order,
  update_cart_count,
};

export default reducer;

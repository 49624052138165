import './style.css';

import { Container } from 'components/common';
import { HeadNew } from 'components/common/header';
import { MyMenus, MyProfile } from 'components/mypage';
import { SESSION } from 'context';
import { check_session } from 'context/global/session';
import ModalProvider from 'context/modal';
import { get_my_profile, save_category } from 'context/mypage';
import { ToastContext } from 'context/toast';
import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const MyPage = ({ check_session, session }) => {
  const location = useLocation();
  const history = useHistory();
  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    load();
  }, [location]);

  const load = async () => {
    await check_session()
      .then((session) => {
        if (!session?.identity) {
          // document.cookie =
          //   'pirates-cookie/SESSION.TOKEN=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;' +
          //   `Domain=${conf.domain}`;
          showToast('로그인 후 시도해주세요.');
          history.replace('/');
        }
      })
      .catch(() => {
        showToast('정보를 불러오는데 실패하였습니다.');
        history.replace('/');
      });
  };

  return (
    <ModalProvider>
      {/* <LoginTop /> */}
      <HeadNew
        background="#2a4b8d"
        color="white"
        label="마이페이지"
        type="center"
      />
      <Container bg="#fff">
        <MyProfile session={session} />
        <MyMenus />
      </Container>
    </ModalProvider>
  );
};

export default connect(
  ({ [SESSION]: session }) => ({
    // category: mypage.category,
    session,
  }),
  {
    // remove_session,
    check_session,
    get_my_profile,
    save_category,
  }
)(MyPage);

import getAccountUrl from 'api/getAccountUrl';
import { Container } from 'components/common';
import Document from 'components/common/document';
import { HeadNew } from 'components/common/header';
import { Section } from 'components/section';
import { conf } from 'conf/settings';
import { SESSION } from 'context';
import { check_session } from 'context/global/session';
import ModalProvider from 'context/modal';
import {
  get_my_messages,
  get_my_settings,
  update_my_settings,
} from 'context/mypage';
import renderDate from 'functions/render_date';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import EmptyPage from './_empty_page';

// const _settings = {
//   couponNotification: '긴급처분 등 수산물 특가정보 알림 수신',
//   productNotification: '내 관심가게에 할인쿠폰 등록 시 알림 수신',
//   reviewNotification: '내 댓글의 답글 달림 알림 수신',
// };

// 현재 사용하지 않음
const MyMessage = memo(
  ({
    check_session,
    get_my_messages,
    get_my_settings,
    session,
    state,
    update_my_settings,
  }) => {
    const history = useHistory();
    const [alarmInfo, setAlarmInfo] = useState(false);

    useEffect(() => {
      // const checkSession = async () => {
      //   const { identity } = await check_session();
      //   if (!identity) {
      //     return window.location.replace('https://market.tpirates.com/mypage');
      //   }
      //   get_my_messages();
      //   get_my_settings();
      // };

      // if (!session || !session.identity) {
      //   checkSession();
      // } else {
      //   get_my_messages();
      //   get_my_settings();
      // }
      check_session()
        .then((r) => {
          if (!r?.identity) {
            window.location.href = getAccountUrl({
              redirectUrl: `${conf.FISH_MARKET_URL}`,
            });
            history.replace('/');
          }
        })
        .catch(() => {
          history.replace('/');
        });
      get_my_messages();
      get_my_settings();
    }, []);

    const onClickSetting = (setting) => {
      const params = {
        ...state.settings,
      };
      for (const set in state.settings) {
        params[set] = params[set] === 'on';
      }
      params[setting] = !params[setting];
      update_my_settings(params);
    };

    return (
      <>
        <Document.Head title="메시지관리 | 마이페이지" type="single" />
        <ModalProvider>
          <Container bg="#fff">
            <HeadNew label="메시지 관리" />
            <AlarmStyle className="main my-page" style={{ paddingTop: '56px' }}>
              <button
                className={alarmInfo ? 'EXIST' : ''}
                onClick={() => {
                  history.push('/마이페이지/알림설정');
                  // setAlarmInfo((p) => !p);
                }}
              >
                앱 푸쉬 알림 설정
              </button>

              <Section title="" type="board">
                <div className="section card-list8">
                  {state.messages.length !== 0 ? (
                    <ul>
                      {state.messages.map((noti, idx) => {
                        return (
                          <li key={idx}>
                            <div className="date">
                              {renderDate(noti.regDate)}
                              {/* <i className="fas fa-times"></i> */}
                              {/* <img src="/img/bg_texture.svg" alt="" /> */}
                            </div>
                            <a className="subject" href={noti.url}>
                              {noti.message}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <EmptyPage />
                  )}
                </div>
              </Section>
            </AlarmStyle>
          </Container>
        </ModalProvider>
      </>
    );
  }
);

const AlarmStyle = styled.div`
  padding: 0 16px;

  & > button {
    margin-top: 12px;
    width: 100%;
    height: 44px;
    border: 1px solid #c9cdd2;

    font-size: 16px;
    color: #26282b;
    background: #f7f8f9;
    border-radius: 5px;

    cursor: pointer;

    &.EXIST {
      opacity: 0.6;
    }
  }
  & .section.card-list9,
  & .section.card-list8 {
    margin-top: 24px;
    & > ul {
      & > li {
        border: 1px solid #c9cdd2;
        border-radius: 5px;
        padding-top: 12px;
        padding-bottom: 12px;
        & + li {
          margin-top: 12px;
        }
        & > .date {
          overflow: hidden;
          font-size: 12px;
          & > img {
            float: right;
            width: 13px;
            height: 13px;

            /* filter: invert(49%) sepia(7%) saturate(408%) hue-rotate(172deg)
              brightness(93%) contrast(86%); */

            cursor: pointer;
          }
          & > i {
            float: right;
            cursor: pointer;
            font-size: 17px;
            color: #1d79bc;
          }
        }
        & > a {
          &.subject {
            display: block;
            margin-top: 4px;
            color: #26282b;
            /* font-weight: 500; */
          }
        }
      }
    }
  }
`;

export default connect(
  ({ mypage, [SESSION]: session }) => ({ session, state: mypage }),
  {
    check_session,
    get_my_messages,
    get_my_settings,
    update_my_settings,
  }
)(MyMessage);

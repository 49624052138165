import React from 'react';
import styled from 'styled-components';

/**
 * @param {object} props
 * @param {React.CSSProperties['height']} props.height
 * @param {React.InputHTMLAttributes['id']} props.id
 * @param {React.InputHTMLAttributes['name']} props.name
 * @param {React.RefObject} props.ref
 * @param {React.InputHTMLAttributes['value']} props.value
 * @param {React.CSSProperties['width']} props.width
 * @param {React.FocusEventHandler<HTMLTextAreaElement>} props.onBlur
 * @param {React.ChangeEventHandler<HTMLTextAreaElement>} props.onChange
 */
const Textarea = (
  { height, id, name, onBlur, onChange, value = '', width, ...props },
  ref
) => {
  return (
    <Container
      ref={ref}
      $height={height}
      $width={width}
      id={id}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      {...props}
    />
  );
};

const Container = styled.textarea`
  background-color: ${({ theme }) => theme.gray10};
  border: 1px solid transparent;
  border-radius: 5px;
  height: ${({ $height }) => $height};
  padding: 10px 12px;
  resize: none;
  width: ${({ $width }) => $width};

  &::placeholder {
    color: ${({ theme }) => theme.gray300};
  }
`;

export default React.forwardRef(Textarea);

import { useQuery } from '@tanstack/react-query';
import { getLatestQuoteByStoreAndItem } from 'api/Prices';
import { convertMinToMS } from 'utils/convertTimeToMS';

import priceKeys from './keys';

export default function useGetLatestQuoteByStoreAndItem({ itemId, storeId }) {
  return useQuery(
    priceKeys.getLatestQuoteByStoreAndItem({ itemId, storeId }),
    () => getLatestQuoteByStoreAndItem({ itemId, storeId }),
    {
      select: (data) => {
        return data.content;
      },
      staleTime: convertMinToMS(5),
    }
  );
}

import * as coupon_api from 'api/coupon';
import * as filter_api from 'api/search-filters';
import * as store_api from 'api/store';
import { handleActions } from 'redux-actions';

import createApiRequest from '../_support';

const FILTER = 'pirates-data.stores/FILTER';
const COUPONS = 'pirates-data.stores/COUPONS';
const RECOMMENDS = 'pirates-data.stores/RECOMMENDS';
const STORES = 'pirates-data.stores/STORES';
const TOGGLE_FAVORITE = 'pirates-data.stores/LIKE';
const SET_CURRENT_PAGE = 'pirates-data.stores/SET_PAGE';
const SAVE_QUERY = 'pirates-data.stores/QUERY_SAVE';
const SAVE_SORT = 'pirates-data.stores/SORT_SAVE';
const INIT_QUERY = 'pirates-data.stores/QUERY_INIT';
const LOAD_DATA = 'pirates-data.stores/LOAD_DATA';

const STORES_GROUP = `${STORES}.GROUP`;
const STORES_ORDER = `${STORES}.ORDER`;
const STORES_RECENT = `${STORES}.RECENT`;
const STORES_GROUP_MORE = `${STORES_GROUP}.MORE`;
const STORES_ORDER_MORE = `${STORES_ORDER}.MORE`;

const load_filters = async (target) => {
  const regions = await (target === 'market'
    ? filter_api.load_store_regions()
    : filter_api.load_restaurant_regions());
  const items = await (target === 'market'
    ? filter_api.load_item_codes()
    : filter_api.load_restaurant_features());

  return {
    data: {
      items: items.data,
      orders: [
        { code: 'default', label: '기본 순' },
        { code: 'popularity', label: '인기 순' },
        { code: 'recent', label: '최근 본 가게' },
      ],
      regions: regions.data,
    },
  };
};

const [get_filters, FILTER_OK, FILTER_FAIL] = createApiRequest(
  FILTER,
  load_filters
);
const [get_store_recommends, RECOMMENDS_OK, RECOMMENDS_FAIL] = createApiRequest(
  RECOMMENDS,
  store_api.get_store_recommends
);
const [get_store_coupons, COUPONS_OK, COUPONS_FAIL] = createApiRequest(
  COUPONS,
  coupon_api.get_coupons
);
const [toggle_favorite, TOGGLE_FAVORITE_OK, TOGGLE_FAVORITE_FAIL] =
  createApiRequest(TOGGLE_FAVORITE, store_api.toggle_store_like);

const [get_stores_group, STORES_GROUP_OK, STORES_GROUP_FAIL] = createApiRequest(
  STORES_GROUP,
  store_api.get_stores
);
const [get_stores_group_more, STORES_GROUP_MORE_OK, STORES_GROUP_MORE_FAIL] =
  createApiRequest(STORES_GROUP_MORE, store_api.get_stores);
const [get_stores_order, STORES_ORDER_OK, STORES_ORDER_FAIL] = createApiRequest(
  STORES_ORDER,
  store_api.get_stores
);
const [get_stores_order_more, STORES_ORDER_MORE_OK, STORES_ORDER_MORE_FAIL] =
  createApiRequest(STORES_ORDER_MORE, store_api.get_stores);
const [set_current_page, SET_CURRENT_PAGE_OK, SET_CURRENT_PAGE_FAIL] =
  createApiRequest(SET_CURRENT_PAGE, (type) => ({ data: type }));
const [save_query, SAVE_QUERY_OK, SAVE_QUERY_FAIL] = createApiRequest(
  SAVE_QUERY,
  (query) => ({ data: query })
);
const [save_sort, SAVE_SORT_OK, SAVE_SORT_FAIL] = createApiRequest(
  SAVE_SORT,
  (query) => ({ data: query })
);
const [init_saved_query, INIT_QUERY_OK, INIT_QUERY_FAIL] = createApiRequest(
  INIT_QUERY,
  () => ({ data: null })
);
const [load_data, LOAD_DATA_OK, LOAD_DATA_FAIL] = createApiRequest(
  LOAD_DATA,
  (data) => ({ data })
);
const [get_stores_recent, STORES_RECENT_OK, STORES_RECENT_FAIL] =
  createApiRequest(STORES_RECENT, store_api.get_stores);

const state = {
  coupons: [],
  cur_page: '',
  filter: {
    orders: [
      { code: 'default', label: '기본 순' },
      { code: 'popularity', label: '인기 순' },
      { code: 'recent', label: '최근 본 가게' },
    ],
  },
  query: {
    'keyword': '',
    'market.deliveryCode': ['전체가게'],
    'market.items': [{ code: '0000000000', label: '모든 품목' }],
    'market.order': [{ code: 'default', label: '기본 순' }],
    'market.region': [
      { code: { place: 'all', zone: 'all' }, label: '모든 지역' },
    ],
  },
  recommends: [],
  sortBy: 'default',
  stores: {
    excellent: [],
    list: [],
    next: {},
    premium: [],
    recent: [],
    regular: [],
  },
};

const next_token = (list) => (([last]) => (last || {}).sort)(list.slice(-1));

const compose = (stores) => {
  const group = stores.reduce(
    (acc, s) => {
      const level = s.sort['level.label']?.toLowerCase();
      const prev = acc[level] || [];
      return { ...acc, ...{ [level]: [...prev, s] } };
    },
    { excellent: [], premium: [], regular: [] }
  );

  return {
    ...group,
    next: next_token(stores),
  };
};

const list = handleActions(
  {
    [COUPONS]: (state) => ({ ...state }),
    [COUPONS_OK]: (state, action) => ({
      ...state,
      coupons: action.payload.list,
    }),
    [FILTER]: (state) => ({ ...state }),
    [FILTER_OK]: (state, action) => ({
      ...state,
      filter: {
        ...action.payload,
        items: action.payload.items,
      },
    }),

    [INIT_QUERY]: (state) => ({ ...state }),
    [INIT_QUERY_OK]: (_state, action) => ({
      ..._state,
      query: state.query,
    }),

    [LOAD_DATA]: (state) => ({ ...state }),
    [RECOMMENDS]: (state) => ({ ...state }),

    [LOAD_DATA_OK]: (state, action) => ({
      ...action.payload,
    }),
    [RECOMMENDS_OK]: (state, action) => ({
      ...state,
      recommends: action.payload,
    }),
    [SAVE_QUERY]: (state) => ({ ...state }),
    [SET_CURRENT_PAGE]: (state) => ({ ...state }),
    [SAVE_QUERY_OK]: (state, action) => ({
      ...state,
      query: action.payload,
    }),
    [STORES_GROUP]: (state) => ({ ...state }),

    [SAVE_SORT]: (state) => ({ ...state }),
    [STORES_GROUP_MORE]: (state) => {
      return { ...state };
    },

    [SAVE_SORT_OK]: (state, action) => ({
      ...state,
      sortBy: action.payload,
    }),
    [STORES_GROUP_MORE_OK]: (state, action) => {
      return {
        ...state,
        stores: (({ excellent, next, premium, regular }) => {
          return {
            ...state.stores,
            excellent: [...state.stores.excellent, ...excellent],
            next,
            premium: [...state.stores.premium, ...premium],
            regular: [...state.stores.regular, ...regular],
          };
        })(compose(action.payload)),
      };
    },

    [SET_CURRENT_PAGE_OK]: (state, action) => ({
      ...state,
      cur_page: action.payload,
    }),
    [STORES_GROUP_OK]: (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          ...compose(action.payload),
          totalElements: action.totalElements,
        },
      };
    },

    [STORES_ORDER]: (state) => {
      return { ...state };
    },
    [STORES_RECENT]: (state) => ({ ...state }),

    [STORES_ORDER_MORE]: (state) => {
      return { ...state };
    },
    [STORES_RECENT_OK]: (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          recent: action.payload.reverse(),
        },
      };
    },
    [STORES_ORDER_MORE_OK]: (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          list: [...state.stores.list, ...action.payload],
          next: next_token(action.payload),
        },
      };
    },
    [STORES_ORDER_OK]: (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          list: action.payload,
          next: next_token(action.payload),
        },
      };
    },
    [TOGGLE_FAVORITE]: (state) => ({ ...state }),
    [TOGGLE_FAVORITE_OK]: (state, action) => ({
      ...state,
      recommends: state.recommends.map((recommend) => {
        if (recommend.id === action.payload.targetCode) {
          return {
            ...recommend,
            favorite: action.payload.doLike ? 'on' : 'off',
          };
        }
        return recommend;
      }),
    }),
  },
  state
);

const Types = {
  COUPONS,
  RECOMMENDS,
  STORES_GROUP,
  STORES_GROUP_MORE,
  STORES_ORDER,
  STORES_ORDER_MORE,
};

export {
  get_filters,
  get_store_coupons,
  get_store_recommends,
  get_stores_group,
  get_stores_group_more,
  get_stores_order,
  get_stores_order_more,
  get_stores_recent,
  init_saved_query,
  load_data,
  save_query,
  save_sort,
  set_current_page,
  toggle_favorite,
  Types,
};

export default list;

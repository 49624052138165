/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import client from 'api/client';
import OrderPanel, {
  formatDate,
  ORDERTYPE,
  STATUS,
} from 'components/order/_order';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { setOrderStore } from 'features/orderStoreSlice';
import { useToast } from 'hooks';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { moneyFormatter } from 'utils/Formatter';

const getQty = (details) => {
  if (details.length === 0) {
    return;
  }
  return details.reduce((acc, tmp) => {
    return {
      ...acc,
      qty: acc.qty + tmp.qty,
    };
  }).qty;
};

const minusOneHour = (time) => {
  const parsed = parseInt(time?.substring(0, 2));
  const minute = time?.substring(3, 5);
  if (parsed > 10) {
    return `${parsed - 1}:${minute}`;
  }
  return `0${parsed - 1}:${minute}`;
};

const disableSet = new Set(['REQ', 'APPROVAL_PAYMENT', 'REQUEST']);
const OrderHistoryItem = memo(
  ({
    cancelOrder,
    children,
    isDetail,
    onClickDetail,
    order,
    setInquirySlideOpen,
  }) => {
    const {
      code,
      delivery2,
      details,
      orderDate,
      orderType,
      reason,
      reservationDateTime,
      reservationTime,
      status,
      totalPrice,
    } = order;

    const length = getQty(details);
    const showToast = useToast();
    const dispatch = useDispatch();

    const disabled =
      orderType === 3 ? status !== 'APPROVAL_PAYMENT' : !disableSet.has(status);
    const isCXL =
      status &&
      (status.indexOf('CXL') !== -1 || status.indexOf('CANCEL') !== -1);

    const statusString = () => {
      let result = '';
      if (status && orderType) {
        result = `${
          // reservationDateTime ? (orderType !== 4 ? '[예약] ' : '') : ''
          orderType === 1 && delivery2?.deliveryTime === '-1' ? '[예약] ' : ''
        }${ORDERTYPE[orderType]} -  ${
          STATUS[orderType]?.[
            orderType === 3 ? status : status.replace(/_\w+/, '')
          ] || ''
        }`;
      }
      return result;
    };

    const orderTimeString = () => {
      if (isCXL) return `취소사유 : ${reason || '구매자 취소'}`;

      switch (orderType) {
        case 1:
          return delivery2
            ? delivery2?.deliveryTime === '-1'
              ? `배달 예약일<br/>${dayjs(
                  reservationDateTime || orderDate
                ).format('MM/DD(dd)')} ${minusOneHour(
                  reservationTime
                )} ~ ${reservationTime}`
              : `배달소요시간 : ${delivery2?.deliveryTime}분 내외`
            : '';
        case 2:
          return reservationDateTime
            ? `픽업 예약일 ${dayjs(reservationDateTime).format(
                'MM/DD(dd)'
              )} ${reservationTime}`
            : `픽업예정시간 ${reservationTime}`;
        case 3:
          return `배송 예정일 ${dayjs(reservationTime).format(
            'MM/DD(ddd) 저녁 8시 전'
          )}`;
        case 4:
          return `배송 예정일 ${dayjs(reservationDateTime).format(
            'MM/DD (ddd) HH:mm 부터 픽업 가능'
          )}`;
        default:
          return '';
      }
    };
    const head = (
      <>
        <div className="order-date">{formatDate(orderDate)}</div>
        <div className="order-price blue" onClick={() => onClickDetail(order)}>
          주문 상세
          <i className="fas fa-chevron-right" style={{ marginLeft: '6px' }} />
        </div>
      </>
    );

    const body = (
      <OrderPanelBodyStyle className={`${isDetail ? 'ORDER-DETAIL' : ''}`}>
        <div className="order-status blue">{statusString()}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(orderTimeString()),
          }}
          className="reservation-info"
          style={{
            color: '#464C53',
            fontSize: '16px',
            letterSpacing: '-0.3px',
            lineHeight: '24px',
            margin: '8px 0 16px',
          }}
        />
        <div className="store-name">
          {details.length === 0
            ? ''
            : `${details[0].store} ${details[0].market}`}
        </div>
        {!isDetail && (
          <>
            <div className="order-name-price">
              <div className="order-name-section">
                <div className="order-name">
                  {details.length === 0
                    ? ''
                    : length === 1
                      ? details[0]?.itemLabel?.replace('[★]', '')
                      : `${`${details[0]?.itemLabel?.replace('[★]', '')}${
                          details[0].itemOption
                            ? ` | ${details[0].itemOption}`
                            : ''
                        }`} 외 ${length - 1}`}
                </div>
              </div>
            </div>
            <div className="store-order-price">
              {`${moneyFormatter(totalPrice)}원`}
            </div>
          </>
        )}
        {children}
        <div className="partition" />
        <div className="order-button-area">
          {!isDetail && (
            <Link
              to={`/${order.details[0]?.permalink}/리뷰`}
              onClick={(e) => {
                if (isCXL || !order.isActivatedStore) e.preventDefault();
              }}
            >
              <button
                className={isDetail ? 'order-button-blue' : 'order-button'}
                disabled={!order.isActivatedStore}
              >
                리뷰
              </button>
            </Link>
          )}
        </div>
        {isDetail && (
          <ButtonWrap>
            <div className="order-button-area">
              <button
                className="order-button"
                disabled={disabled}
                onClick={() => cancelOrder({ code, orderType })}
              >
                주문취소
              </button>
              <Link
                to={`/${order.details[0]?.permalink}/리뷰`}
                onClick={(e) => {
                  if (isCXL || !order.isActivatedStore) e.preventDefault();
                }}
              >
                <button
                  className={isDetail ? 'order-button-blue' : 'order-button'}
                  disabled={!order.isActivatedStore}
                >
                  리뷰
                </button>
              </Link>
              <button
                className="order-button-inquiry"
                onClick={() => {
                  setInquirySlideOpen(true);

                  client
                    .get(`/www/quick/my/${code}`)
                    .then((res) => {
                      dispatch(
                        setOrderStore({
                          customerService: res.data.tpirates,
                          store: res.data.store,
                        })
                      );
                    })
                    .catch((error) => {
                      showToast(error.response?.data?.message || error.message);
                    });
                }}
              >
                문의
              </button>
            </div>
          </ButtonWrap>
        )}
      </OrderPanelBodyStyle>
    );

    return (
      <OrderPanel
        cancel={isCXL}
        head={head}
        // type={true}
      >
        {body}
      </OrderPanel>
    );
  },
  (prev, next) =>
    prev.order.status === next.order.status &&
    prev.order.code === next.order.code
);

const ButtonWrap = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const OrderPanelBodyStyle = styled.div`
  & > .order-status {
    & > .reservation-info {
      text-align: right !important;
      white-space: pre-wrap !important;
      text-overflow: unset !important;
      overflow: unset !important;
    }
  }
  &.ORDER-DETAIL {
    width: 100%;
    & > .order-status {
    }
    & > .store-name {
    }
    & > .order-titles {
    }
  }
`;

export default OrderHistoryItem;

import axios from 'axios';
import { conf } from 'conf/settings';

import getAccountUrl from './getAccountUrl';
const clientV3 = axios.create();

clientV3.defaults.baseURL = `${conf.FISH_MARKET_API_URL}/v3`;
clientV3.defaults.withCredentials = true;

clientV3.defaults.headers.put.Pragma = 'no-cache';
clientV3.defaults.headers.put['Cache-Control'] = 'no-cache';
clientV3.defaults.headers.delete.Pragma = 'no-cache';
clientV3.defaults.headers.delete['Cache-Control'] = 'no-cache';

clientV3.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const prevPath = sessionStorage.getItem('prevPath') || '';
    const redirectUrl = `${conf.FISH_MARKET_URL}${
      prevPath === 'null' ? '' : prevPath
    }`;
    switch (error.response?.status) {
      case 401: {
        const data = await fetch(`${conf.ACCOUNT_API_URL}/v3/auth/token`, {
          credentials: 'include',
          method: 'post',
        });
        if (data.ok) {
          window.location.reload();
        }
        if (data.status === 401) {
          window.location.href = `${getAccountUrl({ redirectUrl })}`;
        }
        return Promise.reject(error);
      }
      default:
        return Promise.reject(error);
    }
  }
);
export default clientV3;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerService: '',
  store: [],
};

const orderStoreSlice = createSlice({
  initialState,
  name: 'orderStore',
  reducers: {
    setOrderStore: (state, { payload }) => {
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

export const { setOrderStore } = orderStoreSlice.actions;
export default orderStoreSlice.reducer;

import styled from 'styled-components';

/**
 * @param {object} props
 * @param {React.CSSProperties['border']} props.border
 * @param {React.CSSProperties['borderRadius']} props.borderRadius
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {React.RefObject} props.forwardRef
 * @param {React.CSSProperties['fontWeight']} props.fontWeight
 * @param {React.CSSProperties['height']} props.height
 * @param {React.CSSProperties['padding']} props.padding
 * @param {React.CSSProperties} props.style
 * @param {React.ButtonHTMLAttributes['type']} props.type
 * @param {React.CSSProperties['width']} props.width
 * @param {React.FocusEventHandler<HTMLButtonElement>} props.onBlur
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.onClick
 */
const ButtonBase = ({
  border = 'none',
  borderRadius = '5px',
  children,
  className,
  fontWeight,
  forwardRef,
  height,
  onBlur,
  onClick,
  padding,
  style,
  type = 'button',
  width = '100%',
  ...rest
}) => {
  return (
    <Button
      ref={forwardRef}
      $border={border}
      $borderRadius={borderRadius}
      $fontWeight={fontWeight}
      $height={height}
      $padding={padding}
      $width={width}
      className={className}
      style={style}
      type={type}
      onBlur={onBlur}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

const Button = styled.button`
  background-color: transparent;
  border: ${({ $border }) => $border};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  cursor: pointer;
  font: inherit;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
  transition: filter ease 100ms;
  width: ${({ $width }) => $width};

  &:hover {
    filter: brightness(96%);
  }
`;

export default ButtonBase;

import IcoMoon from 'react-icomoon';

import iconSet from './selection.json';

const Icon = ({
  color = '#8A8D90',
  disableFill = true,
  name,
  size = 24,
  style,
  ...props
}) => (
  <IcoMoon
    disableFill={disableFill}
    icon={name}
    iconSet={iconSet}
    size={size}
    style={{
      fill: color,
      minHeight: `${size}px`,
      minWidth: `${size}px`,
      stroke: color,
      ...style,
    }}
    {...props}
  />
);

export default Icon;

import TagManager from '@sooro-io/react-gtm-module';
import { conf } from 'conf/settings';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

const init = () => {
  // ReactGA.initialize(conf.tools.ga);
  TagManager.initialize({
    gtmId: conf.tools.gtm,
  });
};

const GoogleAnalyticsComponent = ({ location }) => {
  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(`${location.pathname}${location.search}`);
  }, [location]);
  return null;
};

const GATracker = () => <Route component={GoogleAnalyticsComponent} />;

export default {
  Tracker: GATracker,
  init,
};

import * as content_api from 'api/content';
import * as mypage_api from 'api/mypage';
import * as order_api from 'api/order';
import * as retail_api from 'api/retail';
import * as r_api from 'api/review';
import * as s_api from 'api/store';
import dayjs from 'dayjs';
import { handleActions } from 'redux-actions';

import createApiRequest from './_support';

const GET_MY_MESSAGES = 'pirates-data.my.messages/GET';
const GET_MY_SETTINGS = 'pirates-data.my.settings/GET';
const UPDATE_MY_SETTINGS = 'pirates-data.my.settings/UPDATE';
const GET_MY_COUPONS = 'pirates-data.my.coupons/GET';
const GET_MY_LIKES = 'pirates-data.my.likes/GET';
const GET_MY_REVIEWS = 'pirates-data.my.reviews/GET';
const GET_MY_REVIEWS_MORE = 'pirates-data.my.reviews/GET_MORE';
const GET_MY_CONTENT_REVIEWS = 'pirates-data.my.reviews/GET_CONTENT';
const GET_MY_CONTENT_REVIEWS_MORE = 'pirates-data.my.reviews/GET_CONTENT_MORE';
const GET_MY_PROFILE = 'pirates-data.my.profile/GET';
const GET_MY_ORDERS = 'pirates-data.my.orders/GET';
const GET_MY_ORDERS_MORE = 'pirates-data.my.orders/GET_MORE';
const GET_MANAGER_PHONE = 'pirates-data.my.orders/GET_MANAGER_PHONE';
const GET_MY_PHONE_AUTH = 'pirates-data.my.orders/GET_MY_PHONE_AUTH';
const GET_PHONE_AUTH_SMS = 'pirates-data.my.orders/GET_PHONE_AUTH_SMS';
const CERTIFY_PHONE_AUTH = 'pirates-data.my.orders/CERTIFY_PHONE_AUTH';
const CANCEL_ORDER = 'pirates-data.my.orders/CANCEL';
const CANCEL_ORDER_TODAY = 'pirates-data.my.orders/CANCEL_TODAY';
const UPDATE_MY_PROFILE = 'pirates-data.my.profile/UPDATE';
const SAVE_CATEGORY = 'pirates-data.my.profile/CATEGORY';
const GET_MY_COUNTS = 'pirates-data.my.profile/PROFILECOUNT';
const DELETE_STORE_LIKE = 'pirates-data.my.profile/DELETE_STORE_LIKE';
const DELETE_CONTENT_LIKE = 'pirates-data.my.profile/DELETE_CONTENT_LIKE';
const DELETE_RETAIL_LIKE = 'pirates-data.my.profile/DELETE_RETAIL_LIKE';
const DELETE_MY_REVIEW = 'pirates-data.my.profile/DELETE_MY_REVIEW';

const [get_my_messages, GET_MY_MESSAGES_OK, GET_MY_MESSAGES_FAIL] =
  createApiRequest(GET_MY_MESSAGES, mypage_api.get_my_messages);
const [get_my_settings, GET_MY_SETTINGS_OK, GET_MY_SETTINGS_FAIL] =
  createApiRequest(GET_MY_SETTINGS, mypage_api.get_my_settings);
const [update_my_settings, UPDATE_MY_SETTINGS_OK, UPDATE_MY_SETTINGS_FAIL] =
  createApiRequest(UPDATE_MY_SETTINGS, mypage_api.update_my_settings);
const [get_my_coupons, GET_MY_COUPONS_OK, GET_MY_COUPONS_FAIL] =
  createApiRequest(GET_MY_COUPONS, mypage_api.get_my_coupons);
const [get_my_likes, GET_MY_LIKES_OK, GET_MY_LIKES_FAIL] = createApiRequest(
  GET_MY_LIKES,
  mypage_api.get_my_likes
);
const [get_my_reviews, GET_MY_REVIEWS_OK, GET_MY_REVIEWS_FAIL] =
  createApiRequest(GET_MY_REVIEWS, mypage_api.get_my_reviews);
const [get_my_reviews_more, GET_MY_REVIEWS_MORE_OK, GET_MY_REVIEWS_MORE_FAIL] =
  createApiRequest(GET_MY_REVIEWS_MORE, mypage_api.get_my_reviews_more);
const [
  get_my_content_reviews,
  GET_MY_CONTENT_REVIEWS_OK,
  GET_MY_CONTENT_REVIEWS_FAIL,
] = createApiRequest(GET_MY_CONTENT_REVIEWS, mypage_api.get_my_reviews);
const [
  get_my_content_reviews_more,
  GET_MY_CONTENT_REVIEWS_MORE_OK,
  GET_MY_CONTENT_REVIEWS_MORE_FAIL,
] = createApiRequest(
  GET_MY_CONTENT_REVIEWS_MORE,
  mypage_api.get_my_reviews_more
);
const [get_my_profile, GET_MY_PROFILE_OK, GET_MY_PROFILE_FAIL] =
  createApiRequest(GET_MY_PROFILE, mypage_api.get_my_profile);
const [get_my_orders, GET_MY_ORDERS_OK, GET_MY_ORDERS_FAIL] = createApiRequest(
  GET_MY_ORDERS,
  mypage_api.get_my_orders
);
const [get_my_orders_more, GET_MY_ORDERS_MORE_OK, GET_MY_ORDERS_MORE_FAIL] =
  createApiRequest(GET_MY_ORDERS_MORE, mypage_api.get_my_orders);
const [get_manager_phone, GET_MANAGER_PHONE_OK, GET_MANAGER_PHONE_FAIL] =
  createApiRequest(GET_MANAGER_PHONE, mypage_api.get_manager_phone);
const [get_my_phone_auth, GET_MY_PHONE_AUTH_OK, GET_MY_PHONE_AUTH_FAIL] =
  createApiRequest(GET_MY_PHONE_AUTH, mypage_api.get_my_phone_auth);
const [get_phone_auth_sms, GET_PHONE_AUTH_SMS_OK, GET_PHONE_AUTH_SMS_FAIL] =
  createApiRequest(GET_PHONE_AUTH_SMS, mypage_api.get_phone_auth_sms);
const [certify_phone_auth, CERTIFY_PHONE_AUTH_OK, CERTIFY_PHONE_AUTH_FAIL] =
  createApiRequest(CERTIFY_PHONE_AUTH, mypage_api.certify_phone_auth);
const [cancel_order, CANCEL_ORDER_OK, CANCEL_ORDER_FAIL] = createApiRequest(
  CANCEL_ORDER,
  order_api.cancel_order
);
const [cancel_order_today, CANCEL_ORDER_TODAY_OK, CANCEL_ORDER_TODAY_FAIL] =
  createApiRequest(CANCEL_ORDER_TODAY, order_api.cancel_order);
const [update_my_profile, UPDATE_MY_PROFILE_OK, UPDATE_MY_PROFILE_FAIL] =
  createApiRequest(UPDATE_MY_PROFILE, mypage_api.update_my_profile);
const [save_category, SAVE_CATEGORY_OK, SAVE_CATEGORY_FAIL] = createApiRequest(
  SAVE_CATEGORY,
  (category) => ({ data: category })
);

const [get_my_counts, GET_MY_COUNTS_OK, GET_MY_COUNTS_FAIL] = createApiRequest(
  GET_MY_COUNTS,
  mypage_api.get_my_counts
);
const [delete_store_like, DELETE_STORE_LIKE_OK] = createApiRequest(
  DELETE_STORE_LIKE,
  s_api.toggle_store_like
);
const [delete_content_like, DELETE_CONTENT_LIKE_OK] = createApiRequest(
  DELETE_CONTENT_LIKE,
  content_api.toggle_content_like
);
const [delete_retail_like, DELETE_RETAIL_LIKE_OK] = createApiRequest(
  DELETE_RETAIL_LIKE,
  retail_api.toggle_retail_like
);
const [delete_my_review, DELETE_MY_REVIEW_OK, DELETE_MY_REVIEW_FAIL] =
  createApiRequest(DELETE_MY_REVIEW, r_api.delete_review);

const initialState = {
  category: '내 메시지',
  contentReviewNext: false,
  contentReviews: [],
  coupons: [],
  couponsRequest: false,
  done: [],
  likes: {
    contents: [],
    delicious: [],
    market: [],
    shopping: [],
  },
  managerPhone: false,
  messages: [],
  next: null,
  orders: [],
  profile: {},
  reviewNext: false,
  reviews: [],
  settings: [],
};

const classifyOrder = (orders) => {
  orders.sort((a, b) => {
    return dayjs(b.orderDate).valueOf() - dayjs(a.orderDate).valueOf();
  });
  const processing = [];
  const done = [];

  const doneSet = new Set([
    'CXL',
    'SNT',
    'CXL_ADMIN',
    'CXL_AUTO',
    'CANCEL_USER',
    'CANCEL_ADMIN',
    'COMPLETE',
    'PCF',
  ]);

  orders.forEach((order) => {
    if (doneSet.has(order.status)) {
      done.push(order);
    } else {
      processing.push(order);
    }
  });
  return [processing, done];
};

const reducer = handleActions(
  {
    [CANCEL_ORDER]: (state, action) => ({ ...state }),
    [CANCEL_ORDER_FAIL]: (state, action) => {
      const code = action.payload?.response?.data?.publicOrderCode;
      const status = action.payload?.response?.data?.status;

      if (!code || !status) {
        return state;
      }

      const { orders = [] } = state;

      const newOrders = orders.map((order) => {
        if (order.code === code) {
          return { ...order, status };
        }

        return order;
      });

      return { ...state, orders: newOrders };
    },
    [CANCEL_ORDER_OK]: (state, action) => {
      const [orders, done] = classifyOrder([...state.done, ...state.orders]);
      orders.forEach((order, idx) => {
        if (
          order.code === action.payload.data &&
          action.payload.code === 'success'
        ) {
          return (orders[idx] = { ...order, status: 'CXL' });
        }
      });
      return {
        ...state,
        done,
        orders,
      };
    },
    [CANCEL_ORDER_TODAY]: (state, action) => ({ ...state }),
    [CANCEL_ORDER_TODAY_FAIL]: (state, action) => {
      // const code = action.payload?.response?.data?.publicOrderCode;
      // const status = action.payload?.response?.data?.status;

      // if (!code || !status) {
      //   return state;
      // }

      // const { orders = [] } = state;
      // const newOrders = orders.map((order) => {
      //   if (order.code === code) {
      //     return { ...order, status };
      //   }
      //   return order;
      // });

      // return { ...state, orders: newOrders };
      return state;
    },
    [CANCEL_ORDER_TODAY_OK]: (state, action) => {
      const [orders, done] = classifyOrder([...state.done, ...state.orders]);
      orders.forEach((order, idx) => {
        if (
          order.code === action.payload.data &&
          action.payload.code === 'success'
        ) {
          return (orders[idx] = { ...order, status: 'CANCEL_USER' });
        }
      });
      return {
        ...state,
        done,
        orders,
      };
    },
    [DELETE_CONTENT_LIKE]: (state, action) => ({ ...state }),
    [DELETE_CONTENT_LIKE_OK]: (state, action) => ({
      ...state,
      likes: {
        ...state.likes,
        contents: state.likes.contents.filter(
          (e) => e.id !== action.payload.targetCode
        ),
      },
    }),
    [DELETE_MY_REVIEW]: (state, action) => state,
    [DELETE_MY_REVIEW_OK]: (state, action) => {
      if (action.payload.code === 'success') {
        state.reviews = state.reviews.filter(
          (v) => v.id !== action.payload.data.id
        );
        state.contentReviews = state.contentReviews.filter(
          (v) => v.id !== action.payload.data.id
        );
      }
      return state;
    },
    [DELETE_RETAIL_LIKE]: (state, action) => ({ ...state }),
    [DELETE_RETAIL_LIKE_OK]: (state, action) => ({
      ...state,
      likes: {
        ...state.likes,
        shopping: state.likes.shopping.filter(
          (e) => e.id !== action.payload.targetCode
        ),
      },
    }),
    [DELETE_STORE_LIKE]: (state, action) => ({ ...state }),
    [DELETE_STORE_LIKE_OK]: (state, action) => ({
      ...state,
      likes: {
        ...state.likes,
        market: state.likes.market.filter(
          (e) => e.id !== action.payload.targetCode
        ),
      },
    }),
    [GET_MANAGER_PHONE]: (state, action) => ({ ...state }),
    [GET_MANAGER_PHONE_FAIL]: (state, action) => ({
      ...state,
      managerPhone: false,
    }),
    [GET_MANAGER_PHONE_OK]: (state, action) => {
      return {
        ...state,
        managerPhone: action.payload.telephoneInquiry,
      };
    },
    [GET_MY_CONTENT_REVIEWS]: (state, action) => ({ ...state }),
    [GET_MY_CONTENT_REVIEWS_MORE]: (state, action) => ({ ...state }),
    [GET_MY_CONTENT_REVIEWS_MORE_OK]: (state, action) => {
      const temp = state.contentReviews.concat();

      for (const i in action.payload.list) {
        let bool = false;
        for (const j in temp) {
          if (action.payload.list[i].next === temp[j].next) {
            bool = true;
            break;
          }
        }
        if (!bool) {
          temp.push(action.payload.list[i]);
        }
      }

      return {
        ...state,
        contentReviewNext: action.payload.next,
        contentReviews: temp,
      };
    },
    [GET_MY_CONTENT_REVIEWS_OK]: (state, action) => ({
      ...state,
      contentReviewNext: action.payload.next,
      contentReviews: action.payload.list,
    }),
    [GET_MY_COUNTS]: (state, action) => ({ ...state }),
    [GET_MY_COUNTS_OK]: (state, action) => ({
      ...state,
    }),
    [GET_MY_COUPONS]: (state, action) => ({ ...state }),
    [GET_MY_COUPONS_OK]: (state, action) => {
      return {
        ...state,
        coupons:
          action.payload.request.page == 1
            ? action.payload.list
            : [...state.coupons, ...action.payload.list],
        couponsRequest: {
          ...action.payload.request,
          nextPageNo: action.payload.nextPageNo,
          total: action.payload.total,
        },
      };
    },
    [GET_MY_LIKES]: (state, action) => ({ ...state }),
    [GET_MY_LIKES_OK]: (state, action) => ({
      ...state,
      likes: {
        ...state.likes,
        ...action.payload,
      },
    }),
    [GET_MY_MESSAGES]: (state, action) => ({ ...state }),
    [GET_MY_MESSAGES_OK]: (state, action) => ({
      ...state,
      messages: action.payload,
    }),
    [GET_MY_ORDERS]: (state, action) => ({ ...state }),
    [GET_MY_ORDERS_MORE]: (state, action) => ({ ...state }),
    [GET_MY_ORDERS_MORE_OK]: (state, action) => {
      const [orders, done] = classifyOrder(action.payload.list);

      return {
        ...state,
        done: [...state.done, ...done],
        next: action.payload.next,
        orders: [...state.orders, ...orders],
      };
    },
    [GET_MY_ORDERS_OK]: (state, action) => {
      const [orders, done] = classifyOrder(action.payload.list);

      return {
        ...state,
        done,
        next: action.payload.next,
        orders,
      };
    },
    [GET_MY_PROFILE]: (state, action) => ({ ...state }),
    [GET_MY_PROFILE_OK]: (state, action) => ({
      ...state,
      profile: action.payload,
    }),
    [GET_MY_REVIEWS]: (state, action) => ({ ...state }),
    [GET_MY_REVIEWS_MORE]: (state, action) => ({ ...state }),
    [GET_MY_REVIEWS_MORE_OK]: (state, action) => {
      const temp = state.reviews.concat();

      for (const i in action.payload.list) {
        let bool = false;
        for (const j in temp) {
          if (action.payload.list[i].next === temp[j].next) {
            bool = true;
            break;
          }
        }
        if (!bool) {
          temp.push(action.payload.list[i]);
        }
      }

      return {
        ...state,
        reviewNext: action.payload.next,
        reviews: temp,
      };
    },
    [GET_MY_REVIEWS_OK]: (state, action) => ({
      ...state,
      reviewNext: action.payload.next,
      reviews: action.payload.list,
    }),
    [GET_MY_SETTINGS]: (state, action) => ({ ...state }),
    [GET_MY_SETTINGS_OK]: (state, action) => ({
      ...state,
      settings: action.payload,
    }),
    [SAVE_CATEGORY]: (state, action) => ({ ...state }),
    [SAVE_CATEGORY_OK]: (state, action) => ({
      ...state,
      category: action.payload,
    }),
    [UPDATE_MY_PROFILE]: (state, action) => ({ ...state }),
    [UPDATE_MY_PROFILE_OK]: (state, action) => ({
      ...state,
      profile: {
        ...state.profile,
        ...action.payload,
      },
    }),
    [UPDATE_MY_SETTINGS]: (state, action) => ({ ...state }),
    [UPDATE_MY_SETTINGS_OK]: (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        ...action.payload,
      },
    }),
  },
  initialState
);

const Types = {
  CANCEL_ORDER,
  GET_MANAGER_PHONE,
  GET_MY_COUPONS,
  GET_MY_LIKES,
  GET_MY_MESSAGES,
  GET_MY_ORDERS,
  GET_MY_ORDERS_MORE,
  GET_MY_PROFILE,
  GET_MY_REVIEWS,
  GET_MY_SETTINGS,
  SAVE_CATEGORY,
  UPDATE_MY_PROFILE,
  UPDATE_MY_SETTINGS,
};

export {
  cancel_order,
  cancel_order_today,
  delete_content_like,
  delete_my_review,
  delete_retail_like,
  delete_store_like,
  get_manager_phone,
  get_my_content_reviews,
  get_my_content_reviews_more,
  get_my_counts,
  get_my_coupons,
  get_my_likes,
  get_my_messages,
  get_my_orders,
  get_my_orders_more,
  get_my_profile,
  get_my_reviews,
  get_my_reviews_more,
  get_my_settings,
  save_category,
  Types,
  update_my_profile,
  update_my_settings,
};

export default reducer;

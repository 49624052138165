import './style.css';

import { Typography } from 'components/atoms';
import ChevronDown from 'components/Icons/ChevronDown';
import ChevronUp from 'components/Icons/ChevronUp';
import Icon from 'components/Icons/v2/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import chevronRight from './img/chevronRight.svg';

const BoardSection = ({
  children,
  headerStyle,
  link,
  linkTitle,
  setLoading,
  singlePage = false,
  style,
  subBoardStyle,
  subtitle,
  title,
  titleStyle,
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      {subBoardStyle && title && (
        <Container
          className="section-title"
          style={{ borderBottom: '1px solid #E8EBED' }}
        >
          <Typography color="gray500">
            {title}
            {subtitle && <span>{subtitle}</span>}
          </Typography>
          {link && (
            <a className="more" href={link} style={{ lineHeight: '26px' }}>
              {linkTitle}{' '}
              <img alt="arrow" height={16} src={chevronRight} width={16} />
            </a>
          )}
        </Container>
      )}

      {!subBoardStyle && title && !singlePage && (
        <SectionContainer style={headerStyle}>
          <Title style={titleStyle}>
            {title}
            {subtitle && <span>{subtitle}</span>}
          </Title>
          {link && (
            <Link
              href={link}
              style={{ lineHeight: '26px' }}
              onClick={() => {
                setLoading(true);
                const timeout = setTimeout(() => {
                  setLoading(false);
                  clearTimeout(timeout);
                }, 2000);
              }}
            >
              {linkTitle} <Icon name="arrow_right-16_1" size={16} />
            </Link>
          )}
        </SectionContainer>
      )}

      {!subBoardStyle && title && singlePage && (
        <SectionContainer style={headerStyle}>
          <Title style={titleStyle}>
            {title}
            {subtitle && <span>{subtitle}</span>}
          </Title>
          {link && (
            <Link
              onClick={() => {
                if (location.pathname === '/홈' || location.pathname === '/') {
                  sessionStorage.setItem('홈-scrollY', window.scrollY);
                }
                history.push(link);
              }}
            >
              {linkTitle}
              <Icon name="arrow_right-16_1" size={16} />
            </Link>
          )}
        </SectionContainer>
      )}
      <div style={style}>{children}</div>
    </>
  );
};

const Container = styled.div`
  padding: 16px;
`;

const ContentSection = ({
  badge,
  children,
  handleShowMore,
  more,
  showMore,
  style,
  subtitle,
  title,
}) => {
  return (
    <>
      <div className="section-title2" style={style}>
        <h3 style={{ fontWeight: '700' }}>{title}</h3>
        <span className="description txt-ellipsis">{subtitle}</span>
        {badge}
      </div>
      {children}
      {more && (
        <div className="section more" onClick={handleShowMore}>
          {more}
        </div>
      )}
    </>
  );
};

const MenuSection = ({
  badge,
  children,
  handleShowMore,
  more,
  showMore,
  subtitle,
  title,
}) => {
  return (
    <>
      <div
        style={{
          backgroundColor: title === '최근 본 가게' ? '#F7F8F9' : 'inherit',
          padding: '',
        }}
      >
        <Typography color="newGray11" fontSize="18px" fontWeight={700}>
          {title}
        </Typography>
        <span className="description txt-ellipsis">{subtitle}</span>
        {badge}
      </div>
      {children}
      {more && (
        <div className="menu-section wrap">
          {!showMore && <div className="menu-section gradient" />}
          <div className="menu-section more" onClick={handleShowMore}>
            {more}
            {showMore ? (
              <ChevronUp fill="#2F80ED" style={{ marginLeft: '11px' }} />
            ) : (
              <ChevronDown fill="#2F80ED" style={{ marginLeft: '11px' }} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

const Section = ({
  badge,
  children,
  handleShowMore,
  headerStyle,
  link,
  linkTitle,
  more,
  setLoading,
  showMore,
  singlePage,
  style = {},
  subBoardStyle,
  subtitle,
  title,
  titleStyle,
  type,
}) => {
  switch (type) {
    case 'content':
      return (
        <ContentSection
          badge={badge}
          handleShowMore={handleShowMore}
          more={more}
          showMore={showMore}
          style={style}
          subtitle={subtitle}
          title={title}
        >
          {children}
        </ContentSection>
      );
    case 'menu':
      return (
        <MenuSection
          badge={badge}
          handleShowMore={handleShowMore}
          more={more}
          showMore={showMore}
          subtitle={subtitle}
          title={title}
        >
          {children}
        </MenuSection>
      );
    case 'board':
    default:
      return (
        <BoardSection
          headerStyle={headerStyle}
          link={link}
          linkTitle={linkTitle}
          setLoading={setLoading}
          singlePage={singlePage}
          style={style}
          subBoardStyle={subBoardStyle}
          subtitle={subtitle}
          title={title}
          titleStyle={titleStyle}
        >
          {children}
        </BoardSection>
      );
  }
};

Section.defaultProps = {
  linkTitle: '더보기',
};

Section.propTypes = {
  type: PropTypes.string.isRequired,
};

const Link = styled.a`
  display: flex;
  align-items: center;
  color: var(--Gray-gray-9, #464c53);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

const Title = styled.div`
  color: var(--Gray-gray-11, #26282b);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 0 24px;
`;

export default React.memo(Section);

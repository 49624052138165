import { Image } from '../views';
import React from 'react';
import closeImg from 'img/ftp_fa_x.svg';

const RecommendedProduct = ({ product, removeItem, remove }) => {
  return (
    <div
      className="card-template type13"
      onClick={() => {
        window.location.href = product.hyperlink || product.link;
      }}
    >
      <div className="fixed inner-section type2">
        <span className="img-block">
          <Image
            scale="1x1"
            className="display-block"
            src={product.thumbnail}
            height="97px"
            style={{ objectFit: 'cover' }}
          />
        </span>
      </div>
      <div className="fixed inner-section type2">
        <div className="inner-inner-section type2">
          <span className="subject">{product.subject}</span>
          <br />
          <span className="description">{product.description}</span>
          {product.description !== '' ? <br /> : null}
          <span className="price">{product.price}</span>
        </div>
      </div>
      {remove && (
        <img
          className="close_img"
          src={closeImg}
          alt="close_img"
          onClick={(e) => {
            e.stopPropagation();
            removeItem(product, 'retail');
          }}
        />
      )}
    </div>
  );
};

const RecommendedProducts = ({ products, removeItem, remove }) => {
  return products.map((product, i) => (
    <RecommendedProduct
      product={product}
      key={i}
      removeItem={removeItem}
      remove={remove}
    />
  ));
};

export default RecommendedProducts;

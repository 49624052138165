import { Typography } from 'components/atoms';
import BoardTitleSection from 'components/common/BoardTitleSection';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const RecentStores = ({ list }) => {
  const history = useHistory();

  return (
    <BoardTitleSection
      backgroundColor="#f7f8f9"
      margin="0 0 24px 0"
      padding="24px 0 0px 0"
      title="최근 본 가게"
    >
      <RecentStoreWrapper>
        {list.map((store, i) => {
          return (
            <RecentStore
              key={i}
              onClick={() => {
                history.replace(store.link);
              }}
            >
              <ImageWrapper>
                <img alt="" src={store.thumbnail} />
              </ImageWrapper>
              <div>
                <Typography color="gray600" fontSize="16px" fontWeight={500}>
                  {store.label}
                </Typography>
                <Typography color="gray400" fontSize="14px" fontWeight={400}>
                  {store.market}
                </Typography>
              </div>
            </RecentStore>
          );
        })}
      </RecentStoreWrapper>
    </BoardTitleSection>
  );
};

const ImageWrapper = styled.div`
  img {
    border-radius: 50%;
    object-fit: cover;
    width: 50px;
    height: 50px;
  }
`;

const RecentStoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 16px 16px 16px;
`;

const RecentStore = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;

export default RecentStores;

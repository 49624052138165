import {
  start_data_load,
  finish_data_load,
  fail_data_load,
} from './global/mode';

export default function createApiRequest(action_type, request) {
  const RESPONSE_OK = `${action_type}_OK`;
  const RESPONSE_FAIL = `${action_type}_FAIL`;
  const requester =
    (...params) =>
    async (dispatch) => {
      dispatch({ type: action_type });
      dispatch(start_data_load(action_type));
      try {
        const response = await request(...params);
        dispatch({
          type: RESPONSE_OK,
          payload: response.data,
          totalElements: response.totalElements,
        });
        dispatch(finish_data_load(action_type));
        return response.data;
      } catch (e) {
        dispatch({
          type: RESPONSE_FAIL,
          payload: e,
        });
        dispatch(fail_data_load(action_type));
        throw e;
      }
    };

  return [requester, RESPONSE_OK, RESPONSE_FAIL];
}

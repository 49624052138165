import BoardTitleSection from 'components/common/BoardTitleSection';
import Tag from 'components/molecules/Tag';
import { useWholeSaleSortState } from 'hooks/custom/useWholeSaleSortState';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { globalListOrder, globalSearchStoreScrollPage } from 'recoil/filter';
import { globalPriceFilter, globalStoresFilter } from 'recoil/price';
import { eventSearchKeyword, globalSearchKeyword } from 'recoil/search';
import styled from 'styled-components';
import genPrevPath from 'utils/genPrevPath';

const RecommendKeyword = ({ isWholeSale, recommends }) => {
  const history = useHistory();
  const [globalKeyword, setGlobalKeyword] = useRecoilState(globalSearchKeyword);
  const setEventKeyword = useSetRecoilState(eventSearchKeyword);
  const [storesFilter, setStoresFilter] = useRecoilState(globalStoresFilter);
  const [priceFilter, setPriceFilter] = useRecoilState(globalPriceFilter);
  const setListOrder = useSetRecoilState(globalListOrder);
  const prevPath = sessionStorage.getItem('prevPath');

  const setStorePage = useSetRecoilState(globalSearchStoreScrollPage);
  const { initWholeSaleSortState } = useWholeSaleSortState();

  const clickTag = (key) => {
    initWholeSaleSortState();
    if (!globalKeyword.length) {
      const newObj = Object.keys(storesFilter).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});
      setStoresFilter(newObj);

      const newPriceObj = Object.keys(priceFilter).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});
      setPriceFilter(newPriceObj);
    }
    setGlobalKeyword(key);
    setEventKeyword('');
    setListOrder('default');
    setStorePage(1);
    if (isWholeSale) {
      history.push('/도매');
      return;
    }
    history.push(genPrevPath(prevPath));
  };

  return (
    <BoardTitleSection
      margin="0 0 24px 0"
      padding="24px 0 24px 0"
      title="추천 검색어"
    >
      <TagWrapper>
        {recommends.map((v, i) => {
          return (
            <Tag key={i} onClick={() => clickTag(v)}>
              {v}
            </Tag>
          );
        })}
      </TagWrapper>
    </BoardTitleSection>
  );
};

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  overflow: hidden;
  padding: 0px 16px;
`;

export default RecommendKeyword;

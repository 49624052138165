import React from 'react';
import styled, { keyframes } from 'styled-components';

/**
 * @param {object} props
 * @param {number} props.size
 */
const Circular = (props) => {
  const { size = 40, className } = props;

  return (
    <Container $size={size} className={className}>
      <Svg viewBox="22 22 44 44">
        <Circle cx="44" cy="44" r="20.2"></Circle>
      </Svg>
    </Container>
  );
};

const A1 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const A2 = keyframes`
  0% {
    stroke-dasharray: 1px, 200px; 
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`;

const Circle = styled.circle`
  animation: 1.4s ease-in-out 0s infinite normal none running ${A2};
  color: inherit;
  fill: none;
  stroke: currentColor;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  stroke-width: 3.6;
`;

const Container = styled.div`
  animation: 1.4s linear 0s infinite normal none running ${A1};
  color: inherit;
  display: inline-block;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
`;

const Svg = styled.svg`
  color: inherit;
  display: block;
`;

export default Circular;

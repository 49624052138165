import { useQuery } from '@tanstack/react-query';
import { getCouponDetail } from 'api/coupon';

import couponListKeys from './keys';

export default function useGetCouponDetail(id) {
  return useQuery(
    couponListKeys.getCouponDetail(id),
    () => getCouponDetail(id),
    {
      cacheTime: 0,
      enabled: !!id,
      staleTime: 0,
    }
  );
}

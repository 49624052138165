import client from 'api/client';
import clientV3 from 'api/clientV3';

export const get_best_reviews = (region, prev) =>
  client.get(`/www/comment/home`, {
    params: { commentCode: prev, zoneCode: region },
  });

export const get_store_reviews = (code, query) => {
  const { id, next } = { ...{ id: null, next: null, user: null }, ...query };
  const api_url = id
    ? `/www/comment/${code}/comments/${id}/`
    : `/www/comment/store/${code}/comments`;

  return client.get(api_url, { params: { sortNum: next, type: 1 } });
};

export const get_content_reviews = (code, query) => {
  const { id, next } = { ...{ id: null, next: null, user: null }, ...query };
  const api_url = id
    ? `/www/comment/${code}/comments/${id}/`
    : `/www/comment/content/${code}/comments`;
  return client.get(api_url, { params: { sortNum: next, type: 2 } });
};

export const add_review = (query, parentCode) =>
  client.post(
    `/www/comment/${parentCode ? `${parentCode}/` : ''}register`,
    query
  );

export const modify_review = (query, commentCode) =>
  client.put(`/www/comment/${commentCode}`, query);

export const delete_review = (code, params) =>
  client.delete(`/www/comment/${code}`, params);

export const report_review = (params) =>
  client.post(`/www/comment/report`, params);

export const report_review_block_user = (params) =>
  client.post(`/www/block/user`, params);

export const getRealTimeReviews = ({ commentCode, zoneCode }) =>
  clientV3
    .get(`/www/comment/home`, {
      params: { commentCode, zoneCode },
    })
    .then((res) => res.data);

import { useQuery } from '@tanstack/react-query';
import { getOrderHistories } from 'api/mypage';

import myPageKeys from './keys';

const useGetOrderHistories = () => {
  // * 서버에서 페이지로 나눠서 조회가 불가능, 이후 개선되면 무한스크롤로 바꿀것. 지금은 DOM의 갯수를 클라이언트가 줄여서 표현하여 구현
  return useQuery(myPageKeys.getOrderHistories(), () => getOrderHistories(), {
    cacheTime: 0,
  });
};

export default useGetOrderHistories;

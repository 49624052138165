import { Box } from 'components/atoms';
import MyLikeAppFilter from 'pages/_mypage/_my_like_appFilter';
import { useEffect, useState } from 'react';

import FooterButtons from './footer-button';
import { ReactComponent as Pirates } from './img/pirates.svg';
import RegularView from './signboard/_regular';
import WideView from './signboard/_wide';
import { filter_selector } from './supports';

const StoreList = ({
  _handle_store_link,
  items,
  my_like,
  order,
  remove,
  removeItem,
  scale,
  version,
}) => {
  const [stores, setStores] = useState([]);
  const [checked, setChecked] = useState('앱주문');
  const appOrderFilter = items?.filter((item) =>
    item.tags.includes('app-order')
  );

  useEffect(() => {
    if (items) {
      if (my_like && checked === '앱주문') {
        setStores(appOrderFilter);
      } else {
        setStores(items);
      }
    }
  }, [items, checked]);

  return (
    <>
      {my_like && <MyLikeAppFilter checked={checked} setChecked={setChecked} />}

      {stores.map((item, idx) => (
        <RegularView
          key={idx}
          _handle_store_link={_handle_store_link}
          item={item}
          order={order ? idx + 1 : null}
          remove={remove}
          removeItem={removeItem}
          scale={scale}
          version={version}
        />
      ))}

      {my_like && stores.length === 0 && (
        <Box display="block" textAlign="center">
          <Pirates style={{ marginBottom: '8px', marginTop: '100px' }} />
          <Box
            color="#9FA4AA !important"
            display="block"
            fontSize="21px"
            fontWeight="500"
            letterSpacing="-0.3px"
            lineHeight="30px"
          >
            자주가는 가게를 찜해주세요
          </Box>
        </Box>
      )}
    </>
  );
};

const Signboard = ({
  _handle_store_link,
  handleBannerRef,
  item,
  onClickFavorite,
  scale,
  session,
  type,
}) => {
  switch (scale) {
    case 'wide':
      return (
        <WideView
          _handle_store_link={_handle_store_link}
          handleBannerRef={handleBannerRef}
          item={item}
          session={session}
          type={type}
          onClickFavorite={onClickFavorite}
        />
      );
    default:
      return <RegularView item={item} scale={scale} />;
  }
};

export { StoreList as List, Signboard };

export { FooterButtons };
export { filter_selector };

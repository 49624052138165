import { useEffect } from 'react';

const InfiniteScroll = (onIntersect, sensor, options) => {
  useEffect(() => {
    if (!sensor) {
      return;
    }

    const _options = {
      rootMargin: '0px',
      threshold: 1,
      ...options,
    };

    const observer = new IntersectionObserver(onIntersect, _options);
    observer.observe(sensor);

    return () => {
      observer.unobserve(sensor);
      observer.disconnect();
    };
  }, [sensor, onIntersect, options]);
};

export default InfiniteScroll;

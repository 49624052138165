import { Modal } from '../common';
import { TextView } from '../views';

const NotificationModal = ({ onClose, state, type }) => {
  const { image, text } = state.data || {};
  return (
    <Modal
      className="modal-notice"
      state={state}
      title="공지사항"
      type={type}
      onClose={onClose}
    >
      <div className="main">
        <img alt="" src={image} style={{ width: '100%' }} />
        {text && <TextView>{text}</TextView>}
      </div>
      <div className="foot">
        <div className="btn-area">
          <a>
            <button onClick={onClose}>확인</button>
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;

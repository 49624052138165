import React from 'react';
import { Box } from 'components/atoms';

/**
 * @param {SystemProps} props
 */
const ErrorMessage = (props) => {
  return <Box as="p" color="red2" fontSize="12px" {...props} />;
};

export default ErrorMessage;

import { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';

const OPEN = 'pirates.coupon/POPUP_OPEN';
const CLOSE = 'pirates.coupon/POPUP_CLOSE';

function reducer(state, action) {
  switch (action.type) {
    case OPEN:
      return { data: action.payload, popup: 'on' };
    case CLOSE:
      return { data: null, popup: 'off' };
    default:
      return state;
  }
}

const usePopup = () => {
  const [state, dispatch] = useReducer(reducer, { data: null, popup: 'off' });
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'POP') {
        dispatch({ type: CLOSE });
      }
    });
  }, []);

  const actions = {
    close: () => {
      dispatch({ type: CLOSE });
    },
    open: (data) => {
      dispatch({ payload: data, type: OPEN });
    },
  };

  return [state, actions];
};
export default usePopup;

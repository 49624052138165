const ClipBoard = ({ size }) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 18 18"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4997 10.3901C11.8066 10.6969 12.3041 10.6969 12.611 10.3901L14.5557 8.44535C15.9366 7.06445 15.9366 4.82557 14.5557 3.44467C13.1748 2.06377 10.9359 2.06377 9.55503 3.44467L7.61032 5.38938C7.30345 5.69624 7.30345 6.19377 7.61032 6.50064C7.91719 6.80751 8.41471 6.80751 8.72158 6.50064L10.6663 4.55593C11.4335 3.78876 12.6773 3.78876 13.4444 4.55593C14.2116 5.3231 14.2116 6.56692 13.4444 7.33409L11.4997 9.27879C11.1929 9.58566 11.1929 10.0832 11.4997 10.3901Z"
        fill="#464C53"
      />
      <path
        d="M10.3884 11.5013C10.6953 11.8082 10.6953 12.3057 10.3884 12.6126L8.4437 14.5573C7.0628 15.9382 4.82392 15.9382 3.44302 14.5573C2.06212 13.1764 2.06212 10.9375 3.44302 9.55661L5.38773 7.61191C5.6946 7.30504 6.19212 7.30504 6.49899 7.61191C6.80586 7.91877 6.80586 8.4163 6.49899 8.72317L4.55428 10.6679C3.78712 11.435 3.78712 12.6789 4.55428 13.446C5.32145 14.2132 6.56527 14.2132 7.33244 13.446L9.27715 11.5013C9.58401 11.1945 10.0815 11.1945 10.3884 11.5013Z"
        fill="#464C53"
      />
      <rect
        fill="#464C53"
        height="1.57156"
        rx="0.785781"
        transform="rotate(-45 6.22168 10.6673)"
        width="6.28625"
        x="6.22168"
        y="10.6673"
      />
    </svg>
  );
};

export default ClipBoard;

import { Box } from 'components/atoms';
import React from 'react';
import { useController } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';
import StyledInput from './StyledInput';

/**
 * @typedef {object} FormInputNumberProps
 * @property {import('react-hook-form').Control<import('react-hook-form').FieldValues>} control
 * @property {string} name
 * @property {string} defaultValue
 * @property {number} maxLength
 * @property {boolean} shouldUnregister
 *
 * @param {FormInputNumberProps & React.HTMLAttributes<HTMLInputElement>} props
 */
const FormInputNumber = ({
  control,
  name,
  defaultValue,
  maxLength,
  shouldUnregister,
  ...props
}) => {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue: defaultValue || '',
    shouldUnregister: shouldUnregister,
  });

  return (
    <>
      <StyledInput
        {...props}
        error={error}
        pattern="[0-9]*"
        onChange={(e) => {
          const newValue = e.target.value
            .replace(/[^0-9]/g, '')
            .substring(0, maxLength);
          onChange(newValue);
        }}
        {...field}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </>
  );
};

export default FormInputNumber;

const DEFAULT_PREFIX = '_tp_';
/**
 * _TP_: 레거시 세팅 대응 필요 시
 */
// const LEGACY_PREFIX = '_TP_';

const DEFAULT_COOKIE_OPTION = {
  domain: '',
  path: '/',
};

export class Cookie {
  constructor(prefix = DEFAULT_PREFIX) {
    // initialize function
    this.getAllCookies(document.cookie);
    this.allCookies = this.getAllCookies(document.cookie);
    this.prefixCookies = this.getAllCookies(document.cookie, {
      onlyPrefixCookie: true,
      prefix,
    });
    this.prefix = prefix;
  }

  getAllCookies(cookies, options) {
    let cookieArray = [];
    if (typeof window !== 'undefined') {
      cookieArray = cookies
        /** @description split(';')보다 정확히 분할하기 위해 '; ' 띄어쓰기까지 잘라냄 */
        .split('; ')
        .map((rawCookie) => {
          const cookie = rawCookie.trim();
          const index = cookie.indexOf('=');
          /** @description split('=')을 쓸 경우 쿠키의 값중에 url 쿼리스트링이 있으면 파싱 에러남 */
          const result = [cookie.slice(0, index), cookie.slice(index + 1)];
          return result;
        });
    } else {
      cookieArray = Object.entries(cookies);
    }

    const parsedCookies = cookieArray.reduce((acc, [key, value]) => {
      let parsedValue = '';
      try {
        parsedValue = JSON.parse(value || '');
      } catch {
        parsedValue = value || '';
      }
      if (key.startsWith(options?.prefix || DEFAULT_PREFIX)) {
        const newKey = key.replace(options?.prefix || DEFAULT_PREFIX, '');
        return { ...acc, [newKey]: parsedValue };
      }
      if (options?.onlyPrefixCookie) return acc;
      return { ...acc, [key]: parsedValue };
    }, {});
    return parsedCookies;
  }

  getCookie(key) {
    const cookie = key ? this.allCookies?.[key] || '' : this.allCookies || '';
    try {
      const cleanedJsonString = cookie.replace(/\\/g, '');
      return JSON.parse(cleanedJsonString);
    } catch {
      return cookie;
    }
  }

  /**
   *
   * @param cookieObject 셋팅 할 쿠키의 key에 쿠키 이름, value에는 쿠키의 값
   * @example cookieObject 쿠키이름: cookie1 / 쿠키값: value1 -> { cookie1: 'value1' }
   * @param option.path ex) path=/main-path;
   * @param option.domain ex) domain=tpirates.com;
   * @param option.expires ex) expires=Tue, 19 Jan 2038 03:14:07 GMT;
   * @param option.maxAge ex) max-age=3600; (second 단위)
   * @param option.secure ex) secure;
   * @param option.sameSite ex) samesite=strict
   */
  setCookies(cookieObject, cookieOption) {
    const cookies = Object.entries(cookieObject || {}).map(([key, value]) => {
      let cookie = '';
      let option = '';
      const isArray = Array.isArray(value);
      const isObject = typeof value === 'object';

      if (isArray || isObject) {
        const objectValue = JSON.stringify(value);
        cookie = `${cookieOption?.prefix || this.prefix}${key}=${objectValue || ''};`;
      } else {
        cookie = `${cookieOption?.prefix || this.prefix}${key}=${value || ''};`;
      }
      if (!cookieOption) {
        option = `domain=; path=/;`;
      } else {
        option = Object.entries(cookieOption || DEFAULT_COOKIE_OPTION)
          .map(([optionKey, optionValue]) => {
            if (optionKey === 'domain') {
              return `domain=${cookieDomainParser(cookieOption.domain || '')}`;
            }
            if (optionKey === 'path') {
              return `path=${cookieOption.path || '/'}`;
            }
            if (optionKey === 'maxAge') {
              return `max-age=${cookieOption?.maxAge || 0}`;
            }
            if (optionKey === 'secure') {
              return `${optionKey}`;
            }
            return `${optionKey}=${optionValue || ''}`;
          })
          .join('; ');
      }

      return `${cookie} ${option}`;
    });
    if (typeof window !== 'undefined') {
      cookies.forEach((cookie) => {
        document.cookie = cookie;
      });
    } else {
      // this?.res?.setHeader("set-cookie", cookies);
      throw new Error('window not defined');
    }
  }

  deleteCookies(cookieName, cookieOption) {
    let option = '';
    if (!cookieOption) {
      option = `expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=; path=/; max-age=0;`;
    } else {
      option = Object.entries(cookieOption || DEFAULT_COOKIE_OPTION)
        .map(([optionKey, optionValue]) => {
          if (optionKey === 'domain') {
            return `domain=${cookieDomainParser(cookieOption.domain || '')}`;
          }
          if (optionKey === 'path') {
            return `path=${cookieOption.path || '/'}`;
          }
          if (optionKey === 'maxAge') {
            return 'max-age=0';
          }
          if (optionKey === 'secure') {
            return `${optionKey}`;
          }
          if (optionKey === 'expires') {
            return 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
          }
          return `${optionKey}=${optionValue}`;
        })
        .join('; ');
    }
    if (typeof cookieName === 'string') {
      if (!this.allCookies?.[cookieName]) return;
      document.cookie = `${
        cookieOption?.prefix || this.prefix
      }${cookieName}=; ${option}`;
    } else if (Array.isArray(cookieName)) {
      cookieName.forEach((cookie) => {
        document.cookie = `${cookieOption?.prefix || this.prefix}${cookie}=; ${option}`;
      });
    }
  }

  deleteAllPrefixCookie() {
    this.deleteCookies([...Object.keys(this.prefixCookies)]);
  }
}

export const cookieDomainParser = (domain) => {
  if (domain instanceof URL) {
    return domain.host;
  }
  const regex = /[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
  if (regex.test(domain)) {
    return domain;
  }
  throw new Error('domain parse error');
};

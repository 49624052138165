import client from 'api/client';

import noInterceptors from './noInterceptors';
import profileClient from './profileClient';

export const exchange_auth_code = (provider, token) =>
  client.post(`/www/oauth/exchange`, { provider, ...token });

// 로그인 상태 판단 api
export const get_login_state = () => {
  return noInterceptors.get(`/auth`);
};

export const get_profile = () => {
  return get_login_state().then(async (res) => {
    // localStorage에 로그인 상태 저장
    localStorage.setItem('isSignedIn', res.data);

    const newData = {
      address: { building: null, street: null },
      business: null,
      email: null,
      identity: null,
      memberType: null,
      name: null,
      phone: null,
      profileImage: null,
      registerAt: null,
    };
    if (res.data === 'notLogin') {
      // eslint-disable-next-line no-undef
      if (typeof newrelic !== 'undefined' && newrelic.setUserId) {
        // eslint-disable-next-line no-undef
        newrelic.setUserId(null);
      }
      return newData;
    }
    const response = await profileClient.get('/account/profile');
    // eslint-disable-next-line no-undef
    if (typeof newrelic !== 'undefined' && newrelic.setUserId) {
      // eslint-disable-next-line no-undef
      newrelic.setUserId(response.data.identity);
      Object.entries(response.data).forEach(([key, value]) => {
        // eslint-disable-next-line no-undef
        newrelic.setCustomAttribute(key, value, true);
      });
    }
    return response;
  });
};

// export const get_session = (opt) =>
//   client.get('/www/session', opt ? { params: { _c: opt } } : {});

// export const get_cart_session = (opt) =>
//   client.get('/www/session', opt ? { params: { _c: opt } } : {});

// export const sign_in_with_provider = (provider, identity, integration) => {
//   return client.post('/www/session', {
//     provider: provider,
//     identity: {
//       accessToken: identity.accessToken,
//       id: identity.id,
//       name: identity.name,
//       email: identity.email,
//     },
//     integration,
//   });
// };

export const sign_out = () =>
  client.delete('/www/session').then((res) => {
    return Promise.resolve(res);
  });

export const sign_in_with_email = (email, password) =>
  client.post('/www/login', { email, password });

export const get_user_contracts = ({ email, identity, provider }) =>
  client.get(`/www/user/contracts/agreements`, {
    params: { email, identity, provider },
  });

export const add_app_token = (params) =>
  // client.put('/www/session/appToken', {}, { params });
  profileClient.put('/account/push/token', params);

import './style.css';

import Spinner from './_spinner';

const Loading = ({ message = 'Loading ...' }) => {
  return (
    <div className="progress-view loading-container">
      <span className="loading">
        <Spinner />
      </span>
    </div>
  );
};

export default Loading;

export const moneyFormatter = (num = 0) => {
  const formatter = new Intl.NumberFormat('ko-KR');
  return formatter.format(num);
};

export const joinStrBySlash = (...args) => {
  return args.reduce((acc, cur) => {
    if (!acc && cur) {
      return cur;
    }
    if (cur) {
      return `${acc} / ${cur}`;
    }
    return acc;
  }, '');
};

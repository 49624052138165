import SearchArchive from './_archive';
import { SearchProvider } from './_context';
import KeywordFilter from './_keyword';
import Notice from './_notice';
import OptionFilter, { OptionGroup } from './_option';
import TagFilter from './_tag';

const Search = ({ children, onActive, onInactive, onSubmit }) => {
  return (
    <SearchProvider
      handleActive={onActive}
      handleInactive={onInactive}
      handleSubmit={onSubmit}
    >
      <div className="section search-filter type2">
        <SearchArchive />
        {children}
      </div>
    </SearchProvider>
  );
};

Search.defaultProps = {
  initTrigger: [],
  onActive: () => {},
  onInactive: () => {},
  onSubmit: () => {},
};

export { KeywordFilter, Notice, OptionFilter, OptionGroup, TagFilter };
export default Search;

import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'api/client';

export const fetchAddresses = createAsyncThunk(
  'address/fetchAddresses',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await client.get(
        `/www/member-service/members/${id}/delivery-addresses`
      );
      return data.filter((address) => !address.deleted);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

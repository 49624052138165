import { useEffect, useState } from 'react';

export const useCalcLabelOffset = ({ calcDeps, targetRef }) => {
  const [offsetLeft, setOffsetLeft] = useState(0);
  const nonSwiftWidth = 320;

  useEffect(() => {
    if (targetRef.current) {
      setOffsetLeft(
        targetRef.current.offsetLeft > nonSwiftWidth
          ? targetRef.current.offsetLeft - nonSwiftWidth
          : 0
      );
    }
  }, [offsetLeft, calcDeps, targetRef]);

  return { offsetLeft };
};

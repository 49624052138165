import React from 'react';

import Input from './Input';

const NumberInput = (
  {
    disabled,
    id,
    isError,
    name,
    onBlur,
    onChange,
    placeholder,
    style,
    value = '',
  },
  ref
) => {
  return (
    <Input
      ref={ref}
      disabled={disabled}
      id={id}
      inputMode="numeric"
      isError={isError}
      name={name}
      pattern="\d*"
      placeholder={placeholder}
      style={style}
      value={value}
      onBlur={onBlur}
      onChange={(e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        onChange(e);
      }}
    />
  );
};

export default React.forwardRef(NumberInput);

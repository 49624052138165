import client from 'api/client';

import { update_view_or_like } from './global';

export const get_contents = ({ keyword, level, next } = {}) =>
  client.get(`/www/contents/home`, {
    params: { keyword: keyword || null, randNum: next, sortLevel: level },
  });

export const get_recommend_contents = () =>
  client.get(`/www/contents/recommends`);

export const get_content = (code) => client.get(`/www/contents/detail/${code}`);

export const get_related = (code, type) =>
  client.get(`/www/contents/${code}/relation/${type}`);

export const get_related_contents = (code) => get_related(code, 'contents');

export const get_related_prices = (code) => get_related(code, 'prices');

export const get_related_products = (code) => get_related(code, 'products');

export const update_content_view = (item) =>
  update_view_or_like(item, { contentsType: 2, doLike: false, type: 'view' });

export const toggle_content_like = (item) =>
  update_view_or_like(item, { contentsType: 2, type: 'like' });

import { Box } from 'components/atoms';
import Footer from 'components/new/Footer';
import styled from 'styled-components';
import { mobileIosCheck } from 'utils/mobileCheck';

const FooterButtons = ({ onClickOrder, orderCount }) => {
  if (orderCount === 0) {
    return null;
  }

  const mobileIOS = mobileIosCheck();

  return (
    <Footer mobileIOS={mobileIOS}>
      <StyledButton
        alignItems="center"
        as="button"
        backgroundColor="transparent"
        color="white"
        fontSize="18px"
        fontWeight={500}
        gap="6px"
        justifyContent="center"
        letterSpacing="-0.3px"
        type="button"
        width="100%"
        onClick={onClickOrder}
      >
        <Box
          alignItems="center"
          borderRadius="50px"
          color="blue2"
          // fontFamily="Open Sans, Noto Sans KR, sans-serif"
          fontSize="16px"
          fontWeight={600}
          height="20px"
          justifyContent="center"
          letterSpacing="-0.3px"
          backgroundColor="white"
          // width="20px"
          padding="0 5px"
        >
          {orderCount > 99 ? '99+' : orderCount}
        </Box>
        주문하기
      </StyledButton>
    </Footer>
  );
};

const StyledButton = styled(Box)`
  cursor: pointer;
`;

export default FooterButtons;

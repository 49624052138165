import { atom, selector } from 'recoil';
import { v1 } from 'uuid';

export const globalPriceListOrderState = atom({
  default: 'default',
  key: `globalPriceListOrderState/${v1()}`,
});

export const globalPriceFilter = atom({
  default: {
    around: [],
    location: [],
    origin: [],
    product: [],
    raised: [],
  },
  key: `globalPriceFilter/${v1()}`,
});

export const globalStoresFilter = atom({
  default: {
    around: [],
    location: [],
    orderType: [],
    product: [],
  },
  key: `globalStoresFilter/${v1()}`,
});

export const globalViewportElement = atom({
  default: null,
  key: `globalViewportElement/${v1()}`,
});

export const globalZoneFilterData = atom({
  default: [],
  key: `globalZoneFilterData/${v1()}`,
});

export const globalQuoteRowAccordionOpenId = atom({
  default: null,
  key: `globalQuoteRowAccordionOpenId/${v1()}`,
});

export const globalQuoteChartSortState = atom({
  default: 'avgPrice',
  key: `globalQuoteChartSortState/${v1()}`,
});

import { load_recommend_keywords } from 'api/search-filters';
import PartDivider from 'components/atoms/PartDivider';
import { Container } from 'components/common';
import SearchInput from 'components/molecules/inputs/SearchInput';
import RecentKeyword from 'components/templates/Search/RecentKeyword';
import RecentStores from 'components/templates/Search/RecentStores';
import RecommendKeyword from 'components/templates/Search/RecommendKeyword';
import usePriceSortState from 'hooks/custom/usePriceSortState';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { globalSearchStoreScrollPage } from 'recoil/filter';
import { globalSearchKeyword } from 'recoil/search';
import styled from 'styled-components';
import genPrevPath from 'utils/genPrevPath';
import { mobileIosCheck } from 'utils/mobileCheck';

import { visited_stored } from './_store/_store_supports';

const COOKIE_KEY_RECENTLY = 'pirates-component/RECENTLY.KEYWORDS';
const COOKIE_RECENTLY = 'pirates-cookie/STORE.RECENTLY';

const Search = () => {
  const history = useHistory();
  const [recommends, setRecommends] = useState([]);
  const [visitedList, setVisitedList] = useState([]);
  const [containerHeight, setContainerHeight] = useState(null);
  const setGlobalKeyword = useSetRecoilState(globalSearchKeyword);
  const setStorePage = useSetRecoilState(globalSearchStoreScrollPage);
  const { initializePriceState } = usePriceSortState();
  const location = useLocation();
  const isWholeSale = location.pathname.includes('도매');
  const prevPath = sessionStorage.getItem('prevPath');
  const [cookies, setCookie] = useCookies([COOKIE_KEY_RECENTLY]);
  const mobileIOS = mobileIosCheck();
  const recently = (cookies[COOKIE_KEY_RECENTLY] || '')
    .split(',')
    .filter((k) => k && k !== '');

  useEffect(() => {
    window.scrollTo(0, 0);
    load_recommend_keywords().then((res) => {
      setRecommends(res.data);
    });
  }, []);

  useEffect(() => {
    const cookie = cookies[COOKIE_RECENTLY];
    if (cookie) {
      visited_stored(cookie).then((visited) => {
        const modifiedList = visited.map((store) => {
          return {
            label: store.label,
            link: store.uri,
            market: store.market,
            thumbnail: store.thumbnail,
          };
        });
        setVisitedList(modifiedList);
      });
    }
  }, [cookies]);

  useEffect(() => {
    if (!mobileIOS) {
      return;
    }

    const handleResize = () => {
      const height = window.innerHeight;
      setContainerHeight(height);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container
      style={{ height: containerHeight ? `${containerHeight}px` : null }}
    >
      <SearchInput
        handleClickBack={() => {
          setGlobalKeyword('');
          setStorePage(1);
          initializePriceState();
          if (isWholeSale) {
            history.push('/도매');
            return;
          }
          history.push(genPrevPath(prevPath));
        }}
        isWholeSale={isWholeSale}
        recently={recently}
        setCookie={setCookie}
      />
      <SearchInfos>
        <PartDivider level={10}>
          <div style={{ borderTop: '10px solid #F3F3F3' }}>
            <RecentKeyword isWholeSale={isWholeSale} recently={recently} />
          </div>
          <div>
            <RecommendKeyword
              isWholeSale={isWholeSale}
              recommends={recommends}
            />
            {!isWholeSale && <RecentStores list={visitedList} />}
          </div>
        </PartDivider>
      </SearchInfos>
    </Container>
  );
};

const SearchInfos = styled.div`
  padding-top: 56px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* .title {
    padding: 32px 0px 16px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
  } */
`;

export default Search;

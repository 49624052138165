import './style.css';
const FileButton = ({
  accept,
  children,
  className,
  disabled,
  handleNotLoggedIn,
  onChange,
}) => {
  return (
    <div
      className="file-button"
      onClick={(e) => {
        if (disabled) {
          handleNotLoggedIn(e);
        }
      }}
    >
      <label className={className}>
        {children}
        <input
          accept={accept}
          disabled={disabled}
          multiple="multiple"
          type="file"
          onChange={onChange}
        />
      </label>
    </div>
  );
};

export default FileButton;

import './style-spinner.css';

const Spinner = () => {
  return (
    <div className="progress-view square-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;

import axios from 'axios';
import * as settings from 'conf/settings';
import { conf } from 'conf/settings';

import getAccountUrl from './getAccountUrl';
const client = axios.create();

client.defaults.baseURL = settings.api_url('');
client.defaults.withCredentials = true;

client.defaults.headers.put.Pragma = 'no-cache';
client.defaults.headers.put['Cache-Control'] = 'no-cache';
client.defaults.headers.delete.Pragma = 'no-cache';
client.defaults.headers.delete['Cache-Control'] = 'no-cache';

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const prevPath = sessionStorage.getItem('prevPath') || '';
    const redirectUrl = `${conf.FISH_MARKET_URL}${
      prevPath === 'null' ? '' : prevPath
    }`;

    switch (error.response?.status) {
      case 401: {
        const data = await fetch(`${conf.ACCOUNT_API_URL}/v3/auth/token`, {
          credentials: 'include',
          method: 'post',
        });
        if (data.ok) {
          window.location.reload();
        }
        if (data.status === 401) {
          window.location.href = `${getAccountUrl({ redirectUrl })}`;
        }
        return Promise.reject(error);
      }
      default:
        return Promise.reject(error);
    }
  }
);
export default client;

/* eslint-disable react/jsx-no-useless-fragment */
import { APP, SESSION } from 'context';
import { apptoken_session, check_session } from 'context/global/session';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import HeaderFixed from './_header_fixed';
import Head, { HeadWithCloseButton } from './_page_header';
import HeadNew from './_page_header_new';

export const footerPaths = ['/도매'];

export const headerNotPaths = [
  '/',
  '/홈',
  '/시세',
  '/콘텐츠',
  '/시장',
  '/도매',
  '/도매/장바구니',
  '/도매/내주문',
];

const { useEffect } = React;
const HeaderContainer = ({
  active,
  apptoken_session,
  check_session,
  session,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const location = useLocation();
  const [viewHeader, setViewHeader] = useState(true);

  // 메인페이지 세션 체크
  useEffect(() => {
    alter_check_session();
  }, []);

  useEffect(() => {
    setViewHeader(headerNotPaths.includes(location.pathname));
  }, [location]);

  const alter_check_session = async () => {
    const res = await check_session();
    if (
      res &&
      res.identity &&
      cookies.tpirates_app_fcm_token &&
      cookies.tpirates_app_platform
    ) {
      const fcmToken = localStorage.getItem('tpirates_app_fcm_token') || null;
      const appPlatform = localStorage.getItem('tpirates_app_platform') || null;
      if (
        !fcmToken ||
        fcmToken !== cookies.tpirates_app_fcm_token ||
        !appPlatform ||
        appPlatform !== cookies.tpirates_app_platform
      ) {
        await apptoken_session({
          appPlatform: cookies.tpirates_app_platform,
          appToken: cookies.tpirates_app_fcm_token,
        });
        localStorage.setItem(
          'tpirates_app_fcm_token',
          cookies.tpirates_app_fcm_token
        );
        localStorage.setItem(
          'tpirates_app_platform',
          cookies.tpirates_app_platform
        );
      }
    }
  };

  return <>{viewHeader && <HeaderFixed session={session} />}</>;
};

export default connect(
  ({ [APP]: app, [SESSION]: session }) => ({ menus: app.menus, session }),
  {
    apptoken_session,
    check_session,
  }
)(HeaderContainer);

export { Head, HeadNew, HeadWithCloseButton };

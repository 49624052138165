import styled from 'styled-components';

const ShowMoreBox = ({
  children,
  hasNextPage,
  hideGradient = false,
  onClickShowMore,
  renderButton,
  style,
  text = '상품 더보기',
}) => {
  return (
    <Container>
      {children}
      <div style={style}>
        {hasNextPage && !hideGradient && <div className="gradient" />}

        {hasNextPage && (
          <ButtonWrapper>
            {renderButton || <Button onClick={onClickShowMore}>{text}</Button>}
          </ButtonWrapper>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  .gradient {
    height: 80px;
    position: absolute;
    bottom: 44px;
    width: 100%;
    background: transparent linear-gradient(181deg, #ffffff12 0%, #ffffff 100%)
      0% 0% no-repeat padding-box;
    pointer-events: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Button = styled.button`
  width: 100%;
  height: 44px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.newGray3};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;
export default ShowMoreBox;

import React from 'react';

const Kakao = ({ fill = '#432426', size = 20 }) => {
  return (
    <svg
      height={size}
      viewBox="0 0 18.092 16.705"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-25.692,85.632c-5,0-9.046,3.2-9.046,7.144a6.965,6.965,0,0,0,4.2,6.033l-.853,3.182a.265.265,0,0,0,.066.271.256.256,0,0,0,.184.075.259.259,0,0,0,.158-.06l3.665-2.476a11.473,11.473,0,0,0,1.629.119c4.994,0,9.047-3.2,9.047-7.145s-4.053-7.144-9.047-7.144"
        fill={fill}
        transform="translate(34.738 -85.632)"
      />
    </svg>
  );
};
export default React.memo(Kakao);

import React from 'react';
import styled from 'styled-components';

const SquareButton = ({ children, height = 29 }) => {
  return <StyledButton height={height}>{children}</StyledButton>;
};

export default React.memo(SquareButton);

const StyledButton = styled.button`
  padding: 4px 12px;
  color: #464c53;
  border: 1px solid #c9cdd2;
  background-color: #fff;
`;

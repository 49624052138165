import CommonDiscountChip from 'components/common/CommonDiscountChip';
import {
  DISCOUNT_TYPE_COUPON,
  DISCOUNT_TYPE_SPECIAL,
} from 'script/const/service-consts';
import { select } from 'script/util/common-utils';
import { getExclusiveInfoToOrderTypeMessage } from 'script/util/service-utils';
import styled from 'styled-components';

import PaymentItemList from '../payment-item-list';

const ProductBox = ({ product }) => {
  return (
    <Container>
      <InfoWrap>
        <ProductInfoWrap>
          <ChipWrap>
            {select(
              Object.values(product?.exclusiveInfo || {}).some((el) => el),
              <StateTag className="state-tag">
                {`${getExclusiveInfoToOrderTypeMessage(product?.exclusiveInfo).join(', ')} 전용 상품`}
              </StateTag>
            )}
            {select(
              product?.discountType === DISCOUNT_TYPE_COUPON,
              <CommonDiscountChip discountType={DISCOUNT_TYPE_COUPON} />
            )}
            {select(
              product?.discountType === DISCOUNT_TYPE_SPECIAL,
              <CommonDiscountChip discountType={DISCOUNT_TYPE_SPECIAL} />
            )}
          </ChipWrap>

          <h3>{product?.name || ''}</h3>
        </ProductInfoWrap>
        <Image src={product?.imageUrl || null} />
      </InfoWrap>

      <PaymentItemList product={product} />
    </Container>
  );
};

const Image = styled.div`
  width: 120px;
  min-width: 120px;
  height: 78px;
  border-radius: 8px;
  border: 1px solid var(--Gray-gray-1, #f7f8f9);
  background: ${(props) => `url(${props.src}) lightgray 50% / cover no-repeat`};
`;

const ProductInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    color: var(--Gray-gray-11, #26282b);
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    word-break: break-all;
  }
`;

const InfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
`;

const ChipWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StateTag = styled.div`
  display: flex;
  align-items: center;
  color: #1a1b1e;
  font-size: 11px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.3px;
  border-radius: 4px;
  background-color: #ecf6ff;
  width: fit-content;
  padding: 0 6px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default ProductBox;

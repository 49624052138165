import { usePopup } from 'components/hook';
import Icon from 'components/Icons/v2/Icon';
import {
  Container,
  ConvenienceStoreMapModal,
  SectionInnerText,
  SectionInnerTitle,
  SectionTitle,
} from 'components/mypage/myOrder/orderHistoryDetail';
import { ToastContext } from 'context/toast';
import { useContext } from 'react';
import styled from 'styled-components';

const PickUpStore = ({ pickupConvenience }) => {
  const [mapModalState, mapModalAction] = usePopup();
  const onOpenMapModal = () => {
    mapModalAction.open();
  };
  const onCloseMapModal = () => {
    mapModalAction.close();
  };

  const { showToast } = useContext(ToastContext);

  const copyAddress = (address) => {
    navigator.clipboard.writeText(address);
    showToast('주소가 복사되었습니다.');
  };

  return (
    <>
      <Container>
        <SectionTitleBox>
          <SectionTitle>픽업매장</SectionTitle>

          <DetailBox onClick={() => onOpenMapModal()}>
            <DetailTypo>상세보기</DetailTypo>
            <Icon color="#2F80ED" name="arrow_right-16_1" size={16} />
          </DetailBox>
        </SectionTitleBox>
        <Box>
          <SectionInnerTitle>매장명</SectionInnerTitle>
          <SectionInnerText>{pickupConvenience.addressDetail}</SectionInnerText>
        </Box>
        <Box>
          <SectionInnerTitle>매장주소</SectionInnerTitle>
          <SectionInnerText>{pickupConvenience.address}</SectionInnerText>

          <CopyBox onClick={() => copyAddress(pickupConvenience.address)}>
            <Icon color="#2F80ED" name="copy-20" />
            <CopyTypo>주소복사</CopyTypo>
          </CopyBox>
        </Box>
      </Container>

      <ConvenienceStoreMapModal
        address={pickupConvenience.address}
        location={{
          latitude: pickupConvenience.latitude,
          longitude: pickupConvenience.longitude,
        }}
        state={mapModalState}
        title={pickupConvenience.addressDetail}
        onClose={onCloseMapModal}
      />
    </>
  );
};

const CopyTypo = styled.div`
  overflow: hidden;
  color: var(--Blue-blue-9, #2f80ed);
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const CopyBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DetailTypo = styled.div`
  color: var(--new-blue-blue-9, #2f80ed);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

const DetailBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

const SectionTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export default PickUpStore;

import { createAction, handleActions } from 'redux-actions';

const LOAD_STARTED = 'pirates-state.load/BEGIN';
const LOAD_FINISHED = 'pirates-state.load/FINISHED';
const LOAD_FAILED = 'pirates-state.load/FAILED';

const SAVE_POINT = 'pirates-state.restore/SAVE-POINT';
const RESTORE_POINT = 'pirates-state.restore/RESTORE-POINT';

export const start_data_load = createAction(LOAD_STARTED, (target) => target);
export const finish_data_load = createAction(LOAD_FINISHED, (target) => target);
export const fail_data_load = createAction(LOAD_FAILED, (target) => target);

export const save_point = createAction(SAVE_POINT, (point, data) => ({
  data,
  point,
}));
export const restore_point = createAction(RESTORE_POINT, (point) => point);

const state = {
  load: null,
  restore: {},
};

const mode = handleActions(
  {
    [LOAD_FAILED]: (state, action) => ({
      ...state,
      load: {
        ...state.load,
        [action.payload]: 'failed',
      },
    }),
    [LOAD_FINISHED]: (state, action) => ({
      ...state,
      load: {
        ...state.load,
        [action.payload]: 'complete',
      },
    }),
    [LOAD_STARTED]: (state, action) => ({
      ...state,
      load: {
        ...state.load,
        [action.payload]: 'loading',
      },
    }),

    [RESTORE_POINT]: (state, action) => ({
      ...state,
      restore: {
        ...Object.keys(state.restore)
          .filter((k) => k !== action.payload)
          .reduce((acc, k) => ({ ...acc, [k]: state.restore[k] }), {}),
      },
    }),

    [SAVE_POINT]: (state, action) => ({
      ...state,
      restore: {
        ...state.restore,
        [action.payload.point]: action.payload.data,
      },
    }),
  },
  state
);

export default mode;

import PropTypes from 'prop-types';
import React from 'react';

import SearchContext, { Types } from '../_context';
import { _OptionTabItem } from './_tabs';

const { useEffect } = React;

const _OptionGroup = ({ action, label, mode, options, state, value }) => {
  const OPTION_GROUP = `${Types.OPTION}-${label.toUpperCase()}`;
  const _filter = state.filter;
  const _state_value = state.value[OPTION_GROUP];

  const _handleToggle = () => {
    if (_filter.type === OPTION_GROUP) {
      action.inactiveFilter(OPTION_GROUP);
    } else {
      action.activeFilter(OPTION_GROUP, { mode, options });
    }
  };

  const _get_label = (value) => {
    if (Array.isArray(value)) {
      const [last] = value.slice(-1);
      return last.label;
    }
    return value.label;
  };

  useEffect(() => {
    action.update({ [OPTION_GROUP]: Array.isArray(value) ? value : [value] });
  }, [value]);

  return (
    <_OptionTabItem
      on={_filter.type === null || _filter.type === OPTION_GROUP}
      open={_filter.type === OPTION_GROUP}
      selected={_get_label(_state_value || value)}
      onClick={_handleToggle}
    />
  );
};

const Container = ({ label, mode, options, reducer, value }) => {
  return (
    <SearchContext.Consumer>
      {({ action, state }) => (
        <_OptionGroup
          action={action}
          label={label}
          mode={mode}
          options={reducer(options)}
          state={state}
          value={value}
        />
      )}
    </SearchContext.Consumer>
  );
};

Container.defaultProps = {
  reducer: (options) => options,
};

Container.propTypes = {
  label: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['list', 'grid']).isRequired,
};

export default Container;

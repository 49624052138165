import React from 'react';

const ListView = ({ items, render }) => {
  return (
    <ul>
      {items.map((item, idx) => (
        <li key={idx}>{render(item, idx)}</li>
      ))}
    </ul>
  );
};

export default ListView;

const filterKeys = {
  all: ['market', 'filter'],
  getCoupons: ({ ...params }) => [...filterKeys.all, 'coupons', params],
  getFilteredCoupons: ({ ...params }) => [
    ...filterKeys.all,
    'coupons',
    'filtered',
    params,
  ],
  getPriceProduct: ({ globalKeyword, itemValue, serial, zoneValue }) => [
    ...filterKeys.priceAll,
    'product',
    zoneValue,
    itemValue,
    serial,
    globalKeyword,
  ],
  getPriceProductFilter: () => [...filterKeys.priceAll, 'group'],
  getPriceRecommend: () => [...filterKeys.priceAll, 'Recommend'],
  getProduct: () => [...filterKeys.all, 'product'],
  getRecommendStores: ({ ...params }) => [
    ...filterKeys.all,
    'recommend',
    params,
  ],
  getRegion: () => [...filterKeys.priceAll, 'Region'],
  getStores: ({ ...params }) => [...filterKeys.all, 'sorted', params],
  getWholeSaleSearch: ({ keyword }) => [
    ...filterKeys.all,
    'wholesale',
    keyword,
  ],
  getWholesale: () => [...filterKeys.wholesaleAll, 'filter'],
  getWholesaleList: ({ productsCode, searchKeyword }) => [
    ...filterKeys.wholesaleAll,
    'sortedList',
    productsCode,
    searchKeyword,
  ],
  getZone: () => [...filterKeys.all, 'zone'],
  priceAll: ['price', 'filter'],
  wholesaleAll: ['wholesale', 'filter'],
};

export default filterKeys;

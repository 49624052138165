import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveCoupon } from 'api/coupon';
import couponListKeys from 'hooks/Query/CouponList/keys';
import filterKeys from 'hooks/Query/Filter/keys';
import storeDetailKeys from 'hooks/Query/StoreDetail/keys';
import { useRecoilValue } from 'recoil';
import { globalStoresFilter } from 'recoil/price';
import { globalSearchKeyword } from 'recoil/search';

export default function useSaveCoupon(storeCode, id) {
  const queryClient = useQueryClient();

  const keyword = useRecoilValue(globalSearchKeyword);
  const filter = useRecoilValue(globalStoresFilter);
  const category = filter?.product[0]?.category
    ? filter.product[0]?.categoryCode
    : null;
  const itemName = filter?.product[0]?.category
    ? null
    : filter.product[0]?.itemName;
  const deliveryList =
    filter?.orderType?.map((item) => item.value)?.join(',') || null;
  const latitude = filter?.around[0] ? filter.around[0].lat : null;
  const longitude = filter?.around[0] ? filter.around[0].long : null;
  const marketName =
    filter?.location?.map((item) => item.marketName)?.join(',') || null;
  const params = {
    category,
    deliveryList,
    itemName,
    keyword,
    latitude,
    longitude,
    marketName,
  };

  return useMutation(saveCoupon, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(
          storeDetailKeys.getStoreCoupons({
            storeCode,
          })
        );
        queryClient.invalidateQueries(filterKeys.getCoupons(params));
        queryClient.invalidateQueries(couponListKeys.getCouponDetail(id));
      }, 200);
    },
  });
}

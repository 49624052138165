import React from 'react';
import styled from 'styled-components';

import { Circular } from '../spinners';

/**
 * @typedef {object} IProps
 * @property {string} color
 * @property {boolean} isLoading
 *
 * @param {IProps & React.ButtonHTMLAttributes<HTMLButtonElement>} props
 */
const LoadingButton = (props) => {
  const {
    children,
    color = '#fff',
    isLoading,
    type = 'button',
    ..._props
  } = props;

  return (
    <Button {..._props} type={type} disabled={isLoading} $color={color}>
      {isLoading ? <Circular /> : children}
    </Button>
  );
};

const Button = styled.button`
  background-color: transparent;
  color: ${({ $color }) => $color};
  cursor: pointer;
  flex: 1;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  padding: 0;
`;

export default LoadingButton;

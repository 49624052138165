const storeDetailKeys = {
  all: ['storeDetail', 'store'],
  createPaymentPage: (payId) => [...storeDetailKeys.all, 'paymentPage', payId],
  getKeywords: (storeCode) => [...storeDetailKeys.all, 'keywords', storeCode],
  getNotices: ({ storeCode, type }) => [
    ...storeDetailKeys.all,
    'notices',
    type,
    storeCode,
  ],
  getProduct: (productId) => [...storeDetailKeys.all, 'product', productId],
  getProducts: ({ keywordId, storeCode }) => [
    ...storeDetailKeys.all,
    keywordId,
    storeCode,
    'products',
  ],
  getRecent: (storeCodeList) => [
    ...storeDetailKeys.all,
    'recent',
    ...storeCodeList,
  ],
  getRecommendReviews: (storeCode) => [
    ...storeDetailKeys.all,
    'recommendReview',
    storeCode,
  ],
  getReviews: (storeCode) => [...storeDetailKeys.all, 'reviews', storeCode],
  getShippingMethod: (storeCode) => [
    ...storeDetailKeys.all,
    'shipping-method',
    storeCode,
  ],
  getStoreCoupons: ({ storeCode }) => [
    ...storeDetailKeys.all,
    'coupons',
    storeCode,
  ],
  getStoreDetail: (store) => [...storeDetailKeys.all, store, 'detail'],
  getStoreLocation: (storeCode) => [
    ...storeDetailKeys.all,
    'location',
    storeCode,
  ],
  getStorePrices: (storeCode) => [...storeDetailKeys.all, 'prices', storeCode],
  getStores: (storeCode) => [...storeDetailKeys.all, 'products', storeCode],
  getTourArticle: (storeCode) => [
    ...storeDetailKeys.all,
    'tourArticle',
    storeCode,
  ],
};

export default storeDetailKeys;

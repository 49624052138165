import './style.css';

import { useContext, useState } from 'react';
import styled from 'styled-components';

import LoginContext, { View } from './login/_context';

const Footer = () => {
  const [expand, setExpand] = useState(false);
  const { action } = useContext(LoginContext);

  const onClickService = () => action.open(View.CONTRACT_SERVICE_TMP);
  const onClickPrivacy = () => action.open(View.CONTRACT_PRIVACY_TMP);
  const onClickLocation = () => action.open(View.CONTRACT_LOCATION_TMP);

  return (
    <div className="foot rule-area">
      <Span onClick={onClickService}>이용약관</Span> ∙{' '}
      <Span style={{ fontWeight: '500' }} onClick={onClickPrivacy}>
        개인정보처리방침
      </Span>{' '}
      ∙ <Span onClick={onClickLocation}>위치기반서비스이용약관</Span>
      <div className="copyright" onClick={() => setExpand((p) => !p)}>
        (주)더파이러츠{expand ? '' : ''}
      </div>
      {expand && (
        <div className="foot-expand">
          <div className="foot-expand-line">
            <strong>상호</strong>
            <span>주식회사 더파이러츠(THE PIRATES)</span>
          </div>
          <div className="foot-expand-line">
            <strong>대표자</strong>
            <span>윤기홍</span>
          </div>
          <div className="foot-expand-line">
            <strong>소재지</strong>
            <span>
              서울특별시 영등포구 선유로 146, 614호(양평동3가, 이앤씨드림타워)
            </span>
          </div>
          <div className="foot-expand-line">
            <strong>전화번호</strong>
            <span>02-2068-3241</span>
          </div>
          <div className="foot-expand-line">
            <strong>email</strong>
            <span>mkt@tpirates.com</span>
          </div>
          <div className="foot-expand-line">
            <strong>사업자등록번호</strong>
            <span>116-86-00992</span>
          </div>
          <div className="foot-expand-line">
            <strong>통신판매업</strong>
            <span>2018-서울영등포-0160</span>
          </div>
        </div>
      )}
    </div>
  );
};

const Span = styled.span`
  cursor: pointer;
`;

export default Footer;

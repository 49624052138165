import styled, { css } from 'styled-components';

import { ButtonBase } from '.';

const IconButton = styled(ButtonBase)`
  font-size: 0;
  padding: 0;

  ${({ size = 'default' }) => {
    switch (size) {
      case 'large':
        return css`
          height: 48px;
          width: 48px;
        `;
      case 'medium':
        return css`
          height: 32px;
          width: 32px;
        `;
      default:
        return css`
          height: 24px;
          width: 24px;
        `;
    }
  }}

  & svg {
    display: inline-block;
    vertical-align: middle;
  }
`;

export default IconButton;

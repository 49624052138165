import getAccountUrl from 'api/getAccountUrl';
import { Container } from 'components/common';
import Document from 'components/common/document';
import { HeadNew } from 'components/common/header';
import { CloseThin } from 'components/Icons';
import { Section } from 'components/section';
import LabelList from 'components/templates/Favorite/LabelList';
import { conf } from 'conf/settings';
import { SESSION } from 'context';
import { check_session } from 'context/global/session';
import ModalProvider from 'context/modal';
import { useMyLikes } from 'hooks/Query/MyPage/useMyLikes';
import xImgGray from 'img/icon_close_gray.svg';
import { memo, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Recommended from '../../components/recommends';
import * as store from '../../components/stores';
import {
  delete_content_like,
  delete_retail_like,
  delete_store_like,
  get_my_likes,
} from '../../context/mypage';
import { ToastContext } from '../../context/toast';
import EmptyPage from './_empty_page';
const Product = memo(({ items, removeItem }) => {
  return (
    <ProductStyle>
      {items.map((v, i) => (
        <li
          key={i}
          onClick={() => {
            window.location.href = v.link;
          }}
        >
          <figure>
            <img alt="" src={v.thumbnail} />
            {/* <span>Sale</span> */}
          </figure>
          <div className="text-content-wrap">
            <div className="text-content">
              <p>{v.subject}</p>
              <div>
                <strong>{v.price}</strong>
                {/* <span>53531</span> */}
              </div>
            </div>
          </div>

          <DeleteBtn
            onClick={(e) => {
              e.stopPropagation();
              removeItem(v, 'retail');
            }}
          >
            <div>
              <CloseThin />
            </div>
          </DeleteBtn>
        </li>
      ))}
    </ProductStyle>
  );
});

const DeleteBtn = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  position: absolute;
  top: 5px;
  right: 6px;
`;

const ProductStyle = styled.ul`
  & * {
    color: #26282b;
    border: none;
  }
  & > li {
    display: flex;
    height: 96px;

    position: relative;
    border: 1px solid #c9cdd2;
    border-radius: 5px;

    overflow: hidden;
    cursor: pointer;

    & + li {
      margin-top: 12px;
    }

    & > * {
      /* height: 100%; */
      margin: 0;
      box-sizing: border-box;
    }
    & > figure {
      position: relative;
      flex: 0 0 96px;
      & > img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
      & > span {
        position: absolute;
        top: 0;
        left: 0;

        width: 45px;
        height: 24px;
        line-height: 24px;

        color: #fff;
        font-size: 12px;
        text-align: center;

        border-radius: 0 0 5px 0;
        background: #de2222;
      }
    }
    & > .text-content-wrap {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;
      position: relative;
      flex: 1;
      padding: 14px 0 14px 16px;
      & > .text-content {
        & > p {
          width: calc(100% - 20px);
          font-size: 14px;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        & > div {
          display: flex;
          align-content: center;

          margin-top: 2px;

          & > strong {
            font-size: 16px;
            font-weight: 500;
          }
          & > span {
            margin-left: 6px;
            font-size: 14px;
            color: #9fa4aa;
            text-decoration: line-through;
          }
        }
      }
      & > img {
        position: absolute;
        top: 16px;
        right: 16px;

        width: 13px;
        height: 13px;

        filter: invert(49%) sepia(10%) saturate(314%) hue-rotate(172deg)
          brightness(92%) contrast(86%);

        cursor: pointer;
        &:hover {
          filter: none;
        }
      }
    }
  }
`;

const MyLike = memo(
  ({
    check_session,
    delete_content_like,
    delete_retail_like,
    delete_store_like,
    get_my_likes,
    state,
  }) => {
    const { data, refetch } = useMyLikes();
    const contents = data?.contents ?? [];
    const market = data?.market ?? [];
    const shopping = data?.shopping ?? [];

    const history = useHistory();
    const { showToast } = useContext(ToastContext);
    const [category, setCategory] = useState('store');
    const deleteLike = async (item, type) => {
      const filterItem = {
        favorite: 'on',
        id: item.id,
      };
      switch (type) {
        case 'store':
          await delete_store_like(filterItem, 1);
          showToast('내 즐겨찾기 가게 목록에서 삭제되었습니다.');
          break;
        case 'content':
          await delete_content_like(filterItem);
          showToast('내 즐겨찾기 콘텐츠 목록에서 삭제되었습니다.');
          break;
        case 'retail':
          await delete_retail_like(filterItem);
          showToast('내 즐겨찾기 상품 목록에서 삭제되었습니다.');
          break;
        default:
          break;
      }
      refetch();
    };

    useEffect(() => {
      check_session()
        .then((r) => {
          if (!r?.identity) {
            window.location.href = getAccountUrl({
              redirectUrl: `${conf.FISH_MARKET_URL}/마이페이지/내관심정보`,
            });
            history.replace('/');
          }
        })
        .catch(() => {
          history.replace('/');
        });
      get_my_likes();
    }, []);

    useEffect(() => {
      sessionStorage.setItem('mainPath', '/마이페이지/내관심정보');
    }, []);

    return (
      <>
        <Document.Head title="즐겨찾기 | 마이페이지" type="single" />
        <ModalProvider>
          <Container bg="#fff">
            <HeadNew label="즐겨찾기" />
            <MyLikeStyle
              className="main my-page"
              style={{ paddingTop: '56px' }}
            >
              <div className="select-review-category">
                <span
                  className={category === 'store' ? `ON` : ``}
                  onClick={() => setCategory('store')}
                >
                  가게
                </span>
                <span
                  className={category === 'label' ? `ON` : ``}
                  onClick={() => setCategory('label')}
                >
                  품목
                </span>
                <span
                  className={category === 'product' ? `ON` : ``}
                  onClick={() => setCategory('product')}
                >
                  상품
                </span>
                <span
                  className={category === 'content' ? `ON` : ``}
                  onClick={() => setCategory('content')}
                >
                  콘텐츠
                </span>
              </div>

              {category === 'store' && (
                <Section type="board">
                  <store.List
                    items={market}
                    removeItem={deleteLike}
                    scale="reqular"
                    version="new"
                    my_like
                    remove
                  />
                </Section>
              )}
              {category === 'label' && (
                <Section type="board">
                  <LabelList />
                </Section>
              )}
              <div className="content-wrap">
                {category === 'product' && (
                  <Section type="board">
                    {shopping?.length !== 0 ? (
                      <Product items={shopping} removeItem={deleteLike} />
                    ) : (
                      <EmptyPage />
                    )}
                  </Section>
                )}
                {category === 'content' && (
                  <Section type="board">
                    {contents?.length !== 0 ? (
                      <Recommended
                        items={contents}
                        removeItem={deleteLike}
                        type="contents"
                        remove
                      />
                    ) : (
                      <EmptyPage />
                    )}
                  </Section>
                )}
              </div>
            </MyLikeStyle>
          </Container>
        </ModalProvider>
      </>
    );
  }
);

const MyLikeStyle = styled.div`
  & * {
    box-sizing: border-box;
  }
  & > .select-review-category {
    display: flex;
    padding: 0 16px;

    justify-content: space-between;

    border-bottom: 1px solid #e8ebed;
    background: #fff;
    & > span {
      position: relative;
      padding: 11px 16px;
      height: 100%;
      align-items: center;
      line-height: 20px;
      text-align: center;
      color: #9fa4aa;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;

      &.ON {
        color: #26282b;
        font-weight: 500;
        &::after {
          display: block;
          content: '';

          position: absolute;
          bottom: -1px;
          left: 0;

          width: 100%;
          height: 2px;
          background: #26282b;
        }
      }
    }
  }

  & > a {
    & > .card-template.type1 {
      margin-top: 0;
      height: 132px;

      border-bottom: 1px solid #e8ebed;

      & > .close_img {
        top: 10px;
        right: 16px;
        width: 18px;
        height: 18px;
        filter: invert(45%) sepia(70%) saturate(61%) hue-rotate(173deg)
          brightness(88%) contrast(82%);
      }

      & > .fixed.inner-section {
        &.type1 {
          /* margin-left: 116px; */

          & > .inner-inner-section {
            &.type2 {
              & > span {
                &.name.txt-ellipsis {
                  width: calc(100% - 24px);

                  font-size: 16px;
                  color: #26282b;
                  & > .market {
                    color: #72787f;
                  }
                }
                &.description.tag {
                  margin-top: 8px;
                  & > ul {
                    & > li {
                      border-radius: 20px;
                      background: #e8ebed;
                      color: #26282b;
                    }
                  }
                }
              }
            }
            &.type3 {
              & > span {
                &.business-status {
                }
                &.summary {
                  & > .rating {
                    color: #26282b;
                    & > i {
                      color: #ffcc33;
                    }
                  }
                }
              }
            }
          }
        }
        &.type2 {
          width: 116px;
          height: 116px;
        }
      }
    }
    /* & + a {
        & > .card-template.type1 {
          margin-top: 8px;
        }
      } */
  }

  & > .content-wrap {
    margin-top: 24px;
    padding: 0 16px;
    overflow: hidden;
    & > .section-title {
      & > h3 {
        padding: 16px 0 !important;

        font-size: 16px !important;
        font-weight: 600 !important;
        color: #26282b !important;

        & > span {
          margin-left: 4px;
          font-size: 12px;
          line-height: 22px;
          color: #72787f;
        }
      }
    }

    & .card-template {
      &.type12 {
        display: flex;
        flex-flow: column;

        float: left;
        width: calc(50% - 6px);

        border: 1px solid #c9cdd2;
        border-radius: 5px;

        & > .fixed.inner-section.type1 {
          width: 100%;
          /* flex: 0 0 74px; */
          & > a {
            display: block;
            margin: 0px;
            width: 110%;
            & span.img-block {
              margin: 0px;
              width: 105%;
              height: 74px;
              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          &.description {
            flex: 0 0 calc(132px - 74px);
            margin: 0;
            display: flex;
            align-content: center;

            & > a {
              & > .inner-inner-section.type2 {
                padding: 0 8px;
                & > span {
                  font-size: 14px;
                }
              }
            }
          }
        }
        & > img {
          width: 14px;
          height: 14px;
          background: url(${xImgGray}) no-repeat center center;
          background-size: 36px 36px;
        }
      }
      &:nth-of-type(2n) {
        margin-left: 12px;
      }
      &:nth-of-type(n + 3) {
        margin-top: 12px;
      }
      /* & + .card-template {
        margin-top: 8px;
      } */
    }
  }
`;

export default connect(
  ({ mypage, [SESSION]: session }) => ({ session, state: mypage }),
  {
    check_session,
    delete_content_like,
    delete_retail_like,
    delete_store_like,
    get_my_likes,
  }
)(MyLike);

import Icon from 'components/Icons/v2/Icon';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { ModalView } from '../views';

const Modal = ({
  children,
  onClose,
  overflowNotFunction,
  state,
  title,
  type,
}) => {
  return (
    <ModalView
      overflowNotFunction={overflowNotFunction}
      style={{
        paddingBottom: '10vh',
        top: isMobile ? '16px' : '10vh',
      }}
      toggle={state.popup}
    >
      <div className="fixed modal-coupon">
        <HeadBox>
          <Title>{title}</Title>
          <Icon
            color={type === 'home' ? '#2F80ED' : '#464C53'}
            name="close-24_2"
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </HeadBox>
        {children}
      </div>
    </ModalView>
  );
};

const Title = styled.div`
  overflow: hidden;
  color: var(--new-gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.3px;
`;

const HeadBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 0 16px;
`;

export default Modal;

import ConvenienceIcon from 'components/Icons/Temp/ConvenienceIcon';
import PackageIcon from 'components/Icons/Temp/PackageIcon';
import QuickDeliveryIcon from 'components/Icons/Temp/QuickDeliveryIcon';
import TodayDeliveryIcon from 'components/Icons/Temp/TodayDeliveryIcon';

export const getOrderTypeIcon = (orderType) => {
  switch (orderType) {
    case 'QUICK':
      return <QuickDeliveryIcon />;
    case 'PACKAGE':
      return <PackageIcon />;
    case 'DAY_DELIVERY':
      return <TodayDeliveryIcon />;
    case 'CONVENIENCE_STORE':
      return <ConvenienceIcon />;
  }
};

import CouponModalNew from 'components/coupons/_coupon_modal_new';
import { usePopup } from 'components/hook';
import { createContext } from 'react';

const ModalContext = createContext({});

const ModalProvider = ({ children, closeModal, session, type }) => {
  const [popupState, popupAction] = usePopup();

  const onCloseCoupon = () => {
    popupAction.close();
    closeModal();
  };

  const showModal = (data) => {
    popupAction.open(data);
  };

  return (
    <ModalContext.Provider value={{ showModal }}>
      {children}
      <CouponModalNew
        session={session}
        state={popupState}
        type={type}
        onClose={onCloseCoupon}
      />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
export { ModalContext };

import client from './client';

/**
 * @param {object} params
 * @param {string} params.storeCode
 * @param {string} params.orderType
 * @returns {Promise}
 */
export const getReservationDate = (params) =>
  client.get('/www/quick/orderAvailableDates', {
    params,
  });

/**
 * @param {object} params
 * @param {string} params.storeCode
 * @returns {Promise}
 */
export const getPackageReservationDate = (params) =>
  client.get('/www/quick/reservationSlot/package', {
    params,
  });

/**
 * @param {object} params
 * @param {string} params.storeCode
 * @param {string} params.address
 * @returns {Promise}
 */
export const getQuickReservationDate = (params) =>
  client.get('/www/quick/reservationSlot/quick', {
    params,
  });

import React from 'react';

const ScoreView = ({ score, onScores }) => {
  return (
    <div
      style={{ display: score === -1 ? 'none' : '' }}
      className="rating-area"
    >
      <ul className="rating">
        {[1, 2, 3, 4, 5].map((num, i) => (
          <li
            key={i}
            className={num <= score ? 'fill' : ''}
            style={{ cursor: 'pointer' }}
          >
            <em
              className="img-bul star"
              onClick={(e) => onScores(score === 1 && num === 1 ? 0 : num)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const ScoreViewNew = ({ score, onScores }) => {
  return (
    <>
      {score !== -1 ? (
        <div className="rating-area">
          <ul className="rating">
            {[1, 2, 3, 4, 5].map((num, i) => (
              <i
                key={i}
                style={{
                  color: num <= score ? '#FFCC33' : '#C9CDD2',
                }}
                className={`fas fa-star`}
                onClick={(e) => onScores(score === 1 && num === 1 ? 0 : num)}
              ></i>
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export { ScoreViewNew };
export default ScoreView;

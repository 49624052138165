import client from 'api/client';
let cancel;
export const get_coupons = (query = {}) => {
  try {
    if (cancel) {
      cancel();
    }

    const _options = {
      ...{
        keyword: null,
        region: { place: null, zone: null },
        store: null,
      },
      ...query,
    };

    return client.get(`/www/coupons`, {
      params: {
        // keyword: _options.keyword,
        keyword: _options.searchKeyword,

        marketCode: _options.region.place,

        storeCode: _options.store,

        zoneCode: _options.region.zone,
      },
      // cancelToken: new CancelToken(function executor(c) {
      //   cancel = c;
      // }),
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const get_coupons_all = async (query = {}, params) => {
  const _options = {
    ...{
      keyword: null,
      region: { place: null, zone: null },
      store: null,
    },
    ...query,
  };

  const data = await client.get(`/www/coupons`, {
    params: params || {
      keyword: _options.keyword,
      marketCode: _options.region.place,
      storeCode: _options.store,
      type: 'all',
      zoneCode: _options.region.zone,
    },
  });
  return { data };
};

export const save_coupon = (id) => client.get(`/www/my/coupon/${id}/save`);

export const getCoupons = (params) =>
  client.get('/www/coupons', { params }).then((res) => res.data);

export const getFilteredCoupons = (params) =>
  client.get('/www/coupons/filtered', { params }).then((res) => res.data);

export const getCouponDetail = (id) =>
  client.get(`/www/coupons/${id}`).then((res) => res.data);

export const getMyCoupons = (params) =>
  client.get('/www/my/coupons', { params }).then((res) => res.data);

export const saveCoupon = (id) =>
  client.get(`/www/my/coupon/${id}/save`).then((res) => res.data);

import { report_review_block_user } from 'api/review';
import CustomAlert from 'components/common/customAlert';
import { set_reply_report } from 'context/review';
import React from 'react';
import { useSelector } from 'react-redux';

const BlockWrap = ({ dispatch, replyReport, showToast }) => {
  const comments = useSelector((state) => state);

  const blockAgree = {
    message: '이 회원이 쓴 후기는\n앞으로 보이지 않습니다.',
    options: [
      {
        callback: () => {
          dispatch(set_reply_report(false));
        },
        label: '취소',
      },
      {
        callback: () => {
          report_review_block_user({
            memberCode: replyReport.writer.id,
            type: 'BLOCK',
          })
            .then(({ data }) => {
              if (data?.code === 'ok') {
                if (comments['content.view'].reviews) {
                  comments['content.view'].reviews.forEach((review) => {
                    if (replyReport.writer.id === review.writer.id) {
                      review.blind.blocked = true;
                    }
                    for (const reply of review.replies || []) {
                      if (replyReport.writer.id === reply.writer.id) {
                        reply.blind.blocked = true;
                      }
                    }
                  });
                }
                if (comments['store.view'].review.regular) {
                  comments['store.view'].review.regular.forEach((review) => {
                    if (replyReport.writer.id === review.writer.id) {
                      review.blind.blocked = true;
                    }
                    for (const reply of review.replies || []) {
                      if (replyReport.writer.id === reply.writer.id) {
                        reply.blind.blocked = true;
                      }
                    }
                  });
                }
                dispatch(
                  set_reply_report({
                    reportAgree: true,
                    reportType: 'blockComplete',
                  })
                );
              }
            })
            .catch((err) => {
              const errCode = err?.response?.data?.status;
              if (errCode === 409)
                dispatch(
                  set_reply_report({
                    reportAgree: true,
                    reportType: 'blockAlready',
                  })
                );
            });
        },
        label: '확인',
      },
    ],
    title: '차단하시겠어요?',
  };

  return <CustomAlert type="button" visible={blockAgree} />;
};

export default BlockWrap;

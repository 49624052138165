/**
 *
 * @param {string} url
 */
export const androidWindowOpen = (url) => {
  if (
    navigator.userAgent.indexOf('tpirates') >= 0 &&
    navigator.userAgent.indexOf('Android') >= 0
  ) {
    window.location.href = url;
  } else {
    window.open(url);
  }
};

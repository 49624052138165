import styled from 'styled-components';

/**
 * @typedef {object} StyledInputProps
 * @property {boolean} error
 */

/**
 * @type {import('styled-components').StyledComponent<'div', import('styled-components').DefaultTheme, StyledInputProps>}
 */
const StyledInput = styled.input`
  background-color: ${({ theme, error }) =>
    error ? theme.white : theme.gray10};
  border: 2px solid
    ${({ error, theme }) => (error ? theme.red2 : 'transparent')};
  border-radius: 5px !important;
  padding: 10px 12px;
  position: relative;
  width: 100%;
  transition: 0.05s background-color ease-in;

  &::placeholder {
    color: ${({ theme }) => theme.gray300};
  }
  &:focus-visible {
    border: 2px solid
      ${({ error, theme }) => (error ? theme.red2 : theme.blue2)};
    background-color: white;
  }
`;

export default StyledInput;

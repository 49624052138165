import client from 'api/client';

import { update_view_or_like } from './global';

export const get_retail_recommends = () =>
  client.get('2/www/retail/items/recommend');

export const get_retails = () => client.get('2/www/retail/items/home');

export const search_retails = ({ code, keyword }) =>
  client.get('2/www/retail/items/search', {
    params: { keyword, shoppingItemCode: code },
  });

export const toggle_retail_like = (item) =>
  update_view_or_like(item, { contentsType: 8, type: 'like' });

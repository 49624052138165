export const BUILD_TYPE_LOCAL = 'local';
export const BUILD_TYPE_DEVELOPMENT = 'dev';
export const BUILD_TYPE_STAGING = 'stg';
export const BUILD_TYPE_PRODUCTION = 'prod';

export const getBuildType = () => {
  if (process.env.REACT_APP_ENV === BUILD_TYPE_PRODUCTION) {
    return BUILD_TYPE_PRODUCTION;
  }
  if (process.env.REACT_APP_ENV === BUILD_TYPE_STAGING) {
    return BUILD_TYPE_STAGING;
  }
  if (process.env.REACT_APP_ENV === BUILD_TYPE_DEVELOPMENT) {
    return BUILD_TYPE_DEVELOPMENT;
  }
  return BUILD_TYPE_LOCAL;
};

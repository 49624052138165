import { Box } from 'components/atoms';
import CryingPirates from 'components/Icons/CryingPirates';
import { conf } from 'conf/settings';

const NotFound = ({ message = <>요청하신 페이지를 찾을 수 없습니다.</> }) => {
  return (
    <Box
      alignItems="center"
      backgroundColor="white"
      flexDirection="column"
      height="100vh"
      justifyContent="center"
    >
      <Box>
        <CryingPirates />
      </Box>
      <Box
        color="gray500"
        fontSize="18px"
        letterSpacing="-0.3px"
        lineHeight="26px"
        marginTop="16px"
      >
        {message}
      </Box>
      <Box
        boxSizing="border-box"
        justifyContent="center"
        marginTop="24px"
        padding="0 48px"
        width="100%"
      >
        <Box
          alignItems="center"
          as="button"
          backgroundColor="white"
          border="1px solid #2F80ED"
          borderRadius="5px"
          color="#2F80ED"
          fontSize="14px"
          fontWeight="500"
          height="50px"
          justifyContent="center"
          letterSpacing="-0.3px"
          lineHeight="20px"
          style={{ cursor: 'pointer' }}
          width="168px"
          onClick={() => {
            window.location.href = `${conf.FISH_MARKET_URL}`;
          }}
        >
          홈으로 돌아가기
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;

import { yupResolver } from '@hookform/resolvers/yup';
import client from 'api/client';
import { Box } from 'components/atoms';
import FormInput from 'components/molecules/forms/FormInput';
import FormInputNumber from 'components/molecules/forms/FormInputNumber';
import { updateNotification } from 'context/store/view';
import { useToast } from 'hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';

const scheme = yup.object().shape({
  name: yup.string().required('이름을 입력해 주세요.'),
  phoneNumber: yup.string().required('전화번호를 입력해 주세요.'),
});

const NotificationRequestContent = ({ data, onClose }) => {
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(scheme),
    shouldUnregister: true,
  });
  const { id: userId } = useSelector((state) => state['global.session']);
  const dispatch = useDispatch();
  const showToast = useToast();

  const { name, phoneNumber } = useSelector((state) => state.profile);

  useEffect(() => {
    setValue('name', name || '');
    setValue('phoneNumber', phoneNumber || '');
  }, [name, phoneNumber, setValue]);

  return (
    <>
      <StyledDiv style={{ marginTop: '24px' }}>
        <p>현재 시세 조사 중으로 구매가 불가능합니다.</p>
        <p>&apos;알림받기&apos;를 하시면 가능한 시점에 알림이 발송됩니다.</p>
      </StyledDiv>
      <StyledDiv>
        <p>당일 시세조사 결과에 따라</p>
        <p>메뉴가 변동될 수 있습니다.</p>
      </StyledDiv>

      <StyledBox
        as="form"
        flexDirection="column"
        id="notification-request"
        marginTop="24px"
        onSubmit={handleSubmit((fieldValues) => {
          client
            .post('/www/shell-fish-notifications', {
              memberCode: userId,
              name: fieldValues.name,
              phone: fieldValues.phoneNumber,
              storeCode: data?.store.id,
            })
            .then(() => {
              dispatch(updateNotification());
              onClose();
            })
            .catch((err) => {
              showToast(err.response?.data.message || err.message);
            });
        })}
      >
        <FormInput
          control={control}
          name="name"
          placeholder="이름"
          type="text"
        />
        <FormInputNumber
          control={control}
          name="phoneNumber"
          placeholder="전화번호"
          type="tel"
        />
      </StyledBox>

      <Box
        as="p"
        display="block"
        fontSize="14px"
        marginTop="24px"
        textAlign="center"
      >
        위 번호로 알림을 받으시겠습니까?
      </Box>

      <div style={{ height: 56 }}>
        <ButtonWrapper>
          <Box
            as="button"
            backgroundColor="gray10"
            display="block"
            style={{ cursor: 'pointer' }}
            type="button"
            onClick={onClose}
          >
            받지않기
          </Box>
          <Box
            as="button"
            backgroundColor="blue2"
            color="white"
            display="block"
            form="notification-request"
            style={{ cursor: 'pointer' }}
            type="submit"
          >
            알림받기
          </Box>
        </ButtonWrapper>
      </div>
    </>
  );
};

const StyledBox = styled(Box)`
  & > input[placeholder='전화번호'] {
    margin-top: 12px;
  }
`;

const StyledDiv = styled.div`
  word-break: keep-all;
  & > p {
    text-align: center;
  }
  & + & {
    margin-top: 24px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 56px;

  & > button {
    flex: 1;
  }
`;

export default NotificationRequestContent;

import * as filter_api from 'api/search-filters';
import * as wholesale_api from 'api/wholesale';
import { handleActions } from 'redux-actions';

import createApiRequest from '../_support';

const FILTER = 'pirates-data.biz/FILTER';
const SEARCH = 'pirates-data.biz/SEARCH';
const SAVE_QUERY = 'pirates-data.biz/SAVE_QUERY';
const LOAD_DATA = 'pirates-data.biz/LOAD_DATA';

const [get_filters, FILTER_OK, FILTER_FAIL] = createApiRequest(
  FILTER,
  filter_api.load_wholesale_item_codes
);
const [get_wholesale_items, SEARCH_OK, SEARCH_FAIL] = createApiRequest(
  SEARCH,
  wholesale_api.search_wholesale_items
);
const [save_query, SAVE_QUERY_OK, SAVE_QUERY_FAIL] = createApiRequest(
  SAVE_QUERY,
  (query) => ({ data: query })
);
const [load_data, LOAD_DATA_OK, LOAD_DATA_FAIL] = createApiRequest(
  LOAD_DATA,
  (data) => ({ data })
);

const state = {
  filter: [],
  items: [],
  query: false,
};

const reducer = handleActions(
  {
    [FILTER]: (state) => state,
    [FILTER_OK]: (state, action) => ({
      ...state,
      filter: action.payload,
    }),

    [LOAD_DATA]: (state) => ({ ...state }),
    [LOAD_DATA_OK]: (state, action) => ({
      ...action.payload,
    }),

    [SAVE_QUERY]: (state) => ({ ...state }),
    [SAVE_QUERY_OK]: (state, action) => ({
      ...state,
      query: action.payload,
    }),

    [SEARCH]: (state) => state,
    [SEARCH_OK]: (state, action) => ({
      ...state,
      items: action.payload,
    }),
  },
  state
);

const Types = {
  SEARCH,
};

export { get_filters, get_wholesale_items, load_data, save_query, Types };

export default reducer;

import FixedCover from 'components/modules/FixedCover';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Normal = ({
  children,
  footer,
  noOverflow = false,
  onClose,
  padding,
  visible,
  width,
}) => {
  const onStopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  if (!visible) {
    return null;
  }

  return createPortal(
    <FixedCover noOverflow={noOverflow} visible={visible} onClose={onClose}>
      <Wrapper width={width} onClick={onStopPropagation}>
        <Main padding={padding}>{children}</Main>
        {footer}
      </Wrapper>
    </FixedCover>,
    document.getElementById('__portal')
  );
};

const Main = styled.div`
  padding: ${({ padding = '20px' }) => padding};
`;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  bottom: 50%;
  width: ${({ width = 'auto' }) => width};
  left: ${({ width }) => (width ? '50%' : '24px')};
  right: 24px;
  max-height: 100%;
  overflow: auto;
  position: absolute;
  transform: translateY(50%);
  transform: ${({ width }) =>
    width ? 'translate(-50%,30%)' : ' translateY(50%)'};
  z-index: 61;
`;

export default Normal;

import { createSlice } from '@reduxjs/toolkit';

import { fetchProfile } from './thunks';

const initialState = {
  /** @deprecated */
  address: '',

  /** @deprecated */
  addressDetail: '',

  isError: false,

  isLoading: true,
  isValid: false,
  name: '',
  phoneNumber: '',
  temporary: false,
};
const profile = createSlice({
  extraReducers: {
    [fetchProfile.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [fetchProfile.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;

      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    [fetchProfile.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
  initialState,
  name: 'profile',
  reducers: {
    setProfile(state, { payload }) {
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

export { fetchProfile };
export const { setProfile } = profile.actions;
export default profile.reducer;

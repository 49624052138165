import { useQuery } from '@tanstack/react-query';
import { getContentsByRecommendQuote } from 'api/Prices';
import { convertMinToMS } from 'utils/convertTimeToMS';

import priceKeys from './keys';

export default function useGetContentsByRecommendQuote({ id }) {
  return useQuery(
    priceKeys.getContentsByRecommendQuote({ id }),
    () => getContentsByRecommendQuote({ id }),
    {
      enabled: !!id,
      staleTime: convertMinToMS(10),
    }
  );
}

import styled from 'styled-components';

/**
 * @typedef {object} IProps
 * @property {boolean} flex
 * @param {IProps & React.HTMLAttributes<HTMLDivElement>} props
 */
const GlobalStyle = (props) => {
  const { flex, ..._props } = props;
  return <Container $flex={flex} {..._props} />;
};

const Container = styled.div`
  color: ${({ theme }) => theme.gray600};
  display: ${({ $flex }) => $flex && 'flex'};
  flex-direction: ${({ $flex }) => $flex && 'column'};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 1.5;
  min-height: 100%;

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    color: inherit;
    font: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  }

  input[type='checkbox'] {
    min-width: 16px;
    min-height: 16px;
  }
`;

export default GlobalStyle;

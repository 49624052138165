import styled from 'styled-components';

import TransparentButton from './Transparent';

const UnderlineButton = styled(TransparentButton)`
  color: #9fa4aa;
  display: inline-flex;
  font-size: 14px;
  letter-spacing: -0.7px;
  position: relative;

  &::after {
    content: '';
    border-bottom: 1px solid #9fa4aa;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
`;

export default UnderlineButton;

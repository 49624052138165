import { KakaoButton } from './_buttons';
import { Article, Post } from './_post';
import Signboard from './_signboard';

const ContentList = ({ items, onClickFavorite, session }) => {
  return (
    <>
      {items.map((item) => (
        <div key={item.id}>
          <Signboard
            item={item}
            scale="wide"
            session={session}
            onClickFavorite={() => onClickFavorite(item)}
          />
        </div>
      ))}
    </>
  );
};

export { Article, ContentList, KakaoButton, Post, Signboard };

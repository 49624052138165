import * as retail_api from 'api/retail';
import * as filter_api from 'api/search-filters';
import { handleActions } from 'redux-actions';

import createApiRequest from './_support';

const FILTER = 'pirates-data.retail/FILTER';
const RETAILS = 'pirates-data.retail/RETAILS';
const RECOMMENDS = 'pirates-data.retail/RECOMMENDS';
const SEARCH = 'pirates-data.retail/SEARCH';
const TOGGLE_FAVORITE = 'pirates-data.retail/LIKE';
const TOGGLE_RECOMMEND = 'pirates-data.retail/RECOMMEND';
const TOGGLE_SEARCH_LIKE = 'pirates-data.retail/SEARCH_LIKE';
const SAVE_QUERY = 'pirates-data.retail/SAVE_QUERY';
const LOAD_DATA = 'pirates-data.retail/LOAD_DATA';

const [get_filters, FILTER_OK, FILTER_FAIL] = createApiRequest(
  FILTER,
  filter_api.load_retail_groups
);
const [get_retails, RETAILS_OK, RETAILS_FAIL] = createApiRequest(
  RETAILS,
  retail_api.get_retails
);
const [get_retail_recommends, RECOMMENDS_OK, RECOMMENDS_FAIL] =
  createApiRequest(RECOMMENDS, retail_api.get_retail_recommends);
const [search_retails, SEARCH_OK, SEARCH_FAIL] = createApiRequest(
  SEARCH,
  retail_api.search_retails
);
const [toggle_favorite, TOGGLE_FAVORITE_OK, TOGGLE_FAVORITE_FAIL] =
  createApiRequest(TOGGLE_FAVORITE, retail_api.toggle_retail_like);
const [toggle_search_like, TOGGLE_SEARCH_LIKE_OK, TOGGLE_SEARCH_LIKE_FAIL] =
  createApiRequest(TOGGLE_SEARCH_LIKE, retail_api.toggle_retail_like);
const [toggle_recommend, TOGGLE_RECOMMEND_OK, TOGGLE_RECOMMEND_FAIL] =
  createApiRequest(TOGGLE_RECOMMEND, retail_api.toggle_retail_like);
const [save_query, SAVE_QUERY_OK, SAVE_QUERY_FAIL] = createApiRequest(
  SAVE_QUERY,
  (query) => ({ data: query })
);
const [load_data, LOAD_DATA_OK, LOAD_DATA_FAIL] = createApiRequest(
  LOAD_DATA,
  (data) => ({ data })
);

const state = {
  filter: [],
  query: false,
  recommends: [],
  retails: [],
};

const reducer = handleActions(
  {
    [FILTER]: (state) => ({ ...state }),
    [FILTER_OK]: (state, action) => ({
      ...state,
      filter: action.payload,
    }),

    [LOAD_DATA]: (state) => ({ ...state }),
    [LOAD_DATA_OK]: (state, action) => ({
      ...action.payload,
    }),

    [RECOMMENDS]: (state) => ({ ...state }),
    [RECOMMENDS_OK]: (state, action) => ({
      ...state,
      recommends: action.payload,
    }),

    [RETAILS]: (state) => ({ ...state }),
    [RETAILS_OK]: (state, action) => ({
      ...state,
      retails: action.payload,
    }),

    [SAVE_QUERY]: (state) => ({ ...state }),
    [SAVE_QUERY_OK]: (state, action) => ({
      ...state,
      query: action.payload,
    }),

    [SEARCH]: (state) => ({ ...state }),
    [SEARCH_OK]: (state, action) => ({
      ...state,
      retails: action.payload,
    }),

    [TOGGLE_FAVORITE]: (state) => ({ ...state }),
    [TOGGLE_FAVORITE_OK]: (state, action) => ({
      ...state,
      retails: state.retails.map((retail) => ({
        ...retail,
        items: retail.items.map((item) => {
          if (item.id === action.payload.targetCode) {
            return {
              ...item,
              favorite: action.payload.doLike ? 'on' : 'off',
            };
          }
          return item;
        }),
      })),
    }),
    [TOGGLE_RECOMMEND]: (state) => ({ ...state }),
    [TOGGLE_RECOMMEND_OK]: (state, action) => ({
      ...state,
      recommends: state.recommends.map((recommend) => {
        if (recommend.id === action.payload.targetCode) {
          return {
            ...recommend,
            favorite: action.payload.doLike ? 'on' : 'off',
          };
        }
        return recommend;
      }),
    }),
    [TOGGLE_SEARCH_LIKE]: (state) => ({ ...state }),
    [TOGGLE_SEARCH_LIKE_OK]: (state, action) => ({
      ...state,
      retails: state.retails.map((retail) => {
        if (retail.id === action.payload.targetCode) {
          return {
            ...retail,
            favorite: action.payload.doLike ? 'on' : 'off',
          };
        }
        return retail;
      }),
    }),
  },
  state
);

const Types = {
  RECOMMENDS,
  RETAILS,
  SEARCH,
};

export {
  get_filters,
  get_retail_recommends,
  get_retails,
  load_data,
  save_query,
  search_retails,
  toggle_favorite,
  toggle_recommend,
  toggle_search_like,
  Types,
};

export default reducer;

import './style.css';

import Document from 'components/common/document';
import { Image, TextView } from 'components/views';
import { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ToastContext } from '../../context/toast';
import { KakaoButton } from './_buttons';

const MetaData = ({ post }) => {
  const share = post.share.subject || post.subject;
  const title = `${share}`;

  const documentMeta = {
    'description': share.description,
    'og:description': share.description,
    'og:image': post.thumbnail,
    'thumbnail': post.thumbnail,
  };

  return <Document.Head meta={documentMeta} title={title} />;
};

const Article = ({ article }) => {
  return (
    <div className="section article default-html">
      {article &&
        article.map((p, idx) => (
          <Paragraph key={idx} figure={p.figure} no={p.no} url={p.hyperlink}>
            {p.content}
          </Paragraph>
        ))}
    </div>
  );
};

const Paragraph = ({ children, figure, no, url }) => {
  const p_id = { id: `article-no-${no}` };
  return (
    <>
      {figure && (
        <div {...p_id} className="img-block sub-content">
          {/* <LazyLoad offset={100}> */}
          {url ? (
            <a href={url}>
              <Image
                height="100%"
                scale="16x9"
                src={figure}
                style={{ display: 'block' }}
                width="100%"
              />
            </a>
          ) : (
            <Image
              height="100%"
              scale="16x9"
              src={figure}
              style={{ display: 'block' }}
              width="100%"
            />
          )}
          {/* </LazyLoad> */}
        </div>
      )}
      {children !== '' && (
        <p
          {...(figure ? {} : p_id)}
          className="sub-content"
          style={{ fontSize: '16px', lineHeight: '24px' }}
        >
          <TextView>{children}</TextView>
        </p>
      )}
    </>
  );
};

const ShareSection = ({ children }) => {
  const url = decodeURIComponent(window.location.href);
  const { showToast } = useContext(ToastContext);

  return (
    <div className="section share">
      {children}
      <CopyToClipboard text={url}>
        <button
          className="btn fl-right ml5 use-copy-link"
          onClick={() => showToast('주소가 복사되었습니다.')}
        >
          주소복사
        </button>
      </CopyToClipboard>
      <KakaoButton>카카오톡 공유</KakaoButton>
    </div>
  );
};

const Post = ({ post }) => {
  const moveToComment = () => {
    const location = document.getElementById('comment')?.offsetTop;
    window.scrollTo({ behavior: 'smooth', top: location });
  };

  return (
    <div className="main">
      <MetaData post={post} />
      <ShareSection>
        <div className="comment btn" onClick={moveToComment}>
          {post.statistics.reviews}
        </div>
      </ShareSection>
      <Article article={post.article} />
      <ShareSection />
    </div>
  );
};

export { Article, Post };

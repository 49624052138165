import { useInfiniteQuery } from '@tanstack/react-query';
import { getCoupons } from 'api/coupon';
import { useRecoilState } from 'recoil';
import { allCouponsListState } from 'recoil/coupons';

import couponListKeys from './keys';

export default function useGetCouponList() {
  const [couponList, setCouponList] = useRecoilState(allCouponsListState);

  return useInfiniteQuery(
    couponListKeys.getCouponList({
      sortBy: couponList.sort,
      zoneCode: couponList.region.code,
    }),
    ({ pageParam = 1 }) => {
      const params = {
        pageNum: pageParam,
        pageSize: 10,
        sortBy: couponList.sort || null,
        type: 'all',
        zoneCode: couponList.region.code || null,
      };

      if (couponList.region.code === 'all') {
        delete params.zoneCode;
      }

      // if (pageParam === 1) {
      //   delete params.pageNum;
      // }

      return getCoupons(params);
    },
    {
      getNextPageParam: ({ nextPageNo }) => nextPageNo,
    }
  );
}

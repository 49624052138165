import { Box } from 'components/atoms';
import { Image } from 'components/views';
import { ToastContext } from 'context/toast';
import { useCallback, useContext, useRef } from 'react';
import LazyLoad from 'react-lazyload';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Heart } from '../stores/img/heart.svg';
import { ReactComponent as HeartOn } from '../stores/img/heart_on.svg';

const WideView = ({
  handleBannerRef,
  item,
  items,
  onClickFavorite,
  session,
}) => {
  const location = useLocation();
  const pageTitle = location.pathname.slice(1);
  const { showToast } = useContext(ToastContext);

  const bannerRef = useRef();
  const handleOnLoad = useCallback(() => {
    if (handleBannerRef) {
      handleBannerRef(bannerRef);
    }
  }, [handleBannerRef]);

  const handleClick = () => {
    sessionStorage.setItem(
      'contents-scrollY',
      JSON.stringify({
        scrollY: window.scrollY,
      })
    );
  };

  return (
    <div
      ref={bannerRef}
      className="card-template type2"
      id="bannerBox"
      style={{ paddingTop: '16px' }}
      onLoad={handleOnLoad}
    >
      <div className="fixed inner-section type1">
        {!item?.url || item?.url === '' ? (
          <Link
            to={{ pathname: `/콘텐츠/${item.uri}`, state: pageTitle }}
            onClick={handleClick}
          >
            <span className="img-block">
              <LazyLoad
                height={190}
                offset={100}
                style={{
                  padding: '0 16px',
                }}
              >
                <Image
                  className="contents-thumbnail"
                  height="100%"
                  scale="11x5"
                  src={item.thumbnail}
                  style={{ borderRadius: '8px', display: 'block' }}
                  width="100%"
                />
              </LazyLoad>
            </span>
          </Link>
        ) : (
          <a href={item.url}>
            <span className="img-block">
              <Image
                className="contents-thumbnail"
                scale="11x5"
                src={item.thumbnail}
                style={{ display: 'block' }}
              />
            </span>
          </a>
        )}
      </div>
      {!item?.url || item?.url === '' ? (
        <DescriptionWrapper>
          <Link to={{ pathname: `/콘텐츠/${item.uri}`, state: pageTitle }}>
            <div
              className="subject txt-ellipsis"
              style={{ color: '#26282B', fontSize: '16px', fontWeight: '700' }}
            >
              {item.subject}
            </div>
            <p className="description">{item.description}</p>
          </Link>
          {session && (
            <Box
              flexShrink={0}
              onClick={() => {
                onClickFavorite();
                if (session.identity) {
                  if (item.favorite !== 'off') {
                    showToast('즐겨찾기 등록이 해제되었습니다.');
                  } else {
                    showToast(
                      '즐겨찾기에 추가되었습니다.마이페이지에서 확인하세요.'
                    );
                  }
                }
              }}
            >
              {item.favorite === 'off' || !session.identity ? (
                <Heart fill="#EB5757" />
              ) : (
                <HeartOn fill="#EB5757" />
              )}
            </Box>
          )}
        </DescriptionWrapper>
      ) : (
        <DescriptionWrapper>
          <a href={item.url}>
            <div className="subject txt-ellipsis">{item.subject}</div>
            <p className="description">{item.description}</p>
          </a>
          <Box
            flexShrink={0}
            onClick={() => {
              onClickFavorite();
              if (session.identity) {
                if (item.favorite !== 'off') {
                  showToast('즐겨찾기 등록이 해제되었습니다.');
                } else {
                  showToast(
                    '즐겨찾기에 추가되었습니다.마이페이지에서 확인하세요.'
                  );
                }
              }
            }}
          >
            {item.favorite === 'off' || !session.identity ? (
              <Heart fill="#EB5757" />
            ) : (
              <HeartOn fill="#EB5757" />
            )}
          </Box>
        </DescriptionWrapper>
      )}
    </div>
  );
};

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  .txt-ellipsis {
    margin-bottom: 2px;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RegularView = ({ item }) => {
  return (
    <div className="card-template type11">
      <div className="fixed inner-section type1">
        <span className="description">
          방어의 계절이 오고 있다. 알아두면 유용한 방어 상식
        </span>
      </div>
      <div className="fixed inner-section type2">
        <span className="img-block">
          <Image
            src="https://cdn.tpirates.com/files/etc/2020/0319/b65b308d8156ea0cc3dab44719c531c9_FThumb.jpg"
            style={{ display: 'block' }}
          />
        </span>
      </div>
    </div>
  );
};

const Signboard = ({
  handleBannerRef,
  item,
  items,
  onClickFavorite,
  scale,
  session,
}) => {
  switch (scale) {
    case 'wide':
      return (
        <WideView
          handleBannerRef={handleBannerRef}
          item={item}
          items={items}
          session={session}
          onClickFavorite={onClickFavorite}
        />
      );
    case 'regular':
    default:
      return <RegularView item={item} />;
  }
};

export default Signboard;

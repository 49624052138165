import styled from 'styled-components';

const Dots = ({ activeIndex, length }) => {
  return (
    <DotsContainer>
      {new Array(length).fill('').map((_, i) => (
        <Dot key={i} isActive={i === activeIndex} />
      ))}
    </DotsContainer>
  );
};

const Dot = styled.span`
  width: 4px;
  height: 4px;
  background-color: ${({ isActive }) => (isActive ? 'black' : '#C9CDD2')};
  border-radius: 50%;
  margin-right: 6px;
  transform: ${({ isActive }) => (isActive ? 'scale(1.3)' : 'scale(1)')};
`;

const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;

export default Dots;

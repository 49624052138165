import React from 'react';
import styled, { css } from 'styled-components';

import ButtonBase from './ButtonBase';

const StyledButtonBase = styled(ButtonBase)`
  align-items: center;
  display: flex;
  gap: ${({ gap }) => gap};
  justify-content: center;

  ${({ color, theme }) => {
    switch (color) {
      case 'primary':
        return css`
          background-color: ${theme.blue4};
          border: 1px solid ${theme.primary};
          color: ${theme.primary};
        `;
      default:
        return css`
          background-color: ${theme.gray0};
          border: 1px solid ${theme.gray300};
          color: ${theme.gray600};
        `;
    }
  }}
`;

export default React.memo(StyledButtonBase);

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const { useRef } = React;

const _Filter = ({ keyword, onActive, onChange, onSubmit }) => {
  const _e_keyword = useRef(null);
  const _handle_keydown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e.target.value);
      e.target.blur();
    }
  };

  // useEffect(() => {
  //   onChange({ target: { value: keyword } });
  //   _e_keyword.current.value = keyword || "";
  // }, [keyword]);

  useEffect(() => {
    if (onSubmit) {
      let form = document.getElementById('header-search-form');
      const headFilterSearch = () => {
        let temp = document.getElementById('header-search-form-text');
        if (temp) {
          let keyword = temp.value;
          if (keyword) {
            onSubmit(keyword);
          }
        }
      };

      if (form) {
        form.onsubmit = headFilterSearch;
      }
    }
  }, [onSubmit]);

  useEffect(() => {
    let temp = document.getElementById('header-search-form');
    return () => {
      if (temp) temp.onsubmit = () => {};
    };
  }, []);

  return <></>;
};

export default _Filter;

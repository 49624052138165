import FixedCover from 'components/modules/FixedCover';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

const FullSlide = ({ children, onClose, visible }) => {
  const onStopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  if (!visible) {
    return null;
  }

  const portal = document.getElementById('__portal');

  if (!portal) {
    return null;
  }

  return createPortal(
    <FixedCover visible={visible} onClose={onClose}>
      <Wrapper onClick={onStopPropagation}>{children}</Wrapper>
    </FixedCover>,
    portal
  );
};

const bottomUp = keyframes`
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  animation: ${bottomUp} 300ms ease;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  max-height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 61;
`;

export default FullSlide;

import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const BackButton = ({ history, session }) => {
  const mainPath = sessionStorage.getItem('mainPath') || '';
  const location = useLocation();

  const handler = {
    back: (e) => {
      e.preventDefault();
      // 로그인 된 상태에서 뒤로가기 시 다시 로그인 페이지 뜨지 않고 바로 홈으로 가도록 조건 추가
      if (history.length > 1 && session?.identity) {
        history.replace(mainPath);
      } else if (
        // cms에서 콘텐츠 댓글링크 클릭해서 들어온 후 뒤로가기 클릭시 홈으로 이동하도록 조건 추가
        history.length > 1 &&
        location.search &&
        location.pathname.split('/')[1] === '콘텐츠'
      ) {
        history.replace('/홈');
      } else if (history.length > 1) {
        history.goBack();
      } else {
        history.replace('/홈');
      }
    },
  };

  return (
    <a className="back" href="#" onClick={handler.back}>
      <span className="img-bul arrow-left center">뒤로가기</span>
    </a>
  );
};

export default withRouter(BackButton);

import { atom } from 'recoil';
import { v1 } from 'uuid';

// 필터 모달 상태값
export const globalFilterIsOpen = atom({
  default: false,
  key: `filterIsOpen/${v1()}`,
});
export const globalOrderTypeIsOpen = atom({
  default: false,
  key: `orderTypeIsOpen/${v1()}`,
});
export const globalProductsIsOpen = atom({
  default: false,
  key: `productsIsOpen/${v1()}`,
});
export const globalLocationsIsOpen = atom({
  default: false,
  key: `locationsIsOpen/${v1()}`,
});
export const globalBackToFilterModal = atom({
  default: false,
  key: `backToFilterModal/${v1()}`,
});

export const globalTempSortState = atom({
  default: [
    { contents: '내주변', key: 'around', value: [] },
    { contents: '주문유형', key: 'orderType', value: [] },
    { contents: '품목', key: 'products', value: [] },
    { contents: '지역', key: 'locations', value: [] },
  ],
  key: `tempSortState/${v1()}`,
});

export const priceSortState = atom({
  default: [
    {
      contents: '모든지역',
      key: 'locations',
      value: '',
      zoneCode: '0000000000',
    },
    {
      contents: '모든품목',
      itemGroupCode: '0000000000',
      key: 'products',
      value: '',
    },
  ],
  key: `priceSortState/${v1()}`,
});

export const wholesaleSortState = atom({
  default: { product: [] },
  key: `wholesaleSortState/${v1()}`,
});

export const globalListOrder = atom({
  default: 'default',
  key: `globalListOrder/${v1()}`,
});

export const categoryFilterList = atom({
  default: [],
  key: `categoryFilterList/${v1()}`,
});

export const globalScrollPage = atom({
  default: 1,
  key: `globalScrollPage/${v1()}`,
});

export const globalSearchStoreScrollPage = atom({
  default: 1,
  key: `globalSearchStoreScrollPage/${v1()}`,
});

export const globalCarouselImageWidth = atom({
  default: 388,
  key: `globalCarouselImageWidth/${v1()}`,
});

import styled from 'styled-components';

/**
 * @typedef {object} IProps
 * @property {keyof HTMLElementTagNameMap} as
 * @property {string} color
 * @property {React.CSSProperties['display']} display
 * @property {React.CSSProperties['fontSize']} fontSize
 * @property {React.CSSProperties['fontStyle']} fontStyle
 * @property {React.CSSProperties['fontWeight']} fontWeight
 * @property {React.CSSProperties['textAlign']} textAlign
 * @property {React.CSSProperties['textDecoration']} textDecoration
 * @property {React.CSSProperties['wordBreak']} wordBreak
 *
 * @param {IProps & React.HTMLAttributes<HTMLDivElement>} props
 */
const Typography = (props) => {
  const {
    as,
    color,
    display,
    fontSize,
    fontStyle,
    fontWeight,
    letterSpacing,
    lineHeight,
    textAlign,
    textDecoration,
    wordBreak,
    ..._props
  } = props;
  return (
    <Container
      $color={color}
      $display={display}
      $fontSize={fontSize}
      $fontStyle={fontStyle}
      $fontWeight={fontWeight}
      $letterSpacing={letterSpacing}
      $lineHeight={lineHeight}
      $textAlign={textAlign}
      $textDecoration={textDecoration}
      $wordBreak={wordBreak}
      as={as}
      {..._props}
    />
  );
};

const Container = styled.div`
  color: ${({ $color, theme }) => theme[$color] || $color};
  display: ${({ $display }) => $display};
  font-size: ${({ $fontSize }) => $fontSize};
  font-style: ${({ $fontStyle }) => $fontStyle};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  text-align: ${({ $textAlign }) => $textAlign};
  text-decoration: ${({ $textDecoration }) => $textDecoration};
  word-break: ${({ $wordBreak }) => $wordBreak};
  line-height: ${({ $lineHeight }) => $lineHeight};
  letter-spacing: ${({ $letterSpacing }) => $letterSpacing};
`;

export default Typography;

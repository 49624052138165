import styled from 'styled-components';
import { ButtonBase } from '.';

const RoundButton = styled(ButtonBase)`
  border: 1px solid #c9cdd2;
  border-radius: 30px;
  color: ${({ theme }) => theme.gray600};
  flex-shrink: 0;
  font-size: 12px;
  padding: 2px 8px;
  width: auto;
`;

export default RoundButton;

export const extractInfinityDataFromDeep = (data, ...rest) => {
  if (rest.length === 1) {
    const newData = data.pages.reduce((prev, curr) => {
      return prev.concat(curr[rest[0]]);
    }, []);
    return newData;
  }
  if (rest.length === 2) {
    const newData = data.pages.reduce((prev, curr) => {
      return prev.concat(curr[rest[0]][rest[1]]);
    }, []);
    return newData;
  }
  if (rest.length === 3) {
    const newData = data.pages.reduce((prev, curr) => {
      return prev.concat(curr[rest[0]][rest[1]][rest[2]]);
    }, []);
    return newData;
  }
  throw new Error('please check arguments of target keys ');
};

// TODO: theme 구조 변경
/**
 * @type {import('styled-components').DefaultTheme}
 */
const theme = {
  blue1: '#0E4E97',
  blue100: '#ECF6FF',
  blue2: '#2F80ED',
  blue3: '#2D9CDB',
  blue300: '#2F80ED',
  blue4: '#ECF6FF',
  blue400: '#0E4E97',
  gradientDeepGrayWhite:
    'linear-gradient(263deg, rgba(255, 255, 255, 0.80) -18.97%, rgba(255, 255, 255, 0.00) 63.18%), #6C7582;',
  gradientSkyDeepBlue:
    'linear-gradient(263deg, rgba(255, 255, 255, 0.80) -18.97%, rgba(255, 255, 255, 0.00) 63.18%), #2F80ED',
  gradientWhiteGray:
    'linear-gradient(277deg, rgba(0, 0, 0, 0.20) -70.75%, rgba(0, 0, 0, 0.00) 69.54%), #F7F8F9',
  gray0: '#FFFFFF',
  gray01: '#FFFFFF80',
  gray10: '#F7F8F9',
  gray100: '#E8EBED',
  gray200: '#C9CDD2',
  gray300: '#9FA4AA',
  gray400: '#72787F',
  gray500: '#464C53',
  gray600: '#26282B',
  gray601: '#26282B99',
  gray602: '#26282B40',
  green100: '#EEFDFA',
  green200: '#00AF89',
  newBlue12: '#0E4E97',
  newBlue2: '#ECF6FF',
  newBlue4: '#C1E1FF',
  newBlue6: '#8AD2FB',
  newBlue7: '#54B7EF',
  newBlue8: '#2D9CDB',
  newBlue9: '#2F80ED',
  newGray1: '#F7F8F9',
  newGray11: '#26282B',
  newGray12: '#1A1B1E',
  newGray2: '#F3F3F3',
  newGray3: '#E8EBED',
  newGray4: '#C9CDD2',
  newGray5: '#9FA4AA',
  newGray6: '#8A8D90',
  newRed2: '#FEE7E6',
  newRed8: '#EB5757',
  newRed9: '#F43636',
  primary: '#1C79BC',
  red1: '#B32424',
  red2: '#EB5757',
  red3: '#FEE7E6',
  white100: '#FFFFFF',
  white200: '#F7F8F9',
  yellow1: '#F2994A',
  yellow2: '#FFCC33',
  yellow3: '#FEF6E7',
  yellow300: '#F2994A',
};

export default theme;

import { Typography } from 'components/atoms';
import ChevronDown from 'components/atoms/ChevronDown';
import ChevronUp from 'components/atoms/ChevronUp';
import Icon from 'components/Icons/v2/Icon';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import DropDown from '../DropDown';

const SortFilter = ({
  chartInstance,
  funcWithClick,
  onChangeSelection,
  option,
  renderTitle,
  value,
  vertical = 'left',
}) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const handleClickModal = (e) => {
    e.stopPropagation();
    setSelectOpen((prev) => !prev);
    if (funcWithClick) funcWithClick();
  };

  useEffect(() => {
    const clickEventFunc = () => {
      if (selectOpen) setSelectOpen(false);
    };

    window.addEventListener('click', clickEventFunc);
    if (chartInstance) {
      chartInstance.getDom().addEventListener('touchmove', clickEventFunc);
    }

    return () => {
      window.removeEventListener('click', clickEventFunc);
    };
  }, [selectOpen, chartInstance]);

  return (
    <Container justifyEnd={vertical === 'left'} onClick={handleClickModal}>
      <Contents>
        <Typography color="newGray11" fontSize="12px" fontWeight={400}>
          {renderTitle || value}
        </Typography>
        <Icon
          name={selectOpen ? 'arrow_top-16_1' : 'arrow_bottom-16_1'}
          size={16}
        />
      </Contents>
      <DropDown visible={selectOpen}>
        <StyledUl>
          {option.map((item) => (
            <StyledLi
              key={item.key}
              id={item.key}
              isSelect={value === item.label}
              onClick={onChangeSelection}
            >
              {item.label}
            </StyledLi>
          ))}
        </StyledUl>
      </DropDown>
    </Container>
  );
};

const Container = styled.div`
  width: 100px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: ${({ justifyEnd }) =>
    justifyEnd ? 'flex-end' : 'flex-start'};
  gap: 6px;

  svg {
    pointer-events: none;
    padding-top: 1px;
  }
`;

const Contents = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  gap: 4px;
`;

const StyledUl = styled.ul`
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.gray100};
  width: 100px;
`;

const StyledLi = styled.li`
  color: ${({ isSelect, theme }) =>
    isSelect ? theme.newGray11 : theme.newGray6};
  padding: 10px;
  cursor: pointer;
`;

export default SortFilter;

import Box from '../../atoms/Box';

const Footer = ({ as, children, mobileIOS, ...rest }) => {
  return (
    <Box
      as={as}
      backgroundColor="blue2"
      bottom={mobileIOS ? 10 : 0}
      height="56px"
      left={0}
      margin="auto"
      maxWidth="420px"
      position="fixed"
      right={0}
      width="100%"
      zIndex={500}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Footer;

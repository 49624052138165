import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createCarts } from 'api/cart';

import cartKeys from './key';

export default function useCreateCarts(setIsOpenAddCartSlide) {
  const queryClient = useQueryClient();

  return useMutation(createCarts, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartKeys.getCartsCount());
      setIsOpenAddCartSlide(true);
      localStorage.removeItem('addCartPrevProduct');
    },
  });
}

import { useDialogActionContext } from '@locked-dobby/library-react-dialog';
import styled from 'styled-components';

const Confirm = ({ message, textNo, textYes, title }) => {
  const { hide } = useDialogActionContext();
  return (
    <CustomAlertStyle>
      <div className="alert-wrap">
        <div className="ques">
          {title && (
            <h3>
              <pre>{title}</pre>
            </h3>
          )}
          <pre>{message}</pre>
        </div>
        <div className="respon">
          <span
            className="dialogCssActionButton negative"
            onClick={() => {
              hide(false);
            }}
          >
            {textNo ?? '취소'}
          </span>
          <span
            className="dialogCssActionButton positive"
            onClick={() => {
              hide(true);
            }}
          >
            {textYes ?? '확인'}
          </span>
        </div>
      </div>
    </CustomAlertStyle>
  );
};

const CustomAlertStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.3);

  & > .alert-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 270px;

    transform: translate(-50%, -50%);

    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    & > .img-bul.close {
      position: absolute;
      right: 4px;
      top: 4px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    & > .ques {
      padding: 24px 16px;
      & > * {
        text-align: center;
        color: #26282b;
      }
      & > h3 {
        font-size: 16px;
        font-weight: 700;
        & > pre {
          font-size: 16px;
          font-weight: 700;
        }
        & + pre {
          margin-top: 8px;
        }
      }
      & > pre {
        font-size: 14px;
        white-space: pre-wrap;
      }
    }
    & > .respon {
      display: flex;
      height: 50px;

      border-top: 1px solid #c9cdd2;
      & > span {
        flex: 1;
        height: 100%;
        line-height: 50px;
        font-size: 14px;
        text-align: center;

        box-sizing: border-box;
        cursor: pointer;
        & + span {
          border-left: 1px solid #c9cdd2;
        }
        &:last-of-type {
          color: #3366cc;
        }
      }
    }
  }
`;

export default Confirm;

import React from 'react';
import styled from 'styled-components';

const Tag = ({ children, onClick }) => {
  return <TagSpan onClick={onClick}>{children}</TagSpan>;
};
const TagSpan = styled.span`
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.gray200};
  border-radius: 20px;
  color: ${({ theme }) => theme.gray400};
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.gray100};
    color: ${({ theme }) => theme.gray600};
  }
`;

export default Tag;

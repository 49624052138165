import React from 'react';
import TextForm from './_text';

const WriteForm = ({
  targetCode,
  member,
  type,
  parentCode,
  addReview,
  tabState,
  secretGroup,
  placeHolder,
  scrollRef,
  page,
}) => {
  const props = {
    targetCode,
    member,
    type,
    parentCode,
    secretGroup,
    placeHolder,
    initText: '',
    initScore: 0,
    toastMessage: '댓글이 등록되었습니다.',
  };

  return (
    <TextForm
      page={page}
      props={props}
      scrollRef={scrollRef}
      onSubmit={addReview}
      tabState={tabState}
    />
  );
};

export default WriteForm;

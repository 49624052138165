const genPrevPath = (path) => {
  switch (path) {
    case '/%EC%8B%9C%EC%9E%A5':
      return '/시장';
    case '/%EC%8B%9C%EC%84%B8':
      return '/시세';
    case '/%EC%BD%98%ED%85%90%EC%B8%A0':
      return '/콘텐츠';
    default:
      return '/홈';
  }
};

export default genPrevPath;

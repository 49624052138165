import { useQuery } from '@tanstack/react-query';
import client from 'api/client';
import { Box } from 'components/atoms';
import { conf } from 'conf/settings';
import React, { useState } from 'react';

import { View } from './_context';

const _SERVICE = `제 1 조 (목적)
이 약관은 (주)더파이러츠(The Pirates)("회사")가 제공하는 인어교주해적단 관련 제반 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
본 약관은 PC 통신, 스마트폰(안드로이드폰, 아이폰 등) 앱등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 준용됩니다.

*2018년 1월 해적단(개인사업자)은 법인설립에 따라 회사명이 (주)더파이러츠로 변경되었습니다.

제 2 조 (정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1)"서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 "회원"이 이용할 수 있는 인어교주해적단 관련 제반 서비스를 의미합니다.
2)"회원"이라 함은 회사의 "서비스"에 접속하여 이 약관에 따라 "회사"와 이용계약을 체결하고 "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.
3)“비회원” 이라 함은 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
4)"아이디(ID)"라 함은 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고 "회사"가 승인하는 문자와 숫자의 조합을 의미합니다.
5)"비밀번호"라 함은 "회원"이 부여 받은 "아이디와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
6)"게시물"이라 함은 "회원"이 "서비스"를 이용함에 있어 "서비스상"에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.

제 3 조 (약관의 게시와 개정)
1)"회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다. 다만, 약관의 구체적 내용은 이용자가 연결화면을 통하여 볼 수 있습니다.
2)"회사"는 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
3)"회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 서비스내 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
4)"회사"가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
5)"회원"이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.

제 4 조 (관련법령과의 관계)
이 약관 또는 개별약관에서 정하지 않은 사항은 전기통신사업법, 전자거래기본법, 정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 개인정보보호법 등 관련 법령의 규정과 일반적인 상관례에 의합니다.

제 5 조 (이용계약 체결)
1)이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 "회사"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
2)"회사"는 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
1.가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 "회사"의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
2.실명이 아니거나 타인의 명의를 이용한 경우
3.회사가 실명확인절차를 실시할 경우에 이용자의 실명가입신청이 사실 아님이 확인된 경우
4.허위의 정보를 기재하거나, "회사"가 제시하는 내용을 기재하지 않은 경우
5.14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우
6.이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
3)제1항에 따른 신청에 있어 "회사"는 "회원"의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
4)"회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
5)제2항과 제5항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, "회사"는 원칙적으로 이를 가입신청자에게 알리도록 합니다.
6)이용계약의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.
7)"회사"는 "회원"에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
8)"회사"는 "회원"에 대하여 "영화및비디오물의진흥에관한법률" 및 "청소년보호법"등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.

제 6 조 (회원정보의 변경)
1)"회원"은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 주민등록번호, 아이디 등은 수정이 불가능합니다.
2)"회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 "회사"에 대하여 그 변경사항을 알려야 합니다.
3)제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.

제 7 조 (개인정보보호 의무)
"회사"는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 "회사"의 개인정보취급방침이 적용됩니다. 다만, "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보취급방침이 적용되지 않습니다.

제 8 조 ("회원"의 "아이디" 및 "비밀번호"의 관리에 대한 의무)
1)"회원"의 "아이디"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
2)"회사"는 "회원"의 "아이디"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 "회사" 및 "회사"의 운영자로 오인한 우려가 있는 경우, 해당 "아이디"의 이용을 제한할 수 있습니다.
3)"회원"은 "아이디" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.
4)제3항의 경우에 해당 "회원"이 "회사"에 그 사실을 통지하지 않거나, 통지한 경우에도 "회사"의 안내에 따르지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.

제 9 조 ("회원"에 대한 통지)
1)"회사"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.
2)"회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.

제 10 조 ("회사"의 의무)
1)"회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
2)"회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.
3)"회사"는 서비스이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.
4)"회사"는 서비스이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.

제 11 조 ("회원"의 의무)
1)"회원"은 다음 행위를 하여서는 안 됩니다. 1.신청 또는 변경 시 허위내용의 등록
2.타인의 정보도용
3."회사"가 게시한 정보의 변경
4."회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
5."회사"와 기타 제3자의 저작권 등 지적재산권에 대한 침해
6."회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
7.외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "서비스"에 공개 또는 게시하는 행위
8.회사의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위
9.정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 행위
9.기타 불법적이거나 부당한 행위
2)"회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, "회사"가 통지하는 사항 등을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안 됩니다.

제 12 조 ("서비스"의 제공 등)
1)회사는 회원에게 아래와 같은 서비스를 제공합니다.
  1.인어교주해적단 : 모바일용 어플리케이션,모바일용 웹사이트,PC용 웹사이트
    2.기타 "회사"가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 "회원"에게 제공하는 일체의 서비스
    2)회사는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
    3)"서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
    4)"회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제9조["회원"에 대한 통지]에 정한 방법으로 "회원"에게 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
    5)"회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.

    제 13 조 ("서비스"의 변경)
    1)"회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.
    2)"서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.
    3)"회사"는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 "회원"에게 별도의 보상을 하지 않습니다.

    제 14 조 (정보의 제공 및 광고의 게재)
1)"회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편,SMS 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
2)제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전 동의를 받아서 전송합니다. 다만, "회원"의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.
3)"회사"는 "서비스"의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "회사"에게 할 수 있습니다.
4)"이용자(회원, 비회원 포함)"는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.

제 15 조 ("게시물"의 저작권)
1)"회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 해당 게시물의 저작자에게 귀속됩니다.
2)"회원"이 "서비스" 내에 게시하는 "게시물"은 검색결과 내지 "서비스" 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, "회원"은 언제든지 고객센터 또는 "서비스" 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
3)"회사"는 제2항 이외의 방법으로 "회원"의 "게시물"을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 "회원"의 동의를 얻어야 합니다.
4)"회원"은 "게시물"을 무상으로 제3자에게 이용하도록 할 수 있습니다. 즉, "회원"은 "회사"와 별도 협의한 경우를 제외하고 "회사"를 포함한 제3자로부터 "게시물" 이용에 대한 대가나 보상을 요구할 수 없습니다.

제 16 조 ("게시물"의 관리)
1)"회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"는 관련법에 따라 조치를 취하여야 합니다.
2)"회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 "게시물"에 대해 신고, 삭제 등의 임시조치 등을 취할 수 있습니다.
- 신고는 "게시물(댓글)"에 있는 '신고/차단' 기능을 통해 진행됩니다.

제 17 조 (권리의 귀속)
1)"서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다. 단, "회원"의 "게시물" 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
2)"회사"는 서비스와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라 계정, "아이디", 콘텐츠, "포인트" 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.

제 18 조 (포인트)
"회사"는 서비스의 효율적 이용 및 운영을 위해 사전 공지 후 "포인트"의 일부 또는 전부를 조정할 수 있으며, "포인트"는 회사가 정한 기간에 따라 주기적으로 소멸할 수 있습니다.

제 19 조 (계약해제, 해지 등)
1)"회원"은 언제든지 서비스초기화면의 고객센터 또는 내 정보 관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, "회사"는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
2)"회원"이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 "회사"가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 "회원"의 모든 데이터는 소멸됩니다.
3)"회원:이 계약을 해지하는 경우, 회원이 작성한 게시물 일체는 삭제되지 않습니다.

제 20 조 (주문거래 기록의 보관)
“주문거래”의 거래기록은 전자상거래등에서의 소비자보호에 관한 법률에 규정에 의해 일정기간 동안 보존 할 수 있습니다.

제 21 조 (주문거래 시 가능한 결제 수단)
회사에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 하나로 할 수 있습니다.
- 신용카드 결제, 핸드폰 결제, 포인트, 기타 회사가 추가 지정하는 결제수단

제 22 조 (주문거래 시 구매결정 및 송금)
회원은 본 약관 및 회사가 정한 규정에 따라 아래 절차를 거쳐 “주문거래”를 할 수 있습니다.
- 성명, 배송지 주소, 연락처의 입력
- 재화 또는 용역의 선택
- 결제방법의 선택

제 23 조 (주문거래 시 취소 및 환불)
1) 회사의 “주문거래”시 취소 및 환불 규정은 전자상거래등의 소비자보호에 관한 법률 등 관련 법령을 준수합니다.
2) “주문거래”시 음식배달의 성격에 따라 회사는 별도의 취소 및 환불 관련 규정을 정할 수 있으며, 이 경우 별도 계약 및 이용조건상의 취소 및 환불 규정이 우선 적용됩니다.
3) “주문거래”시 음식배달의 특성상 취소와 환불은 배달업소와 확인 후 처리 하게 되며, 신용카드결제 및 핸드폰결제는 신용카드사 및 이동통신사의 환불기준에 의거하여 시일이 소요될 수 있습니다.

제 24 조 (이용제한 등)
1)"회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.
2)"회사"는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 "서비스" 이용을 통해 획득한 "포인트" 및 기타 혜택 등도 모두 소멸되며, "회사"는 이에 대해 별도로 보상하지 않습니다.
3)"회사"는 "회원"이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
4)"회사"는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.
5)본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는 "회사"는 제9조["회원"에 대한 통지]에 따라 통지합니다.
6)"회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "회사"가 인정하는 경우 "회사"는 즉시 "서비스"의 이용을 재개합니다.

제 25조 (책임제한)
1)"회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.
2)"회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.
3)"회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
4)"회사"는 "회원" 간 또는 "회원"과 제3자 상호간에 "서비스"를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
5)"회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.

제 26 조 (준거법 및 재판관할)
1)"회사"와 "회원" 간 제기된 소송은 대한민국법을 준거법으로 합니다.
2)"회사"와 "회원"간 발생한 분쟁에 관한 소송은 제소 당시의 "회원"의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 "회원"의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
3)해외에 주소나 거소가 있는 "회원"의 경우 "회사"와 "회원"간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.

부칙
1)이 약관은 2015년 12월 23일부터 적용됩니다.
`;

const v11 = `주식회사 더파이러츠(이하 "회사"라 함)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.


1. 개인정보의 수집 항목

회사는 다음과 같이 이용자의 개인정보를 수집합니다. 회사는 회원가입, 본인확인 및 원활한 고객상담, 각종 서비스의 제공 등을 위해 아래와 같은 최소한의 개인정보를 필수 항목으로 수집하고 있습니다.
- 이메일, 휴대전화 번호, 이름, 닉네임, 비밀번호 외 사용자의 자발적 제공에 의해 수집된 정보

이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.
- SNS 계정정보(네이버, 페이스북, 카카오톡, 인스타그램, 트위터, 유튜브)

또한, 서비스 이용 과정에서 아래와 같은 선택항목의 정보가 수집될 수 있습니다.
- 사진, 주소, 단말기정보(OS, 화면사이즈, 디바이스 아이디), IP주소, 쿠키, 접속로그, 방문일시, 서비스 이용기록, 불량 이용기록

쿠키란 회사의 웹 사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 귀하께서 웹사이트에 접속을 하면 회사의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 아이디 등의 추가 입력없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
- 홈페이지, 어플리케이션(회원가입, 프로필편집, 계정설정 등)
- 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집


2. 개인정보의 수집·이용 목적

처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 관련 법률에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.

- 공통인증 이용 및 회원관리
- 서비스 개발·제공 및 향상
- 리뷰작성 등 서비스 이용
- 서비스 방문 및 이용기록의 통계 및 분석
- 마케팅 및 광고(쿠폰 및 이벤트 등) 활용


3. 개인정보의 제 3자 제공

회사는 일반적으로 이용자의 개인정보를 [개인정보의 수집-이용 목적]에서 명시한 범위 내에서 사용하며, 이용자의 사전 동의 없이 [개인정보의 수집-이용 목적] 범위를 초과하여 사용하거나 제 3자에게 제공하지 않습니다.
이용자 동의 후 개인정보 제공이 발생하는 경우, 제공받는 자와 제공 목적 등은 다음과 같습니다.
 
제공받는 자 : 제휴사(입점 점포)
제공받는 자의 이용 목적 : 점포 이용사실 확인 및 보상
제공하는 항목 : 이름, 연락처, 계좌번호, 통화 및 문자 기록(모든 항목은 회원가입 시에는 수집되지 않음)
제공받는 자의 개인정보 보유 및 이용 기간 : 목적 달성 시 즉시 파기
 
이용자는 개인정보의 제 3자 제공 동의 거부 및 언제든지 철회할 권리가 있습니다. 단, 거부할 경우 중재 및 보상금 지급불가 등의 불이익이 생길 수 있습니다.


다음은 예외로 합니다.

- 이용자가 사전에 공개 또는 제 3자 제공에 동의한 경우
- 법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우


4. 이용자 및 법정대리인의 권리와 그 행사방법

이용자(혹은 법정 대리인)은 자신(혹은 당해 만 14세 미만 아동)의 개인정보를 아래 링크의 ‘내 정보’ 에서 언제든지 조회 및 수정하거나, ‘내 정보>탈퇴하기’를 통해 삭제할 수 있습니다.

(마이페이지 링크) https://www.tpirates.com/마이페이지


이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.

회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 아래 [개인정보의 보유 및 이용기간]에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.

타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.


5. 개인정보의 보유 및 이용기간

회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 이용자의 개인정보를 지체없이 파기합니다. 단, 관련법령 및 회사정책에 따라 보존할 필요가 있는 경우에는 일정 기간 동안 개인정보를 보관할 수 있습니다. 이용자에게 별도의 동의를 얻은 경우는 다음과 같습니다.

- 회원가입남용 및 징계기록 등의 부정이용 기록, 6개월

- 전자상거래 등에서의 소비자 보호에 관한 법률
계약 또는 청약철회 등에 관한 기록, 5년 보관
대금결제 및 재화 등의 공급에 관한 기록, 5년 보관
소비자의 불만 또는 분쟁처리에 관한 기록, 3년 보관

- 전자금융거래법
전자금융에 관한 기록, 5년 보관

- 통신비밀보호법
로그인 기록: 3개월


6. 개인정보 파기절차 및 방법

회사는 개인정보 보유기관의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 개인정보 파기의 절차 및 방법은 다음과 같습니다.

파기방법: 전자적 파일 형태로 저장된 개인정보는 다시 이용할 수 없도록 기술적 방법을 사용하여 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.


7. 개인정보의 보호대책

회사는 개인정보를 취급함에 있어 개인정보 분실, 도난, 누출, 변조, 훼손 등이 되지 않도록 안전성을 확보하기 위해 다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.

- 비밀번호의 암호화
이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

- 해킹 등에 대한 대책
최신 백신프로그램을 이용하여 이용자의 개인정보나 자료가 누출, 손상되지 않도록 방지하고 있습니다.
만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다.
민감한 개인정보는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.

- 개인정보 취급 최소화 및 교육
회사는 개인정보 관련 취급 담당자를 최소한으로 제한하며, 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를 강조하고 있습니다.

- 개인정보보호 전담 담당부서 운영
회사는 개인정보의 보호를 위해 개인정보보호 전담자를 지정해 관리하며, 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발생될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.


8. 개인정보관리책임자 및 담당자의 연락처

회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 관리책임자를 지정하고 있습니다. 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호와 관련된 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보관리책임자 혹은 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.


개인정보 관리책임자
성명 : 유성영
소속 : 마케팅팀
직위 : 팀장
이메일 : yu@tpirates.com
전화 : 010.4515.3797
주소 : 서울특별시 영등포구 선유로146

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
개인정보분쟁조정위원회 (http://kopico.go.kr / 1833-6972)
대검찰청 사이버수사과 (http://www.spo.go.kr/spo/index.jsp / 국번없이 1301)
경찰청 사이버안전국 (http://cyber.go.kr / 국번없이 182)


9. 개인정보취급방침의 개정과 그 고지

회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.

개인정보처리방침을 통해 회사가 어떤 개인정보를 수집하고, 어떻게 이용하며, 누구에게 제공하고 언제 어떻게 파기하는지 등에 대해 확인할 수 있으며, 변경사항이 발생할 경우 개인정보처리방침을 통해 안내하겠습니다.


본 개인정보취급방침은 아래의 시행일자부터 적용되며 법령, 정부의 정책 또는 회사 내부 정책 등 필요에 의하여 변경될 수 있습니다. 내용 추가, 삭제 및 수정이 있을 때에는 개정 최소 7일 전에 고지할 것입니다. 다만, 이용자의 소중한 권리 또는 의무에 중요한 내용이 발생하는 경우에는 최소 30일 전에 고지합니다.

- 공고일자 : 2021년 11월 7일
- 시행일자 : 2021년 11월 7일
- version : 1.1
`;

const v1 = `주식회사 더파이러츠(이하 "회사"라 함)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.

*2018년 1월부터 해적단(개인사업자)은 법인설립에 따라 회사명이 (주)더파이러츠로 변경되었습니다.


1. 개인정보의 수집 항목

회사는 다음과 같이 이용자의 개인정보를 수집합니다. 회사는 회원가입, 본인확인 및 원활한 고객상담, 각종 서비스의 제공 등을 위해 아래와 같은 최소한의 개인정보를 필수 항목으로 수집하고 있습니다.
- 이메일, 휴대전화 번호, 이름, 닉네임, 비밀번호 외 사용자의 자발적 제공에 의해 수집된 정보

이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.
- SNS 계정정보(네이버, 페이스북, 카카오톡, 인스타그램, 트위터, 유튜브)

또한, 서비스 이용 과정에서 아래와 같은 선택항목의 정보가 수집될 수 있습니다.
- 사진, 주소, 단말기정보(OS, 화면사이즈, 디바이스 아이디), IP주소, 쿠키, 접속로그, 방문일시, 서비스 이용기록, 불량 이용기록

쿠키란 회사의 웹 사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 귀하께서 웹사이트에 접속을 하면 회사의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 아이디 등의 추가 입력없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
- 홈페이지, 어플리케이션(회원가입, 프로필편집, 계정설정 등)
- 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집


2. 개인정보의 수집·이용 목적

처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 관련 법률에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.

- 공통인증 이용 및 회원관리
- 서비스 개발·제공 및 향상
- 리뷰작성 등 서비스 이용
- 서비스 방문 및 이용기록의 통계 및 분석
- 마케팅 및 광고(쿠폰 및 이벤트 등) 활용


3. 개인정보의 제 3자 제공

회사는 이용자의 개인정보를 [개인정보의 수집·이용 목적]에서 명시한 범위 내에서 사용하며, 이용자의 사전 동의 없이 [개인정보의 수집·이용 목적]을 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 제공하지 않습니다.

다음의 경우에는 합당한 절차를 통한 이용자의 동의를 얻어 개인정보를 제공 또는 이용할 수 있습니다.

- 제휴관계: 보다 나은 서비스 제공을 위하여 당사는 이용자의 개인정보를 제휴사에 제공할 수 있습니다. 이 경우 사전에 이용자에게 제휴사, 제공되는 개인정보의 항목, 개인정보 제공 목적, 제공기간, 개인정보보호방안 등에 대해서 개별적으로 동의를 구하는 절차를 거치게 되며, 이용자의 동의가 없는 경우에는 제휴사에게 이를 제공하지 않으며, 제휴관계의 변화 또는 종결될 경우에도 고지합니다. 또한 제휴관계가 종결될 경우 제공된 개인정보에 대해서는 이용자님의 동의가 없는 경우 지체 없이 파기하도록 조치하며 개인정보 제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.

- 위탁처리: 회사는 개인정보를 위탁하지 않습니다. 다만, 원활하고 향상된 서비스 제공을 위해 필요한 때에는 개인정보의 취급을 일부 위탁할 수 있고 이 경우 위탁처리 기관 및 위탁업무의 내용을 고지합니다.

다음은 예외로 합니다.

- 이용자가 사전에 공개 또는 제 3자 제공에 동의한 경우
- 법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우


4. 이용자 및 법정대리인의 권리와 그 행사방법

이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며, 회원탈퇴를 요청할 수도 있습니다.

이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.

회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 아래 [개인정보의 보유 및 이용기간]에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.

타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.


5. 개인정보의 보유 및 이용기간

회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 이용자의 개인정보를 지체없이 파기합니다. 단, 관련법령 및 회사정책에 따라 보존할 필요가 있는 경우에는 일정 기간 동안 개인정보를 보관할 수 있습니다. 이용자에게 별도의 동의를 얻은 경우는 다음과 같습니다.

- 회원가입남용 및 징계기록 등의 부정이용 기록, 6개월

- 전자상거래 등에서의 소비자 보호에 관한 법률
계약 또는 청약철회 등에 관한 기록, 5년 보관
대금결제 및 재화 등의 공급에 관한 기록, 5년 보관
소비자의 불만 또는 분쟁처리에 관한 기록, 3년 보관

- 전자금융거래법
전자금융에 관한 기록, 5년 보관

- 통신비밀보호법
로그인 기록: 3개월


6. 개인정보 파기절차 및 방법

회사는 개인정보 보유기관의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 개인정보 파기의 절차 및 방법은 다음과 같습니다.

파기방법: 전자적 파일 형태로 저장된 개인정보는 다시 이용할 수 없도록 기술적 방법을 사용하여 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.


7. 개인정보의 보호대책

회사는 개인정보를 취급함에 있어 개인정보 분실, 도난, 누출, 변조, 훼손 등이 되지 않도록 안전성을 확보하기 위해 다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.

- 비밀번호의 암호화
이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

- 해킹 등에 대한 대책
최신 백신프로그램을 이용하여 이용자의 개인정보나 자료가 누출, 손상되지 않도록 방지하고 있습니다.
만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다.
민감한 개인정보는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.

- 개인정보 취급 최소화 및 교육
회사는 개인정보 관련 취급 담당자를 최소한으로 제한하며, 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를 강조하고 있습니다.

- 개인정보보호 전담 담당부서 운영
회사는 개인정보의 보호를 위해 개인정보보호 전담자를 지정해 관리하며, 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발생될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.


8. 개인정보관리책임자 및 담당자의 연락처

회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 관리책임자를 지정하고 있습니다. 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호와 관련된 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보관리책임자 혹은 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.


개인정보 관리책임자
성명 : 유성영
소속 : 마케팅팀
직위 : 팀장
이메일 : yu@tpirates.com
전화 : 010.4515.3797
주소 : 서울특별시 영등포구 선유로146

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
개인정보분쟁조정위원회 (http://kopico.go.kr / 1833-6972)
대검찰청 사이버수사과 (http://www.spo.go.kr/spo/index.jsp / 국번없이 1301)
경찰청 사이버안전국 (http://cyber.go.kr / 국번없이 182)


9. 개인정보취급방침의 개정과 그 고지

본 개인정보취급방침은 법령, 정부의 정책 또는 회사 내부 정책 등 필요에 의하여 변경될 수 있습니다. 내용 추가, 삭제 및 수정이 있을 때에는 개정 최소 7일에 홈페이지의 ‘공지사항’을 통해 고지할 것입니다. 다만, 이용자의 소중한 권리 또는 의무에 중요한 내용이 발생하는 경우에는 최소 30일 전에 고지합니다.

- 공고일자 : 2019년 1월 1일
- 시행일자 : 2019년 1월 1일
- version : 1.0

회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.

개인정보처리방침을 통해 회사가 어떤 개인정보를 수집하고, 어떻게 이용하며, 누구에게 제공하고 언제 어떻게 파기하는지 등에 대해 확인할 수 있으며, 변경사항이 발생할 경우 개인정보처리방침을 통해 안내하겠습니다.
`;

const _PRIVACY = () => {
  const [version, setVersion] = useState('v1.4');
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const { data } = useQuery(
    ['sellers', page],
    () =>
      client
        .get('/www/store/seller-list', { params: { page } })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
  if (version === 'v1.4') {
    return (
      <>
        {`주식회사 더파이러츠(이하 "회사"라 함)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.


1. 개인정보의 수집 항목

회사는 다음과 같이 이용자의 개인정보를 수집합니다. 회사는 회원가입, 본인확인 및 원활한 고객상담, 각종 서비스의 제공 등을 위해 아래와 같은 최소한의 개인정보를 수집하고 있습니다.

필수항목
- 이메일, 휴대전화 번호, 이름, 닉네임, 비밀번호, 암호화된 동일인 식별정보(CI,DI)

선택항목
- 성별, 생일

이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.
- SNS 계정정보(네이버, 페이스북, 카카오톡, 인스타그램, 트위터, 유튜브)

또한, 서비스 이용 과정에서 아래와 같은 선택항목의 정보가 수집될 수 있습니다.
- 사진, 주소, 단말기정보(OS, 화면사이즈, 디바이스 아이디), IP주소, 쿠키, 접속로그, 방문일시, 서비스 이용기록, 불량 이용기록

쿠키란 회사의 웹 사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 귀하께서 웹사이트에 접속을 하면 회사의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 아이디 등의 추가 입력없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
- 홈페이지, 어플리케이션(회원가입, 프로필편집, 계정설정 등)
- 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집


2. 개인정보의 수집·이용 목적

처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 관련 법률에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.

- 공통인증 이용 및 회원관리
- 서비스 개발·제공 및 향상
- 리뷰작성 등 서비스 이용
- 서비스 방문 및 이용기록의 통계 및 분석
- 마케팅 및 광고(쿠폰 및 이벤트 등) 활용


3. 개인정보의 제 3자 제공

회사는 일반적으로 이용자의 개인정보를 [개인정보의 수집-이용 목적]에서 명시한 범위 내에서 사용하며, 이용자의 사전 동의 없이 [개인정보의 수집-이용 목적] 범위를 초과하여 사용하거나 제 3자에게 제공하지 않습니다.
이용자 동의 후 개인정보 제공이 발생하는 경우, 제공받는 자와 제공 목적 등은 다음과 같습니다.
 
제공받는 자 : `}
        <button
          style={{
            backgroundColor: 'inherit',
            border: '1px solid black',
            cursor: 'pointer',
          }}
          type="button"
          onClick={() => {
            setOpen((state) => !state);
            setPage(0);
          }}
        >
          제휴사(입점 점포)
        </button>
        {open && (
          <Box
            border="1px solid #ddd"
            flexDirection="column"
            marginTop="8px"
            padding="4px"
          >
            <Box
              fontSize="16px"
              fontWeight={500}
              justifyContent="center"
              padding="4px"
            >
              점포명
            </Box>
            {data?.items?.map((seller) => (
              <Box
                borderTop="1px solid #ddd"
                justifyContent="center"
                padding="4px"
              >
                {seller}
              </Box>
            ))}
            <Box gap="8px" justifyContent="flex-end">
              <button
                style={{
                  backgroundColor: 'inherit',
                  border: '1px solid black',
                  cursor: 'pointer',
                }}
                type="button"
                onClick={() => {
                  setPage((page) => (page - 1 > 0 ? page - 1 : 0));
                }}
              >
                이전
              </button>
              <button
                style={{
                  backgroundColor: 'inherit',
                  border: '1px solid black',
                  cursor: 'pointer',
                }}
                type="button"
                onClick={() => {
                  setPage((page) =>
                    page + 1 >= data?.totalPages
                      ? data?.totalPages - 1
                      : page + 1
                  );
                }}
              >
                다음
              </button>
            </Box>
          </Box>
        )}
        {`
제공받는 자의 이용 목적 : 점포 이용사실 확인 및 보상
제공하는 항목 : 이름, 연락처, 계좌번호, 통화 및 문자 기록(모든 항목은 회원가입 시에는 수집되지 않음)
제공받는 자의 개인정보 보유 및 이용 기간 : 목적 달성 시 즉시 파기

개인정보처리자는 이용자의 동의를 받거나 법률의 규정 등 「개인정보 보호법」에서 허용한 경우 이외에는 개인정보를 제 3자에게 제공하지 않으며 이용자는 개인정보의 제 3자 제공 등의 거부 및 언제든지 철회할 권리가 있습니다.
이용자는 개인정보의 제 3자 제공 동의 거부 및 언제든지 철회할 권리가 있습니다. 단, 거부할 경우 중재 및 보상금 지급불가 등의 불이익이 생길 수 있습니다.


다음은 예외로 합니다.

- 이용자가 사전에 공개 또는 제 3자 제공에 동의한 경우
- 법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우


4. 이용자 및 법정대리인의 권리와 그 행사방법

이용자(혹은 법정대리인)은 자신(혹은 당해 만 14세 미만 아동)의 개인정보를 아래 링크의 '회원정보 수정'에서 언제든지 조회 및 수정하거나, '회원정보 수정> 회원 탈퇴'를 통해 삭제할 수 있습니다.

회원정보 수정 : `}
        <a href={`${conf.OPEN_MARKET_URL}/mypage/profile`}>
          {conf.OPEN_MARKET_URL}/mypage/profile
        </a>
        {`
        

이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.

회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 아래 [개인정보의 보유 및 이용기간]에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.

타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.


5. 개인정보의 보유 및 이용기간

회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 이용자의 개인정보를 지체없이 파기합니다. 단, 관련법령 및 회사정책에 따라 보존할 필요가 있는 경우에는 일정 기간 동안 개인정보를 보관할 수 있습니다. 이용자에게 별도의 동의를 얻은 경우는 다음과 같습니다.

- 회원가입남용 및 징계기록 등의 부정이용 기록, 6개월

- 전자상거래 등에서의 소비자 보호에 관한 법률
계약 또는 청약철회 등에 관한 기록, 5년 보관
대금결제 및 재화 등의 공급에 관한 기록, 5년 보관
소비자의 불만 또는 분쟁처리에 관한 기록, 3년 보관

- 전자금융거래법
전자금융에 관한 기록, 5년 보관

- 통신비밀보호법
로그인 기록: 3개월


6. 개인정보 파기절차 및 방법

회사는 개인정보 보유기관의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 개인정보 파기의 절차 및 방법은 다음과 같습니다.

파기방법: 전자적 파일 형태로 저장된 개인정보는 다시 이용할 수 없도록 기술적 방법을 사용하여 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.


7. 개인정보의 보호대책

회사는 개인정보를 취급함에 있어 개인정보 분실, 도난, 누출, 변조, 훼손 등이 되지 않도록 안전성을 확보하기 위해 다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.

- 비밀번호의 암호화
이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

- 해킹 등에 대한 대책
최신 백신프로그램을 이용하여 이용자의 개인정보나 자료가 누출, 손상되지 않도록 방지하고 있습니다.
만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다.
민감한 개인정보는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.

- 개인정보 취급 최소화 및 교육
회사는 개인정보 관련 취급 담당자를 최소한으로 제한하며, 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를 강조하고 있습니다.

- 개인정보보호 전담 담당부서 운영
회사는 개인정보의 보호를 위해 개인정보보호 전담자를 지정해 관리하며, 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발생될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.


8. 개인정보관리책임자 및 담당자의 연락처

회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 관리책임자를 지정하고 있습니다. 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호와 관련된 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보관리책임자 혹은 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.


개인정보 관리책임자
성명 : 유성영
소속 : 마케팅팀
직위 : 팀장
이메일 : yu@tpirates.com
전화 : 010.4515.3797
주소 : 서울특별시 영등포구 선유로146

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
개인정보분쟁조정위원회 (http://kopico.go.kr / 1833-6972)
대검찰청 사이버수사과 (http://www.spo.go.kr/spo/index.jsp / 국번없이 1301)
경찰청 사이버안전국 (http://cyber.go.kr / 국번없이 182)

9. 개인위치정보의 처리

회사는 위치정보의 보호 및 이용 등에 관한 법률에 따라 이용자의 개인위치정보를 안전하게 관리합니다.

- 개인위치정보의 처리목적 및 보유기간
회사는 대부분의 위치기반서비스에서 개인위치정보를 일회성 또는 임시로 이용 후 지체없이 파기합니다.
위치기반서비스를 제공하기 위해 개인위치정보를 처리하는 목적은 아래와 같습니다.
①위치정보를 이용한 주변업체 목록 제공

- 개인위치정보 수집ㆍ이용ㆍ제공사실 확인자료의 보유근거 및 보유기간
회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 이용자의 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 위치정보시스템에 자동으로 기록하며, 6개월 이상 보관합니다.

- 개인위치정보의 파기 절차 및 방법
회사는 개인위치정보의 처리목적이 달성된 경우, 개인위치정보를 재생이 불가능한 방법으로 안전하게 파기하고 있습니다. 전자적 파일 형태는 복구 및 재생이 불가능한 기술적인 방법으로 파기하며, 출력물 등은 분쇄하거나 소각하는 방식으로 파기합니다.

- 개인위치정보의 제3자 제공 및 통보에 관한 사항
회사는 이용자의 사전 동의 없이 개인위치정보를 제3자에게 제공하지 않으며, 이용자가 지정한 제3자에게 개인위치정보를 제공하는 경우 매회 개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다.

회사는 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인위치정보 제공에 직접 동의한 경우, 관련 법령에 의거해 회사에 개인위치정보 제출 의무가 발생한 경우, 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우에 한하여 개인위치정보를 제공합니다.

- 8세 이하의 등의 보호의무자 권리·의무 및 그 행사방법
회사는 아래의 경우에 해당하는 이용자(이하 “8세 이하의 아동 등”이라 함)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.

①8세 이하의 아동
②피성년후견인
③장애인복지법 제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 의하여 장애인등록을 한 사람에 한정)

8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.

- 위치정보 관리책임자의 정보
개인정보 보호책임자가 위치정보 관리책임자를 겸직하고 있습니다.

10. 개인정보취급방침의 개정과 그 고지

회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.

개인정보처리방침을 통해 회사가 어떤 개인정보를 수집하고, 어떻게 이용하며, 누구에게 제공하고 언제 어떻게 파기하는지 등에 대해 확인할 수 있으며, 변경사항이 발생할 경우 개인정보처리방침을 통해 안내하겠습니다.


본 개인정보취급방침은 아래의 시행일자부터 적용되며 법령, 정부의 정책 또는 회사 내부 정책 등 필요에 의하여 변경될 수 있습니다. 내용 추가, 삭제 및 수정이 있을 때에는 개정 최소 7일 전에 고지할 것입니다. 다만, 이용자의 소중한 권리 또는 의무에 중요한 내용이 발생하는 경우에는 최소 30일 전에 고지합니다.

- 공고일자 : 2023년 10월 31일
- 시행일자 : 2023년 11월 07일
- version : 1.4`}
        <br />
        <br />
        [이전 개인정보 처리방침 보기]
        <Box as="ul" flexDirection="column" gap="4px" marginTop="8px">
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.4');
              }}
            >
              v1.4
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.3');
              }}
            >
              v1.3
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.2');
              }}
            >
              v1.2
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.1');
              }}
            >
              v1.1
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.0');
              }}
            >
              v1.0
            </Box>
          </li>
        </Box>
      </>
    );
  }

  if (version === 'v1.3') {
    return (
      <>
        {`주식회사 더파이러츠(이하 "회사"라 함)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.


1. 개인정보의 수집 항목

회사는 다음과 같이 이용자의 개인정보를 수집합니다. 회사는 회원가입, 본인확인 및 원활한 고객상담, 각종 서비스의 제공 등을 위해 아래와 같은 최소한의 개인정보를 수집하고 있습니다.

필수항목
- 이메일, 휴대전화 번호, 이름, 닉네임, 비밀번호, 암호화된 동일인 식별정보(CI,DI)

선택항목
- 성별, 생일

이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.
- SNS 계정정보(네이버, 페이스북, 카카오톡, 인스타그램, 트위터, 유튜브)

또한, 서비스 이용 과정에서 아래와 같은 선택항목의 정보가 수집될 수 있습니다.
- 사진, 주소, 단말기정보(OS, 화면사이즈, 디바이스 아이디), IP주소, 쿠키, 접속로그, 방문일시, 서비스 이용기록, 불량 이용기록

쿠키란 회사의 웹 사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 귀하께서 웹사이트에 접속을 하면 회사의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 아이디 등의 추가 입력없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
- 홈페이지, 어플리케이션(회원가입, 프로필편집, 계정설정 등)
- 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집


2. 개인정보의 수집·이용 목적

처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 관련 법률에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.

- 공통인증 이용 및 회원관리
- 서비스 개발·제공 및 향상
- 리뷰작성 등 서비스 이용
- 서비스 방문 및 이용기록의 통계 및 분석
- 마케팅 및 광고(쿠폰 및 이벤트 등) 활용


3. 개인정보의 제 3자 제공

회사는 일반적으로 이용자의 개인정보를 [개인정보의 수집-이용 목적]에서 명시한 범위 내에서 사용하며, 이용자의 사전 동의 없이 [개인정보의 수집-이용 목적] 범위를 초과하여 사용하거나 제 3자에게 제공하지 않습니다.
이용자 동의 후 개인정보 제공이 발생하는 경우, 제공받는 자와 제공 목적 등은 다음과 같습니다.
 
제공받는 자 : `}
        <button
          style={{
            backgroundColor: 'inherit',
            border: '1px solid black',
            cursor: 'pointer',
          }}
          type="button"
          onClick={() => {
            setOpen((state) => !state);
            setPage(0);
          }}
        >
          제휴사(입점 점포)
        </button>
        {open && (
          <Box
            border="1px solid #ddd"
            flexDirection="column"
            marginTop="8px"
            padding="4px"
          >
            <Box
              fontSize="16px"
              fontWeight={500}
              justifyContent="center"
              padding="4px"
            >
              점포명
            </Box>
            {data?.items?.map((seller) => (
              <Box
                borderTop="1px solid #ddd"
                justifyContent="center"
                padding="4px"
              >
                {seller}
              </Box>
            ))}
            <Box gap="8px" justifyContent="flex-end">
              <button
                style={{
                  backgroundColor: 'inherit',
                  border: '1px solid black',
                  cursor: 'pointer',
                }}
                type="button"
                onClick={() => {
                  setPage((page) => (page - 1 > 0 ? page - 1 : 0));
                }}
              >
                이전
              </button>
              <button
                style={{
                  backgroundColor: 'inherit',
                  border: '1px solid black',
                  cursor: 'pointer',
                }}
                type="button"
                onClick={() => {
                  setPage((page) =>
                    page + 1 >= data?.totalPages
                      ? data?.totalPages - 1
                      : page + 1
                  );
                }}
              >
                다음
              </button>
            </Box>
          </Box>
        )}
        {`
제공받는 자의 이용 목적 : 점포 이용사실 확인 및 보상
제공하는 항목 : 이름, 연락처, 계좌번호, 통화 및 문자 기록(모든 항목은 회원가입 시에는 수집되지 않음)
제공받는 자의 개인정보 보유 및 이용 기간 : 목적 달성 시 즉시 파기

개인정보처리자는 이용자의 동의를 받거나 법률의 규정 등 「개인정보 보호법」에서 허용한 경우 이외에는 개인정보를 제 3자에게 제공하지 않으며 이용자는 개인정보의 제 3자 제공 등의 거부 및 언제든지 철회할 권리가 있습니다.
이용자는 개인정보의 제 3자 제공 동의 거부 및 언제든지 철회할 권리가 있습니다. 단, 거부할 경우 중재 및 보상금 지급불가 등의 불이익이 생길 수 있습니다.


다음은 예외로 합니다.

- 이용자가 사전에 공개 또는 제 3자 제공에 동의한 경우
- 법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우


4. 이용자 및 법정대리인의 권리와 그 행사방법

이용자(혹은 법정대리인)은 자신(혹은 당해 만 14세 미만 아동)의 개인정보를 아래 링크의 '회원정보 수정'에서 언제든지 조회 및 수정하거나, '회원정보 수정> 회원 탈퇴'를 통해 삭제할 수 있습니다.

회원정보 수정 : `}
        <a href={`${conf.OPEN_MARKET_URL}/mypage/profile`}>
          {conf.OPEN_MARKET_URL}/mypage/profile
        </a>
        {`
        

이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.

회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 아래 [개인정보의 보유 및 이용기간]에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.

타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.


5. 개인정보의 보유 및 이용기간

회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 이용자의 개인정보를 지체없이 파기합니다. 단, 관련법령 및 회사정책에 따라 보존할 필요가 있는 경우에는 일정 기간 동안 개인정보를 보관할 수 있습니다. 이용자에게 별도의 동의를 얻은 경우는 다음과 같습니다.

- 회원가입남용 및 징계기록 등의 부정이용 기록, 6개월

- 전자상거래 등에서의 소비자 보호에 관한 법률
계약 또는 청약철회 등에 관한 기록, 5년 보관
대금결제 및 재화 등의 공급에 관한 기록, 5년 보관
소비자의 불만 또는 분쟁처리에 관한 기록, 3년 보관

- 전자금융거래법
전자금융에 관한 기록, 5년 보관

- 통신비밀보호법
로그인 기록: 3개월


6. 개인정보 파기절차 및 방법

회사는 개인정보 보유기관의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 개인정보 파기의 절차 및 방법은 다음과 같습니다.

파기방법: 전자적 파일 형태로 저장된 개인정보는 다시 이용할 수 없도록 기술적 방법을 사용하여 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.


7. 개인정보의 보호대책

회사는 개인정보를 취급함에 있어 개인정보 분실, 도난, 누출, 변조, 훼손 등이 되지 않도록 안전성을 확보하기 위해 다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.

- 비밀번호의 암호화
이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

- 해킹 등에 대한 대책
최신 백신프로그램을 이용하여 이용자의 개인정보나 자료가 누출, 손상되지 않도록 방지하고 있습니다.
만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다.
민감한 개인정보는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.

- 개인정보 취급 최소화 및 교육
회사는 개인정보 관련 취급 담당자를 최소한으로 제한하며, 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를 강조하고 있습니다.

- 개인정보보호 전담 담당부서 운영
회사는 개인정보의 보호를 위해 개인정보보호 전담자를 지정해 관리하며, 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발생될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.


8. 개인정보관리책임자 및 담당자의 연락처

회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 관리책임자를 지정하고 있습니다. 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호와 관련된 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보관리책임자 혹은 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.


개인정보 관리책임자
성명 : 유성영
소속 : 마케팅팀
직위 : 팀장
이메일 : yu@tpirates.com
전화 : 010.4515.3797
주소 : 서울특별시 영등포구 선유로146

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
개인정보분쟁조정위원회 (http://kopico.go.kr / 1833-6972)
대검찰청 사이버수사과 (http://www.spo.go.kr/spo/index.jsp / 국번없이 1301)
경찰청 사이버안전국 (http://cyber.go.kr / 국번없이 182)


9. 개인정보취급방침의 개정과 그 고지

회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.

개인정보처리방침을 통해 회사가 어떤 개인정보를 수집하고, 어떻게 이용하며, 누구에게 제공하고 언제 어떻게 파기하는지 등에 대해 확인할 수 있으며, 변경사항이 발생할 경우 개인정보처리방침을 통해 안내하겠습니다.


본 개인정보취급방침은 아래의 시행일자부터 적용되며 법령, 정부의 정책 또는 회사 내부 정책 등 필요에 의하여 변경될 수 있습니다. 내용 추가, 삭제 및 수정이 있을 때에는 개정 최소 7일 전에 고지할 것입니다. 다만, 이용자의 소중한 권리 또는 의무에 중요한 내용이 발생하는 경우에는 최소 30일 전에 고지합니다.

- 공고일자 : 2023년 06월 09일
- 시행일자 : 2023년 06월 16일
- version : 1.3`}
        <br />
        <br />
        [이전 개인정보 처리방침 보기]
        <Box as="ul" flexDirection="column" gap="4px" marginTop="8px">
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.4');
              }}
            >
              v1.4
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.3');
              }}
            >
              v1.3
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.2');
              }}
            >
              v1.2
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.1');
              }}
            >
              v1.1
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.0');
              }}
            >
              v1.0
            </Box>
          </li>
        </Box>
      </>
    );
  }
  if (version === 'v1.2')
    return (
      <>
        {`주식회사 더파이러츠(이하 "회사"라 함)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.


1. 개인정보의 수집 항목

회사는 다음과 같이 이용자의 개인정보를 수집합니다. 회사는 회원가입, 본인확인 및 원활한 고객상담, 각종 서비스의 제공 등을 위해 아래와 같은 최소한의 개인정보를 필수 항목으로 수집하고 있습니다.
- 이메일, 휴대전화 번호, 이름, 닉네임, 비밀번호 외 사용자의 자발적 제공에 의해 수집된 정보

이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.
- SNS 계정정보(네이버, 페이스북, 카카오톡, 인스타그램, 트위터, 유튜브)

또한, 서비스 이용 과정에서 아래와 같은 선택항목의 정보가 수집될 수 있습니다.
- 사진, 주소, 단말기정보(OS, 화면사이즈, 디바이스 아이디), IP주소, 쿠키, 접속로그, 방문일시, 서비스 이용기록, 불량 이용기록

쿠키란 회사의 웹 사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 귀하께서 웹사이트에 접속을 하면 회사의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 아이디 등의 추가 입력없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
- 홈페이지, 어플리케이션(회원가입, 프로필편집, 계정설정 등)
- 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집


2. 개인정보의 수집·이용 목적

처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 관련 법률에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.

- 공통인증 이용 및 회원관리
- 서비스 개발·제공 및 향상
- 리뷰작성 등 서비스 이용
- 서비스 방문 및 이용기록의 통계 및 분석
- 마케팅 및 광고(쿠폰 및 이벤트 등) 활용


3. 개인정보의 제 3자 제공

회사는 일반적으로 이용자의 개인정보를 [개인정보의 수집-이용 목적]에서 명시한 범위 내에서 사용하며, 이용자의 사전 동의 없이 [개인정보의 수집-이용 목적] 범위를 초과하여 사용하거나 제 3자에게 제공하지 않습니다.
이용자 동의 후 개인정보 제공이 발생하는 경우, 제공받는 자와 제공 목적 등은 다음과 같습니다.
 
제공받는 자 : `}
        <button
          style={{
            backgroundColor: 'inherit',
            border: '1px solid black',
            cursor: 'pointer',
          }}
          type="button"
          onClick={() => {
            setOpen((state) => !state);
            setPage(0);
          }}
        >
          제휴사(입점 점포)
        </button>
        {open && (
          <Box
            border="1px solid #ddd"
            flexDirection="column"
            marginTop="8px"
            padding="4px"
          >
            <Box
              fontSize="16px"
              fontWeight={500}
              justifyContent="center"
              padding="4px"
            >
              점포명
            </Box>
            {data?.items?.map((seller) => (
              <Box
                borderTop="1px solid #ddd"
                justifyContent="center"
                padding="4px"
              >
                {seller}
              </Box>
            ))}
            <Box gap="8px" justifyContent="flex-end">
              <button
                style={{
                  backgroundColor: 'inherit',
                  border: '1px solid black',
                  cursor: 'pointer',
                }}
                type="button"
                onClick={() => {
                  setPage((page) => (page - 1 > 0 ? page - 1 : 0));
                }}
              >
                이전
              </button>
              <button
                style={{
                  backgroundColor: 'inherit',
                  border: '1px solid black',
                  cursor: 'pointer',
                }}
                type="button"
                onClick={() => {
                  setPage((page) =>
                    page + 1 >= data?.totalPages
                      ? data?.totalPages - 1
                      : page + 1
                  );
                }}
              >
                다음
              </button>
            </Box>
          </Box>
        )}
        {`
제공받는 자의 이용 목적 : 점포 이용사실 확인 및 보상
제공하는 항목 : 이름, 연락처, 계좌번호, 통화 및 문자 기록(모든 항목은 회원가입 시에는 수집되지 않음)
제공받는 자의 개인정보 보유 및 이용 기간 : 목적 달성 시 즉시 파기

개인정보처리자는 이용자의 동의를 받거나 법률의 규정 등 「개인정보 보호법」에서 허용한 경우 이외에는 개인정보를 제 3자에게 제공하지 않으며 이용자는 개인정보의 제 3자 제공 등의 거부 및 언제든지 철회할 권리가 있습니다.
이용자는 개인정보의 제 3자 제공 동의 거부 및 언제든지 철회할 권리가 있습니다. 단, 거부할 경우 중재 및 보상금 지급불가 등의 불이익이 생길 수 있습니다.


다음은 예외로 합니다.

- 이용자가 사전에 공개 또는 제 3자 제공에 동의한 경우
- 법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우


4. 이용자 및 법정대리인의 권리와 그 행사방법

이용자(혹은 법정 대리인)은 자신(혹은 당해 만 14세 미만 아동)의 개인정보를 아래 링크의 ‘내 정보’ 에서 언제든지 조회 및 수정하거나, ‘내 정보>탈퇴하기’를 통해 삭제할 수 있습니다.

(마이페이지 링크) https://www.tpirates.com/마이페이지


이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.

회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 아래 [개인정보의 보유 및 이용기간]에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.

타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.


5. 개인정보의 보유 및 이용기간

회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 이용자의 개인정보를 지체없이 파기합니다. 단, 관련법령 및 회사정책에 따라 보존할 필요가 있는 경우에는 일정 기간 동안 개인정보를 보관할 수 있습니다. 이용자에게 별도의 동의를 얻은 경우는 다음과 같습니다.

- 회원가입남용 및 징계기록 등의 부정이용 기록, 6개월

- 전자상거래 등에서의 소비자 보호에 관한 법률
계약 또는 청약철회 등에 관한 기록, 5년 보관
대금결제 및 재화 등의 공급에 관한 기록, 5년 보관
소비자의 불만 또는 분쟁처리에 관한 기록, 3년 보관

- 전자금융거래법
전자금융에 관한 기록, 5년 보관

- 통신비밀보호법
로그인 기록: 3개월


6. 개인정보 파기절차 및 방법

회사는 개인정보 보유기관의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 개인정보 파기의 절차 및 방법은 다음과 같습니다.

파기방법: 전자적 파일 형태로 저장된 개인정보는 다시 이용할 수 없도록 기술적 방법을 사용하여 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.


7. 개인정보의 보호대책

회사는 개인정보를 취급함에 있어 개인정보 분실, 도난, 누출, 변조, 훼손 등이 되지 않도록 안전성을 확보하기 위해 다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.

- 비밀번호의 암호화
이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

- 해킹 등에 대한 대책
최신 백신프로그램을 이용하여 이용자의 개인정보나 자료가 누출, 손상되지 않도록 방지하고 있습니다.
만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다.
민감한 개인정보는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.

- 개인정보 취급 최소화 및 교육
회사는 개인정보 관련 취급 담당자를 최소한으로 제한하며, 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를 강조하고 있습니다.

- 개인정보보호 전담 담당부서 운영
회사는 개인정보의 보호를 위해 개인정보보호 전담자를 지정해 관리하며, 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발생될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.


8. 개인정보관리책임자 및 담당자의 연락처

회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 관리책임자를 지정하고 있습니다. 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호와 관련된 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보관리책임자 혹은 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.


개인정보 관리책임자
성명 : 유성영
소속 : 마케팅팀
직위 : 팀장
이메일 : yu@tpirates.com
전화 : 010.4515.3797
주소 : 서울특별시 영등포구 선유로146

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
개인정보분쟁조정위원회 (http://kopico.go.kr / 1833-6972)
대검찰청 사이버수사과 (http://www.spo.go.kr/spo/index.jsp / 국번없이 1301)
경찰청 사이버안전국 (http://cyber.go.kr / 국번없이 182)


9. 개인정보취급방침의 개정과 그 고지

회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고 이와 관련한 문제를 빠르고 정확하게 처리하기 위해 다음과 같이 개인정보 처리방침을 수립합니다.

개인정보처리방침을 통해 회사가 어떤 개인정보를 수집하고, 어떻게 이용하며, 누구에게 제공하고 언제 어떻게 파기하는지 등에 대해 확인할 수 있으며, 변경사항이 발생할 경우 개인정보처리방침을 통해 안내하겠습니다.


본 개인정보취급방침은 아래의 시행일자부터 적용되며 법령, 정부의 정책 또는 회사 내부 정책 등 필요에 의하여 변경될 수 있습니다. 내용 추가, 삭제 및 수정이 있을 때에는 개정 최소 7일 전에 고지할 것입니다. 다만, 이용자의 소중한 권리 또는 의무에 중요한 내용이 발생하는 경우에는 최소 30일 전에 고지합니다.

- 공고일자 : 2021년 11월 7일
- 시행일자 : 2021년 11월 7일
- version : 1.2
`}
        <br />
        <br />
        [이전 개인정보 처리방침 보기]
        <Box as="ul" flexDirection="column" gap="4px" marginTop="8px">
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.4');
              }}
            >
              v1.4
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.3');
              }}
            >
              v1.3
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.2');
              }}
            >
              v1.2
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.1');
              }}
            >
              v1.1
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.0');
              }}
            >
              v1.0
            </Box>
          </li>
        </Box>
      </>
    );

  if (version === 'v1.1') {
    return (
      <>
        {v11}
        <br />
        <br />
        [이전 개인정보 처리방침 보기]
        <Box as="ul" flexDirection="column" gap="4px" marginTop="8px">
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.4');
              }}
            >
              v1.4
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.3');
              }}
            >
              v1.3
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.2');
              }}
            >
              v1.2
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.1');
              }}
            >
              v1.1
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.0');
              }}
            >
              v1.0
            </Box>
          </li>
        </Box>
      </>
    );
  }
  if (version === 'v1.0') {
    return (
      <>
        {v1}
        <br />
        <br />
        [이전 개인정보 처리방침 보기]
        <Box as="ul" flexDirection="column" gap="4px" marginTop="8px">
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.4');
              }}
            >
              v1.4
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.3');
              }}
            >
              v1.3
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.2');
              }}
            >
              v1.2
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.1');
              }}
            >
              v1.1
            </Box>
          </li>
          <li>
            <Box
              as="button"
              backgroundColor="inherit"
              border="1px solid grey"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVersion('v1.0');
              }}
            >
              v1.0
            </Box>
          </li>
        </Box>
      </>
    );
  }
};

const _LOCATION = `제1장 총 칙

제 1 조 (목적)
본 약관은 회원(인어교주해적단 서비스 약관에 동의한 자를 말합니다. 이하 “회원”이라 고 합니다.)이 주식회사 더파이러츠(이하 “회사”라고 합니다.)가 제공하는 인어교주해적단 서비스(이하 “서비스”라고 합니다)를 이용함에 있어 회사와 회원의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.

제 2 조 (이용약관의 효력 및 변경)
① 본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정 한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생합니다.
② 회원이 온라인에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.
③ 회사는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
④ 회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유 를 명시하여 현행약관과 함께 그 적용일자 10일 전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이 회원에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이 후 상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 회원에게 전자적 형태(전자 우편, SMS 등)로 약관 개정 사실을 발송하여 고지합니다.
⑤ 회사가 전항에 따라 회원에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 봅니다. 회원이 개정 약관에 동의하지 않을 경우 회원은 이용계약을 해지할 수 있습니다.

제 3 조 (관계법령의 적용)
약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명 시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따릅니다.

제 4 조 (서비스의 내용)
회사가 제공하는 서비스는 아래와 같습니다.

서비스 명 :  인어교주해적단
서비스 내용 :  이용자 위치를 기준으로 주변의 가까운 점포 정보(시세 등) 제공

제 5 조 (서비스 이용요금)
① 회사가 제공하는 서비스는 기본적으로 무료입니다. 단, 별도의 유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용 가능합니다.
② 회사는 유료 서비스 이용요금을 회사와 계약한 전자지불업체에서 정한 방법에 의하거나 회사가 정한 청구서에 합산하여 청구할 수 있습니다.
③ 유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 회사의 결제 이용약관 등 관계법에 따릅니다.
④ 회원의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있습니다.
⑤ 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사의 정책에 따릅니다.
⑥ MMS 등으로 게시물을 등록할 경우 발생하는 요금은 이동통신사의 정책에 따릅니다.

제 6 조 (서비스내용변경 통지 등)
① 회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 회원의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있습니다.
② ①항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트 등 기타 회사의 공 지사항을 통하여 회원들에게 통지할 수 있습니다.

제 7 조 (서비스이용의 제한 및 중지)
①회사는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하 거나 중지시킬 수 있습니다.
1. 회원이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우
2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우
3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때
5. 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우
②회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제 한기간 등을 회원에게 알려야 합니다.

제 8 조 (개인위치정보의 이용 또는 제공)
①회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명 시한 후 개인위치정보주체의 동의를 얻어야 합니다.
②회원 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
③회사는 타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 위치정보 이용·제공․ 사실 확인자료를 자동 기록·보존하며, 해당 자료는 6개월간 보관합니다.
④회사는 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수 집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통 보합니다. 단, 아래 각 호의 1에 해당하는 경우에는 회원이 미리 특정하여 지정한 통신 단 말장치 또는 전자우편주소로 통보합니다.
1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우
2. 회원이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우

제 9 조 (개인위치정보주체의 권리)
①회원은 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정 보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수 집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.
②회원은 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.
③회원은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 회원의 요구를 거절할 수 없습니다.
1. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료
2. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용
④회원은 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.

제 10 조 (법정대리인의 권리)
①회사는 14세 미만의 회원에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위 치정보의 제3자 제공에 대한 동의를 당해 회원과 당해 회원의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 제9조에 의한 회원의 권리를 모두 가집니다.
②회사는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용․제공사실 확인자료를 이용 약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세미만의 아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외 합니다.
1. 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용, 제공사 실 확인자료가 필요한 경우
2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가 공하여 제공하는 경우

제 11 조 (8세 이하의 아동 등의 보호의무자의 권리)
①회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.
1. 8세 이하의 아동
2. 금치산자
3. 장애인복지법 제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고 용촉진및직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복 지법 제29조의 규정에 의하여 장애인등록을 한 자에 한한다)
②8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
③보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.

제 12조 (개인위치정보의 보유목적 및 이용기간)
①회사는 위치기반서비스를 제공하기 위해 개인위치정보를 보유 및 이용합니다.
②회사는 위치기반서비스에서 개인위치정보를 일회성으로 이용 후 지체없이 파기합니다.

제 13 조 (위치정보관리책임자의 지정)
①회사는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도 록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.
②위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.

제 14 조 (손해배상)
①회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 회원에게 손해가 발생한 경우 회원은 회사에 대하여 손해배상 청구를 할 수 있습 니다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니 다.
②회원이 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 회원에 대하여 손해 배상을 청구할 수 있습니다. 이 경우 회원은 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.

제 15 조 (면책)
①회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
2. 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우
3. 회원의 귀책사유로 서비스 이용에 장애가 있는 경우
4. 제1호 내지 제3호를 제외한 기타 회사의 고의∙과실이 없는 사유로 인한 경우
②회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니합니다.

제 16 조 (규정의 준용)
①본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.
②본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다.

제 17 조 (분쟁의 조정 및 기타)
①회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방 송통신위원회에 재정을 신청할 수 있습니다.
②회사 또는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법 제43조의 규정에 의한 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.

제 18 조 (회사의 연락처)
회사의 상호 및 주소 등은 다음과 같습니다.
1. 상 호 : 주식회사 더파이러츠
2. 대 표 자 : 윤기홍
3. 주 소 : 서울특별시 영등포구 선유로 146 이앤씨드림타워 614호
4. 대표전화 : 02-2068-3234


부 칙

제1조 (시행일)  이 약관은 2020년 06월 1일부터 시행한다.
제2조 위치정보관리책임자는 2019년 01월을 기준으로 다음과 같이 지정합니다.
1. 소 속 : 경영지원실/권용길
2. 연락처 : 02-2068-3234
`;

const getContract = (viewType) => {
  switch (viewType) {
    case View.CONTRACT_SERVICE:
      return ['이용약관', _SERVICE];
    case View.CONTRACT_PRIVACY:
      return ['개인정보처리방침', <_PRIVACY />];
    case View.CONTRACT_LOCATION:
      return ['위치기반서비스이용약관', _LOCATION];
    case View.CONTRACT_SERVICE_TMP:
      return ['이용약관', _SERVICE];
    case View.CONTRACT_PRIVACY_TMP:
      return ['개인정보처리방침', <_PRIVACY />];
    case View.CONTRACT_LOCATION_TMP:
      return ['위치기반서비스이용약관', _LOCATION];
    default:
      return [null, null];
  }
};

const ContractView = ({ onClose, viewType }) => {
  const [title, contract] = getContract(viewType);

  return (
    <div className="fixed modal-rule" style={{ margin: '15px 15px 50px 15px' }}>
      <div className="head fixed">
        {title} 보기
        <button className="gModal-close" type="button" onClick={onClose}>
          <span className="img-bul close light">닫기</span>
        </button>
      </div>
      <div className="main fixed">
        <div className="section">
          <pre>{contract}</pre>
        </div>
      </div>
    </div>
  );
};
export default ContractView;

import { useInfiniteQuery } from '@tanstack/react-query';
import { getQuoteDetailLabels } from 'api/Prices';
import { convertMinToMS } from 'utils/convertTimeToMS';

import priceKeys from './keys';

export default function useGetQuoteDetailLabels({ filter, id }) {
  const marketCodeList = filter.location.map((item) => item.marketCode);
  const categoryCodeList = filter.product.map((item) => item.categoryCode);
  const originTypeList = filter.origin.map((item) => item.code);
  const raisedTypeList = filter.raised.map((item) => item.code);
  const unitTypeList = filter.unit.map((item) => item.code);
  const sizeTypeList = filter.size.map((item) => item.code);

  const params = {
    categoryCodeList,
    latitude: filter.around[0]?.lat ?? null,
    longitude: filter.around[0]?.long ?? null,
    marketCodeList,
    originTypeList,
    raisedTypeList,
    sizeTypeList,
    unitTypeList,
  };

  return useInfiniteQuery(
    priceKeys.getQuoteDetailLabels({ id, ...params }),
    ({ pageParam = 0 }) => {
      return getQuoteDetailLabels({ id, page: pageParam, params });
    },
    {
      enabled: !!id,

      getNextPageParam: (pagination) => {
        const { last, number } = pagination;
        return last ? null : number + 1;
      },

      select: (data) => data.pages,

      staleTime: convertMinToMS(5),
      // cacheTime: 0,
      suspense: true,
    }
  );
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRecommendLike } from 'api/marketFilter';
import contentsKeys from '../../Query/Contents/keys';
import { useContext } from 'react';
import { ToastContext } from 'context/toast';

export default function useUpdateRecommendContentsLike() {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);

  return useMutation(updateRecommendLike, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(contentsKeys.getRecommend());
      if (res.doLike) {
        showToast('즐겨찾기에 추가되었습니다.마이페이지에서 확인하세요.');
      } else {
        showToast('즐겨찾기 등록이 해제되었습니다.');
      }
    },
    onError: (err) => {},
  });
}

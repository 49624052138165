import { useCallback, useMemo, useState } from 'react';

export const useFindOutDragging = () => {
  const [mouseDownX, setMouseDownX] = useState(0);
  const [mouseUpX, setMouseUpX] = useState(0);
  const handleMouseDown = useCallback((e) => {
    setMouseDownX(e.clientX);
  }, []);
  const handleMouseUp = useCallback((e) => {
    setMouseUpX(e.clientX);
  }, []);
  const isDragging = useMemo(
    () => mouseDownX !== mouseUpX,
    [mouseDownX, mouseUpX]
  );

  return { handleMouseDown, handleMouseUp, isDragging };
};

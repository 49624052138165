import ErrorNotice from 'components/NotFound/ErrorNotice';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorContainer = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (window && errorMessage && errorMessage.includes('chunk')) {
      const pageAlreadyRefreshed = JSON.parse(
        window.sessionStorage.getItem('isErrorPageRefreshed') || 'false'
      );
      if (!pageAlreadyRefreshed) {
        window.sessionStorage.setItem('isErrorPageRefreshed', 'true');
        window.location.reload();
      }
    }
  }, [errorMessage]);

  return (
    <ErrorBoundary
      fallback={<ErrorNotice />}
      onError={(error) => {
        if (error) {
          setErrorMessage(error.message);
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorContainer;

import React from 'react';

const Close = ({ className = '', fill = '#464C53', onClick, size = 15 }) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={size}
      style={{ cursor: 'pointer' }}
      viewBox="0 0 15 15"
      width={size}
      onClick={onClick}
    >
      <path
        d="M20.075,5.752a1.133,1.133,0,0,0-1.6,0L12.908,11.3,7.344,5.74a1.134,1.134,0,0,0-1.6,1.6L11.3,12.908,5.74,18.471a1.134,1.134,0,0,0,1.6,1.6l5.563-5.563,5.563,5.563a1.134,1.134,0,0,0,1.6-1.6l-5.563-5.563,5.563-5.563A1.14,1.14,0,0,0,20.075,5.752Z"
        transform="translate(-5.408 -5.408)"
      />
    </svg>
  );
};

export default React.memo(Close);

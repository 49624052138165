import FixedCover from 'components/modules/FixedCover';
import React, { memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

const Slide = ({ backgroundColor, children, onClose, visible }) => {
  const onStopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  if (!visible) {
    return null;
  }

  return createPortal(
    <FixedCover visible={visible} noOverflow onClose={onClose}>
      <Wrapper backgroundColor={backgroundColor} onClick={onStopPropagation}>
        {children}
      </Wrapper>
    </FixedCover>,
    document.getElementById('__portal')
  );
};

const bottomUp = keyframes`
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  animation: ${bottomUp} 0.3s ease;
  background-color: ${({ backgroundColor }) => backgroundColor || '#f7f8f9'};
  border-radius: 12px 12px 0 0;
  bottom: 0;
  left: 0;
  max-height: 100%;
  overflow: auto;
  padding: 8px 16px 40px;
  position: absolute;
  right: 0;
  z-index: 61;
`;

export default memo(Slide);

/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const ModalView = ({
  children,
  handleClose,
  img,
  overflowNotFunction,
  style,
  toggle,
}) => {
  const _img = img || '';

  const scrollYRef = useRef(0);

  useEffect(() => {
    if (overflowNotFunction) return;
    if (toggle === 'on') {
      scrollYRef.current = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollYRef.current}px`;
      document.body.style.width = '100%';
    } else {
      document.body.style.position = 'unset';
      document.body.style.top = 'unset';
      document.body.style.width = 'unset';
      window.scrollTo(0, scrollYRef.current);
    }
  }, [toggle]);

  return (
    <>
      {toggle === 'on' && (
        <ModalWrap>
          <div
            className={`gModal${_img}`}
            onClick={() => {
              if (handleClose) {
                handleClose();
              }
            }}
          >
            <div className="gModal-content fixed" style={style}>
              {children}
            </div>
          </div>
          {/* <div className={'gModal-background' + _img} id="gModal-background" /> */}
        </ModalWrap>
      )}
    </>
  );
};

const ModalWrap = styled.div`
  overflow: hidden;

  .gModal {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .gModal::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

export default ModalView;

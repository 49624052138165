import React from 'react';
import closeImg from 'components/common/header/img/bul_close.svg';

const CloseButton = ({ onClose }) => {
  return (
    <a className="back" onClick={onClose}>
      <span className="img-bul close light">
        <img src={closeImg} alt="" />
      </span>
    </a>
  );
};

export default CloseButton;

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const getTime = (sec) => {
  const _sec = +sec;
  const hours = Math.floor(_sec / 3600);
  const minutes = Math.floor((_sec - hours * 3600) / 60);
  const seconds = _sec - hours * 3600 - minutes * 60;

  return { hours, minutes, seconds };
};

/**
 * @param {number} seconds
 */
const useTimer = (seconds) => {
  const interval = useRef();
  const [s, setS] = useState(seconds);
  const time = useMemo(() => getTime(s), [s]);

  const restart = useCallback(() => {
    clearInterval(interval.current);

    setS(seconds);

    interval.current = setInterval(() => {
      setS((seconds) => seconds - 1);
    }, 1000);
  }, [seconds]);

  useEffect(() => {
    interval.current = setInterval(() => {
      setS((seconds) => {
        return seconds - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    if (s <= 0) {
      clearInterval(interval.current);
    }
  }, [s]);

  return {
    hours: time.hours.toString().padStart(2, '0'),
    minutes: time.minutes.toString().padStart(2, '0'),
    restart,
    seconds: time.seconds.toString().padStart(2, '0'),
  };
};

export default useTimer;

import React, { useEffect, useState } from 'react';

import PanelItem from './_panel-item';
import './style.css';

const Order = ({ unit, order, price, onChange }) => {
  const [quantity, setQuantity] = useState(order.minQty);

  // const _handle_order_qty = (qty) => {
  //   setQuantity(qty);
  // };

  useEffect(() => {
    if (onChange) {
      onChange({
        qty: quantity,
        price: quantity * price,
      });
    }
  }, [quantity, onChange, price]);

  const isPriceInquiry = (price, unit) => {
    const num = Number(price || 0);
    const isNotNumber = isNaN(num) || num === Infinity || !num;
    return isNotNumber || num === 1
      ? '전화 또는 카카오톡으로 문의해 주세요'
      : `${new Intl.NumberFormat().format(num)}원 / ${unit} 당`;
  };

  return (
    <section>
      <PanelItem label="시세">
        <span>{isPriceInquiry(price, unit.name)}</span>
      </PanelItem>
    </section>
  );
};

const _OrderControl = ({
  value,
  onDecrease,
  onIncrease,
  onChange,
  onBlur,
  style,
}) => {
  return (
    <span style={style} className="order-control">
      <i className="btn minus" onClick={() => onDecrease()} />
      <input
        type="number"
        pattern="\d*"
        value={value}
        style={{ width: '30px' }}
        onClick={(e) => e.target.select()}
        onBlur={(e) => {
          const val = parseInt(e.target.value.replace(/[^0-9]/g, ''))
            ? parseInt(e.target.value.replace(/[^0-9]/g, ''))
            : 0;
          if (onBlur) onBlur(val);
        }}
        onChange={(e) => {
          const val = parseInt(e.target.value.replace(/[^0-9]/g, ''))
            ? parseInt(e.target.value.replace(/[^0-9]/g, ''))
            : 0;
          onChange(val);
        }}
      />
      <i className="btn plus" onClick={() => onIncrease()} />
    </span>
  );
};

// const OrderControl = ({ order, unit, onChange, style, pStyle }) => {
//   const reducer = (state, action) => {
//     switch (action.type) {
//       case 'INCREASE':
//         return state + 1;
//       case 'DECREASE':
//         return state === order.minNum ? order.minNum : state - 1;
//       case 'CHANGE':
//         return order.minNum > action.payload ? order.minNum : action.payload;
//     }
//   };
//   const [state, dispatch] = useReducer(reducer, order.minNum);

//   useEffect(() => {
//     onChange(state * unit.qty);
//   }, [state]);

//   return (
//     <div style={pStyle}>
//       <_OrderControl
//         value={state}
//         onDecrease={() => dispatch({ type: 'DECREASE' })}
//         onIncrease={() => dispatch({ type: 'INCREASE' })}
//         onChange={(val) => dispatch({ type: 'CHANGE', payload: val })}
//         style={style}
//       />
//       <span style={{ marginLeft: '10px', lineHeight: '30px' }}>
//         {unit.label}({(unit.qty * state).toFixed(1)}
//         {unit.name})
//       </span>
//     </div>
//   );
// };

export default Order;

export { _OrderControl };

import './style.css';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const isPriceInquiry = (data) => {
  const num = Number(data || 0);
  const isNotNumber = isNaN(num) || num === Infinity || !num;
  return isNotNumber || num === 1
    ? '문의'
    : `${new Intl.NumberFormat().format(num)}원`;
};

const Store = ({ item }) => {
  return (
    <Link to={item.spotStoreList[0].permalink}>
      <li className="txt-ellipsis">
        <strong className="item">
          {item.name} {item.grade}
        </strong>
        <span className="price">{isPriceInquiry(item.spotPrice)}</span>
      </li>
    </Link>
  );
};

const Item = ({ item, onClick, toggleSpot }) => {
  return (
    <>
      <li className="txt-ellipsis" onClick={onClick}>
        <strong className="item">{item.grade}</strong>
        <span className="price">{isPriceInquiry(item.minPrice)}</span>
      </li>
      {toggleSpot && (
        <li className="spot-list">
          <div className="indicator" />
          {item.spotStoreList.map((spot, idx) => (
            <Link key={`spot_store_list_item_${idx}`} to={spot.permalink}>
              <span className="spot-label">{spot.label}</span>
              <span className="spot-zone">{spot.zoneLabel}</span>
              <span className="price">{isPriceInquiry(spot.price)}</span>
            </Link>
          ))}
        </li>
      )}
    </>
  );
};

const Board = ({ group, sort }) => {
  const [toggle, setToggle] = useState(-1);

  const _handle_toggle = (idx) => {
    if (toggle === idx) {
      setToggle(-1);
    } else {
      setToggle(idx);
    }
  };

  const _render = ({ item, key, onClick, sort, toggleSpot }) =>
    sort === 'store' ? (
      <Store key={key} item={item} />
    ) : (
      <Item key={key} item={item} toggleSpot={toggleSpot} onClick={onClick} />
    );

  return (
    <>
      <TitleWrap>
        <Title>{group.name}</Title>
        <SubTitle className="unit">{group.description}</SubTitle>
      </TitleWrap>
      <div className="section white type3">
        <div className="card-template type9">
          <div className="inner-section _wholesales-board">
            <ul>
              {group.items.map((item, i) =>
                _render({
                  item,
                  key: i,
                  onClick: () => _handle_toggle(i),
                  sort,
                  toggleSpot: toggle === i,
                })
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

const TitleWrap = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  background: ${({ theme }) => theme.white200};
  border-bottom: 1px solid ${({ theme }) => theme.gray100};
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.gray500};
`;
const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.gray400};
`;

export default Board;

import { DialogContextProvider } from '@locked-dobby/library-react-dialog';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import DialogContainerWrap from 'component/dialog/_dialogContainerWrap';
import Confirm from 'component/dialog/confirm';
import { Toast } from 'component/dialog/toast';
import { Header } from 'components/common';
import FooterFixed from 'components/common/footer_fixed';
import { footerPaths, headerNotPaths } from 'components/common/header';
import { LoginProvider } from 'components/common/login/_context';
import ScrollProvider from 'components/common/ScrollProvider';
import DialogContainer from 'components/DialogContainer';
import NotFound from 'components/NotFound';
import ReportPopup from 'components/reviews/form/_report-popup';
import { APP } from 'context';
import { load_setting } from 'context/global/app';
import { check_session } from 'context/global/session';
import ToastProvider from 'context/toast';
import * as pages from 'pages';
import Search from 'pages/Search';
import { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { GlobalStyle } from 'style/GlobalStyle';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import ErrorContainer from 'utils/ErrorContainer';
import { storePrevPath } from 'utils/storePrevPath';

import MarketingComponent from './component/dialog/marketingComponent';
import { theme } from './styles';

const {
  Callback,
  Content,
  Contents,
  Coupons,
  Home,
  MyCoupon,
  // HowToOrder,
  MyLike,
  MyMessage,
  MyOrder,
  MyPage,
  MyReview,
  OrderConfirm,
  OrderHistoryDetail,
  PortalSearch,
  Price,
  PriceDetail,
  ProductDetail,
  RecommendPrice,
  ReviewEvent,
  Reviews,
  Store,
  Stores,
  UpdateProductDetail,
  Wholesale,
  WholesaleItem,
  WholesaleOrder,
} = pages;

const excludedPaths = [
  '/search',
  '/마이페이지/내주문',
  '/마이페이지/내주문/detail',
  '/마이페이지/내쿠폰',
  '/마이페이지/내댓글',
  '/쿠폰/모든쿠폰',
];

const options = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
};

const queryClient = new QueryClient(options);

const AppContainer = ({ create_session, loader }) => {
  const location = useLocation();

  useEffect(() => {
    loader();
  }, [loader]);

  useEffect(() => storePrevPath, [location]);

  const shouldRenderFooter = !excludedPaths.includes(location.pathname);
  return (
    <ThemeProvider theme={theme}>
      <DialogContextProvider
        Confirm={Confirm}
        DialogContainer={({ children }) => {
          return <DialogContainerWrap>{children}</DialogContainerWrap>;
        }}
        Toast={Toast}
      >
        <ToastProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ScrollProvider>
              <LoginProvider
                onComplete={({ identity, integration, provider }) => {
                  create_session(provider, identity, integration);
                }}
              >
                <MarketingComponent>
                  <GlobalStyle />
                  <ErrorContainer>
                    <Header />
                    <Suspense fallback={<div />}>
                      <StyleSheetManager disableCSSOMInjection>
                        <Switch>
                          <Route component={Home} path="/" exact />
                          <Route component={Home} path="/홈" exact />
                          <Route
                            component={RecommendPrice}
                            path="/추천/:contentId"
                            exact
                          />
                          <Route
                            component={(props) => <Stores {...props} />}
                            path="/시장"
                            exact
                          />
                          <Route component={Contents} path="/콘텐츠" exact />
                          <Route
                            component={(params) => (
                              <ErrorBoundary fallback={<NotFound />}>
                                <Content {...params} />
                              </ErrorBoundary>
                            )}
                            path="/콘텐츠/:id/:label"
                          />
                          <Route component={Price} path="/시세" exact />
                          <Route
                            component={PriceDetail}
                            path="/시세/:id"
                            exact
                          />
                          <Route component={Wholesale} path="/도매" exact />
                          <Route
                            component={WholesaleOrder}
                            path="/도매/내주문"
                            exact
                          />
                          <Route component={Search} path="/도매/search" exact />
                          <Route
                            component={WholesaleItem}
                            path="/도매/:id/:label"
                            exact
                          />
                          <Route
                            component={Coupons}
                            path="/쿠폰/모든쿠폰"
                            exact
                          />
                          <Route component={Reviews} path="/후기" exact />
                          <Route component={MyPage} path="/마이페이지" exact />
                          <Route
                            component={MyCoupon}
                            path="/마이페이지/내쿠폰"
                            exact
                          />
                          <Route
                            component={MyLike}
                            path="/마이페이지/내관심정보"
                            exact
                          />
                          <Route
                            component={MyReview}
                            path="/마이페이지/내댓글"
                            exact
                          />
                          <Route
                            component={MyOrder}
                            path="/마이페이지/내주문"
                            exact
                          />
                          <Route
                            component={OrderHistoryDetail}
                            path="/마이페이지/내주문/detail/:orderCode"
                            exact
                          />
                          <Route
                            component={MyMessage}
                            path="/마이페이지/내메시지"
                            exact
                          />
                          <Route
                            component={OrderConfirm}
                            path="/order/:id/confirm"
                            exact
                          />
                          <Route
                            component={Callback}
                            path="/order/callback"
                            exact
                          />
                          <Route component={Search} path="/search" exact />
                          <Route
                            component={PortalSearch}
                            path="/portal/search"
                            exact
                          />
                          {/* <Route component={HowToOrder} path="/how-to-order" exact /> */}
                          <Route
                            component={() => <ProductDetail />}
                            path="/menu/:store/:productId"
                          />
                          <Route
                            component={() => <UpdateProductDetail />}
                            path="/update-menu/:store/:productId"
                          />
                          <Route
                            component={() => <ReviewEvent />}
                            path="/review-event/:store/:storeCode"
                          />
                          <Route
                            component={(params) => <Store {...params} />}
                            path="/:store"
                          />
                          <Route component={NotFound} path="*" exact />
                        </Switch>
                      </StyleSheetManager>
                    </Suspense>
                    {shouldRenderFooter && (
                      <FooterFixed
                        smallHeader={
                          footerPaths.includes(location.pathname) ||
                          !headerNotPaths.includes(location.pathname)
                        }
                      />
                    )}
                    <ReportPopup />
                  </ErrorContainer>
                </MarketingComponent>
              </LoginProvider>
              <DialogContainer />
            </ScrollProvider>
          </QueryClientProvider>
        </ToastProvider>
      </DialogContextProvider>
    </ThemeProvider>
  );
};

export default connect(({ [APP]: global }) => ({ setting: global.setting }), {
  check_session,
  loader: load_setting,
})(AppContainer);

import './style.css';

import { SubLogo } from '@thepirates/icons';
import getAccountUrl from 'api/getAccountUrl';
import { Box } from 'components/atoms';
import { Logo } from 'components/Icons';
import ChevronLeft from 'components/Icons/ChevronLeft';
import CloseThin from 'components/Icons/CloseThin';
import SingleLens from 'components/Icons/SingleLens';
import Icon from 'components/Icons/v2/Icon';
import { conf } from 'conf/settings';
import usePriceSortState from 'hooks/custom/usePriceSortState';
import { useGetCartsCount } from 'hooks/Query/Cart';
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { globalSearchKeyword } from 'recoil/search';
import styled, { css } from 'styled-components';
const COOKIE_KEY_RECENTLY = 'pirates-component/RECENTLY.KEYWORDS';
const PAGE_STATE = 'pirates-component/PAGE_STATE';

const HeaderFixed = ({ session }) => {
  const [globalKeyword, setGlobalKeyword] = useRecoilState(globalSearchKeyword);
  const { initializePriceState } = usePriceSortState();
  const [searchMode, setSearchMode] = useState({
    bool: false,
    recommend: false,
    text: '',
  });
  const history = useHistory();
  const location = useLocation();
  const [, setCookie] = useCookies([COOKIE_KEY_RECENTLY]);

  const isLogin = localStorage.getItem('isSignedIn');
  const redirectUrl = `${conf.CART_URL}`;
  const { data } = useGetCartsCount();
  const cartCount = useMemo(() => !!data && data?.cartCount, [data]);

  const include = useMemo(() => {
    const tabExclude = ['/도매'];
    const searchExclude = ['/도매/장바구니', '/도매/내주문'];
    const colorExclude = ['/스토어'];
    return {
      color: !colorExclude.includes(location.pathname),
      search: !searchExclude.includes(location.pathname),
      tab: !tabExclude.includes(location.pathname),
    };
  }, [location]);

  const isWholesale = location.pathname === '/도매';
  const handleClickClose = () => {
    setGlobalKeyword('');
    history.push(isWholesale ? '/도매/search' : '/search');
  };

  const handleClickBack = () => {
    setGlobalKeyword('');
    initializePriceState();
    history.replace({ pathname: location.pathname, search: '' });
  };

  const handleClickInput = () => {
    history.push(isWholesale ? '/도매/search' : 'search');
  };

  useEffect(() => {
    const initGlobalKeyword = (e) => {
      if (globalKeyword.length) {
        setGlobalKeyword('');
        if (location.search.length) {
          history.replace({ pathname: location.pathname, search: '' });
          return;
        }
        window.history.go(1);
      }
    };
    window.addEventListener('popstate', initGlobalKeyword);
    return () => window.removeEventListener('popstate', initGlobalKeyword);
  }, [globalKeyword, setGlobalKeyword]);

  return (
    <>
      <div style={{ marginBottom: searchMode.bool && '44px' }} />
      <HeaderFixedStyle
        hideLine={isWholesale}
        isOpen={searchMode.recommend}
        nonTabMode={!include.tab}
        searchMode={include.search}
      >
        {globalKeyword.length ? (
          <SearchContainer>
            <ChevronLeft fill="black" size={18} onClick={handleClickBack} />
            <InputWrapper>
              <InputDiv onClick={handleClickInput}>{globalKeyword}</InputDiv>
              <SingleLens className="single-lens" fill="#464C53" size={18} />
              <CloseThin
                className="close"
                size={12}
                onClick={handleClickClose}
              />
            </InputWrapper>
          </SearchContainer>
        ) : (
          <div
            className={`header-top-content ${include.color ? 'market' : ''}`}
          >
            <Box
              alignItems="center"
              gap="4px"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                if (
                  history.location.pathname !== '/' &&
                  history.location.pathname !== '/홈'
                )
                  history.push('/');
                else window.location.reload();
              }}
            >
              <Logo size={30} />
              <SubLogo />
            </Box>
            <Box
              marginLeft="auto"
              style={{ alignItems: 'center', display: 'flex', gap: '12px' }}
            >
              <Box>
                <IconDiv
                  onClick={() => {
                    history.push(isWholesale ? '/도매/search' : '/search');
                  }}
                >
                  <Icon
                    color="#464C53"
                    disableFill={false}
                    name="search_new-24_2"
                  />
                </IconDiv>
              </Box>
              {location.pathname !== '/도매' && (
                <Box marginLeft="auto" position="relative">
                  <IconDiv
                    onClick={() => {
                      // 비로그인 상태에서 장바구니 클릭 시
                      if (isLogin === 'notLogin' || !isLogin) {
                        window.location.href = `${getAccountUrl({
                          redirectUrl,
                        })}`;
                      } else {
                        setCookie(
                          PAGE_STATE,
                          { pathname: location.pathname, storeCode: '' },
                          {
                            domain: `${conf.domain}`,
                            path: '/',
                            secure: true,
                          }
                        );

                        window.location.href = `${conf.CART_URL}`;
                      }
                    }}
                  >
                    {cartCount > 0 && isLogin && (
                      <CartItemCount isOverMax={cartCount > 99}>
                        <span>{cartCount > 99 ? '99+' : cartCount}</span>
                      </CartItemCount>
                    )}
                    <Icon
                      color="#464C53"
                      disableFill={false}
                      name="cart_new-24_2"
                    />
                  </IconDiv>
                </Box>
              )}
            </Box>
          </div>
        )}
        {include.search && (
          <div className="search-form">
            {!searchMode.recommend && include.tab && (
              <TabsWrapper height="44px">
                <TabWrapper
                  active={
                    location.pathname === '/' ||
                    location.pathname.startsWith('/홈')
                  }
                  ml="24px"
                  mr="54px"
                >
                  <Link to="/홈">추천</Link>
                </TabWrapper>
                <TabWrapper
                  active={location.pathname.startsWith('/시장')}
                  mr="54px"
                >
                  <Link to="/시장">시장</Link>
                </TabWrapper>
                <TabWrapper
                  active={location.pathname.startsWith('/시세')}
                  mr="54px"
                >
                  <Link to="/시세">시세</Link>
                </TabWrapper>
                <TabWrapper
                  active={location.pathname.startsWith('/콘텐츠')}
                  mr="24px"
                >
                  <Link to="/콘텐츠">콘텐츠</Link>
                </TabWrapper>
              </TabsWrapper>
            )}
          </div>
        )}
      </HeaderFixedStyle>
    </>
  );
};

const CartItemCount = styled.div`
  position: absolute;
  right: ${({ isOverMax }) => (isOverMax ? '-6px' : '0px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--new-red-red-9, #f43636);
  color: wheat;
  border-radius: 100px;
  width: ${({ isOverMax }) => (isOverMax ? '18px' : '12px')};
  height: 12px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    right: ${({ isOverMax }) => (isOverMax ? '6.5px' : '1.5px')};
    color: wheat;
    font-size: 8px;
    text-align: center;
    line-height: 8px;
    width: 12px;
    height: 12px;
  }
`;

const IconDiv = styled.div`
  cursor: pointer;
`;

const InputDiv = styled.div`
  height: 40px;
  font-size: 16px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.white100};
  border: 1px solid ${({ theme }) => theme.blue300};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 46px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 90%;
  .single-lens {
    position: absolute;
    left: 18px;
    top: 12px;
  }
  .close {
    position: absolute;
    width: 15px;
    right: 14px;
    top: 6px;
  }
`;
const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px;
`;

const TabWrapper = styled(Box)`
  cursor: pointer;
  position: relative;

  & > a {
    font-size: 18px;
    letter-spacing: -0.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    line-height: 26px;
    ${({ active }) =>
      active && { borderBottom: '2px solid #333', fontWeight: 700 }}
  }

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.newGray12} !important;
      font-weight: 700 !important;

      ::after {
        content: '';
        position: absolute;
        bottom: 0;
      }
    `}
`;

const TabsWrapper = styled(Box)`
  justify-content: flex-start;
  gap: 32px;
  color: ${({ theme }) => theme.newGray6};

  & * {
    font: inherit;
    color: inherit;
  }
`;

const HeaderFixedStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: ${({ isOpen }) => {
    return isOpen && 0;
  }};
  margin: 0 auto;
  min-width: 360px;
  max-width: 420px;
  width: 100%;
  padding: 0 16px;

  background: #fff;
  /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important; */
  border-bottom: ${({ hideLine }) => (hideLine ? '' : '1px solid #c9cdd2')};
  box-sizing: border-box;
  z-index: 40;

  & + .container {
    padding-top: ${({ nonTabMode, searchMode }) => {
      if (nonTabMode || !searchMode) return '56px';
      return 'calc(56px + 44px)';
    }};
  }

  & > .header-top-content {
    display: flex;
    align-items: center;
    height: 56px;
    margin: 0;
    box-sizing: border-box;

    &.market > .top-right > .top-button-wrap {
      & > button.ON {
        color: #fff;
        background: #1d79bc;
        cursor: default;
      }
    }
    & > .top-right {
      flex: 1;
    }

    & > img {
      height: 28px;
      cursor: pointer;
    }

    & > .top-right::after {
      content: '';
      display: block;
      clear: both;
      height: 0;
    }

    & > .top-right > .top-button-wrap {
      float: right;
      & > button {
        height: 23px;
        line-height: 23px;
        padding: 0 8px;

        border-radius: 12px;

        font-size: 15px;
        font-weight: 500;
        color: #0e4e97;

        background: none;
        border: none;
        box-sizing: border-box;

        cursor: pointer;
      }
      & > button:hover {
        color: #1c79bc;
      }
      & > button + button {
      }
      & > button.ON {
        color: #fff;
        background: #0e4e97;
        cursor: default;
      }
    }
  }

  & > .search-form {
    & > .search-keywords {
      display: flex;
      align-items: center;
      height: 44px;
      padding: 0 12px;

      & > img {
        width: 18px;
        height: 18px;
        filter: invert(14%) sepia(50%) saturate(16%) hue-rotate(315deg)
          brightness(103%) contrast(87%);
        cursor: pointer;
      }
      & > img + span {
        margin-left: 15px;
      }
      & > span {
        color: #333;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        & + span {
          margin-left: 16px;
        }
      }
    }
    & > .search-input-wrap {
      /* min-height: calc(100vh - 56px - 60px); */
      & > .search-input-padding {
        display: flex;
        height: 44px;
        align-items: center;
        > img {
          margin-left: 10px;
          cursor: pointer;
        }
      }
      & .search-input {
        position: relative;
        flex: 1;
        height: 34px;
        border-radius: 5px;
        background: #eee;
        overflow: hidden;

        & > img {
          position: absolute;
          top: 50%;
          left: 12px;
          transform: translateY(-50%);

          width: 18px;
          height: 18px;
          filter: invert(48%) sepia(8%) saturate(19%) hue-rotate(342deg)
            brightness(94%) contrast(86%);
          cursor: pointer;
        }
        & > input {
          width: 100%;
          height: 100%;
          padding-left: 40px;
          padding-right: 46px;

          color: #333;

          border: none;
          background: none;
          box-sizing: border-box;
        }
        & > input::placeholder {
          color: #777777;
        }
        & > button {
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);

          color: #777;
          font-weight: 500;
          cursor: pointer;
          background: none;
        }
        & > span:hover {
          color: #333;
        }
      }

      & > .search-recommend {
        min-height: calc(100vh - 56px - 60px - 44px);
        margin-top: 16px;

        & > .search-recommend-title,
        & > .search-recent-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 30px;
          padding: 8px 0 12px;
          margin-bottom: 12px;
          border-bottom: 1px solid #eeeeee;

          & > span {
            font-size: 15px;
            font-weight: 500;
            color: #333;
          }
          & > img {
            width: 18px;
            height: 18px;
            filter: invert(14%) sepia(50%) saturate(16%) hue-rotate(315deg)
              brightness(103%) contrast(87%);
            cursor: pointer;
          }
        }
        & > .search-recommend-keywords,
        & > .search-recent-keywords {
          display: flex;
          flex-wrap: wrap;
          & + .search-recent-title {
            margin-top: 10px;
          }

          & > span {
            display: inline;
            height: 36px;
            padding: 0 16px;
            line-height: 35px;
            border: 1px solid #ddd;
            border-radius: 50rem;

            color: #777;
            font-weight: 400;

            box-sizing: border-box;
            cursor: pointer;
            margin-right: 8px;
            margin-bottom: 8px;

            &:hover {
              color: #333;
              border-color: #ddd;
              background-color: #ddd;
              transition:
                color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            }
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export default HeaderFixed;

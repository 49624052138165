import {
  Container,
  SectionInnerText,
  SectionInnerTitle,
  SectionTitle,
} from 'components/mypage/myOrder/orderHistoryDetail';
import styled from 'styled-components';
import { phoneParser } from 'utils';

const OrderDelivery = ({ delivery }) => {
  return (
    <Container>
      <SectionTitle>배송지</SectionTitle>
      <Box>
        <TypoBox>
          <SectionInnerTitle>이름</SectionInnerTitle>
          <SectionInnerText>{delivery.receiverName}</SectionInnerText>
        </TypoBox>

        <TypoBox>
          <SectionInnerTitle>연락처</SectionInnerTitle>
          <SectionInnerText>{phoneParser(delivery.phone)}</SectionInnerText>
        </TypoBox>

        <TypoBox>
          <SectionInnerTitle>배송지</SectionInnerTitle>
          <SectionInnerText>{`${delivery.address} ${delivery.addressDetail}`}</SectionInnerText>
        </TypoBox>
      </Box>
    </Container>
  );
};

const TypoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export default OrderDelivery;

import DragSliderCarousel from 'components/atoms/DragSliderCarousel/DragSliderCarousel';
import { useCalcLabelOffset } from 'components/templates/Store/hook/useCalcLabelOffset';
import { useFindOutDragging } from 'hooks/custom/useFindOutDragging';
import { useRef } from 'react';
import styled from 'styled-components';

const TagItem = ({ handleMouseDown, handleMouseUp, onClick, tag, value }) => {
  return (
    <Tag
      isActive={value.code === tag.code}
      onClick={() => onClick(tag)}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {tag.label}
    </Tag>
  );
};

const TagFilter = ({ filterHandler, tags, value }) => {
  const ref = useRef(null);
  const { offsetLeft } = useCalcLabelOffset({
    calcDeps: tags,
    targetRef: ref,
  });

  const { handleMouseDown, handleMouseUp, isDragging } = useFindOutDragging();

  const handleClick = (tag) => {
    if (isDragging) return;
    filterHandler(tag);
  };

  return (
    <Container>
      {tags && (
        <DragSliderCarousel
          animate={{ x: -offsetLeft }}
          carouselWidthCalcDep={tags}
          handleMouseDown={handleMouseDown}
          handleMouseUp={handleMouseUp}
          isDragging={isDragging}
          itemGap={32}
        >
          {tags.map((tag, index) => (
            <TagItem
              key={`tag${index}`}
              handleMouseDown={handleMouseDown}
              handleMouseUp={handleMouseUp}
              tag={tag}
              value={value || { code: 'all' }}
              onClick={() => handleClick(tag)}
            />
          ))}
        </DragSliderCarousel>
      )}
    </Container>
  );
};

const Tag = styled.div`
  color: ${({ isActive }) =>
    isActive
      ? 'var(--new-gray-gray-12, #1A1B1E)'
      : 'var(--new-gray-gray-6, #8a8d90)'};
  font-family: 'Noto Sans KR';
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  line-height: 24px;
  letter-spacing: -0.3px;
  padding: 11px 0 7px 0;

  border-bottom: ${({ isActive }) => isActive && '2px solid'};
`;

const Container = styled.div`
  width: 100%;
`;

export default TagFilter;

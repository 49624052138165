import dayjs from 'dayjs';
import styled from 'styled-components';
const DAYS = ['일', '월', '화', '수', '목', '금', '토'];
const ORDERTYPE = {
  1: '퀵 배달',
  2: '포장',
  3: '당일배송',
  4: '편의점 픽업',
};
const STATUS = {
  1: {
    CXL: '주문 취소',
    RCV: '상품 준비 중',
    REQ: '주문 접수 중',
    SND: '배송 중',
    SNT: '배송 완료',
  },
  2: {
    CXL: '주문 취소',
    RCV: '상품 준비 중',
    REQ: '주문 접수 중',
    SND: '포장 완료',
    SNT: '픽업 완료',
  },
  3: {
    APPROVAL_PAYMENT: '결제 완료',
    CANCEL_ADMIN: '주문 취소',
    CANCEL_USER: '주문 취소',
    COMPLETE: '배송 완료',
    PICKUP: '배송 중',
    RECEIPT: '상품 준비 중',
    REQUEST: '주문 전달 완료',
  },
  4: {
    CXL: '주문 취소',
    PCF: '수령확인',
    RCV: '상품 준비 중',
    REQ: '결제 완료',
    SND: '배송 중',
    SNT: '픽업 가능',
  },
};

const formatDate = (date) => {
  const _date = new Date(date);
  return dayjs(date).format(`YYYY.MM.DD (${DAYS[_date.getDay()]}) HH:mm`);
};

const OrderPanelHead = ({ children }) => {
  return <div className="order-panel-head">{children}</div>;
};
const OrderPanelBody = ({ children }) => {
  return <div className="inner-section order-panel-body">{children}</div>;
};

const OrderPanel = ({ cancel, children, head, type }) => {
  return (
    <OrderPanelStyle className="order-panel">
      {type && (
        <div className="order-panel-category">
          <div>
            <pre>{'수산\n시장'}</pre>
          </div>
        </div>
      )}
      <ContentStyle className={`order-panel-content ${cancel ? 'CXL' : ''}`}>
        <OrderPanelHead>{head}</OrderPanelHead>
        <OrderPanelBody>{children}</OrderPanelBody>
      </ContentStyle>
    </OrderPanelStyle>
  );
};

const OrderPanelStyle = styled.div`
  padding: 0 16px;
  background: #fff;
  border-bottom: 1px solid #e8ebed;
  display: flex;
  & + .order-panel {
    margin-top: 10px;
  }
  & > .order-panel-category {
    flex: 0 0 80px;
    padding: 8px 0 0;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 66px;
      border-radius: 5px;

      background: #0e4e97;
      & > pre {
        color: #fff;
      }
    }
  }
  & > .order-panel-content {
    flex: 1;
    & > .order-panel-head {
      border: none;
      background: none;
      padding: 16px 0;
      & > .order-date {
        font-size: 16px;
        color: #26282b;
      }
      & > .order-price {
        font-size: 16px;
        color: #26282b;
        cursor: pointer;
        & > i {
        }
      }
    }
    & > .order-panel-body {
      border: none;
      border-top: 1px #9fa4aa solid;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      & .order-status.blue {
        font-size: 16px;
        font-weight: 500;
        color: #2f80ed;
        & > .reservation-info {
          font-size: 14px;
        }
      }
      & .store-name {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.3px;
        color: #26282b;
      }
      & .order-name-price {
        padding-bottom: 4px;
        & > .order-name-section {
          overflow: hidden;
          width: 100%;
          & > .order-name {
            color: #26282b;
            font-size: 16px;
            letter-spacing: -0.3px;
            line-height: 24px;
          }
          & > .order-detail-button {
            float: right;
            cursor: pointer;
          }
        }
      }
      & .store-order-price {
        color: #26282b;
        font-size: 16px;
        font-weight: 600;
      }
      & .partition {
        /* border-top: 1px dashed #cfcfcf; */
        border: none;
      }
      & .order-button-area {
        padding: 16px 0;
        & > button {
          height: 40px;
          border: 1px solid #9fa4aa;
          border-radius: 5px;
          color: #26282b;
          cursor: pointer;
          &:disabled {
            color: #bbb !important;
            border-color: #ccc !important;
            cursor: default;
          }
        }
        & > a {
          & > button {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.3px;
            height: 50px;
            border-radius: 5px;
            /* color: #26282b; */
          }
        }
      }
    }
  }
`;

const ContentStyle = styled.div`
  &.CXL {
    & > .order-panel-head {
      & > .order-date {
      }
    }
    & > .order-panel-body {
      * {
        color: #bbb !important;
        cursor: default !important;
      }
      & .order-status.blue {
        color: #26282b !important;
        & > .reservation-info {
          color: #26282b !important;
        }
      }
      & .store-name {
      }
      & .order-name-price {
        & > .order-name-section {
          & > .order-name {
          }
          & > .order-detail-button {
            color: #999999 !important;
            cursor: pointer !important;
          }
        }
      }
      & .partition {
      }
      & .order-button-area {
        & > button {
          border-color: #ccc;
        }
        & > a {
          & > button {
            background: transparent;
            border-color: #ccc;
          }
        }
        & .order-button-inquiry {
          height: 34px;
          width: 100%;
          background: #ffffff;
          border: 1px #9fa4aa solid;
          border-radius: 5px;
          color: #26282b !important;
          cursor: pointer !important;
        }
      }
    }
  }
`;

const Select = ({
  children,
  disabled,
  onChange,
  placeholder,
  reference,
  value,
}) => {
  return (
    <select
      ref={reference}
      disabled={disabled}
      placeholder={placeholder}
      style={{
        backgroundColor: '#eee',
        border: 'none',
        color: '#555',
      }}
      value={value}
      onChange={onChange}
    >
      <option value={0} disabled>
        {placeholder}
      </option>
      {children}
    </select>
  );
};

export default OrderPanel;

export { formatDate, ORDERTYPE, Select, STATUS };

import getAccountUrl from 'api/getAccountUrl';
import styled from 'styled-components';

const ButtonSwitch = ({
  cancelLabel,
  okLabel = '삭제',
  okLabelColor = 'red200',
  onClose,
  onOk = null,
  type,
}) => {
  return (
    <>
      <NotiBtn
        className="cancel"
        onClick={() => {
          onClose();
          sessionStorage.removeItem('preLoginState');
        }}
      >
        {cancelLabel}
      </NotiBtn>
      <NotiBtn
        color={okLabelColor}
        fontWeight={400}
        $block
        $transparent
        onClick={() => {
          /*if (typeof onOk === 'function') {
            onOk();
            return;
          }*/
          /*window.location.href = getAccountUrl({
            redirectUrl: window.location.href,
          });*/
          onOk();
          // window.location.replace(getAccountUrl({ redirectUrl }));
        }}
      >
        {okLabel}
      </NotiBtn>
    </>
  );
};

const NotiConfirmModal = ({
  cancelLabel,
  content,
  handleAfterOk,
  okLabel,
  okLabelColor,
  onClose,
  onOk,
  replace,
  type,
}) => {
  return (
    <Container>
      <TopContainer>
        {content && (
          <ContentContainer>
            {replace ? content.replace(/\.\s/g, '.\n') : content}
          </ContentContainer>
        )}
      </TopContainer>
      <BottomContainer type={type}>
        <ButtonSwitch
          cancelLabel={cancelLabel || (type === 'notice' ? '닫기' : '취소')}
          handleAfterOk={handleAfterOk}
          okLabel={okLabel}
          okLabelColor={okLabelColor}
          type={type}
          onClose={onClose}
          onOk={onOk}
        />
      </BottomContainer>
    </Container>
  );
};

export default NotiConfirmModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
`;

const TopContainer = styled.div`
  padding: 24px 23px;
`;

const ContentContainer = styled.pre`
  font-size: 14px;
  color: #262828;
  text-align: center;
  word-break: break-all;
  white-space: pre-wrap;
`;

const BottomContainer = styled.div`
  display: flex;
  border-top: 1px solid #c9cdd2;
`;

const NotiBtn = styled.div`
  font-size: 16px;
  width: 100%;
  padding: 0px 7px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  cursor: pointer;
  &:last-child {
    color: #2f80ed;
    border-left: 1px solid #c9cdd2;
  }
`;

import styled from 'styled-components';

/**
 * @param {object} props
 * @param {React.CSSProperties['margin']} props.margin
 */
const Divider = ({ margin }) => {
  return <Container $margin={margin} />;
};

const Container = styled.hr`
  display: block;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.gray100};
  height: 0;
  margin: ${({ $margin }) => $margin};
`;

export default Divider;

const ChevronDown = ({ className, fill = '#0e4e97', onClick, size = 20 }) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={size}
      style={{ cursor: 'pointer' }}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        clipRule="evenodd"
        d="M12.0001 14.5858L5.20718 7.79291L3.79297 9.20712L11.293 16.7071C11.6835 17.0976 12.3167 17.0976 12.7072 16.7071L20.2072 9.20712L18.793 7.79291L12.0001 14.5858Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ChevronDown;

import { animate, useMotionValue } from 'framer-motion/dist/framer-motion';
import * as React from 'react';

import Dots from './dots';
import Slider from './slider';

const containerStyle = {
  display: 'flex',
  height: '100%',
  overflowX: 'hidden',
  position: 'relative',
  width: '100%',
};

const transition = {
  bounce: 0,
  type: 'spring',
};

const Container = React.forwardRef((props, ref) => (
  <div ref={ref} style={containerStyle}>
    {props.children}
  </div>
));

const FramerCarousel = React.forwardRef(
  (
    {
      autoPlay = true,
      children,
      interval = 2000,
      loop = true,
      showDots = true,
    },
    ref
  ) => {
    const x = useMotionValue(0);
    const containerRef = React.useRef(null);
    const [index, setIndex] = React.useState(0);

    const calculateNewX = () =>
      -index * (containerRef.current?.clientWidth || 0);

    const handleEndDrag = (e, dragProps) => {
      const clientWidth = containerRef.current?.clientWidth || 0;

      const { offset } = dragProps;

      if (offset.x > clientWidth / 4) {
        handlePrev();
      } else if (offset.x < -clientWidth / 4) {
        handleNext();
      } else {
        animate(x, calculateNewX(), transition);
      }
    };

    const childrens = React.Children.toArray(children);

    const handleNext = () => {
      const idx = loop ? 0 : index;
      setIndex(index + 1 === childrens.length ? idx : index + 1);
    };

    const handlePrev = () => {
      const idx = loop ? childrens.length - 1 : 0;
      setIndex(index - 1 < 0 ? idx : index - 1);
    };

    React.useEffect(() => {
      const controls = animate(x, calculateNewX(), transition);
      return controls.stop;
    }, [index]);

    React.useEffect(() => {
      if (!autoPlay) {
        return;
      }
      const timer = setInterval(() => handleNext(), interval);
      return () => clearInterval(timer);
    }, [handleNext, interval]);

    React.useImperativeHandle(
      ref,
      () => {
        return {
          handleNext,
          handlePrev,
          setIndex,
        };
      },
      [index]
    );

    return (
      <>
        <Container ref={containerRef}>
          {childrens.map((child, i) => (
            <Slider
              key={`slider_${i}`}
              i={i}
              totalSliders={childrens.length}
              x={x}
              onDragEnd={handleEndDrag}
            >
              {child}
            </Slider>
          ))}
        </Container>
        {showDots && childrens.length > 1 && (
          <Dots
            activeIndex={index}
            length={childrens.length}
            setActiveIndex={setIndex}
          />
        )}
      </>
    );
  }
);

export default FramerCarousel;

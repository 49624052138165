import client from 'api/client';

import profileClient from './profileClient';

export const get_my_messages = () => client.get(`/www/my/push-log`);

export const get_my_settings = (params) =>
  client.get(`/www/my/notification`, params);

export const update_my_settings = (params) =>
  client.put(`/www/my/notification`, params);

export const get_my_coupons = (params) =>
  client.get('/www/my/coupons', { params });

export const get_my_likes = () => client.get('/www/my/likes');
export const getMyLikes = () =>
  client.get('/www/my/likes').then((res) => res.data);

export const get_my_reviews = (params) =>
  client.get('/www/my/comments', { params });

export const get_my_reviews_more = (params) =>
  client.get('/www/my/comments', { params });

export const get_my_orders = (params) =>
  client.get('/www/quick/my/history', params);

export const check_logs = () => client.put('/www/quick/my/push-log');

// 기존 내 프로필 조회 api를 account 프로필 조회 api로 변경
export const get_my_profile = () => profileClient.get('/account/profile');

// 현재 사용 안하는 api
export const update_my_profile = (params) =>
  profileClient.put(`/account/profile`, params);

export const withdraw = (password) =>
  client.post('/www/withdraw', { password });

export const get_manager_phone = () => client.get(`/www/quick/setting`);

// account api로 수정
// export const get_my_phone_auth = () => profileClient.get(`/auth/phone/code`);
export const get_my_phone_auth = () => client.get(`/www/my/auth/phone`);

export const get_phone_auth_sms = (value) =>
  client.post(`/www/my/auth/message`, null, { params: { phone: value } });

export const certify_phone_auth = (params) =>
  client.get(`/www/my/auth/authCode`, { params });

export const get_my_counts = (params) =>
  client.get(`/www/my/counts`, { params });

export const get_delivery_address = () =>
  client.get(`/www/my/shipping-address`);

export const post_delivery_address = ({ params }) =>
  client.post(`/www/my/shipping-address`, params);

export const put_delivery_address = ({ params }) =>
  client.put(`/www/my/shipping-address`, params);

export const delete_delivery_address = ({ params }) =>
  client.delete(`/www/my/shipping-address`, { data: params });

//= ========================================================
export const getMyOrders = (params) =>
  client.get('/www/quick/my/history', params).then((res) => {
    return { ...res.data, pagesNum: params };
  });

export const getOrderHistories = (params) =>
  client.get('/www/order/history', params).then((res) => res.data);

export const getOrderHistoryDetail = (orderCode) =>
  client.get(`/www/order/history/${orderCode}`).then((res) => res.data);

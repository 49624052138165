import * as api from 'api/review';
import * as filter_api from 'api/search-filters';
import { handleActions } from 'redux-actions';

import createApiRequest from './_support';

const BEST = 'pirates-data.review/BEST';
const BEST_MORE = `${BEST}.MORE`;
const FILTER = 'pirates-data.review/FILTER';
const SAVE_QUERY = 'pirates-data.review/SAVE_QUERY';
const SET_REPLY_REPORT = 'pirates-data.review/SET_REPLY_REPORT';

const [get_best_reviews, BEST_OK, BEST_FAIL] = createApiRequest(
  BEST,
  api.get_best_reviews
);
const [more_best_reviews, BEST_MORE_OK, BEST_MORE_FAIL] = createApiRequest(
  BEST_MORE,
  api.get_best_reviews
);
const [get_filters, FILTER_OK, FILTER_FAIL] = createApiRequest(
  FILTER,
  filter_api.load_store_regions
);
const [save_query, SAVE_QUERY_OK, SAVE_QUERY_FAIL] = createApiRequest(
  SAVE_QUERY,
  (query) => ({ data: query })
);
const [set_reply_report, SET_REPLY_REPORT_OK, SET_REPLY_REPORT_FAIL] =
  createApiRequest(SET_REPLY_REPORT, (query) => ({ data: query }));

const state = {
  best: [],
  filter: [],
  query: { code: 'all', label: '모든 지역' },
  replyReport: false,
};

const best = handleActions(
  {
    [BEST]: (state) => ({ ...state }),
    [BEST_MORE]: (state) => ({ ...state }),

    [BEST_MORE_OK]: (state, action) => ({
      ...state,
      best: [...state.best, ...action.payload],
    }),
    [BEST_OK]: (state, action) => ({
      ...state,
      best: action.payload,
    }),

    [FILTER]: (state) => ({ ...state }),
    [FILTER_OK]: (state, action) => ({
      ...state,
      filter: action.payload,
    }),

    [SAVE_QUERY]: (state) => ({ ...state }),
    [SAVE_QUERY_OK]: (state, action) => ({
      ...state,
      query: action.payload,
    }),
    [SET_REPLY_REPORT]: (state) => ({ ...state }),
    [SET_REPLY_REPORT_OK]: (state, action) => ({
      ...state,
      replyReport: action.payload,
    }),
  },
  state
);

const Types = {
  BEST,
  BEST_MORE,
  FILTER,
};

export {
  get_best_reviews,
  get_filters,
  more_best_reviews,
  save_query,
  set_reply_report,
  Types,
};
export default best;

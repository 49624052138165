import { useEffect } from 'react';

import SearchContext from './_context';

const _convert_key = (key) =>
  key
    .replace(/search\.filter\/(OPTION-)?/gi, '')
    .replace(/\./gi, '-')
    .toLocaleLowerCase();
const _convert_value = (value) =>
  ((v) => (typeof v === 'string' ? v : v.label))(
    Array.isArray(value) ? value[0] : value
  );

const _SearchTagArchive = ({ values }) => {
  const archives = Object.entries(values)
    .filter(([k, v]) => v && v !== '')
    .map(([k, v]) => ({ [`data-${_convert_key(k)}`]: _convert_value(v) }))
    .reduce((acc, e) => ({ ...acc, ...e }), {});

  useEffect(() => {
    if (Object.keys(archives).length) {
      window.dataLayer.push({ event: 'tp.search' });
    }
  }, [values]);

  return <div className="search-archive" {...archives} />;
};

const SearchArchive = () => {
  return (
    <SearchContext.Consumer>
      {({ state }) => <_SearchTagArchive values={state.value} />}
    </SearchContext.Consumer>
  );
};

export default SearchArchive;

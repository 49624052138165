const couponListKeys = {
  all: ['coupon', 'list'],
  getCouponList: ({ zoneCode, sortBy }) => [
    ...couponListKeys.all,
    zoneCode,
    sortBy,
  ],
  getMyCouponList: ({ sortBy }) => [...couponListKeys.all, 'myCoupon', sortBy],
  getCouponDetail: (id) => [...couponListKeys.all, 'couponDetail', id],
};

export default couponListKeys;

/**
 * 기본적으로 사용할 유틸을을 작성합니다!
 */

export const select = (flag, a, b = null) => {
  return flag ? a : b;
};

export const selectFunction = (flag, a, b) => {
  return flag ? a && a() : b && b();
};

export const isStringEmpty = (value) => {
  if (typeof value === 'string') {
    return value.length === 0;
  }
  return true;
};

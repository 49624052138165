import './style.css';

import { Box, Fish, Person, Ship, Store } from '@thepirates/icons';
import { Normal } from 'components/modules/Modal';
import { conf } from 'conf/settings';
import { useWholeSaleSortState } from 'hooks/custom/useWholeSaleSortState';
import useScrollDetect from 'hooks/useScrollDetect';
import fishImg from 'img/ftp_fa_fish.svg';
import fishFillImg from 'img/ftp_fa_fish_fill.svg';
import graphImg from 'img/graph.svg';
import graphFillImg from 'img/graph_fill.svg';
import homeImg from 'img/home.svg';
import homeFillImg from 'img/home_fill.svg';
import manImg from 'img/man.svg';
import manFillImg from 'img/man_fill.svg';
import shoppingImg from 'img/shopping.svg';
import shoppingFillImg from 'img/shopping_fill.svg';
import { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { globalListOrder } from 'recoil/filter';
import { globalPriceFilter, globalStoresFilter } from 'recoil/price';
import { globalSearchKeyword } from 'recoil/search';
import styled from 'styled-components';
import { androidWindowOpen } from 'utils/androidWindowOpen';
import { mobileIosCheck } from 'utils/mobileCheck';

import AdditionalFunction from './additionalFunc';

const initStoresFilterState = {
  around: [],
  location: [],
  orderType: [],
  product: [],
};

const initPriceFilterState = {
  around: [],
  location: [],
  origin: [],
  product: [],
  raised: [],
};

const SMART_STORE_URL = 'https://smartstore.naver.com/tpirates';

const footerMenus = () => {
  return [
    {
      fill: homeFillImg,
      icon: <Fish />,
      img: homeImg,
      name: '수산시장',
      path: '/',
    },
    {
      fill: shoppingFillImg,
      icon: <Ship />,
      img: shoppingImg,
      name: '산지마켓',
      path: conf.OPEN_MARKET_URL,
    },
    {
      fill: graphFillImg,
      icon: <Store />,
      img: graphImg,
      name: '쇼핑몰',
      path: SMART_STORE_URL,
      // path: conf.SHOPPING_MALL_URL,
    },
    {
      fill: fishFillImg,
      icon: <Box />,
      img: fishImg,
      name: '도매',
      path: '/도매',
    },
    {
      fill: manFillImg,
      icon: <Person />,
      img: manImg,
      name: 'MY',
      path: conf.MY_URL,
      // isLogin && isLogin !== 'null' && isLogin !== 'notLogin'
      //   ? conf.MY_URL
      //   : getAccountUrl({ redirectUrl: conf.MY_URL, type: 'FISH' }),
    },
  ];
};
const SideFooterPage = ['/도매'];

const FooterItem = ({ item }) => {
  const { name, path } = item;
  const { pathname } = useLocation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const setGlobalSearchKeyword = useSetRecoilState(globalSearchKeyword);
  const setStoresFilter = useSetRecoilState(globalStoresFilter);
  const setPriceFilter = useSetRecoilState(globalPriceFilter);

  const setListOrder = useSetRecoilState(globalListOrder);
  const { initWholeSaleSortState } = useWholeSaleSortState();

  const click = useCallback(() => {
    try {
      const url = new URL(path);
      window.location.href = url.href;
    } catch {
      history.push(path);
    }
  }, [history, path]);

  const isActive =
    (name === '수산시장' &&
      !pathname.startsWith('/도매') &&
      !pathname.startsWith('/마이페이지')) ||
    (name === '도매' && pathname.startsWith('/도매')) ||
    (name === 'MY' && pathname.startsWith('/마이페이지'));

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <figure className={isActive ? 'ON' : ''}>
      <StyledLinkButton
        onClick={(e) => {
          e.preventDefault();
          if (item.name === '쇼핑몰') {
            setIsOpen(true);
            return;
          }
          click(e);
          setGlobalSearchKeyword('');
          setStoresFilter(initStoresFilterState);
          setPriceFilter(initPriceFilterState);
          initWholeSaleSortState();
          setListOrder('default');
          sessionStorage.removeItem('mainPath');
        }}
      >
        <IconWrapper active={isActive}>{item.icon}</IconWrapper>
        <span>{name}</span>
      </StyledLinkButton>
      {isOpen && (
        <Normal
          padding="24px 16px"
          visible={isOpen}
          noOverflow
          onClose={onClose}
        >
          <ModalContainer>
            <div className="title">
              <h1>상품구매</h1>
              <button onClick={onClose}>
                <svg
                  fill="none"
                  height="24"
                  role="button"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.35355 18.3536C6.15829 18.5488 5.84171 18.5488 5.64645 18.3536C5.45118 18.1583 5.45118 17.8417 5.64645 17.6464L11.2929 12L5.64645 6.35355C5.45118 6.15829 5.45118 5.84171 5.64645 5.64645C5.84171 5.45118 6.15829 5.45118 6.35355 5.64645L12 11.2929L17.6464 5.64645C17.8417 5.45118 18.1583 5.45118 18.3536 5.64645C18.5488 5.84171 18.5488 6.15829 18.3536 6.35355L12.7071 12L18.3536 17.6464C18.5488 17.8417 18.5488 18.1583 18.3536 18.3536C18.1583 18.5488 17.8417 18.5488 17.6464 18.3536L12 12.7071L6.35355 18.3536Z"
                    fill="#8A8D90"
                  />
                </svg>
              </button>
            </div>
            <div className="content-container">
              <div className="content-section">
                <div className="content-title">
                  <svg
                    fill="none"
                    height="24"
                    viewBox="0 0 25 24"
                    width="25"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.7508 18.1H13.7008V16.65H21.2508C21.5008 16.65 21.7508 16.5 21.9008 16.25C22.0508 16 22.0008 15.7 21.8508 15.5L13.7008 3.49997V2.24997C13.7008 1.99997 13.5508 1.74997 13.3508 1.64997C13.1508 1.49997 12.8508 1.49997 12.6508 1.59997L12.3008 1.79997C7.10078 4.39997 3.55078 9.74997 3.00078 15.85C3.00078 16.05 3.05078 16.25 3.20078 16.4C3.35078 16.55 3.55078 16.65 3.75078 16.65H12.3008V18.1H3.30078C2.90078 18.1 2.55078 18.45 2.55078 18.85C2.55078 20.85 4.20078 22.5 6.20078 22.5H18.9008C20.9008 22.5 22.5508 20.85 22.5508 18.85C22.5008 18.45 22.1508 18.1 21.7508 18.1ZM19.9008 15.15H13.7008V6.09997L19.9008 15.15ZM12.2508 3.44997V3.69997V15.2H4.55078C5.25078 10.2 8.15078 5.84997 12.2508 3.44997ZM18.8508 21.05H6.15078C5.20078 21.05 4.40078 20.45 4.10078 19.6H20.9008C20.6008 20.4 19.8008 21.05 18.8508 21.05Z"
                      fill="#2F80ED"
                    />
                  </svg>
                  <span>산지직송!</span>
                  산지마켓 상품
                </div>
                <p className="content-text">
                  산지에서 직접 발송하는 신선한 수산물을
                  <br />
                  구매하고 싶다면 아래 버튼을 눌러주세요.
                </p>
                <button
                  className="link-button"
                  onClick={() => {
                    window.location.href = conf.OPEN_MARKET_URL;
                    onClose();
                  }}
                >
                  산지마켓 가기
                </button>
              </div>
              <div className="content-section">
                <div className="content-title">
                  <svg
                    fill="none"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5 8.95C22.5 8.25 22.35 7.6 22.05 7L20.15 3.2C19.9 2.75 19.5 2.5 19 2.5H5C4.5 2.5 4.1 2.75 3.9 3.15L1.95 7C1.65 7.6 1.5 8.3 1.5 8.95C1.5 10.1 2.1 11.15 2.95 11.7V20.25C2.95 20.95 3.5 21.5 4.15 21.5H9.55C9.95 21.5 10.3 21.15 10.3 20.75V17.35C10.3 16.4 11.05 15.65 12 15.65C12.95 15.65 13.7 16.4 13.7 17.35V20.75C13.7 21.15 14.05 21.5 14.45 21.5H19.85C20.5 21.5 21.05 20.95 21.05 20.3V11.75C21.9 11.15 22.5 10.1 22.5 8.95ZM15.15 20.05V17.35C15.15 15.6 13.75 14.2 12 14.2C10.25 14.2 8.85 15.6 8.85 17.35V20.05H4.45V12.25C5.25 12.3 6.1 12.05 6.7 11.55C6.85 11.4 7 11.3 7.1 11.1C7.7 11.8 8.55 12.25 9.55 12.25C10.55 12.25 11.4 11.8 12 11.1C12.6 11.8 13.45 12.25 14.45 12.25C15.45 12.25 16.3 11.8 16.9 11.1C17 11.2 17.1 11.3 17.2 11.4C17.8 11.95 18.55 12.2 19.35 12.2C19.45 12.2 19.5 12.2 19.6 12.2V20H15.15V20.05ZM19.5 10.75C19 10.8 18.55 10.65 18.2 10.3C17.85 10 17.65 9.5 17.65 9.05V8.55C17.65 8.15 17.3 7.8 16.9 7.8C16.5 7.8 16.15 8.15 16.15 8.55V9.05C16.15 10 15.4 10.75 14.45 10.75C13.5 10.75 12.75 10 12.75 9.05V8.55C12.75 8.15 12.4 7.8 12 7.8C11.6 7.8 11.25 8.15 11.25 8.55V9.05C11.25 10 10.5 10.75 9.55 10.75C8.6 10.75 7.85 10 7.85 9.05V8.55C7.85 8.15 7.5 7.8 7.1 7.8C6.7 7.8 6.35 8.15 6.35 8.55V9.05C6.35 9.55 6.1 10.05 5.75 10.35C5.35 10.7 4.85 10.8 4.3 10.7C3.55 10.55 2.95 9.8 2.95 8.9C2.95 8.45 3.05 8 3.25 7.6L5.1 3.9H18.85L20.7 7.6C20.9 8 21 8.45 21 8.9C21.05 9.9 20.35 10.7 19.5 10.75Z"
                      fill="#2F80ED"
                    />
                  </svg>
                  <span>인어교주해적단</span>
                  온라인 상품
                </div>
                <p className="content-text">
                  기존에 구매하시던 인어교주해적단이 선별한
                  <br />
                  상품을 구매하고 싶다면 아래 버튼을 눌러주세요.
                </p>
                <button
                  className="link-button"
                  onClick={() => {
                    androidWindowOpen(SMART_STORE_URL);
                    onClose();
                  }}
                >
                  온라인 스토어 가기
                </button>
              </div>
            </div>
          </ModalContainer>
        </Normal>
      )}
    </figure>
  );
};

const ModalContainer = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background-color: white;
  border-radius: 8px;

  & > .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;

    & > h1 {
      overflow: hidden;
      color: #26282b;
      text-overflow: ellipsis;

      /* NotoSans/Body/b3-700 */
      font-family: 'Noto Sans KR';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.3px;
    }
    & > button {
      width: 24px;
      height: 24px;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }
  }
  & > .content-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    & > .content-section {
      border-radius: 8px;
      background: #f7f8f9;
      padding: 24px;

      & > .content-title {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        margin-bottom: 8px;

        color: #26282b;
        /* NotoSans/Body/b4-700 */
        font-family: 'Noto Sans KR';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        letter-spacing: -0.3px;

        & > span {
          color: #2f80ed;
          /* NotoSans/Body/b4-700 */
          font-family: 'Noto Sans KR';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          letter-spacing: -0.3px;
        }
      }

      & > .content-text {
        color: #464c53;
        text-align: center;

        /* NotoSans/Caption/c2-400 */
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        letter-spacing: -0.3px;
        margin-bottom: 16px;
      }
      & > .link-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 36px;
        padding: 6px 16px;
        cursor: pointer;

        border-radius: 100px;
        background: #2f80ed;

        color: #fff;
        text-align: center;

        /* NotoSans/Caption/c2-400 */
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        letter-spacing: -0.3px;
      }
    }
  }
`;

const IconWrapper = styled.div`
  & > svg {
    fill: ${({ active }) => (active ? '#0e4e97' : '#9fa4aa')};
  }
`;

const StyledLinkButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
`;

const FooterFixed = ({ smallHeader }) => {
  const isOpen = useSelector((state) => state.nav.isOpen);
  const location = useLocation();

  const sideFooter = useMemo(
    () => !!SideFooterPage.includes(location.pathname),
    [location]
  );

  const include = useMemo(() => {
    const colorExclude = ['/스토어', '/도매'];
    return {
      color: !colorExclude.includes(location.pathname),
    };
  }, [location]);

  const mobileIOS = mobileIosCheck();

  const { isDown } = useScrollDetect();

  const ColorFilter = useMemo(() => {
    return (
      <p
        id="footer-color-filter"
        style={{
          background: 'rgba(0,0,0,0.3)',
          display: 'none',
          height: '100%',
          left: '0px',
          position: 'absolute',
          top: '0px',
          width: '100%',
        }}
      />
    );
  }, []);
  return (
    isOpen && (
      <FooterFixedStyle
        $downFlag={isDown}
        className={`footer-fixed ${smallHeader ? 'with-small-header' : ''}${
          sideFooter ? 'side-footer' : ''
        } ${include.color ? 'market' : ''}`}
        mobileIOS={mobileIOS}
      >
        {!location.search.includes('detail') &&
          !location.pathname.includes('search') &&
          !location.pathname.includes('마이페이지') && (
            /** 맨위로 버튼 등 */
            <AdditionalFunction />
          )}
        <div className="footer-change-wrap">
          {footerMenus().map((v, i) => {
            return <FooterItem key={i} item={v} />;
          })}
          {ColorFilter}
        </div>
      </FooterFixedStyle>
    )
  );
};

const FooterFixedStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-width: 360px;
  max-width: 420px;
  width: 100%;

  box-sizing: border-box;
  z-index: 25;

  @media (max-width: 576px) {
    transform: ${({ $downFlag }) => `translateY(${$downFlag ? '100%' : '0%'})`};
    transition: transform 0.3s ease-in-out;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    min-width: 360px;
    max-width: 420px;
    width: 100%;

    box-sizing: border-box;
    z-index: 25;
  }

  &.market > .footer-change-wrap {
    @media only screen and (max-width: 576px) {
      padding: 0px 10px constant(safe-area-inset-bottom);
      padding: 0px 10px env(safe-area-inset-bottom);
      height: ${({ mobileIOS }) => (mobileIOS ? 'auto' : '65px')};
      padding-top: ${({ mobileIOS }) => (mobileIOS ? '10px' : '0px')};
    }

    & > figure {
      & > span {
        margin: 0;
      }
      &.ON {
        & > span {
          color: #0e4e97;
        }
      }
    }
  }
  & > .footer-change-wrap {
    @media only screen and (max-width: 576px) {
      padding: 0px 10px constant(safe-area-inset-bottom);
      padding: 0px 10px env(safe-area-inset-bottom);
      height: ${({ mobileIOS }) => (mobileIOS ? 'auto' : '65px')};
      padding-top: ${({ mobileIOS }) => (mobileIOS ? '10px' : '0px')};
    }
    display: flex;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border-top: 1px solid ${({ theme }) => theme.gray100};
    /* box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 8%) !important; */
    &::after {
      display: block;
      content: '';
      clear: both;
      height: 0;
    }
    & > figure {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;

      height: 100%;
      margin: 0;
      padding: 0;

      cursor: pointer;
      /* & > img {
        width: 20px;
        height: 20px;
        border: none;
        filter: invert(66%) sepia(22%) saturate(0%) hue-rotate(213deg)
          brightness(91%) contrast(77%);
      } */
      & span {
        font-size: 12px;
        line-height: 12px;
        color: #999999;
        margin-top: 5px;
        font-weight: 400;
      }

      & span {
        margin: 0;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: -1px;
      }
      &.ON {
        & span {
          color: #0e4e97;
        }
      }
    }
  }

  @media (min-width: 576px) {
    & > .footer-change-wrap {
      left: none;
      right: none;
      display: flex;

      position: fixed;
      top: 100px;
      /* right: -86.5px; */
      margin-left: -88px;
      padding: 16px 0;
      padding-left: 0;
      padding-right: 0;

      flex-flow: column;
      width: 88px;
      height: auto;
      /* box-shadow: 2px 0 8px inset lightgrey !important; */
      border-radius: 5px 0 0 5px;
      /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
      background: #fff;
      border: 1px solid ${({ theme }) => theme.gray100};
      transition: top 0.3s;

      & > figure {
        width: 100%;
        flex: 0 0 71px;
        & > img {
        }
        & > span {
        }
      }
    }
    &.with-small-header {
      & > .footer-change-wrap {
      }
    }
  }

  &.side-footer {
    display: none;
  }
`;

export default memo(FooterFixed);

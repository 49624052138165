import React from 'react';
import { useHistory } from 'react-router-dom';

const { createContext, useEffect, useReducer } = React;

const KEYWORD = 'search.filter/KEYWORD';
const OPTION = 'search.filter/OPTION';
const TAG = 'search.filter/TAG';

const Types = {
  KEYWORD,
  OPTION,
  TAG,
};

const Event = {
  SUBMIT: 'search.filter.event/SUBMIT',
  UPDATE: 'search.filter.event/UPDATE',
};

const _state = {
  event: null,
  filter: {
    data: null,
    type: null,
  },
  value: {
    [KEYWORD]: null,
    [TAG]: null,
  },
};

const Action = {
  EVENT: 'search.action/EVENT',
  INIT: 'search.action/INIT',
  SET_FILTER: 'search.action/SET.FILTER',
  SET_VALUE: 'search.action/SET.VALUE',
};

const SearchContext = createContext(_state);

const reducer = (state, action) => {
  switch (action.type) {
    case Action.INIT:
      return {
        ...state,
        filter: {
          data: null,
          type: null,
        },
        value: {
          [KEYWORD]: null,
          [TAG]: null,
        },
      };
    case Action.SET_FILTER:
      return {
        ...state,
        filter: {
          data: action.payload.data,
          type: action.payload.type,
        },
      };
    case Action.SET_VALUE:
      return {
        ...state,
        value: {
          ...state.value,
          ...action.payload,
        },
      };
    case Action.EVENT:
      return {
        ...state,
        event: action.payload,
      };
    default:
      return state;
  }
};

const SearchProvider = ({
  children,
  handleActive,
  handleInactive,
  handleSubmit,
}) => {
  const [state, dispatch] = useReducer(reducer, _state);
  const history = useHistory();
  const _convert = (key) =>
    key.replace(/search\.filter\/(OPTION-)?/gi, '').toLocaleLowerCase();

  const action = {
    activeFilter: (target, payload) => {
      dispatch({
        payload: { data: payload, type: target },
        type: Action.SET_FILTER,
      });
    },
    inactiveFilter: (target) => {
      dispatch({
        payload: { data: null, type: null },
        type: Action.SET_FILTER,
      });
      dispatch({ payload: null, type: Action.EVENT });
    },
    submit: (payload) => {
      if (history.location.pathname === '/쇼핑') {
        dispatch({ type: Action.INIT });
      }
      const timeSet = setTimeout(
        () => {
          if (!history.location.hash) {
            history.push({ ...history.location, hash: 'search' });
          } else {
            history.replace({ ...history.location, hash: 'search' });
          }
        },
        100,
        () => {
          clearTimeout(timeSet);
        }
      );
      dispatch({ payload, type: Action.SET_VALUE });
      dispatch({ payload: Event.SUBMIT, type: Action.EVENT });
    },
    update: (payload) => {
      dispatch({ payload, type: Action.SET_VALUE });
      dispatch({ payload: Event.UPDATE, type: Action.EVENT });
    },
  };

  useEffect(() => {
    const [body] = document.getElementsByTagName('body');
    if (state.filter.type) {
      handleActive(state.filter);
      body.style.overflow = 'hidden';
    } else {
      handleInactive();
      body.style.overflow = 'visible';
    }
  }, [state.filter, handleActive, handleInactive]);

  useEffect(() => {
    if (state.event === Event.SUBMIT) {
      const result = Object.entries(state.value)
        .map(([k, v]) => ({ [_convert(k)]: v }))
        .reduce((acc, e) => ({ ...acc, ...e }), {});

      action.inactiveFilter(state.filter.type);
      handleSubmit(result);
    }
  }, [state.value, state.event]);

  return (
    <SearchContext.Provider value={{ action, state }}>
      {children}
    </SearchContext.Provider>
  );
};

export { SearchProvider, Types };
export default SearchContext;

import * as api from 'api/content';
import { handleActions } from 'redux-actions';

import createApiRequest from '../_support';

const RECOMMENDS = 'pirates-data.content/RECOMMENDS';
const CONTENTS = 'pirates-data.content/CONTENTS';
const TOGGLE_FAVORITE = 'pirates-data.content/LIKE';
const TOGGLE_RECOMMEND = 'pirates-data.content/LIKE_RECOMMEND';
const CONTENTS_MORE = `${CONTENTS}.MORE`;
const SAVE_QUERY = 'pirates-data.content/SAVE_QUERY';
const LOAD_DATA = 'pirates-data.content/LOAD_DATA';

const [get_recommends, RECOMMENDS_OK, RECOMMENDS_FAIL] = createApiRequest(
  RECOMMENDS,
  api.get_recommend_contents
);
const [get_contents, CONTENTS_OK, CONTENTS_FAIL] = createApiRequest(
  CONTENTS,
  api.get_contents
);
const [get_contents_more, CONTENTS_MORE_OK, CONTENTS_MORE_FAIL] =
  createApiRequest(CONTENTS_MORE, api.get_contents);
const [toggle_favorite, TOGGLE_FAVORITE_OK, TOGGLE_FAVORITE_FAIL] =
  createApiRequest(TOGGLE_FAVORITE, api.toggle_content_like);
const [toggle_recommend, TOGGLE_RECOMMEND_OK, TOGGLE_RECOMMEND_FAIL] =
  createApiRequest(TOGGLE_RECOMMEND, api.toggle_content_like);
const [save_query, SAVE_QUERY_OK, SAVE_QUERY_FAIL] = createApiRequest(
  SAVE_QUERY,
  (query) => ({ data: query })
);
const [load_data, LOAD_DATA_OK, LOAD_DATA_FAIL] = createApiRequest(
  LOAD_DATA,
  (data) => ({ data })
);

const Types = {
  CONTENTS,
  CONTENTS_MORE,
  RECOMMENDS,
};

const state = {
  contents: [],
  query: '',
  recommends: [],
};

const reducer = handleActions(
  {
    [CONTENTS]: (state) => ({ ...state }),
    [CONTENTS_MORE]: (state) => ({ ...state }),

    [CONTENTS_MORE_OK]: (state, action) => ({
      ...state,
      contents: [...state.contents, ...action.payload],
    }),
    [CONTENTS_OK]: (state, action) => ({
      ...state,
      contents: action.payload,
    }),

    [LOAD_DATA]: (state) => ({ ...state }),
    [LOAD_DATA_OK]: (state, action) => ({
      ...action.payload,
    }),

    [RECOMMENDS]: (state) => ({ ...state }),
    [RECOMMENDS_OK]: (state, action) => ({
      ...state,
      recommends: action.payload,
    }),

    [SAVE_QUERY]: (state) => ({ ...state }),
    [SAVE_QUERY_OK]: (state, action) => ({
      ...state,
      query: action.payload,
    }),

    [TOGGLE_FAVORITE]: (state) => ({ ...state }),
    [TOGGLE_FAVORITE_OK]: (state, action) => ({
      ...state,
      contents: state.contents.map((content) => {
        if (content.id === action.payload.targetCode) {
          return {
            ...content,
            favorite: action.payload.doLike ? 'on' : 'off',
          };
        }
        return content;
      }),
    }),
    [TOGGLE_RECOMMEND]: (state) => ({ ...state }),
    [TOGGLE_RECOMMEND_OK]: (state, action) => ({
      ...state,
      recommends: state.recommends.map((recommend) => {
        if (recommend.id === action.payload.targetCode) {
          return {
            ...recommend,
            favorite: action.payload.doLike ? 'on' : 'off',
          };
        }
        return recommend;
      }),
    }),
  },
  state
);

export {
  get_contents,
  get_contents_more,
  get_recommends,
  load_data,
  save_query,
  toggle_favorite,
  toggle_recommend,
  Types,
};
export default reducer;

import PartDivider from 'components/atoms/PartDivider';
import AnimationSkeleton from 'components/atoms/Skeleton/AnimationSkeleton';
import BoardTitleSection from 'components/common/BoardTitleSection';
import styled from 'styled-components';

const LabelListLoading = () => {
  return (
    <BoardTitleSection
      margin="0 16px 4px 0px"
      padding="24px 0 0 0"
      title="찜한 품목"
    >
      <Container>
        <PartDivider level={1}>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <ItemCard key={item}>
              <AnimationSkeleton height={40} width={40} />
              <InfoBox>
                <AnimationSkeleton height={16} width={120} />
              </InfoBox>
            </ItemCard>
          ))}
        </PartDivider>
      </Container>
    </BoardTitleSection>
  );
};

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemCard = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  gap: 16px;
`;

const Container = styled.div`
  padding: 0px 16px;
`;
export default LabelListLoading;

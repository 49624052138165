import React from 'react';

import RecommendedContents from './contents';
import RecommendedProducts from './products';

const Recommended = ({ type, items, removeItem, remove }) => {
  switch (type) {
    case 'products':
      return (
        <RecommendedProducts
          products={items}
          removeItem={removeItem}
          remove={remove}
        />
      );
    case 'contents':
      return (
        <RecommendedContents
          contents={items}
          removeItem={removeItem}
          remove={remove}
        />
      );
  }
};

export default Recommended;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putLabelFavorite } from 'api/Prices';
import { ToastContext } from 'context/toast';
import priceKeys from 'hooks/Query/Price/keys';
import { useContext } from 'react';

export default function useUpdateLabelFavorite({
  favorite,
  id,
  offMessage = '찜하기가 해제되었습니다',
}) {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);

  return useMutation(putLabelFavorite, {
    onError: (err) => {
      if (err) {
        showToast(err?.response?.data?.message || err?.message || '서버 에러');
      }
    },
    onSuccess: () => {
      if (favorite) {
        showToast(offMessage);
      }
      if (!favorite) {
        showToast(`찜 목록에 추가되었습니다 \n마이페이지에서 확인해주세요`);
      }

      queryClient.invalidateQueries(priceKeys.getQuoteDetail({ id }));
      queryClient.invalidateQueries(priceKeys.getLabelFavoriteList());
    },
  });
}

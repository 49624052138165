import * as c_api from 'api/coupon';
import * as temp_s_api from 'api/Prices';
import * as r_api from 'api/review';
import * as s_api from 'api/store';
import { createAction, handleActions } from 'redux-actions';

import createApiRequest from '../_support';

const load_store_info = async (store_link) => {
  // const info = await s_api.get_store(store_link);
  const info = await temp_s_api.get_store_temp(store_link);
  const coupons = await c_api.get_coupons({ store: info.data.id });

  return {
    ...info,
    data: {
      ...info.data,
      coupons: coupons.data.list,
    },
  };
};

/** Store data retrieve Action Types */
const INFO = 'pirates-data.store/INFO';
const MENUS = 'pirates-data.store/MENUS';
const PRICE = 'pirates-data.store/PRICE';
const ARTICLE = 'pirates-data.store/ARTICLE';
const REVIEWS = 'pirates-data.store/REVIEWS';
const REVIEWS_MORE = `${REVIEWS}.MORE`;
const REVIEWS_SELECTED = `${REVIEWS}.SELECTED`;
const LOCATION = 'pirates-data.store/LOCATION';
const RECOMMEND = `${REVIEWS}.RECOMMEND`;
const TOGGLE_LIKE = 'pirates-data.store/LIKE';
const ADD_REVIEW = `${REVIEWS}.ADD`;
const MODIFY_REVIEW = `${REVIEWS}.MODIFY`;
const DELETE_REVIEW = `${REVIEWS}.DELETE`;

const updateNotification = createAction('MENU_NOTIFICATION');
const [get_store, INFO_OK, INFO_FAIL] = createApiRequest(INFO, load_store_info);
const [get_store_menus, MENUS_OK, MENUS_FAIL] = createApiRequest(
  MENUS,
  s_api.get_store_menus
);
const [get_store_prices, PRICE_OK, PRICE_FAIL] = createApiRequest(
  PRICE,
  s_api.get_store_prices
);
const [get_store_article, ARTICLE_OK, ARTICLE_FAIL] = createApiRequest(
  ARTICLE,
  s_api.get_store_article
);
const [get_store_reviews, REVIEWS_OK, REVIEWS_FAIL] = createApiRequest(
  REVIEWS,
  r_api.get_store_reviews
);
const [get_store_reviews_more, REVIEWS_MORE_OK, REVIEWS_MORE_FAIL] =
  createApiRequest(REVIEWS_MORE, r_api.get_store_reviews);
const [get_store_reviews_selected, REVIEWS_SELECTED_OK, REVIEWS_SELECTED_FAIL] =
  createApiRequest(REVIEWS_SELECTED, r_api.get_store_reviews);
const [get_store_location, LOCATION_OK, LOCATION_FAIL] = createApiRequest(
  LOCATION,
  s_api.get_store_location
);
const [get_store_recommend_reviews, RECOMMEND_OK, RECOMMEND_FAIL] =
  createApiRequest(RECOMMEND, s_api.get_store_recommend_reviews);
const [add_review, ADD_REVIEW_OK, ADD_REVIEW_FAIL] = createApiRequest(
  ADD_REVIEW,
  r_api.add_review
);
const [modify_review, MODIFY_REVIEW_OK, MODIFY_REVIEW_FAIL] = createApiRequest(
  MODIFY_REVIEW,
  r_api.modify_review
);
const [delete_review, DELETE_REVIEW_OK, DELETE_REVIEW_FAIL] = createApiRequest(
  DELETE_REVIEW,
  r_api.delete_review
);
const [toggle_store_like, TOGGLE_LIKE_OK, TOGGLE_LIKE_FAIL] = createApiRequest(
  TOGGLE_LIKE,
  s_api.toggle_store_like
);

const Types = {
  ARTICLE,
  INFO,
  MENUS,
  PRICE,
  RECOMMEND,
  REVIEWS,
  REVIEWS_MORE,
};

const state = {
  article: [],
  info: {
    businessDays: [],
    contacts: [],
    coupons: [],
    deliveries: [],
    favorite: 'off',
    statistics: {},
    status: {},
    tags: [],
  },
  location: [],
  menus: [],
  prices: [],
  review: {
    recommends: [],
    regular: [],
  },
  topMore: false,
};

/** compose reducer. */
const view = handleActions(
  {
    [ADD_REVIEW]: (state) => ({ ...state }),
    [ADD_REVIEW_OK]: (state, action) => {
      if (action.payload.code === 'failure') {
        return state;
      }
      if (action.payload.data.replies !== null) {
        return {
          ...state,
          review: {
            ...state.review,
            regular: [
              {
                ...action.payload.data,
                attachments: action.payload.data.attachments.map((item) => ({
                  code: action.payload.data.id,
                  origin: item.origin,
                  thumbnail: item.thumbnail,
                })),
                secret: action.payload.data.secretStatusCode,
              },
              ...state.review.regular,
            ],
          },
        };
      }
      return {
        ...state,
        review: {
          ...state.review,
          regular: state.review.regular.map((review) => {
            if (review.id === action.payload.data.parentCode) {
              return {
                ...review,
                replies: [
                  ...review.replies,
                  {
                    ...action.payload.data,
                    attachments: action.payload.data.attachments.map(
                      (item) => ({
                        code: action.payload.data.id,
                        origin: item.origin,
                        thumbnail: item.thumbnail,
                      })
                    ),
                  },
                ],
              };
            }
            return review;
          }),
        },
      };
    },
    [ARTICLE]: (state) => ({
      ...state,
    }),
    [ARTICLE_OK]: (state, action) => ({
      ...state,
      article: action.payload,
    }),
    [DELETE_REVIEW]: (state) => ({ ...state }),
    [DELETE_REVIEW_OK]: (state, action) => {
      let [isReview, isReply] = [false, false];
      for (const review of state.review.regular) {
        if (isReply) {
          break;
        } else if (review.id === action.payload.data.id) {
          isReview = true;
          break;
        } else {
          for (const reply of review.replies) {
            if (reply.id === action.payload.data.id) {
              isReply = true;
              break;
            }
          }
        }
      }
      if (isReview) {
        return {
          ...state,
          review: {
            ...state.review,
            regular: state.review.regular.filter(
              (review) => review.id !== action.payload.data.id
            ),
          },
        };
      }
      return {
        ...state,
        review: {
          ...state.review,
          regular: state.review.regular.map((review) => {
            return {
              ...review,
              replies: review.replies.filter(
                (reply) => reply.id !== action.payload.data.id
              ),
            };
          }),
        },
      };
    },
    [INFO]: (state) => ({
      article: [],
      info: {
        businessDays: [],
        contacts: [],
        coupons: [],
        deliveries: [],
        favorite: 'off',
        statistics: {},
        status: {},
        tags: [],
      },
      menus: [],
      prices: [],
      review: {
        recommends: [],
        regular: [],
      },
    }),
    [INFO_OK]: (state, action) => ({
      ...state,
      info: action.payload,
    }),
    [LOCATION]: (state) => ({ ...state }),
    [LOCATION_OK]: (state, action) => ({
      ...state,
      location: action.payload,
    }),
    [MENUS]: (state) => ({
      ...state,
    }),
    [MENUS_OK]: (state, action) => ({
      ...state,
      menus: action.payload,
    }),
    MENU_NOTIFICATION: (state) => {
      const menus = state.menus.map((menu) => ({
        ...menu,
        notificationRequested: true,
      }));

      return { ...state, menus };
    },
    [MODIFY_REVIEW]: (state) => ({ ...state }),
    [MODIFY_REVIEW_OK]: (state, action) => {
      if (action.payload.code === 'failure') {
        return state;
      }
      if (!action.payload.data.parentCode) {
        return {
          ...state,
          review: {
            ...state.review,
            regular: state.review.regular.map((review) => {
              if (action.payload.data.id === review.id) {
                return {
                  ...review,
                  attachments: action.payload.data.attachments.map((item) => ({
                    code: review.id,
                    origin: item.origin,
                    thumbnail: item.thumbnail,
                  })),
                  comment: action.payload.data.comment,
                  rating: action.payload.data.rating,
                  secret: action.payload.data.secretStatusCode,
                };
              }
              return review;
            }),
          },
        };
      }
      return {
        ...state,
        review: {
          ...state.review,
          regular: state.review.regular.map((review) => {
            return {
              ...review,
              replies: review.replies.map((reply) => {
                if (reply.id === action.payload.data.id) {
                  return {
                    ...reply,
                    attachments: action.payload.data.attachments.map(
                      (item) => ({
                        code: reply.id,
                        origin: item.origin,
                        thumbnail: item.thumbnail,
                      })
                    ),
                    comment: action.payload.data.comment,
                  };
                }
                return reply;
              }),
            };
          }),
        },
      };
    },
    [PRICE]: (state) => ({
      ...state,
    }),
    [PRICE_OK]: (state, action) => ({
      ...state,
      prices: action.payload,
    }),
    [RECOMMEND]: (state) => ({
      ...state,
    }),
    [RECOMMEND_OK]: (state, action) => ({
      ...state,
      review: {
        ...state.review,
        recommends: action.payload,
      },
    }),
    [REVIEWS]: (state) => ({ ...state }),
    [REVIEWS_MORE]: (state) => ({ ...state }),
    [REVIEWS_MORE_OK]: (state, action) => ({
      ...state,
      review: {
        ...state.review,
        regular: [...state.review.regular, ...action.payload.slice(1)],
        topMore: action.payload.topMore,
      },
    }),

    [REVIEWS_OK]: (state, action) => ({
      ...state,
      review: {
        ...state.review,
        regular: action.payload,
      },
      topMore: false,
    }),
    [REVIEWS_SELECTED]: (state) => ({ ...state }),
    [REVIEWS_SELECTED_OK]: (state, action) => ({
      ...state,
      review: {
        ...state.review,
        regular: action.payload.review,
      },
      topMore: action.payload.topMore,
    }),
    [TOGGLE_LIKE]: (state) => ({
      ...state,
    }),
    [TOGGLE_LIKE_OK]: (state, action) => ({
      ...state,
      info: {
        ...state.info,
        favorite: action.payload.doLike ? 'on' : 'off',
      },
    }),
  },
  state
);

/** export dispatch functions. */
export {
  add_review,
  delete_review,
  get_store,
  get_store_article,
  get_store_location,
  get_store_menus,
  get_store_prices,
  get_store_recommend_reviews,
  get_store_reviews,
  get_store_reviews_more,
  get_store_reviews_selected,
  modify_review,
  toggle_store_like,
  Types,
  updateNotification,
};
/** export reducer. */
export default view;

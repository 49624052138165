import { useCallback, useState } from 'react';

const useModal = () => {
  const [visible, setVisible] = useState(false);

  const onOpen = useCallback((data) => {
    setVisible(data || true);
  }, []);
  const onClose = useCallback(() => {
    setVisible(false);
  }, []);
  const onToggle = useCallback(() => {
    setVisible((state) => !state);
  }, []);

  return { onClose, onOpen, onToggle, visible };
};

export default useModal;

import React from 'react';
import styled from 'styled-components';

const DropDown = ({ children, visible }) => {
  const [visibilityAnimation, setVisibilityAnimation] = React.useState(false);
  const [repeat, setRepeat] = React.useState(null);

  React.useEffect(() => {
    if (visible) {
      clearTimeout(repeat);
      setRepeat(null);
      setVisibilityAnimation(true);
    } else {
      setRepeat(
        setTimeout(() => {
          setVisibilityAnimation(false);
        }, 400)
      );
    }
  }, [visible]);

  return (
    <Container visible={visibilityAnimation}>
      <article
        className={
          visible ? 'slide-fade-in-dropdown' : 'slide-fade-out-dropdown'
        }
      >
        {visibilityAnimation && children}
      </article>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;

  border-radius: 8px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  z-index: 99999999;
  @keyframes slide-fade-in-dropdown-animation {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }
  @keyframes slide-fade-out-dropdown-animation {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-100%);
    }
  }

  .slide-fade-in-dropdown {
    overflow: hidden;
  }

  .slide-fade-in-dropdown > ul {
    animation: slide-fade-in-dropdown-animation 0.4s ease;
  }

  .slide-fade-out-dropdown {
    overflow: hidden;
  }

  .slide-fade-out-dropdown > ul {
    animation: slide-fade-out-dropdown-animation 0.4s ease;
    animation-fill-mode: forwards;
  }
  ul {
    position: relative;
    margin-top: 0;
    margin-bottom: 5px;
    list-style: none;
  }
`;

export default DropDown;

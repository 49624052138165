import { report_review } from 'api/review';
import { HeadWithCloseButton } from 'components/common/header';
import { set_reply_report } from 'context/review';
import React, { useState } from 'react';
import styled from 'styled-components';

const reasons = [
  { code: 'COMMERCIAL', str: '영리목적의 부적절한 홍보 또는 광고' },
  {
    code: 'INAPPROPRIATE',
    str: '음란성, 선정성, 욕설, 인신공격 등 부적절한 내용',
  },
  { code: 'PRIVACY', str: '개인정보가 노출되어 유출 위험' },
  { code: 'NOT_RELATED', str: '주문과 관련 없는 사진 또는 내용 게시' },
  {
    code: 'COPYRIGHT',
    str: '저작권 도용이 의심되는 내용(사진 및 동영상 포함)',
  },
  { code: 'ETC', str: '기타' },
];

const ReportWrap = ({ dispatch, replyReport, showToast }) => {
  const [reason, setReason] = useState(0);
  const [ex, setEx] = useState('');
  const notEx = reason !== reasons.length - 1;

  const report = () => {
    const params = {
      comment: notEx ? '' : ex,
      commentCode: replyReport.id,
      reason: reasons[reason].code,
    };

    replyReport.blind.reported = true;
    dispatch(
      set_reply_report({
        reportAgree: true,
        reportType: 'reportComplete',
      })
    );
    report_review(params)
      .then(() => {
        replyReport.blind.reported = true;

        dispatch(
          set_reply_report({
            reportAgree: true,
            reportType: 'reportComplete',
          })
        );
      })
      .catch((err) => {
        const errCode = err?.response?.data?.status;
        if (errCode === 409)
          dispatch(
            set_reply_report({
              reportAgree: true,
              reportType: 'reportAlready',
            })
          );
      });
  };

  return (
    <ReportStyle>
      <section>
        <HeadWithCloseButton
          label="신고하기"
          style={{
            display: 'block',
            position: 'absolute',
            textAlign: 'center',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
          }}
          onClose={() => dispatch(set_reply_report(false))}
        />
        <div className="content-wrap">
          <h3>신고사유</h3>
          <p>신고된 내용은 관리자 확인 후 삭제 또는 블라인드 처리 됩니다.</p>
          <div className="options-wrap">
            {reasons.map((reasonObj, i) => (
              <label key={i} onClick={() => setReason(i)}>
                <input
                  checked={i === reason}
                  type="radio"
                  onChange={() => {}}
                />
                <span>{reasonObj.str}</span>
              </label>
            ))}
          </div>
          <div className="text-area-wrap">
            <textarea
              disabled={notEx}
              value={ex}
              onChange={(e) => setEx(e.target.value)}
            />
          </div>

          <div className="button-wrap">
            <button onClick={report}>신고하기</button>
          </div>
        </div>
      </section>
    </ReportStyle>
  );
};

const ReportStyle = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 2000;

  & > section {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    min-width: 360px;
    max-width: 420px;
    height: 100vh;

    transform: translateX(-50%);

    background: #fff;

    & > .content-wrap {
      padding: 32px 16px;
      & > h3 {
        font-size: 18px;
        font-weight: 600;
        color: #222;
      }
      & > p {
        margin-top: 12px;
        font-size: 15px;
        color: #333;
      }
      & > .options-wrap {
        margin-top: 24px;
        & > label {
          display: flex;
          align-items: center;
          height: 28px;

          & > input {
          }
          & > span {
            margin-left: 8px;
          }
        }
      }
      & > .text-area-wrap {
        display: flex;
        & > textarea {
          margin-top: 24px;
          width: 100%;
          flex: 1;
          height: 120px;
          padding: 6px;
          resize: none;
        }
      }
      & > .button-wrap {
        display: flex;
        justify-content: center;

        & > button {
          margin: 24px auto;
          width: 120px;
          height: 40px;
          background: rgba(28, 121, 188, 1);
          border-radius: 4px;

          color: #fff;

          cursor: pointer;
        }
      }
    }
  }
`;

export default ReportWrap;

import client from 'api/client';

export const search_wholesale_items = ({
  category,
  keyword,
  serial,
  sort,
  user,
}) => {
  return client.get('/www/wholesale/search', {
    params: { categoryCode: category, keyword, serialCode: serial, sort },
  });
};

export const getWholeSaleSearch = ({ searchKeyword, serial }) =>
  client.get('/www/wholesale/search', {
    params: { keyword: searchKeyword, serialCode: serial },
  });

export const get_wholesale_item = ({ code }) =>
  client.get(`/www/wholesale/detail/${code}`);

export const get_wholesale_cart_items = (items) =>
  client.get(`/www/wholesale/cart-items`, {
    params: { targets: items.join(',') },
  });

export const submit_order = (params) =>
  client.post(`/www/wholesale/order/`, params);

export const get_orders = () => client.get(`/www/wholesale/orders/`);

export const cancel_order = (code) =>
  client.put(`/www/wholesale/order/${code}/cancel/`);

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  type: 'CLOSE',
};

const slice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    onOpenDialog: (state, { payload }) => {
      state.data = payload.data;
      state.type = payload.type;
    },
    onCloseDialog: (state) => {
      state.data = null;
      state.type = 'CLOSE';
    },
  },
});

export const { onCloseDialog, onOpenDialog } = slice.actions;

export default slice.reducer;

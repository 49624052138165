import React from 'react';
import styled from 'styled-components';

const GridView = ({ items, render }) => {
  let cols = [];
  let rows = [];
  let lst_idx = items.length - 1;
  for (let i in items) {
    cols.push(render(items[i], i));
    if (i % 3 === 2 || lst_idx === i) {
      rows.push(<GridLi key={i}>{cols}</GridLi>);
      cols = [];
    }
  }
  const noWidget =
    document.getElementsByClassName('section widget fixed').length < 1;

  return <ul style={{ marginTop: noWidget ? '16px' : '8px' }}>{rows}</ul>;
};

const GridLi = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr !important;
`;

export default GridView;

import { atom } from 'recoil';
import { v1 } from 'uuid';

export const globalCouponsState = atom({
  default: [],
  key: `globalCouponsState/${v1()}`,
});

export const allCouponsListState = atom({
  default: {
    region: { code: 'all', label: '모든지역' },
    sort: 'useDate',
  },
  key: `allCouponsListState/${v1()}`,
});

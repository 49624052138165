import { Typography } from 'components/atoms';
import PartDivider from 'components/atoms/PartDivider';
import SvgIcon from 'components/atoms/SvgIcon';
import BoardTitleSection from 'components/common/BoardTitleSection';
import NoResultAlarm from 'components/molecules/NoResultAlarm';
import ShowMoreBox from 'components/molecules/ShowMoreBox/ShowMoreBox';
import ErrorNotice from 'components/NotFound/ErrorNotice';
import { Image } from 'components/views';
import useUpdateLabelFavorite from 'hooks/Mutate/Price/useUpdateLabelFavorite';
import { useGetLabelFavoriteList } from 'hooks/Query/Price';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { getImageSrc } from 'utils/imageLoader';

import LabelListLoading from './LabelListLoading';

const LabelList = () => {
  const history = useHistory();
  const { data, fetchNextPage, hasNextPage, isError, isLoading } =
    useGetLabelFavoriteList();
  const { mutate } = useUpdateLabelFavorite({
    favorite: true,
    id: null,
    offMessage: '내 찜 목록에서 삭제되었습니다.',
  });

  const handleClickNextPage = () => {
    if (hasNextPage) fetchNextPage();
  };

  const handleDelete = (id) => {
    mutate({ command: 'CANCEL', id });
  };

  const handleClickLabel = (id) => {
    history.push(`/시세/${id}`);
  };

  if (isLoading) return <LabelListLoading />;
  if (isError) return <ErrorNotice />;
  return (
    <BoardTitleSection
      margin="0 16px 4px 0px"
      padding="24px 0 0 0"
      title="찜한 품목"
    >
      <ShowMoreBox
        hasNextPage={hasNextPage}
        text="더 많은 품목 확인하기"
        hideGradient
        onClickShowMore={handleClickNextPage}
      >
        <Container>
          <PartDivider level={1}>
            {data.length ? (
              data.map((item) => (
                <ItemCard key={item.favoriteLabelId}>
                  <InfoBox onClick={() => handleClickLabel(item.labelId)}>
                    <StyledImage
                      alt={item.labelName}
                      height={40}
                      src={getImageSrc(item.thumbnails[0]?.imgUrl)}
                      width={40}
                    />
                    <Typography fontSize="16px" fontWeight={700}>
                      {item.labelName}
                    </Typography>
                  </InfoBox>
                  <SvgIcon
                    name="x"
                    stroke="#8A8D90"
                    strokeWidth={1}
                    pointer
                    onClick={() => handleDelete(item.labelId)}
                  />
                </ItemCard>
              ))
            ) : (
              <NoResultAlarm />
            )}
          </PartDivider>
        </Container>
      </ShowMoreBox>
    </BoardTitleSection>
  );
};

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  cursor: pointer;
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  border-radius: 8px;
`;

const ItemCard = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0px;
  gap: 16px;
`;

const Container = styled.div`
  padding: 0px 16px;
`;

export default LabelList;

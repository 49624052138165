import arrTopImg from 'img/ftp_fa_Arrow_top.svg';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { mobileIosCheck } from 'utils/mobileCheck';

let setTimeMoveTop;
let scrollY = 0;
const MoveTop = () => {
  const { location } = useHistory();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const pastScroll = document.body.onscroll;
    document.body.onscroll = (e) => {
      if (window.scrollY > 0 && window.scrollY < scrollY) setVisible(2000);
      scrollY = window.scrollY;
    };
    return () => {
      document.body.onscroll = pastScroll;
    };
  }, []);

  useEffect(() => {
    if (visible > 0) {
      setTimeMoveTop = setTimeout(() => {
        if (setTimeMoveTop) clearTimeout(setTimeMoveTop);
        setVisible((p) => p - 500);
      }, 500);
    }
  }, [visible]);

  if (
    location.pathname === '/주문/취소' ||
    location.pathname === '/주문/완료' ||
    location.pathname.split('/')[1] === 'menu'
  ) {
    return null;
  }

  return (
    <div
      className={`move-top ${visible > 0 ? `ON` : ''}`}
      onClick={() => {
        window.scrollTo({ top: 0 });
      }}
    >
      <img alt="" src={arrTopImg} />
    </div>
  );
};

const AdditionalFunc = ({ style }) => {
  const mobileIOS = mobileIosCheck();
  return (
    <AdditionalFuncStyle
      className="add-funcs"
      mobileIOS={mobileIOS}
      style={style}
    >
      {/* <div
        onClick={() => {
          window.location.href = "https://pf.kakao.com/_lVxhbl/chat";
        }}
        className="ask-kakao"
      >
        <img src="" alt="" />
      </div> */}
      <MoveTop />
    </AdditionalFuncStyle>
  );
};

const AdditionalFuncStyle = styled.div`
  position: absolute;
  bottom: ${({ mobileIOS }) => `calc(${mobileIOS ? 27 : 0}px + 16px)`};
  right: 16px;
  & > div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    & + div {
      margin-top: 8px;
    }
  }
  & > .ask-kakao {
    background: #fee500;
    & > img {
      width: 20px;
      height: 20px;
    }
  }
  & > .move-top {
    position: absolute;
    right: 0;
    bottom: 22px;
    background: #fff;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    & > img {
      width: 16px;
      height: 16px;
    }
    &.ON {
      opacity: 1;
      pointer-events: all;
    }
  }
  @media (min-width: 576px) {
  }
`;

export default AdditionalFunc;

import './style.css';

import { Header, Panel } from 'components/biz';
import { Container } from 'components/common';
import { Article } from 'components/contents';
import { Section } from 'components/section';
import { Loading } from 'components/views';
import { BIZ } from 'context';
import { get_item } from 'context/biz/item';
import { ToastContext } from 'context/toast';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';

const WholesaleItem = ({
  articles,
  delivery,
  get_item,
  info,
  isLoading,
  match,
  prices,
}) => {
  const { id } = match.params;
  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    get_item({ code: id }).catch(() => {
      showToast('요청에 실패했습니다.');
    });
  }, [id]);

  if (isLoading) return <Loading />;

  return (
    <Container>
      <Header figure={info.thumbnail} label={info.subject} />
      <div className="main t-pirates-biz">
        {Object.keys(info).length > 0 && (
          <>
            <Section title={info.label} type="content">
              <Panel.Order
                order={info.order}
                price={info.price}
                unit={info.unit}
              />
            </Section>

            <Section title="배송정보" type="content">
              <Panel.Delivery {...{ ...info, ...delivery }} />
            </Section>

            {articles.length > 0 && <Article article={articles} />}

            <Section title="시세변동내역" type="content">
              <Panel.Prices prices={prices} />
            </Section>
          </>
        )}
      </div>
    </Container>
  );
};

export default connect(
  ({ [BIZ.ITEM]: item }) => ({
    articles: item.articles,
    delivery: item.delivery,
    info: item.info,
    isLoading: item.isLoading,
    prices: item.prices,
  }),
  {
    get_item,
  }
)(WholesaleItem);

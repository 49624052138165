import React from 'react';
import styled from 'styled-components';

const Back = ({ fill = '#464C53', size = 24, onClick }) => {
  return (
    <Svg
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 24 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.2679 8.83653H4.6488L11.1288 2.57566C11.2399 2.46902 11.3283 2.34103 11.3887 2.19937C11.4491 2.05771 11.4802 1.9053 11.4802 1.75131C11.4802 1.59732 11.4491 1.44491 11.3887 1.30325C11.3283 1.16159 11.2399 1.0336 11.1288 0.926962C10.8974 0.708662 10.5913 0.587067 10.2731 0.587067C9.95501 0.587067 9.64891 0.708662 9.41749 0.926962L1.73227 8.35653L0.876622 9.17566C0.764181 9.28151 0.674585 9.40925 0.613342 9.55101C0.5521 9.69278 0.520508 9.84558 0.520508 10C0.520508 10.1544 0.5521 10.3072 0.613342 10.449C0.674585 10.5908 0.764181 10.7185 0.876622 10.8244L1.73227 11.6435L9.41749 19.0522C9.64891 19.2705 9.95501 19.3921 10.2731 19.3921C10.5913 19.3921 10.8974 19.2705 11.1288 19.0522C11.2399 18.9455 11.3283 18.8176 11.3887 18.6759C11.4491 18.5342 11.4802 18.3818 11.4802 18.2278C11.4802 18.0738 11.4491 17.9214 11.3887 17.7798C11.3283 17.6381 11.2399 17.5101 11.1288 17.4035L4.6488 11.1426H22.2679C22.5765 11.1426 22.8724 11.02 23.0906 10.8018C23.3088 10.5836 23.4314 10.2877 23.4314 9.97913C23.4314 9.67056 23.3088 9.37463 23.0906 9.15643C22.8724 8.93824 22.5765 8.81566 22.2679 8.81566V8.83653Z" />
    </Svg>
  );
};

const Svg = styled.svg`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export default React.memo(Back);

const SHOW_NAV = 'nav/SHOW_NAV';
const HIDE_NAV = 'nav/HIDE_NAV';

export const hideNav = (payload) => {
  return { type: HIDE_NAV, isOpen: false };
};

export const showNav = (message) => {
  return { type: SHOW_NAV, isOpen: true };
};

const initialState = {
  isOpen: true,
};

const nav = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NAV:
      return { isOpen: action.isOpen };
    case HIDE_NAV:
      return { isOpen: action.isOpen };
    default:
      return state;
  }
};

export default nav;

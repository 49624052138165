import './style.css';

import FramerCarousel from 'components/atoms/FramerCarousel';
import Carousel from 'components/common/Carousel';
// import { useCarousel } from '../hook';
import LoginContext, { View } from 'components/common/login/_context';
import { useFindOutDragging } from 'hooks/custom/useFindOutDragging';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Ticket from './_ticket';

const _SingleSimplifyItemView = ({ items, onClick, session, storeCode }) => {
  return (
    <div className="inner-section type4">
      <FramerCarousel autoPlay={false}>
        {items.map((c, i) => (
          <Ticket
            key={i}
            item={c}
            session={session}
            size="large-simplify"
            storeCode={storeCode}
            onClick={() => onClick(c.id, i)}
          />
        ))}
      </FramerCarousel>
    </div>
  );
};

const _MultiItemView = ({ items, onClick, session }) => {
  const { action } = useContext(LoginContext);
  const { handleMouseDown, handleMouseUp, isDragging } = useFindOutDragging();
  const handleClick = (id, i) => {
    if (isDragging) return;
    onClick(id, i);
  };

  return (
    <div
      className="section card-list1 flicking"
      style={{ backgroundColor: 'white' }}
    >
      <Carousel
        data={items}
        style={{ gap: '12px', padding: '16px 16px 24px 16px' }}
      >
        {[
          ...items.map((item, i) => (
            <div
              key={i.toString()}
              className="item"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <Ticket
                key={`ticket${i}`}
                item={item}
                size="small"
                onClick={() => handleClick(item.id, i)}
              />
            </div>
          )),
          <div key="test" className="item">
            <div key="ticket-my">
              {session.identity ? (
                <Link
                  key="my-coupon"
                  to={{
                    pathname: '/마이페이지/내쿠폰',
                  }}
                >
                  <Ticket
                    key="my-coupon"
                    item={{ itemName: '내 쿠폰 보기', store: ' ' }}
                    size="small"
                  />
                </Link>
              ) : (
                <a key="my-coupon" href="#">
                  <Ticket
                    key="my-coupon"
                    item={{ itemName: '내 쿠폰 보기', store: ' ' }}
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      action.open(View.CHANNEL);
                    }}
                  />
                </a>
              )}
            </div>
          </div>,
        ]}
      </Carousel>
    </div>
  );
};

const _SignboardItemView = ({ items, onClick }) => {
  return (
    <div className="section card-list7 _coupon-signboard">
      {items.map((item, i) => (
        <Ticket
          key={i}
          item={item}
          size="large"
          onClick={() => onClick(item.id, i)}
        />
      ))}
    </div>
  );
};
const _SignboardDetailItemView = ({ items, onClick }) => {
  return (
    <div className="section card-list7 _coupon-signboard">
      {items.map((item, i) => (
        <Ticket
          key={i.toString()}
          item={item}
          size="large-detail"
          onClick={() => onClick(item.id, i)}
        />
      ))}
    </div>
  );
};

const _ImgboardItemView = ({ items, onClick }) => {
  return (
    <GridBox>
      {items.map((item, i) => (
        <Ticket
          key={i.toString()}
          item={item}
          size="image"
          onClick={() => onClick(item.id, i)}
        />
      ))}
    </GridBox>
  );
};

const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 0px 16px;
`;

const Coupons = ({ items, onClick, session, storeCode, view }) => {
  switch (view) {
    case 'multiple':
      return (
        <_MultiItemView items={items} session={session} onClick={onClick} />
      );
    case 'single-simplify':
      return (
        <_SingleSimplifyItemView
          items={items}
          session={session}
          storeCode={storeCode}
          onClick={onClick}
        />
      );
    case 'signboard':
      return <_SignboardItemView items={items} onClick={onClick} />;
    case 'signboard-detail':
      return <_SignboardDetailItemView items={items} onClick={onClick} />;
    case 'imgboard':
      return <_ImgboardItemView items={items} onClick={onClick} />;
    default:
      return <></>;
  }
};

export default Coupons;

import { useGetCouponList } from 'hooks/Query/CouponList';

const useAllCouponList = () => {
  const {
    data = { pages: [{ coupons: [], total: 0 }] },
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetCouponList();
  const excellentIndex = data?.pages.map((item) => item.coupons);

  const newCouponList = excellentIndex.reduce((acc, arr) => {
    arr?.forEach((obj) => {
      acc.push(obj);
    });

    return acc;
  }, []);

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    newCouponList,
  };
};

export default useAllCouponList;

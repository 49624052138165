import { useRecoilState } from 'recoil';
import { wholesaleSortState } from 'recoil/filter';

export const useWholeSaleSortState = () => {
  const [sortState, setSortState] = useRecoilState(wholesaleSortState);

  const initWholeSaleSortState = () => {
    setSortState({ product: [] });
  };

  return { initWholeSaleSortState, setSortState, sortState };
};

import { useInfiniteQuery } from '@tanstack/react-query';
import { getMyCoupons } from 'api/coupon';
import { useRecoilState } from 'recoil';
import { allCouponsListState } from 'recoil/coupons';

import couponListKeys from './keys';

export default function useGetMyCouponList() {
  const [couponList, setCouponList] = useRecoilState(allCouponsListState);

  return useInfiniteQuery(
    couponListKeys.getMyCouponList({
      sortBy: couponList.sort,
    }),
    ({ pageParam = 1 }) => {
      const params = {
        pageNum: pageParam,
        sortBy: couponList.sort || null,
      };

      if (pageParam === 1) {
        delete params.pageNum;
      }

      return getMyCoupons(params);
    },
    {
      cacheTime: 0,
      getNextPageParam: ({ nextPageNo }) => nextPageNo,
      staleTime: 0,
    }
  );
}

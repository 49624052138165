/**
 * 1. 네이버 시세로 진입시 Spring 서버 RequestMapping 통해서 해당 url로 이동
 * portal/search?zone=0000000000#100
 * (RequestMapping 은 pirates-www-server/src/main/java/com/pirates/www/webServiceController.java 확인)
 * 2. itemCode로 label정보를 조회
 * 3. 조회한 label정보를 가지고 시세 상세페이지로 이동해서 해당 품목의 시세를 유저에게 노출
 *  * 추가: zone 쿼리스트링을 보내면 지역까지 선택됨
 */
import { Loading } from 'components/views';
import { ToastContext } from 'context/toast';
import { useGetLabelByItemCode } from 'hooks/Query/Price';
import { useContext, useEffect } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { getParam } from 'utils/queryString';

const PortalSearch = () => {
  const history = useHistory();
  const { hash } = useLocation();
  const zone = getParam('zone');
  const itemCode = hash.replace('#', '');
  const { data, isError } = useGetLabelByItemCode({ itemCode });
  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    if (itemCode && data && zone) {
      history.replace(`/시세/${data.labelId}?target=${itemCode}&zone=${zone}`);
    }
    if (isError) {
      showToast('검색 가능한 시세가 없습니다');
      history.replace('/시세');
    }
  }, [data, itemCode, isError, zone]);

  return <Loading />;
};

export default PortalSearch;

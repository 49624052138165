const renderDate = (date) => {
  const old_date = new Date(date);
  const today = new Date();
  const yesterday = new Date(today - 24 * 60 * 60 * 1000);
  let ret = '';

  let gap = parseInt((today.getTime() - old_date.getTime()) / 60000);
  if (isNaN(gap)) {
    gap = 0;
  }

  if (gap < 1) {
    ret = '지금';
  } else if (gap < 60) {
    ret = `${gap}분 전`;
  } else if (old_date.toDateString() === today.toDateString()) {
    ret = '오늘';
  } else if (old_date.toDateString() === yesterday.toDateString()) {
    ret = '어제';
  } else {
    ret = `${old_date.getFullYear()}.${
      old_date.getMonth() + 1
    }.${old_date.getDate()}`;
  }

  return ret;
};

export default renderDate;

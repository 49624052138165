/* eslint-disable import/no-anonymous-default-export */
const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

function date(format) {
  const padding = (num, digit = 2) =>
    digit > 0 ? `00${num}`.slice(-digit) : `${num}`;

  const d = new Date(this);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();

  const hour = d.getHours();
  const minute = d.getMinutes();
  const second = d.getSeconds();

  const day = dayOfWeek[d.getDay()];

  return format
    .replace(/%Y/gi, padding(year, 4))
    .replace(/%y/gi, padding(year))
    .replace(/%m/gi, padding(month))
    .replace(/%d/gi, padding(date))
    .replace(/%e/gi, padding(date, 0))
    .replace(/%H/gi, padding(hour))
    .replace(/%h/gi, padding(hour, 0))
    .replace(/%i/gi, padding(minute))
    .replace(/%s/gi, padding(second))
    .replace(/%W/gi, day);
}

function now() {
  return new Date();
}

function isToday() {
  const int = (d) =>
    d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate();

  if (!this) return false;

  return int(new Date()) === int(new Date(this));
}

function human() {
  const _t = new Date();
  const _d = new Date(this);

  if (_t.getFullYear() > _d.getFullYear()) {
    return `${_d.getFullYear()}년 ${_d.getMonth() + 1}월 ${_d.getDate()}일`;
  }
  return `${_d.getMonth() + 1}월 ${_d.getDate()}일(${dayOfWeek[_d.getDay()]})`;
}

export default (value) => {
  return {
    date: date.bind(value),
    human: human.bind(value),
    isToday: isToday.bind(value),
    now: now.bind(value),
  };
};

import { useEffect } from 'react';

const LoadSDK = (init, { module, src }) => {
  const load_module = (module, src) => {
    const sdkId = `${module}-js-sdk`;
    if (document.getElementById(sdkId)) {
      return;
    }
    const [last] = Array.prototype.slice.call(
      document.getElementsByTagName('script'),
      -1
    );
    const sdk = document.createElement('script');
    sdk.id = sdkId;
    sdk.src = src;
    sdk.async = true;
    sdk.defer = true;
    if (module === 'facebook') {
      sdk.crossOrigin = 'anonymous';
    }
    sdk.onload = init;
    last.parentNode.append(sdk);
  };

  useEffect(() => {
    if (src) {
      load_module(module, src);
    }
  }, []);
};
export default LoadSDK;

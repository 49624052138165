import { Box } from 'components/atoms';
import ConfirmModal from 'components/modules/ConfirmModal';
import { onOpenDialog } from 'features/dialogSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GlobalStyle } from 'styles';

const Footer = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  return (
    <GlobalStyle>
      <Box height="48px" width="100%">
        <Box
          alignItems="center"
          backgroundColor="blue2"
          color="white"
          flex={1}
          justifyContent="center"
          role="button"
          onClick={() => {
            push({ hash: '#address' });
            dispatch(onOpenDialog({ type: 'SELECT_ADDRESS' }));
          }}
        >
          주소 변경
        </Box>
        <Box
          alignItems="center"
          borderTop="1px solid #C9CDD2"
          flex={2}
          justifyContent="center"
          role="button"
          onClick={() => {
            dispatch(onOpenDialog({ type: 'SELECT_SHIPPING_METHOD' }));
          }}
        >
          주문유형 변경
        </Box>
      </Box>
    </GlobalStyle>
  );
};

const ShippingUnavailable = ({ data = {}, onClose, open }) => {
  return (
    <ConfirmModal footer={<Footer />} visible={open} onClose={onClose}>
      <GlobalStyle>
        <Box display="block" textAlign="center">
          현재 선택하신 주소는
        </Box>
        <Box display="block" textAlign="center">
          시장과의 거리가 {Math.ceil(data?.distance / 1000) || '-'} KM 입니다.
        </Box>
        <Box display="block" marginTop="16px" textAlign="center">
          상품 특성상 신선도 문제로 인하여
          <br /> 배달이 어렵습니다.
        </Box>

        <Box display="block" marginTop="16px" textAlign="center">
          포장 주문 또는 배송지를 변경하여
          <br /> 주문 부탁드립니다.
        </Box>
      </GlobalStyle>
    </ConfirmModal>
  );
};

export default ShippingUnavailable;

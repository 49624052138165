import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

const statusReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state, action) => {
        const type = action.type.slice(0, -8);

        state.isLoading = true;

        if (state[type]) {
          state[type].isError = null;
          state[type].isLoading = true;
        } else {
          state[type] = { isError: null, isLoading: true };
        }
      }
    )
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        const type = action.type.slice(0, -9);

        state.isLoading = false;

        if (state[type]) {
          state[type].isError =
            action.payload?.message ?? action.error?.message;
          state[type].isLoading = false;
        } else {
          state[type] = {
            isError: 'Error in the status reducer',
            isLoading: false,
          };
        }
      }
    )
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const type = action.type.slice(0, -10);

        state.isLoading = false;

        if (state[type]) {
          state[type].isError = null;
          state[type].isLoading = false;
        } else {
          state[type] = {
            isError: 'Error in the status reducer',
            isLoading: false,
          };
        }
      }
    );
});

export default statusReducer;

import React from 'react';
import { Button } from 'components/views';
import { Image } from '../views';

const Header = ({ label, figure }) => {
  return (
    <div className="head">
      <div className="section content-information">
        <div className="inner-section type1 use-fixed">
          <div className="head-line-area" style={{ paddingRight: '10px' }}>
            <Button.Back />
            <span className="name txt-ellipsis">{label}</span>
          </div>
        </div>
        <div className="inner-section">
          <div className="img-block biz">
            {figure && (
              <Image noImageType="logo" alt={label} src={figure} scale="16x9" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React, { useReducer, useEffect, useState } from 'react';
import ModalView from '../_modal-view';

const ACTION_OPEN = 't.pirates.dialog/CONFIRM_OPEN';
const ACTION_CONFIRM = 't.pirates.dialog/CONFIRM_OK';
const ACTION_CANCEL = 't.pirates.dialog/CONFIRM_CANCEL';

const _confirm_reducer = (state, action) => {
  switch (action.type) {
    case ACTION_OPEN:
      return { ...state, open: 'on', res: null, payload: action.payload };
    case ACTION_CONFIRM:
      return { ...state, open: 'off', res: true };
    case ACTION_CANCEL:
      return { ...state, open: 'off', res: false };
    default:
      return state;
  }
};

const ConfirmDialog = ({ message, context, onConfirm, onCancel }) => {
  const [_state, _dispatch] = useReducer(_confirm_reducer, {
    open: 'off',
    res: null,
  });

  const _handle_confirm = (e) => {
    _dispatch({ type: ACTION_CONFIRM });
    onConfirm(_state.payload);
  };
  const _handle_cancel = (e) => {
    _dispatch({ type: ACTION_CANCEL });
    onCancel();
  };

  const _actions = {
    open: (e) => _dispatch({ type: ACTION_OPEN, payload: e }),
  };

  useEffect(() => {
    context(_actions);
  }, [context]);

  return (
    <>
      <ModalView toggle={_state.open}>
        <div className="fixed modal-coupon">
          <div className="head">확인</div>
          <div className="main">
            <div>{message}</div>
          </div>
          <div className="foot">
            <div className="btn-area">
              <a onClick={_handle_confirm}>
                <button>확인</button>
              </a>
              <a onClick={_handle_cancel}>
                <button>취소</button>
              </a>
            </div>
          </div>
        </div>
      </ModalView>
    </>
  );
};

ConfirmDialog.defaultProps = {
  context: (ctx) => {},
  onConfirm: () => {},
  onCancel: () => {},
};

export default ConfirmDialog;

import React from 'react';
import styled from 'styled-components';
import { Typography } from '..';

const Input = (props, ref) => {
  return (
    <>
      <Container ref={ref} {...props} $isError={props.isError} />
      {props.isError && (
        <Typography as="p" color="red2" fontSize="12px">
          {props.isError?.message}
        </Typography>
      )}
    </>
  );
};

const Container = styled.input`
  background-color: ${({ theme }) => theme.gray10};
  border: 2px solid
    ${({ $isError, theme }) => ($isError ? theme.red2 : 'transparent')};
  border-radius: 5px;
  padding: 10px 12px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.gray300};
  }
`;

export default React.forwardRef(Input);

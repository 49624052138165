export const getOrderType = (orderType) => {
  switch (orderType) {
    case 'PACKAGE':
      return '포장';
    case 'QUICK':
      return '퀵 배달';
    case 'CONVENIENCE_STORE':
      return '편의점 픽업';
    case 'DAY_DELIVERY':
      return '당일 배송';
  }
};

import no_image_mypage from 'components/images/no-image_mypage.svg';
import { ScoreViewNew } from 'components/reviews/form';
import dayjs from 'dayjs';
import elipImg from 'img/elipsis.svg';
import { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';

const ReviewProfile = ({
  expand = false,
  item,
  modify = false,
  profile,
  remove = false,
  reply = false,
  setExpand = () => {},
}) => {
  const noBubbling = useCallback((ref) => {
    if (ref)
      ref.onclick = (e) => {
        e.stopPropagation();
      };
  }, []);

  const onClick = useCallback(
    (ref) => {
      if (ref)
        ref.onclick = () => setExpand((p) => (p === item ? false : item));
    },
    [item]
  );

  const onClickCallback = useCallback(
    (ref, func) => {
      if (ref) ref.onclick = func;
    },
    [item]
  );

  return (
    <ReviewProfileStyle className="info-area">
      {reply && <i className="fas fa-reply reply-img" />}
      {useMemo(
        () => (
          <>
            <div className="info-profile-pic">
              <figure>
                <img alt="" src={profile?.profileImage || no_image_mypage} />
              </figure>
            </div>
            <div className="info-profile-text">
              {item.rating !== -1 && !reply && (
                <ScoreViewNew score={item.rating} onScores={() => null} />
              )}
              <span>
                {profile.name}
                <span>|</span>
                {dayjs(item.time).format('YYYY.MM.DD')}
              </span>
            </div>
          </>
        ),
        [profile, item]
      )}
      {useMemo(
        () => (
          <>
            {(modify || remove) && (
              <div ref={noBubbling} className="info-fix-delete">
                <figure ref={onClick}>
                  <img alt="" src={elipImg} />
                </figure>
                {expand === item && (
                  <div className="fix-delete-popup">
                    {modify && (
                      <button
                        ref={(ref) => onClickCallback(ref, modify)}
                        className="modify-button"
                      >
                        수정
                      </button>
                    )}
                    {remove && (
                      <button ref={(ref) => onClickCallback(ref, remove)}>
                        삭제
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        ),
        [modify, remove, expand, item]
      )}
    </ReviewProfileStyle>
  );
};

const ReviewProfileStyle = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & > .reply-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transform: rotateZ(180deg);
  }

  & > .info-profile-pic {
    flex: 0 0 36px;
    height: 36px;
    & > figure {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid #eee;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  & > .info-profile-text {
    margin-left: 8px;
    & > .rating-area {
      & > ul {
        overflow: hidden;
        & > i {
          color: #1c79bc;
          & + i {
            margin-left: 1px;
          }
        }
      }
    }
    & > span {
      color: #9fa4aa;
      font-size: 12px;
      padding-top: 4px;
      & > span {
        color: inherit;
        font-size: inherit;
        margin: 0 6px;
      }
    }
  }
  & > .info-profile-secret {
    margin-left: 8px;
    & > i {
      position: relative;
      top: -1px;
    }
  }
  & > .info-fix-delete {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;

    & > figure {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 36px;
      height: 36px;
      cursor: pointer;

      & > img {
        width: 16px;
        height: 4px;
      }
    }
    & > .fix-delete-popup {
      position: absolute;
      top: 30px;
      right: 0;
      width: 74px;
      background: #fff;
      z-index: 10;
      /* gray200 */

      border: 1px solid #c9cdd2;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      overflow: hidden;

      & > button {
        width: 100%;
        height: 55px;
        background: #fff;

        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
`;

export default memo(ReviewProfile);

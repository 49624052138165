/* eslint-disable no-undef */
import './style.css';

import Icon from 'components/Icons/v2/Icon';
import { memo, useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

const HeadTemplateNew = ({ background, children, type }) => {
  const [top, setTop] = useState(!window.scrollY);
  const location = useLocation();

  useEffect(() => {
    const before = document.onscroll || null;
    document.onscroll = () => {
      setTop(!window.scrollY);
    };
    return () => {
      document.onscroll = before;
    };
  }, []);

  useEffect(() => {
    setTop(!window.scrollY);
  }, [location]);

  return (
    <HeadTemplateNewStyle bShadowNone={top} style={{ background }} type={type}>
      {children}
    </HeadTemplateNewStyle>
  );
};

const HeadTemplateNewStyle = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  min-width: 360px;
  max-width: 420px;
  width: 100%;
  padding: 0 16px;

  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;

  box-sizing: border-box;
  z-index: 40;

  & + .container {
    padding-top: 56px;
  }
  ${({ type }) => {
    switch (type) {
      case 'center':
        return css`
          background: #eeeeee;
          box-shadow: none !important;
        `;
      default:
        break;
    }
  }}
  ${({ bShadowNone }) => {
    if (bShadowNone)
      return css`
        box-shadow: none !important;
      `;
  }}
`;

const HeadWithBackButtonNew = ({
  backUrl,
  background,
  children,
  close,
  color,
  label,
  rightIcon,
  type,
  url,
}) => {
  const route = useHistory();
  const { pathname } = useLocation();

  const mainPath = sessionStorage.getItem('mainPath');
  const prevPath = sessionStorage.getItem('prevPath');
  const onClickBack = () => {
    if (backUrl) {
      return route.push(backUrl);
    }

    if (
      // 네이버로 시세상세페이지로 진입했거나, 시세상세 페이지에서 다른 시세상세페이지로 간경우
      prevPath === '/portal/search' ||
      prevPath === 'null' ||
      decodeURIComponent(prevPath).includes('/시세/')
    ) {
      route.push('/시세');
      return;
    }

    if (!mainPath) {
      route.push('/');
      return;
    }

    if (pathname.includes('마이페이지')) {
      window.location.href =
        process.env.REACT_APP_ENV === 'local' ||
        process.env.REACT_APP_ENV === 'dev'
          ? 'https://market.test.tpirates.com/mypage'
          : 'https://market.tpirates.com/mypage';

      return;
    }

    if (pathname.includes('후기')) {
      route.push('/');
      return;
    }

    if (
      mainPath === '/홈' ||
      mainPath === '/시장' ||
      mainPath === '/시세' ||
      mainPath.includes('마이페이지')
    ) {
      route.push(mainPath);
      const mainName = mainPath.replace('/', '');
      const scrollY = sessionStorage.getItem(`${mainName}-scrollY`);
      const scrollFunc = () => {
        window.scrollTo(0, +scrollY);
        sessionStorage.removeItem(`${mainName}-scrollY`);
      };
      if (scrollY) {
        setTimeout(scrollFunc, 50);
      }
      return;
    }

    route.goBack();
  };

  return (
    <HeadTemplateNew background={background} type={type}>
      <HeadWithBackButtonNewStyle type={type}>
        {close ? (
          <Icon
            color="var(--Gray-gray-9, #464C53)"
            name="close-24_2"
            onClick={() => close()}
          />
        ) : (
          <div
            style={{
              cursor: 'pointer',
              filter:
                color === 'white'
                  ? 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(107%) contrast(100%)'
                  : '',
            }}
            onClick={onClickBack}
          >
            <Icon
              color="var(--Gray-gray-9, #464C53)"
              name="back-24_2"
              onClick={onClickBack}
            />
          </div>
        )}

        <span style={{ color, fontWeight: 500 }}>{label}</span>
        {rightIcon && url && (
          <div className="right-icon" onClick={() => route.push(url)}>
            {rightIcon}
          </div>
        )}
        {children}
      </HeadWithBackButtonNewStyle>
      <p
        id="header-color-filter"
        style={{
          background: 'rgba(0,0,0,0.3)',
          display: 'none',
          height: '100%',
          left: '0px',
          position: 'absolute',
          top: '0px',
          width: '100%',
        }}
      />
    </HeadTemplateNew>
  );
};

const HeadWithBackButtonNewStyle = styled.div`
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #333;
    /* font-weight: 500; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > .right-icon {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }
  & > img {
    transform: rotateZ(-90deg);

    width: 16px;
    height: 16px;
    padding: 0px 7px 7px;

    filter: invert(53%) sepia(2%) saturate(13%) hue-rotate(6deg) brightness(87%)
      contrast(88%);

    cursor: pointer;
  }
  ${({ type }) => {
    switch (type) {
      case 'center':
        return css`
          justify-content: center;
          img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
          }
        `;
      default:
        break;
    }
  }}
`;

export default withRouter(memo(HeadWithBackButtonNew));

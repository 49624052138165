import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReservationDate } from 'api/checkout';
import client from 'api/client';
import { order_available } from 'api/order';

export const fetchDeliveryTimes = createAsyncThunk(
  'deliveryTime/fetchDeliveryTimes',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await client.get(
        '/www/order-service/orders/delivery-arrival-times',
        {
          params,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response?.data);
    }
  }
);

export const fetchDeliveryDates = createAsyncThunk(
  'deliveryTime/fetchDeliveryDates',
  async ({ storeId, today }, { rejectWithValue }) => {
    const [orderRes, preorderRes] = await Promise.allSettled([
      order_available(storeId, 'QUICK'),
      getReservationDate({ orderType: 'QUICK', storeCode: storeId }),
    ]);

    let dates = [];

    if (orderRes.status === 'fulfilled') {
      const { todayAvailable } = orderRes.value.data;

      if (todayAvailable) {
        dates.push({ type: 'today', value: today });
      }
    }

    if (preorderRes.status === 'fulfilled') {
      const availableDays = preorderRes.value.data.availableDays.map(
        ({ day, endDate }) => ({
          endDate,
          type: 'preorder',
          value: day,
        })
      );

      dates = dates.concat(availableDays);
    }

    return dates;
  }
);

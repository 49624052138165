import React from 'react';
import SearchContext from '../_context';

import _OptionTabs from './_tabs';
import _OptionSection from './_section';

const Container = ({ children }) => {
  return (
    <SearchContext.Consumer>
      {({ state, action }) => (
        <div className="filter-area">
          <_OptionTabs>{children}</_OptionTabs>
          <_OptionSection
            filter={state.filter}
            value={state.value}
            onSelect={action.submit}
            onChange={action.update}
          />
        </div>
      )}
    </SearchContext.Consumer>
  );
};

export { default as OptionGroup } from './_group';
export default Container;

import * as coupon_api from 'api/coupon';
import * as filter_api from 'api/search-filters';
import { handleActions } from 'redux-actions';

import createApiRequest from './_support';

const COUPONS = 'pirates-data.coupon/COUPONS.ALL';
const COUPONS_ALL = 'pirates-data.coupon/COUPONS.ALL.LIST';
const FILTER = 'pirates-data.coupon/FILTER';

const [get_coupons, COUPONS_OK, COUPONS_FAIL] = createApiRequest(
  COUPONS,
  coupon_api.get_coupons
);
const [get_coupons_all, COUPONS_ALL_OK, COUPONS_ALL_FAIL] = createApiRequest(
  COUPONS,
  coupon_api.get_coupons_all
);
const [get_filters, FILTER_OK, FILTER_FAIL] = createApiRequest(
  FILTER,
  filter_api.load_store_regions
);

const state = {
  all: {
    list: [],
    request: {},
  },
  filter: [],
  list: [],
};

const reducer = handleActions(
  {
    [COUPONS]: (state) => state,
    [COUPONS_ALL]: (state) => state,

    [COUPONS_ALL_OK]: (state, action) => {
      let tempBool = false;
      if (state.all.request.query) {
        for (const i in action.payload.config.params) {
          if (i === 'pageNum') continue;
          if (action.payload.config.params[i] !== state.all.request.query[i]) {
            tempBool = true;
            break;
          }
        }
      }
      const temp =
        !tempBool && action.payload.data.request.page !== 1
          ? state.all.list.concat()
          : [];
      for (const i in action.payload.data.list) {
        if (action.payload.data.list[i].id)
          temp.push(action.payload.data.list[i]);
      }

      return {
        ...state,
        all: {
          list: temp,
          request: {
            ...action.payload.data.request,
            nextPageNo: action.payload.data?.nextPageNo,
            query: action.payload.config.params,
            total: action.payload.data.total,
          },
        },
      };
    },
    [COUPONS_OK]: (state, action) => ({
      ...state,
      list: action.payload.list,
    }),

    [FILTER]: (state) => ({ ...state }),
    [FILTER_OK]: (state, action) => ({
      ...state,
      filter: action.payload,
    }),
  },
  state
);

const Types = {
  COUPONS,
};

export { get_coupons, get_coupons_all, get_filters, Types };

export default reducer;

import { cookieDomainParser } from '../util/cookie/cookie';
import {
  BUILD_TYPE_DEVELOPMENT,
  BUILD_TYPE_LOCAL,
  BUILD_TYPE_STAGING,
  getBuildType,
} from './build-configs';

export const getPayOrigin = () => {
  const buildType = getBuildType();
  let origin = '';
  if (buildType === BUILD_TYPE_LOCAL || buildType === BUILD_TYPE_DEVELOPMENT) {
    origin = 'https://pay.test.tpirates.com';
  } else if (buildType === BUILD_TYPE_STAGING) {
    origin = 'https://pay.stg.tpirates.com';
  } else {
    origin = 'https://pay.tpirates.com';
  }
  return origin;
};

export const getApiOrigin = () => {
  const buildType = getBuildType();
  let origin = '';
  if (buildType === BUILD_TYPE_LOCAL || buildType === BUILD_TYPE_DEVELOPMENT) {
    origin = 'https://api.test.tpirates.com';
  } else if (buildType === BUILD_TYPE_STAGING) {
    origin = 'https://api.stg.tpirates.com';
  } else {
    origin = 'https://api.tpirates.com';
  }
  return origin;
};

export const getFishMarketOrigin = () => {
  const buildType = getBuildType();
  let origin = '';
  if (buildType === BUILD_TYPE_LOCAL || buildType === BUILD_TYPE_DEVELOPMENT) {
    origin = 'https://test.tpirates.com';
  } else if (buildType === BUILD_TYPE_STAGING) {
    origin = 'https://stg.tpirates.com';
  } else {
    origin = 'https://tpirates.com';
  }
  return origin;
};

export const getLocalMarketOrigin = () => {
  const buildType = getBuildType();
  let origin = '';
  if (buildType === BUILD_TYPE_LOCAL || buildType === BUILD_TYPE_DEVELOPMENT) {
    origin = 'https://market.test.tpirates.com';
  } else if (buildType === BUILD_TYPE_STAGING) {
    origin = 'https://market.stg.tpirates.com';
  } else {
    origin = 'https://market.tpirates.com';
  }
  return origin;
};

export const getFishMarketPublicApiOrigin = (version = 'v2') => {
  const buildType = getBuildType();
  let origin = '';
  if (buildType === BUILD_TYPE_LOCAL || buildType === BUILD_TYPE_DEVELOPMENT) {
    origin = `https://pub-api.test.tpirates.com/${version}/www`;
  } else if (buildType === BUILD_TYPE_STAGING) {
    origin = `https://pub-api.stg.tpirates.com/${version}/www`;
  } else {
    origin = `https://pub-api.tpirates.com/${version}/www`;
  }
  return origin;
};

export const getLocalMarketApiOrigin = (version = 'v1') => {
  const buildType = getBuildType();
  let origin = '';
  if (buildType === BUILD_TYPE_LOCAL || buildType === BUILD_TYPE_DEVELOPMENT) {
    origin = `https://api.test.tpirates.com/${version}/market`;
  } else if (buildType === BUILD_TYPE_STAGING) {
    origin = `https://api.stg.tpirates.com/${version}/market`;
  } else {
    origin = `https://api.tpirates.com/${version}/market`;
  }
  return origin;
};

export const handleCookieDomain = (customDomain = '') => {
  if (customDomain) return cookieDomainParser(customDomain);
  const buildType = getBuildType();
  let domain = '';
  if (buildType === BUILD_TYPE_LOCAL || buildType === BUILD_TYPE_DEVELOPMENT) {
    domain = `.test.tpirates.com`;
  } else if (buildType === BUILD_TYPE_STAGING) {
    domain = '.stg.tpirates.com';
  } else {
    domain = '.tpirates.com';
  }
  return domain;
};

import client from 'api/client';
import clientV3 from 'api/clientV3';

export const load_specials = () => client.get('/www/contents');

export const get_recommends = (type) => client.get(`/www/recommend/${type}`);

export const getRecentReviews = () => clientV3.get('/www/comment/recent');

const search_home = (type, query) =>
  client.get(`/www/home-search/${type}`, { params: query });

export const count_widget = (code) =>
  client.put(`/www/widget/hit`, {}, { params: { bannerCode: code } });

export const search_home_price = (query) => search_home('price', query);

export const search_home_coupon = (query) =>
  client.get('/www/coupons/filtered', { params: query });

export const search_home_delicious = (query) => search_home('delicious', query);

export const search_home_contents = (query) => search_home('contents', query);

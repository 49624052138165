import { ToastContext } from 'context/toast';
import { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import PanelItem from './_panel-item';

const EstimatePanel = () => {
  const { showToast } = useContext(ToastContext);
  return (
    <PanelItem label="결제방법">
      <div>
        <p style={{ fontWeight: 'bold' }}>계좌이체만 가능합니다.</p>
        <CopyToClipboard text="752601-04-290349">
          <a
            className="account-link"
            href="#752601-04-290349"
            onClick={(e) => {
              e.preventDefault();
              showToast('계좌번호가 복사되었습니다.');
            }}
          >
            국민은행 752601-04-290349 (주)더파이러츠
          </a>
        </CopyToClipboard>
      </div>
    </PanelItem>
  );
};

const _Estimate = ({ delivery_charge, order_price, tax }) => {
  return (
    <>
      <PanelItem label="소계">
        <span>
          <span style={{ fontWeight: 'bold' }}>
            {order_price.toLocaleString()}원
          </span>{' '}
          {tax && (tax === 'VAT' ? '(부가세 포함)' : '(면세)')}
        </span>
      </PanelItem>
      <PanelItem label="배송비">
        <span style={{ fontWeight: 'bold' }}>
          {delivery_charge === 0
            ? '무료'
            : `${delivery_charge.toLocaleString()}원`}
        </span>
      </PanelItem>
      <PanelItem label="합계">
        <span style={{ color: '#1c79bc', fontWeight: 'bold' }}>
          {(order_price + delivery_charge).toLocaleString()}원
        </span>
      </PanelItem>
    </>
  );
};

const Estimate = ({ charge, price, quantity, tax, usePanel }) => {
  const order_price = price * (tax === 'VAT' ? 1.1 : 1);
  const delivery_charge = calc_delivery_charge(
    charge.type,
    charge.price,
    quantity
  );
  const total_price = delivery_charge + order_price;

  return (
    <section>
      {usePanel && <EstimatePanel />}
      <_Estimate
        delivery_charge={delivery_charge}
        order_price={order_price}
        tax={tax}
        total_price={total_price}
      />
    </section>
  );
};

const calc_delivery_charge = (type, price, qty) => {
  switch (type) {
    case 0: // 무료배송
      return 0;
    case 1: // 조건부 무료배송
      return 0;
    case 2: // 유료
      return price;
    case 3: // 단위당 유료
      return price * qty;
    default:
      return price * qty;
  }
};

export default Estimate;
export { _Estimate, calc_delivery_charge };

import { onCloseDialog } from 'features/dialogSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationRequest from './NotificationRequest';
import ShippingUnavailable from './ShippingUnavailable';

const DialogContainer = () => {
  const { data, type } = useSelector((state) => state.dialog);

  const dispatch = useDispatch();

  return (
    <>
      <NotificationRequest
        open={type === 'NOTIFICATION_REQUEST'}
        data={data}
        onClose={() => {
          dispatch(onCloseDialog());
        }}
      />
      <ShippingUnavailable
        open={type === 'SHIPPING_UNAVAILABLE'}
        data={data}
        onClose={() => {
          dispatch(onCloseDialog());
        }}
      />
    </>
  );
};

export default DialogContainer;

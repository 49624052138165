import React from 'react';
import { useController } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

import StyledInput from './StyledInput';

const FormInput = ({ control, name, defaultValue, ...props }) => {
  const {
    field: { value, onChange, ...field },
    fieldState: { error },
  } = useController({ control, name, defaultValue: defaultValue || '' });

  return (
    <>
      <StyledInput
        {...props}
        error={error}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        {...field}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </>
  );
};

export default FormInput;

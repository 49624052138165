import { ModalView } from 'components/views';

import ChannelView from './_channel/_view';
import { View } from './_context';
import ContractView from './_contract-view';

const Render = ({ action, bgClick, state }) => {
  const modal = state.isOpen ? 'on' : 'off';

  switch (state.view.current) {
    case View.CHANNEL:
      return (
        <ModalView
          handleClose={bgClick ? action.close : undefined}
          toggle={modal}
        >
          <ChannelView
            onClose={action.close}
            onOk={() => {
              if (state?.data?.onOk && typeof state.data.onOk === 'function') {
                state.data?.onOk();
              }
              action.close_at_login();
            }}
          />
        </ModalView>
      );
    // footer 이용약관 / 개인정보처리방침 / 위치기반서비스이용약관
    case View.CONTRACT_SERVICE_TMP:
    case View.CONTRACT_PRIVACY_TMP:
    case View.CONTRACT_LOCATION_TMP:
      return (
        <ModalView style={{ top: '16px' }} toggle={modal}>
          <ContractView
            viewType={state.view.current}
            onClose={() => action.close_contract(state.view.current)}
          />
        </ModalView>
      );
    default:
      return <ModalView toggle={modal} />;
  }
};

const _LoginView = ({ action, bgClick, state }) => {
  return <Render action={action} bgClick={bgClick} state={state} />;
};
export default _LoginView;

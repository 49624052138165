/* eslint-disable no-useless-catch */
import * as api from 'api/store';

const COOKIE = {
  RECENTLY: 'pirates-cookie/STORE.RECENTLY',
};

Array.prototype.limit = function (limit) {
  return this.slice(0, Math.min(this.length, limit));
};

async function visited_stored(target, exclude) {
  try {
    const storeCodeList = target.filter((s) => s !== exclude).limit(10);
    const sortMap = storeCodeList
      .map((code, i) => ({ [code]: i }))
      .reduce((acc, e) => ({ ...acc, ...e }), {});

    const res = await api.get_stores_recent({
      storeCodeList: storeCodeList.join(','),
    });
    return res.data.sort((prev, next) => sortMap[prev.id] - sortMap[next.id]);
  } catch (e) {
    throw e;
  }
}

export { COOKIE, visited_stored };

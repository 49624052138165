import React from 'react';

const Lock = ({ size = '20' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0653 7.99191H11.5556V4.55539C11.5556 2.04356 9.51204 0 7 0C4.48817 0 2.44461 2.04356 2.44461 4.55539V7.99191H1.93471C0.866207 7.99191 0 8.85812 0 9.92641V17.6617C0 18.5007 0.680145 19.1806 1.51888 19.1806H12.4811C13.3199 19.1806 14 18.5007 14 17.6617V9.92641C14 8.85812 13.1338 7.99191 12.0653 7.99191ZM7 15.5043C5.94066 15.5043 5.08194 14.6456 5.08194 13.5862C5.08194 12.5269 5.94066 11.6682 7 11.6682C8.05934 11.6682 8.91806 12.5269 8.91806 13.5862C8.91806 14.6456 8.05934 15.5043 7 15.5043ZM9.63754 7.99191H4.36267V4.55539C4.36267 3.10124 5.54585 1.91806 7 1.91806C8.45436 1.91806 9.63754 3.10124 9.63754 4.55539V7.99191Z"
        fill="#26282B"
      />
    </svg>
  );
};

export default Lock;
